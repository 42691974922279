import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnInit,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { DashboardDataService } from '../../services/dashboard-data.service';
import { DashboardLayout } from '../../models/DashboardConfig';
import { WidgetInstanceService } from '../../services/widget-instance.service';
import { ContextMenuItem, LoadingEntity } from '@inst-iot/bosch-angular-ui-components';
import { ToggleControlsOnHoverDirective } from './directives/toggle-controls-on-hover.directive';
import { DashboardGridService } from '../../services/dashboard-grid.service';
import { ProjectsService } from '../../../shared-projects/services/projects.service';
import { map } from 'rxjs/operators';
import { iif, Observable, of } from 'rxjs';
import { getTotalWidthOfElement } from '../../../shared/functional-utils';
import { isNil } from 'lodash-es';

@Component({
  selector: 'widget-container',
  templateUrl: './widget-container.component.html',
  styleUrls: ['./widget-container.component.scss'],
  hostDirectives: [ToggleControlsOnHoverDirective]
})
export class WidgetContainerComponent implements OnInit, AfterViewInit {
  @Input()
  widgetInstance: WidgetInstanceService;

  @Input()
  dataLoader: LoadingEntity<any>;

  @Input()
  widgetControls: TemplateRef<any>;

  @Input()
  extraControls: ContextMenuItem[];

  @Input()
  showWidgetControls = true;

  @Input()
  showWidgetTitle = true;

  @Input()
  showWidgetHeader = true;

  @Input()
  showWidgetMenu = true;

  @Input()
  titleWhitespace = 0;

  @Input()
  customCSSClasses = '';

  @Input()
  reloadableWidget = false;

  @ViewChild('widgetTitle')
  widgetTitle: ElementRef<HTMLElement>;

  @ViewChild('widgetBody') widgetBody: ElementRef<HTMLElement>;

  currentLayout: DashboardLayout;

  isWidgetReloadable: boolean;

  isWidgetMaximizable: boolean;

  isGridLayout: boolean;

  layoutCssClass: 'grid' | 'columnBased';

  /*
   * If User has Edit-Rights, Relayout and Edit Options will be visible
   * */
  userCanEdit = false;

  showLoadingStatusInTooltip$: Observable<boolean>;

  contextMenuOpen = false;

  private readonly minWidthForLoadingStatusPopoverPx = 300;

  get hasSelectableOptions() {
    return (
      this.extraControls?.length > 0 ||
      this.isWidgetMaximizable ||
      this.isWidgetReloadable ||
      this.userCanEdit
    );
  }

  constructor(
    public dashboardDataService: DashboardDataService,
    private projectService: ProjectsService,
    private dashboardGridService: DashboardGridService,
    private cdRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.currentLayout = this.dashboardDataService.currentLayout;
    this.isWidgetReloadable = this.reloadableWidget || this.getWidgetReloadableStatus();
    this.isWidgetMaximizable = this.getWidgetMaximizableStatus();
    this.isGridLayout = this.dashboardDataService.isGridLayout(this.currentLayout);
    this.layoutCssClass = this.isGridLayout ? 'grid' : 'columnBased';
    this.userCanEdit = this.projectService.hasAnyRole(['admin', 'manager', 'power_user']);
    if (
      this.dashboardDataService.isGridLayout(this.currentLayout) &&
      this.widgetInstance.widgetType.gridLayoutConfig?.hideOuterScrollbar
    ) {
      this.layoutCssClass += ' overflow-y-hidden';
    }
  }

  ngAfterViewInit() {
    this.showLoadingStatusInTooltip$ = iif(
      () => this.isGridLayout,
      this.dashboardGridService.widgetWidthChangeById$(this.widgetInstance.id).pipe(
        map((widget) => {
          const widgetTitleWidth = isNil(this.widgetTitle)
            ? 0
            : getTotalWidthOfElement(this.widgetTitle.nativeElement);
          const remainingSpace = widget.width - widgetTitleWidth;

          return this.isWidgetReloadable && this.minWidthForLoadingStatusPopoverPx > remainingSpace;
        })
      ),
      of(false)
    );

    this.cdRef.detectChanges();
  }

  private widgetContainsDataSource() {
    return !!this.widgetInstance.properties.dataSourceConfig?.sources?.length;
  }

  private getWidgetReloadableStatus() {
    if (typeof this.widgetInstance.widgetType.reloadable === 'boolean') {
      return this.widgetInstance.widgetType.reloadable;
    } else {
      return this.widgetContainsDataSource();
    }
  }

  private getWidgetMaximizableStatus() {
    return (
      this.widgetInstance.widgetType.type !== 'actionButton' &&
      this.widgetInstance.widgetType.type !== 'playback'
    );
  }
}
