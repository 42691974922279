import { Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { isEmpty } from 'lodash-es';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { triggerResizeEvent } from '../../../core/services/fullscreen.service';
import { ProjectUrlPipe } from '../../../shared-projects/pipes/project-url.pipe';
import { TranslatedPipe } from '../../../shared/pipes/translated.pipe';
import { DashboardLayout } from '../../models/DashboardConfig';
import { DashboardDataService } from '../../services/dashboard-data.service';
import {
  DashboardGridSingletonService,
  relayoutContext
} from '../../services/dashboard-grid-singleton.service';
import { WidgetInstanceService } from '../../services/widget-instance.service';
import { WidgetRemovalService } from '../../services/widget-removal.service';
import { ModalOptions, ModalService } from '@inst-iot/bosch-angular-ui-components';
import { WidgetExportInfoComponent } from '../widget-container/widget-export-info/widget-export-info.component';
import {
  DashboardAction,
  DashboardActionsService
} from '../../../shared/services/dashboard-actions.service';
import { ProjectsService } from '../../../shared-projects/services/projects.service';

@Component({
  selector: 'widget-control',
  templateUrl: './widget-control.component.html',
  styleUrls: ['./widget-control.component.scss'],
  providers: [WidgetRemovalService]
})
export class WidgetControlComponent implements OnInit, OnDestroy {
  @Input()
  layout: DashboardLayout = DashboardLayout.ColumnBased;

  @Input()
  isWidgetReloadable: boolean;

  @Input()
  isWidgetMaximizable: boolean;

  @Input()
  showWidgetEditOptions: boolean;

  dashboardLayout = DashboardLayout;
  isLoading = false;
  maximized = false;
  isAutoRefreshing = false;
  hasAutoRefreshing = false;
  insideTabWidget = false;
  isRelayoutButtonVisible: boolean;
  widgetName: string;
  relayoutContext: string;
  canEdit = false;

  readonly dashboardAction = DashboardAction;

  private destroy = new Subject<null>();
  private subscriptions: Subscription = new Subscription();
  private readonly EXPORTABLE_WIDGET_TYPES = ['basic-charts'];

  get widgetId() {
    return this.widgetInstance?.id;
  }

  setWidgetName() {
    const widgetNameFromTitle = this.translatedPipe.transform(
      this.widgetInstance.properties?.title
    );
    this.widgetName = isEmpty(widgetNameFromTitle)
      ? this.translatedPipe.transform(this.widgetInstance.widgetType.label)
      : widgetNameFromTitle;
  }

  constructor(
    private widgetInstance: WidgetInstanceService,
    private translatedPipe: TranslatedPipe,
    private widgetRemovalService: WidgetRemovalService,
    private projectUrl: ProjectUrlPipe,
    private modal: ModalService,
    private dashboardsService: DashboardDataService,
    private dashboardGridSingletonService: DashboardGridSingletonService,
    private projectsService: ProjectsService,
    public dashboardActionService: DashboardActionsService
  ) {}

  ngOnInit() {
    this.canEdit = this.projectsService.hasRoleOfCurrentProject('power_user');
    this.widgetInstance.loading.pipe(takeUntil(this.destroy)).subscribe((state) => {
      this.isLoading = state;
    });
    this.widgetInstance.maximize.pipe(takeUntil(this.destroy)).subscribe((state) => {
      this.maximized = state;
    });
    if (this.widgetInstance.hasAutoRefreshing) {
      this.hasAutoRefreshing = this.widgetInstance.hasAutoRefreshing;
      this.widgetInstance.autoRefreshing.pipe(takeUntil(this.destroy)).subscribe((state) => {
        this.isAutoRefreshing = state;
      });
    }
    this.insideTabWidget = !!this.widgetInstance?.parentWidgetInstance?.id;
    this.subscriptions.add(
      this.dashboardGridSingletonService.relayoutContext.subscribe((relayoutContext) => {
        this.isRelayoutButtonVisible = this.insideTabWidget
          ? relayoutContext !==
            this.dashboardGridSingletonService.assembleTabUniqueIdentifier(
              this.widgetInstance.parentWidgetInstance.id,
              this.widgetInstance.tabId
            )
          : relayoutContext !== 'main';
      })
    );
    if (this.layout !== DashboardLayout.ColumnBased) {
      this.relayoutContext = this.insideTabWidget
        ? this.dashboardGridSingletonService.assembleTabUniqueIdentifier(
            this.widgetInstance.parentWidgetInstance.id,
            this.widgetInstance.tabId
          )
        : 'main';
      this.subscriptions.add(
        this.dashboardActionService.saveRelayoutAction.subscribe(() => this.saveRelayout())
      );
      this.subscriptions.add(
        this.dashboardActionService.startRelayoutAction.subscribe(({ payload }) => {
          if (this.relayoutContext === payload) {
            this.relayout();
          }
        })
      );
    }

    this.setWidgetName();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
    this.destroy.next(null);
  }

  toggleAutoRefresh() {
    this.widgetInstance.autoRefreshing.next(!this.isAutoRefreshing);
  }

  openExportInfoPopup() {
    const options: ModalOptions = {
      showClose: false
    };

    const modalRef = this.modal.openComponent(WidgetExportInfoComponent, options);

    modalRef.instance.widgetId = this.widgetInstance.id;
    modalRef.instance.dashboardName = this.widgetInstance.dashboardName;
    modalRef.instance.isInsideTabWidget = this.widgetInstance.tabId !== undefined;
  }

  toggleMaximize() {
    this.widgetInstance.maximize.next(!this.maximized);
    setTimeout(() => {
      triggerResizeEvent();
    });
  }

  refresh() {
    this.widgetInstance.reload.next(null);
  }

  editWidget() {
    this.projectUrl.navigate(this.dashboardsService.getEditWidgetDashboardUrl(this.widgetId));
  }

  relayout() {
    this.dashboardGridSingletonService.activateRelayout(this.relayoutContext);
  }

  saveRelayout() {
    this.dashboardGridSingletonService.saveRelayout();
  }

  removeWidget() {
    this.widgetRemovalService.deleteWidget(this.widgetInstance.widgetType, this.widgetId);
  }

  isExportableWidget() {
    return this.EXPORTABLE_WIDGET_TYPES.includes(this.widgetInstance.widgetType?.type);
  }
}
