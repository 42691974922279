<div *ngIf="loading">
  <em class="rb-ic rb-ic-refresh" [class.fa-spin]="loading"></em>
  <span *ngIf="status === undefined">
    {{ 'widgets.actionButton.running' | translate }}
  </span>
  <span *ngIf="status !== undefined" class="hint-label align-text-top">
    {{ 'widgets.actionButton.runningCacheRefreshing' | translate }}
  </span>
</div>

<ng-container *ngIf="status !== undefined">
  <div *ngIf="!loading" class="d-flex align-items-center">
    <span
      class="rb-ic rb-1-3x mr-1"
      [ngClass]="{
        'rb-ic-alert-warning-filled red': error,
        'rb-ic-alert-success-filled green': !error && !loading
      }"
    ></span>
    <span data-cy="actionButtonSuccessState">
      <span *ngIf="status">{{ status }}:</span>
      {{ statusText }}
    </span>
  </div>
  <div *ngIf="(error || response) && responsePopoverContent" style="max-width: 50rem">
    <rb-callout class="no-margin" *ngIf="error" [type]="'error'">
      <rb-error-format
        class="response-container"
        [error]="
          error === 'whitelist' ? ('widgets.actionButton.whitelistError' | translate) : error
        "
      ></rb-error-format>
    </rb-callout>
    <div *ngIf="response" class="response-container p-1">
      <div *ngTemplateOutlet="responsePopoverContent"></div>
    </div>
  </div>
</ng-container>
