<form #settingNameForm="ngForm" (ngSubmit)="submitForm()">
  <div
    class="m-dialog"
    role="dialog"
    aria-labelledby="frontend-kit-dialog-default-non-modal-dialog-title"
  >
    <div class="a-box -floating">
      <div class="m-dialog__header">
        <div class="m-dialog__title">{{ 'downloadDialog.addNewSetting' | translate }}</div>
        <button
          (click)="cancel()"
          type="button"
          class="a-button a-button--integrated -without-label dialog-close-btn"
          aria-label="close dialog"
        >
          <span class="a-icon a-button__icon ui-ic-close" title="Close button"></span>
        </button>
      </div>
    </div>
    <hr class="a-divider" />
    <div class="m-dialog__content scrollable">
      <div class="m-dialog__body">
        <rb-form-input
          type="text"
          #nameInput
          [minlength]="3"
          [maxlength]="30"
          [(ngModel)]="name"
          csvSettingNameValidator
          required
          [label]="'downloadDialog.settingNamePlaceholder' | translate"
          name="name"
        >
          <ng-template rbFormValidationMessage="required">
            {{ 'downloadDialog.nameRequired' | translate }}
          </ng-template>
          <ng-template rbFormValidationMessage="minlength">
            {{ 'downloadDialog.nameLengthMin' | translate }}
          </ng-template>
          <ng-template rbFormValidationMessage="maxlength">
            {{ 'downloadDialog.nameLengthMax' | translate }}
          </ng-template>
          <ng-template rbFormValidationMessage="used">
            {{ 'downloadDialog.settingLabelUsed' | translate }}
          </ng-template>
        </rb-form-input>
      </div>
      <div class="m-dialog__actions">
        <button
          type="submit"
          class="rb-btn rb-primary rb-regular"
          [disabled]="!settingNameForm.valid"
        >
          {{ 'downloadDialog.save' | translate }}
        </button>
        <button type="button" class="rb-btn rb-regular" (click)="cancel()">
          {{ 'downloadDialog.cancel' | translate }}
        </button>
      </div>
    </div>
  </div>
</form>
