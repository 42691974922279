<div class="d-md-flex">
  <color-selector
    [color]="currentColor"
    (colorChange)="updateCurrentColor($event)"
    [alpha]="enableAlpha"
  ></color-selector>

  <div class="m-2" *ngIf="enableRules && paths?.length" style="min-width: 300px">
    <div class="d-flex align-items-center justify-content-between">
      <div>
        <a
          href="#"
          class="color-block"
          [style.backgroundColor]="_config.color"
          (click)="activateRule('primary')"
          [class.active]="currentColorSource === 'primary'"
        ></a>
        {{ 'colorPicker.primaryColor' | translate }}
      </div>
      <button class="rb-btn rb-tiny" (click)="addRule()" translate>colorPicker.addRule</button>
    </div>

    <div *ngFor="let rule of rules" class="d-flex align-items-center">
      <a
        href="#"
        class="color-block"
        [style.backgroundColor]="rule.color"
        [class.active]="currentColorSource === rule"
        (click)="activateRule(rule)"
      ></a>
      <condition-input
        [paths]="paths"
        [condition]="rule.cond"
        (conditionChange)="ruleChange(rule, $event)"
      ></condition-input>
      <button class="rb-btn rb-danger rb-tiny" (click)="removeRule(rule)">
        <span class="rb-ic rb-ic-close"></span>
      </button>
    </div>
  </div>
</div>
