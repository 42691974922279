<div class="row p-2">
  <div class="col-4">
    <button
      *ngFor="let button of tableButtons | slice: 0:6"
      (click)="tableAction(button.action, button.position); close()"
      type="button"
      [attr.data-cy]="'richtext-table-popover-' + button.action + '-' + button.position"
      class="rb-btn rb-link rb-dropdown-item"
    >
      <span *ngIf="button.iconClass" class="rb-ic mr-1" [ngClass]="button.iconClass"></span>
      <img [src]="button.imgSrc" [alt]="button.label | translate" />
      {{ button.label | translate }}
    </button>
  </div>

  <div class="col-4">
    <button
      *ngFor="let button of tableButtons | slice: 6:10"
      (click)="tableAction(button.action, button.position); close()"
      type="button"
      [attr.data-cy]="'richtext-table-popover-' + button.action + '-' + button.position"
      class="rb-btn rb-link rb-dropdown-item"
    >
      <span *ngIf="button.iconClass" class="rb-ic mr-1" [ngClass]="button.iconClass"></span>
      {{ button.label | translate }}
    </button>
  </div>
  <div class="col-4">
    <button
      *ngFor="let button of tableButtons | slice: 10:tableButtons.length"
      (click)="tableAction(button.action, button.position); close()"
      type="button"
      [attr.data-cy]="'richtext-table-popover-' + button.action"
      class="rb-btn rb-link rb-dropdown-item"
    >
      <span *ngIf="button.iconClass" class="rb-ic mr-1" [ngClass]="button.iconClass"></span>
      <img *ngIf="button.imgSrc" [src]="button.imgSrc" [alt]="button.label | translate" />
      {{ button.label | translate }}
    </button>
  </div>
</div>
