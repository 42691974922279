import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { PathInfo, PropertyPathType } from '../../data-selection/data-info-util';
import { FieldDefinition } from '../../dynamic-filter/dynamic-filter.model';
import { ConditionChip } from '../../query-condition-input/models/condition-chip.model';
import { InputObject } from '../../query-condition-input/models/util-objects.model';
import { UntypedFormGroup } from '@angular/forms';
import { SimpleSearchInputService } from './services/simple-search-input.service';
import { isRelative } from '@inst-iot/bosch-angular-ui-components';
import { getAbsoluteFromRelative } from '../../../shared/date-time-range-popover/date-time-range-popover.model';
import { Observable, of } from 'rxjs';
import { FieldSelectOption } from '../../../shared/filter-model/filter.model';

@Component({
  selector: 'simple-search-input',
  templateUrl: './simple-search-input.component.html',
  styleUrls: ['./simple-search-input.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SimpleSearchInputComponent implements OnChanges, AfterViewInit {
  @Input()
  activeChip: ConditionChip;

  @Input() supportedDataTypes: string[];

  @Input() set inputs(values: PathInfo[] | FieldDefinition[]) {
    this.simpleSearchInput.setInputs(values);
  }

  @Input() set chips(values: ConditionChip[]) {
    this.simpleSearchInput.setChips(values);
  }

  @Input({ required: true }) set propertyPathType(context: PropertyPathType) {
    this.simpleSearchInput.setPropertyPathType(context);
  }

  @Output()
  create = new EventEmitter<InputObject>();

  @Output()
  update = new EventEmitter<InputObject>();

  @Output()
  selected = new EventEmitter<ConditionChip>();

  @ViewChild('form') ngForm: UntypedFormGroup;

  protected readonly PropertyPathType = PropertyPathType;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    public simpleSearchInput: SimpleSearchInputService<PathInfo | FieldDefinition>
  ) {}

  @HostBinding('class') class = 'condition-input';

  get valueInputNotRequired() {
    return (
      this.simpleSearchInput.operatorInput?.technicalID === 'exists' ||
      this.simpleSearchInput.dataTypeInput === 'null' ||
      this.simpleSearchInput.dataTypeInput === null
    );
  }

  get isManualPathAndStringType() {
    return (
      this.simpleSearchInput.propertyPathType === this.PropertyPathType.MANUAL_PATH &&
      this.simpleSearchInput.dataTypeInput === 'string'
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.activeChip && changes.activeChip.currentValue) {
      this.simpleSearchInput.loadInputsFromChip(changes.activeChip.currentValue);
    } else {
      this.simpleSearchInput.resetInputs();
    }
  }

  ngAfterViewInit() {
    this.simpleSearchInput.setSupportedDataTypes(this.supportedDataTypes);
    this.changeDetectorRef.detectChanges();
  }

  handleEnterEvent() {
    if (this.ngForm.valid) {
      this.activeChip ? this.updateChip() : this.createChip();
    }
  }

  createChip() {
    const inputObject: InputObject = this.simpleSearchInput.getInputsAsObject();
    this.create.emit(inputObject);
    this.simpleSearchInput.resetInputs();
  }

  updateChip() {
    const inputObject: InputObject = this.simpleSearchInput.getInputsAsObject();
    this.update.emit(inputObject);
    this.simpleSearchInput.resetInputs();
  }

  cancelEditMode() {
    this.selected.emit();
  }

  setBooleanValue(bool: boolean) {
    this.simpleSearchInput.inputValue = bool;
  }

  updateRange(v: any) {
    if (isRelative(v)) {
      this.simpleSearchInput.inputValue = getAbsoluteFromRelative(v);
    } else {
      this.simpleSearchInput.inputValue = v;
    }
  }

  getAlreadySelectedDevicesFilter() {
    const localThingIds = this.simpleSearchInput.alreadySelectedDevices.filter((id) => !!id); // filter out initial empty
    if (localThingIds?.length) {
      return localThingIds.map((data) => 'ne(thingId,"' + data + '")').join(',');
    }
    return '';
  }

  searchSelectOptions(value: string): Observable<FieldSelectOption[]> {
    const rgx = new RegExp(`^.*${value}.*`, 'i');
    this.simpleSearchInput.filteredSelectOptions =
      this.simpleSearchInput.currentInput.selectOptions.filter((option) => rgx.test(option.label));

    return of(this.simpleSearchInput.filteredSelectOptions);
  }
}
