<form [formGroup]="form" [ngClass]="layout">
  <div *ngFor="let option of _options" class="formField">
    <ng-container *ngIf="option.hint">
      <ellipsis-text>
        <div [innerHtml]="option.hint | translate"></div>
      </ellipsis-text>
    </ng-container>
    <ng-container *ngIf="option.selectionValues && option.selectionValues.length; else directInput">
      <rb-form-custom-select
        data-cy="optionsConfigurationFormatOptions"
        [label]="option.label | translate"
        [formControlName]="option.name"
      >
        <ng-container *ngFor="let opt of option.selectionValues">
          <ng-container
            *ngIf="opt.hasOwnProperty('value') && opt.hasOwnProperty('label'); else simple"
          >
            <rb-form-select-option
              [attr.data-cy]="'optionsConfigurationFormatOption-' + opt.label"
              [value]="opt.value"
            >
              {{ opt.label | translate }}
            </rb-form-select-option>
          </ng-container>
          <ng-template #simple>
            <rb-form-select-option [value]="opt">
              {{ opt }}
            </rb-form-select-option>
          </ng-template>
        </ng-container>
      </rb-form-custom-select>
    </ng-container>
    <ng-template #directInput>
      <ng-container *ngIf="option.type === 'string'">
        <rb-form-input
          data-cy="optionsConfigurationFormInput"
          [label]="option.label | translate"
          [formControlName]="option.name"
        ></rb-form-input>
      </ng-container>
      <ng-container *ngIf="option.type === 'int' || option.type === 'decimal'">
        <rb-form-input
          [label]="option.label | translate"
          [rbMin]="option.validators?.min ?? -Infinity"
          [rbMax]="option.validators?.max ?? Infinity"
          [formControlName]="option.name"
          type="number"
          [step]="option.step"
        >
          <ng-template rbFormValidationMessage="rbMin">
            {{ 'widget.options.minValueError' | translate: { minValue: option.validators?.min } }}
          </ng-template>
          <ng-template rbFormValidationMessage="rbMax">
            {{ 'widget.options.maxValueError' | translate: { maxValue: option.validators?.max } }}
          </ng-template>
        </rb-form-input>
      </ng-container>
      <ng-container *ngIf="option.type === 'color'">
        <color-picker-input
          [label]="option.label | translate"
          [formControlName]="option.name"
        ></color-picker-input>
      </ng-container>
      <ng-container *ngIf="option.type === 'colorWithoutAlpha'">
        <color-picker-input
          [label]="option.label | translate"
          [formControlName]="option.name"
          [enableAlpha]="false"
        ></color-picker-input>
      </ng-container>
      <ng-container *ngIf="option.type === 'boolean'">
        <ellipsis-text *ngIf="option?.hint">{{ option.hint | translate }}</ellipsis-text>
        <rb-form-checkbox
          data-cy="optionsConfigurationsBoolean"
          class="no-margin-top"
          [formControlName]="option.name"
          [label]="option.label | translate"
        ></rb-form-checkbox>
      </ng-container>
      <ng-container *ngIf="option.type === 'path'">
        <data-selector-input
          data-cy="optionsConfigurationsDataSelectorInput"
          [label]="option.label | translate"
          [paths]="option.paths"
          [formControlName]="option.name"
        ></data-selector-input>
      </ng-container>
    </ng-template>
  </div>
</form>
