<div class="d-flex">
  <data-selector-input
    class="grow-1 mr-1"
    style="max-width: 150px"
    [label]="'conditionInput.path' | translate"
    [paths]="paths"
    customClass="static"
    [ngModel]="_condition.path"
    (ngModelChange)="selectPath($event)"
  ></data-selector-input>
  <rb-form-select
    class="static"
    [label]="'conditionInput.operator' | translate"
    [ngModel]="_condition.op"
    (ngModelChange)="updateOp($event)"
  >
    <option value="equals">==</option>
    <option value="neq">!=</option>
    <option value="gt">&gt;</option>
    <option value="gte">&gt;=</option>
    <option value="lt">&lt;</option>
    <option value="lte">&lt;=</option>
  </rb-form-select>
  <rb-form-input
    class="static grow-1 ml-1"
    [label]="'conditionInput.value' | translate"
    [ngModel]="_condition.value"
    (ngModelChange)="updateValue($event)"
    [ngModelOptions]="{ updateOn: 'blur' }"
  ></rb-form-input>
</div>
