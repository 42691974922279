import { Component, Input, OnInit, Optional, Self } from '@angular/core';
import { DeviceTypesService } from '../../../devices/services/device-types.service';
import { emptyFunction, LoadingEntity } from '@inst-iot/bosch-angular-ui-components';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { tap } from 'rxjs/operators';
import { BookableStatus, DeviceTypeDefinition } from '../../../devices/models/device-types';
import { DeviceTypesSelection } from '../../data-widgets/data-source-config/device-types-selection';

@Component({
  selector: 'device-type-search',
  templateUrl: './device-type-search.component.html'
})
export class DeviceTypeSearchComponent implements OnInit, ControlValueAccessor {
  @Input() onlyLinkedDeviceTypes = false;
  @Input() onlyBookableTypes = false;
  @Input() addClearButton = false;
  @Input() required = false;
  @Input() customDeviceTypes: string[] = null;
  private deviceTypeInitialId: string;

  deviceTypes: DeviceTypeDefinition[];
  deviceType: DeviceTypeDefinition = null;
  loader = new LoadingEntity<any>();
  disabled = false;
  onChange: (DeviceTypeDefinition) => void = emptyFunction;
  onTouched = emptyFunction;

  constructor(
    @Optional() @Self() public ngControl: NgControl,
    private deviceTypesService: DeviceTypesService
  ) {
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }

  ngOnInit(): void {
    this.loader
      .run(
        this.deviceTypesService.getDeviceTypes().pipe(
          tap((deviceTypes) => {
            this.deviceTypes = deviceTypes?.filter((dt) => dt.visible);

            if (this.onlyBookableTypes) {
              this.deviceTypes = this.deviceTypes?.filter(
                (dt) =>
                  dt.bookable === BookableStatus.BOOKABLE ||
                  dt.bookable === BookableStatus.BOOKABLE_WITH_CONFIRMATION
              );
            }
            if (this.onlyLinkedDeviceTypes) {
              this.deviceTypes = this.deviceTypes.filter((dt) =>
                dt.features.some((f) => f.definition === 'insights:devicelinks')
              );
            }
            if (this.customDeviceTypes) {
              this.applyDataSourceTypeFilters();
            }
            this.updateInitialValue();
          })
        )
      )
      .subscribe();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  writeValue(deviceType: string): void {
    if (deviceType === null) {
      this.deviceType = null;
    }

    this.deviceTypeInitialId = deviceType;
    this.updateInitialValue();
  }

  setDisabledState(isDisabled) {
    this.disabled = isDisabled;
  }

  updateValue(deviceType: DeviceTypeDefinition) {
    this.onChange(deviceType);
  }

  resetDeviceType() {
    this.deviceType = null;
    this.updateValue(null);
  }

  private updateInitialValue() {
    if (this.deviceTypes) {
      this.deviceType = this.deviceTypes.find((value) => value.type === this.deviceTypeInitialId);
    }
  }

  private applyDataSourceTypeFilters() {
    const filterIsRequired = !!DeviceTypesSelection.decideOnDeviceTypeFilterPresets(
      this.customDeviceTypes
    );

    if (filterIsRequired) {
      this.deviceTypes = this.deviceTypes.filter((dt) => {
        return this.customDeviceTypes.find((d) => d === dt.type);
      });
    }
  }
}
