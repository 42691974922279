import { Component, Input, OnChanges } from '@angular/core';
import { LanguagesService } from '../../../core/services/languages.service';
import { ValueViewService } from './value-view.service';
import { TableContext } from '../../configurable-charts/services/table-context';
import { getValueByPath } from '../../data-selection/data-info-util';

@Component({
  selector: 'value-view',
  templateUrl: './value-view.component.html',
  styleUrls: ['./value-view.component.scss'],
  providers: [ValueViewService]
})
export class ValueViewComponent implements OnChanges {
  values = [];

  @Input() data;

  @Input() config;

  @Input() preview;

  locale: string;

  pathError = false;
  tableContext: TableContext = null;

  constructor(private languagesService: LanguagesService, private valueService: ValueViewService) {
    this.locale = this.languagesService.currentLanguage;
  }

  ngOnChanges() {
    this.loadData();
  }
  loadData() {
    this.values = [];
    this.tableContext = new TableContext({ type: null, path: [], columns: [] }, this.locale);
    if (this.data && this.config) {
      this.data.forEach((val) => {
        this.config.forEach((conf) => {
          if (conf.path) {
            const value = { value: getValueByPath(val, conf.path) };
            conf['configErr'] = false;
            if (
              JSON.stringify(value) !== '{}' &&
              value.value !== undefined &&
              value.value !== null
            ) {
              this.values.push(Object.assign({}, value, conf));
            } else {
              this.values.push(Object.assign({}, { value: 'Null/Non-existant' }, conf));
            }
          } else {
            this.pathError = true;
          }
        });
      });
    }
  }

  checkRule(value) {
    if (value.showOnlyValue) {
      return undefined;
    }

    let isGreen = false;
    let isRed = false;

    if (value.value === 'Null/Non-existant') {
      return 'gray';
    }

    if (value.format === 'dateTime' || value.format === 'date' || value.format === 'time') {
      isRed = this.valueService.compareDates(
        value.value,
        value.rules.red.minVal,
        value.rules.red.maxVal,
        'red'
      );
      isGreen = this.valueService.compareDates(
        value.value,
        value.rules.green.minVal,
        value.rules.green.maxVal,
        'green'
      );
    } else if (
      value.format === 'trueFalse' ||
      value.format === 'yesNo' ||
      value.format === 'binary'
    ) {
      isGreen = this.valueService.compareBoolean(
        value.value,
        value.rules.green.equals,
        value.format
      );
      isRed = this.valueService.compareBoolean(value.value, value.rules.red.equals, value.format);

      if (!isRed && !isGreen) {
        return 'gray';
      }
    } else if (value.format === 'string') {
      isGreen = this.valueService.compareString(value.value, value.rules.green.equals);
      isRed = this.valueService.compareString(value.value, value.rules.red.equals);

      if (!isRed && !isGreen) {
        return 'gray';
      }
    } else {
      isGreen = this.valueService.compareNumbers(
        value.value,
        value.rules.green.minVal,
        value.rules.green.maxVal,
        'green'
      );

      isRed = this.valueService.compareNumbers(
        value.value,
        value.rules.red.minVal,
        value.rules.red.maxVal,
        'red'
      );
    }

    if (isRed) {
      return 'red';
    }

    if (isGreen) {
      return 'green';
    }

    if (!isGreen && !isRed) {
      return 'yellow';
    }

    return undefined;
  }

  formatValue(value) {
    return this.tableContext.formatRawOutputValue(value.value, value.dataType, value.format, {
      thousandsSeparator: value.thousands
    });
  }
}
