import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ConditionChip } from '../../query-condition-input/models/condition-chip.model';
import { TranslateService } from '@ngx-translate/core';
import { DatePipe } from '../../../shared/pipes/date.pipe';

@Component({
  selector: 'simple-search-chip',
  templateUrl: './simple-search-chip.component.html'
})
export class SimpleSearchChipComponent {
  @Input()
  showLabels: boolean;

  @Input()
  chips: ConditionChip[] = [];

  @Input()
  activeChip: ConditionChip;

  @Output()
  remove = new EventEmitter<ConditionChip>();

  @Output()
  selected = new EventEmitter<ConditionChip>();

  removeChip(chip) {
    this.remove.emit(chip);
  }

  selectChip(chipSelection: ConditionChip) {
    if (this.clickedOnActiveChip(chipSelection)) {
      this.selected.emit();
    } else {
      this.selected.emit(chipSelection);
    }
  }

  clickedOnActiveChip(chip: ConditionChip): boolean {
    return chip === this.activeChip;
  }
}
