import { Component, Inject, Input, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ProjectsService } from '../../../../shared-projects/services/projects.service';
import { ClipboardService } from '../../../../shared/services/clipboard.service';

@Component({
  selector: 'widget-export-info',
  templateUrl: './widget-export-info.component.html',
  providers: [ClipboardService]
})
export class WidgetExportInfoComponent implements OnInit {
  @Input() widgetId: string;
  @Input() projectName: string;
  @Input() dashboardName: string;
  @Input() isInsideTabWidget: boolean;

  readonly aceOptions = {
    minLines: 10,
    wrap: true,
    maxLines: 32
  };

  dashboardUrl: string;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    public clipboardService: ClipboardService,
    private projectsService: ProjectsService
  ) {}

  ngOnInit() {
    this.initializeDashboardUrlAndQueryParams();
  }

  private initializeDashboardUrlAndQueryParams() {
    const baseUrl = `${location.protocol}//${location.host}`;
    const baseDashboardUrl = `${baseUrl}/widget-export-service/v1/${this.projectsService.projectName}/${this.dashboardName}/${this.widgetId}${this.document.location.search}`;
    this.dashboardUrl = this.isInsideTabWidget
      ? `${baseDashboardUrl}?exportTabWidget=true`
      : baseDashboardUrl;
  }
}
