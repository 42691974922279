<rb-dialog
  [dialogTitle]="'widget.exportInfo.title' | translate"
  cancelButtonLabel="{{ 'widget.exportInfo.closeWindow' | translate }}"
>
  <rb-callout [type]="'info'">
    <span>
      {{ 'widget.exportInfo.infoMessage' | translate }}
    </span>
  </rb-callout>

  <h4>
    {{ 'widget.exportInfo.url' | translate }}
  </h4>

  <p class="d-flex">
    <span style="word-break: break-all">
      {{ dashboardUrl }}
    </span>

    <a
      class="rb-ic rb-ic-lg rb-ic-copy ml-1"
      href="#"
      title="{{ 'copyToClipboard' | translate }}"
      [rbPopover]="'copied' | translate"
      [position]="'top'"
      [closePopoverAfter]="1000"
      (click)="clipboardService.copyToClipboard(dashboardUrl)"
    ></a>
  </p>

  <h4>
    {{ 'widget.exportInfo.headers' | translate }}
  </h4>

  <pre class="m-0">
Content-Type: application/json
Authorization: Basic base64(username:password)
Accept-Language: en
X-Timezone: Europe/Berlin
X-TimeFormat: 24
X-Units: metric
X-height: 480
X-width: 640
X-exclude-title: false
  </pre>

  <p class="mb-1">
    <span class="rb-ic rb-ic-info mr-1"></span>
    <span class="hint-label">
      {{ 'widget.exportInfo.userSettingsHint' | translate }}
    </span>
  </p>
</rb-dialog>
