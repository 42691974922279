import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'popoverContent'
})
export class PopoverContentPipe implements PipeTransform {
  transform(element: any, readonly: boolean): string | null {
    if (!readonly) {
      return null;
    }

    // Check if element or element.children is null/undefined
    if (!element || !Array.isArray(element.children)) {
      return null;
    }

    const showInPopoverIndex = element.children.findIndex(
      (child) => typeof child === 'object' && child !== null && 'showInPopover' in child
    );

    // Check if showInPopover property was found
    if (showInPopoverIndex === -1) {
      return null;
    }

    // Ensure the child with showInPopover has a 'fullText' property
    const childWithPopover = element.children[showInPopoverIndex];
    if ('fullText' in childWithPopover && typeof childWithPopover.fullText === 'string') {
      return childWithPopover.fullText;
    }

    return null;
  }
}
