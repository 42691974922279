import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  Renderer2,
  ViewChild
} from '@angular/core';
import { BaseRange, Editor, Range, Transforms } from 'slate';
import { AngularEditor } from 'slate-angular';
import { PathInfo } from '../../../../shared-modules/data-selection/data-info-util';
import { insertReference } from '../../plugins/reference';
import {
  DataFormattingInputType,
  ValueFormattingBaseConfig
} from '../../../../shared-modules/data-conditional-formatting/data-conditional-formatting.model';
import { DsParameter } from '../../../../shared-modules/data-widgets/data-widgets-common';

@Component({
  selector: 'reference-suggestion-list',
  templateUrl: 'reference-suggestion-list.component.html',
  styleUrls: ['reference-suggestion-list.component.scss']
})
export class ReferenceSuggestionListComponent {
  @Input() editor: AngularEditor;
  private _paths: PathInfo[];

  @ViewChild('suggestionList', { static: true })
  suggestionList: ElementRef;
  @ViewChild('listWrapper', { static: false }) listWrapper: ElementRef;

  searchText = '';
  suggestions: string[] = [];
  target: Range;
  activeIndex = 0;
  trigger = '${';
  previousChar;

  constructor(private renderer2: Renderer2, private cdr: ChangeDetectorRef) {}

  @Input() set paths(val: PathInfo[]) {
    this._paths = val;
    if (this._paths) {
      this.updateSuggestionsLocation();
    }
  }

  showPopover(selection: BaseRange) {
    this.target = {
      anchor: {
        ...Editor.before(this.editor, selection.anchor),
        offset: Editor.before(this.editor, selection.anchor).offset - 1
      },
      focus: selection.focus
    };
    this.searchText = '';
    this.activeIndex = 0;
    this.updateSuggestionsLocation();
  }

  checkForReferenceTrigger() {
    const { selection, operations } = this.editor;
    if (!operations.length) {
      return;
    }
    const operation = operations[0];
    const isDesiredSyntaxTyped =
      operation.type === 'insert_text' &&
      (operation.text === this.trigger || `${this.previousChar}${operation.text}` === this.trigger);
    if (isDesiredSyntaxTyped) {
      this.showPopover(selection);
      return;
    }
    if (operation.type === 'insert_text') {
      this.previousChar = operation['text'];
    }

    if (selection && Range.isCollapsed(selection) && this.target) {
      const beforeRange = Editor.range(this.editor, this.target.anchor, selection.focus);
      const beforeText = Editor.string(this.editor, beforeRange);
      const beforeMatch = beforeText && beforeText.match(/^\${(\w*)$/);
      if (beforeMatch) {
        this.updateSearch(beforeText.slice(2));
        return;
      }
    }

    if (this.target) {
      this.target = null;
      this.updateSuggestionsLocation();
    }
  }

  mousedown(event: MouseEvent, item) {
    event.preventDefault();
    this.removeSearchText();
    insertReference(
      this.editor,
      item,
      this.initializeValueFormatting() as ValueFormattingBaseConfig<DsParameter>
    );
  }

  onKeydown = (event: KeyboardEvent) => {
    if (!this.target) {
      return;
    }
    switch (event.key) {
      case 'ArrowDown':
        event.preventDefault();
        if (this.activeIndex === this.suggestions.length - 1) {
          this.activeIndex = 0;
        } else {
          this.activeIndex++;
        }
        this.cdr.detectChanges();
        break;
      case 'ArrowUp':
        event.preventDefault();
        if (this.activeIndex === 0) {
          this.activeIndex = this.suggestions.length - 1;
        } else {
          this.activeIndex--;
        }
        this.cdr.detectChanges();
        break;
      case 'Tab':
      case 'Enter':
        event.preventDefault();
        this.removeSearchText();
        insertReference(
          this.editor,
          this.suggestions[this.activeIndex],
          this.initializeValueFormatting() as ValueFormattingBaseConfig<DsParameter>
        );
        this.cdr.detectChanges();
        break;
      case 'Escape':
        event.preventDefault();
        this.target = null;
        this.updateSuggestionsLocation();
        this.cdr.detectChanges();
        break;
    }
  };

  private updateSuggestionsLocation() {
    this.suggestions = this._paths
      .filter(({ path }) => {
        return path.toLowerCase().includes(this.searchText.toLowerCase());
      })
      .map((path) => path.path);
    if (this.target && this.suggestions.length) {
      const nativeRange = AngularEditor.toDOMRange(this.editor, this.target);
      const rect = nativeRange.getBoundingClientRect();
      const wrapper = this.listWrapper.nativeElement.getBoundingClientRect();

      this.renderer2.setStyle(
        this.suggestionList.nativeElement,
        'left',
        `${rect.left - wrapper.left}px`
      );
      this.renderer2.setStyle(
        this.suggestionList.nativeElement,
        'top',
        `${rect.top - wrapper.top + 25}px`
      );
      return;
    }
    this.renderer2.removeStyle(this.suggestionList.nativeElement, 'left');
    this.renderer2.removeStyle(this.suggestionList.nativeElement, 'top');
  }

  private removeSearchText() {
    const range = {
      anchor: {
        path: this.editor.selection.anchor.path,
        offset: this.editor.selection.anchor.offset - this.searchText.length - 2
      },
      focus: this.editor.selection.focus
    };
    Transforms.select(this.editor, range);
    Transforms.delete(this.editor);
  }

  private updateSearch(newSearchStr: string) {
    this.searchText = newSearchStr;
    this.activeIndex = 0;
    this.updateSuggestionsLocation();
  }

  private initializeValueFormatting(): Partial<ValueFormattingBaseConfig<DsParameter>> {
    return {
      dataType: DataFormattingInputType.STRING,
      automaticPadding: true
    };
  }
}
