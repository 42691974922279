import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Component, forwardRef, Input, OnInit, TemplateRef } from '@angular/core';
import { emptyFunction } from '@inst-iot/bosch-angular-ui-components';
import { PathInfo } from '../data-selection/data-info-util';
import { FilterParameterConfig } from '../../dashboards/widgets/filter-widget/filter-widget.model';
import moment from 'moment';
import {
  ArrayValueSeparatorType,
  DataFormattingInputType,
  DateFormatType,
  TimeFormat,
  ValueFormattingBaseConfig,
  ValueFormattingBooleanConfig,
  ValueFormattingDatetimeConfig,
  ValueFormattingJsonConfig,
  ValueFormattingNumberConfig,
  ValueFormattingStringConfig
} from '../data-conditional-formatting/data-conditional-formatting.model';
import { translate } from '../../shared/translation-util';
import { preDefinedFormats } from '../../shared/date-pipe-util';
import { DsParameter } from '../data-widgets/data-widgets-common';
import { FieldConfig } from '../../dashboards/widgets/input-widget/models/input.model';

@Component({
  selector: 'value-formatting-component',
  templateUrl: './value-formatting.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ValueFormattingComponent),
      multi: true
    }
  ]
})
export class ValueFormattingComponent implements ControlValueAccessor, OnInit {
  viewModelBaseConfig: ValueFormattingBaseConfig<DsParameter>;
  valueFormattingBoolean: ValueFormattingBooleanConfig<DsParameter>;
  valueFormattingNumber: ValueFormattingNumberConfig<DsParameter>;
  valueFormattingString: ValueFormattingStringConfig<DsParameter>;
  valueFormattingDatetime: ValueFormattingDatetimeConfig<DsParameter>;
  valueFormattingJson: ValueFormattingJsonConfig<DsParameter>;

  @Input() filterParameters: FilterParameterConfig[] = [];
  @Input() inputParameters: FieldConfig[] = [];

  @Input() dataType: DataFormattingInputType;

  @Input() label: string | TemplateRef<any> = null;

  @Input() paths: PathInfo[];

  @Input() hideWrapText = false;

  @Input() automaticPadding = true;

  disabled: boolean;

  dataFormattingInputType = DataFormattingInputType;

  valueChanged = false;

  onChange = emptyFunction;
  onTouched = emptyFunction;

  selectTypesList: { value: string; label: string }[] = [
    {
      value: DataFormattingInputType.BOOLEAN,
      label: translate('input.booleanLabel')
    },
    {
      value: DataFormattingInputType.NUMBER,
      label: translate('valueFormatting.number')
    },
    {
      value: DataFormattingInputType.STRING,
      label: translate('valueFormatting.string')
    },
    {
      value: DataFormattingInputType.DATETIME,
      label: translate('valueFormatting.datetime')
    },
    {
      value: DataFormattingInputType.JSON,
      label: translate('valueFormatting.json')
    }
  ];

  arrayValueSeparatorList: { value: string; label: string }[] = [
    {
      value: ArrayValueSeparatorType.LINE_BREAK,
      label: translate('valueFormatting.lineBreakSeparator')
    },
    {
      value: ArrayValueSeparatorType.COMMA,
      label: translate('valueFormatting.commaSeparator')
    },
    {
      value: ArrayValueSeparatorType.SEMICOLON,
      label: translate('valueFormatting.semicolonSeparator')
    },
    {
      value: ArrayValueSeparatorType.WHITESPACE,
      label: translate('valueFormatting.whitespaceSeparator')
    },
    {
      value: ArrayValueSeparatorType.LIST_BASED,
      label: translate('valueFormatting.listBasedSeparator')
    }
  ];
  selectionType: DataFormattingInputType = DataFormattingInputType.STRING;

  decimalPoints: number[] = [0, 1, 2, 3, 4, 5, 6, 7];

  timeFormats: TimeFormat[] = [
    { value: DateFormatType.NONE, label: DateFormatType.NONE },
    { value: DateFormatType.MEDIUM, label: moment().format(preDefinedFormats.medium) },
    { value: DateFormatType.SHORT, label: moment().format(preDefinedFormats.short) },
    { value: DateFormatType.TIMESTAMP, label: moment().format(preDefinedFormats.timeStamp) },
    { value: DateFormatType.LONG_DATE, label: moment().format(preDefinedFormats.longDate) },
    {
      value: DateFormatType.MEDIUM_DATE,
      label: moment().format(preDefinedFormats.mediumDate)
    },
    {
      value: DateFormatType.SHORT_DATE,
      label: moment().format(preDefinedFormats.shortDate)
    },
    {
      value: DateFormatType.MEDIUM_TIME,
      label: moment().format(preDefinedFormats.mediumTime)
    },
    {
      value: DateFormatType.SHORT_TIME,
      label: moment().format(preDefinedFormats.shortTime)
    },
    {
      value: DateFormatType.LOG_TIMESTAMP,
      label: moment().format(preDefinedFormats.logTimeStamp)
    },
    { value: DateFormatType.LONG, label: moment().format(preDefinedFormats.long) },
    { value: DateFormatType.WEEKDAY, label: moment().format(preDefinedFormats.weekDay) },
    { value: DateFormatType.ISO, label: `${moment().toISOString()} (ISO 8601)` },
    {
      value: DateFormatType.MEDIUM_SECONDS,
      label: moment().format(preDefinedFormats.mediumSeconds)
    },
    {
      value: DateFormatType.MEDIUM_MS,
      label: moment().format(preDefinedFormats.mediumMs)
    },
    {
      value: DateFormatType.SHORT_SECONDS,
      label: moment().format(preDefinedFormats.shortSeconds)
    },
    {
      value: DateFormatType.SHORT_MS,
      label: moment().format(preDefinedFormats.shortMs)
    },
    { value: DateFormatType.RELATIVE, label: DateFormatType.RELATIVE }
  ];

  ngOnInit() {
    this.viewModelBaseConfig = this.setViewModelBaseConfig();
    this.valueFormattingBoolean = this.initializeValueFormattingBoolean();
    this.valueFormattingNumber = this.initializeValueFormattingNumber();
    this.valueFormattingString = this.initializeValueFormattingString();
    this.valueFormattingDatetime = this.initializeValueFormattingDateTime();
    this.valueFormattingJson = this.initializeValueFormattingJson();
  }

  writeValue(obj: any): void {
    if (obj) {
      this.selectionType = obj.dataType || DataFormattingInputType.STRING;
      this.viewModelBaseConfig = {
        ...this.setViewModelBaseConfig(),
        ...obj
      };
      if (this.selectionType === this.dataFormattingInputType.BOOLEAN) {
        this.valueFormattingBoolean = {
          ...this.viewModelBaseConfig,
          ...obj
        };
      } else if (this.selectionType === this.dataFormattingInputType.STRING) {
        this.valueFormattingString = {
          ...this.viewModelBaseConfig,
          ...obj
        };
      } else if (this.selectionType === this.dataFormattingInputType.NUMBER) {
        this.valueFormattingNumber = {
          ...this.viewModelBaseConfig,
          ...obj
        };
      } else if (this.selectionType === this.dataFormattingInputType.DATETIME) {
        this.valueFormattingDatetime = {
          ...this.viewModelBaseConfig,
          ...obj
        };
      } else if (this.selectionType === this.dataFormattingInputType.JSON) {
        this.valueFormattingJson = {
          ...this.viewModelBaseConfig,
          ...obj
        };
      }
    }
  }

  registerOnChange(fn: () => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  setViewModelBaseConfig(): ValueFormattingBaseConfig<DsParameter> {
    if (this.valueChanged) {
      return this.viewModelBaseConfig;
    } else {
      return {
        dataType: DataFormattingInputType.STRING,
        multipleValues: false,
        arrayValueSeparator: ArrayValueSeparatorType.LINE_BREAK,
        prefix: {
          value: ''
        },
        suffix: {
          value: ''
        },
        hidden: false,
        backgroundColor: {
          staticColor: {
            value: ''
          }
        },
        textColor: {
          staticColor: {
            value: ''
          }
        },
        automaticPadding: this.automaticPadding
      };
    }
  }

  initializeValueFormattingBoolean(): ValueFormattingBooleanConfig<DsParameter> {
    return {
      ...this.viewModelBaseConfig,
      dataType: DataFormattingInputType.BOOLEAN,
      trueText: null,
      falseText: null
    };
  }

  initializeValueFormattingString(): ValueFormattingStringConfig<DsParameter> {
    return {
      ...this.viewModelBaseConfig,
      dataType: DataFormattingInputType.STRING,
      ...(this.hideWrapText ? {} : { wrapText: false })
    };
  }

  initializeValueFormattingNumber(): ValueFormattingNumberConfig<DsParameter> {
    return {
      ...this.viewModelBaseConfig,
      dataType: DataFormattingInputType.NUMBER,
      thousandsSeparator: false,
      decimalPoints: 2
    };
  }

  initializeValueFormattingDateTime(): ValueFormattingDatetimeConfig<DsParameter> {
    return {
      ...this.viewModelBaseConfig,
      dataType: DataFormattingInputType.DATETIME,
      dateFormat: {
        value: DateFormatType.MEDIUM,
        label: 'lll'
      },
      convertTimezone: false
    };
  }

  initializeValueFormattingJson(): ValueFormattingJsonConfig<DsParameter> {
    return {
      ...this.viewModelBaseConfig,
      dataType: DataFormattingInputType.JSON,
      autoFormat: null,
      jsonEditorMaxLines: 5,
      showInPopover: null
    };
  }

  updateSelectionType(type: DataFormattingInputType) {
    switch (type) {
      case DataFormattingInputType.BOOLEAN:
        this.viewModelBaseConfig = this.valueFormattingBoolean;
        break;
      case DataFormattingInputType.STRING:
        this.viewModelBaseConfig = this.valueFormattingString;
        break;
      case DataFormattingInputType.DATETIME:
        this.viewModelBaseConfig = this.valueFormattingDatetime;
        break;
      case DataFormattingInputType.NUMBER:
        this.viewModelBaseConfig = this.valueFormattingNumber;
        break;
      case DataFormattingInputType.JSON:
        this.viewModelBaseConfig = this.valueFormattingJson;
        break;
    }
    this.onChange(this.viewModelBaseConfig);
  }

  updateValue(value?): void {
    let multiTypesViewModel: ValueFormattingBaseConfig<DsParameter> = null;
    if (value) {
      this.valueChanged = true;
      multiTypesViewModel = {
        ...this.viewModelBaseConfig,
        ...value
      };
      this.viewModelBaseConfig = multiTypesViewModel;
      this.setViewModelBaseConfig();
      this.onChange(multiTypesViewModel);
    }
  }
}
