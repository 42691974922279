import { traverse, generate, NodePath } from 'regexp-tree';
import { AstRegExp, CapturingGroup } from 'regexp-tree/ast';

export interface RegexMatch {
  group: number;
  text: string;
  row: number;
  col: number;
}

export interface GroupInfo {
  number: number;
  name: string;
  text: string;
  start: number;
  end: number;
  parentNumber?: number;
}

export function identifyMatches(text, tree: AstRegExp): RegexMatch[] {
  const matches: RegexMatch[] = [];
  const regexp = tree.loc.source;
  const lastSlash = regexp.lastIndexOf('/');
  const regex = regexp.substring(1, lastSlash);
  const matcher = new RegExp(regex);

  const groups = getGroupsWithParent(tree);

  const rows = text.split('\n');
  for (let r = 0; r < rows.length; r++) {
    const result = matcher.exec(rows[r]);
    if (!result) {
      continue;
    }
    const matchText = result[0];
    let offset = 0;
    for (let i = 1; i < result.length; i++) {
      // iterate the group matches
      const grpText = result[i];
      const grpInfo = groups[i - 1];
      let index;
      if (!grpInfo.parentNumber) {
        // matched string occurs multiple times in search string, position of match can therefore not be determined
        if (matchText.substring(offset).match(new RegExp(grpText, 'g')).length > 1) {
          matches.push({
            group: -1,
            text: rows[r],
            row: r,
            col: 0
          });
          break;
        }
        index = matchText.indexOf(grpText, offset);
        offset = index;
        matches.push({
          group: i,
          text: grpText,
          row: r,
          col: result.index + index
        });
        offset += matches[matches.length - 1].text.length;
      }
    }
  }
  return matches;
}

function findLastWithParentNumber(groups: GroupInfo[], index: number, parentNumber: number) {
  for (let i = index; i >= 0; i--) {
    if (groups[i].parentNumber === parentNumber) {
      return groups[i];
    }
  }
  return null;
}

export function getGroups(tree: AstRegExp): GroupInfo[] {
  const groups: GroupInfo[] = [];
  traverse(tree, {
    Group: {
      pre: ({ node }) => {
        if (node.capturing) {
          const groupInfo = {
            number: node.number,
            name: node.name,
            text: generate(node),
            start: (node.loc.start as any).offset,
            end: (node.loc.end as any).offset
          };
          groups.push(groupInfo);
        }
      }
    }
  });
  return groups;
}

export function getGroupsWithParent(tree: AstRegExp): GroupInfo[] {
  const groups: GroupInfo[] = [];
  traverse(tree, {
    Group: {
      pre: (ast) => {
        const node = ast.node;
        if (node.capturing) {
          const parentGroup = getParentGroup(ast.parentPath);
          const groupInfo = {
            number: node.number,
            name: node.name,
            text: generate(node),
            start: (node.loc.start as any).offset,
            end: (node.loc.end as any).offset,
            parentNumber: parentGroup ? parentGroup.number : null
          };
          groups.push(groupInfo);
        }
      }
    }
  });
  return groups;
}

function getParentGroup(np: NodePath): CapturingGroup | null {
  if (np && np.node.type === 'Group' && np.node.capturing) {
    return np.node;
  } else if (np && np.parentPath) {
    return getParentGroup(np.parentPath);
  }
  return null;
}
