<rb-form-custom-select
  name="deviceType"
  [label]="'deviceTypeSearch.label' | translate"
  (ngModelChange)="updateValue($event)"
  (blur)="onTouched()"
  [disabled]="disabled"
  [(ngModel)]="deviceType"
  [required]="required"
  [selectionTpl]="typeSelection"
>
  {{ deviceType ? (deviceType.label | translated) : '' }}
</rb-form-custom-select>

<ng-template #typeSelection let-select="select">
  <button
    *ngIf="addClearButton && (deviceTypes.length || deviceType)"
    class="rb-btn rb-link rb-dropdown-item"
    [class.active]="deviceType === null"
    (click)="resetDeviceType()"
  >
    {{ 'widget.dataSource.clear' | translate }}
  </button>
  <div *ngIf="deviceTypes?.length === 0" class="rb-dropdown-item disabled">
    {{ 'deviceTypeSearch.noTypesAvailable' | translate }}
  </div>
  <button
    type="button"
    *ngFor="let dt of deviceTypes"
    class="rb-btn rb-link rb-dropdown-item"
    (click)="select(dt)"
    [class.active]="deviceType === dt"
  >
    {{ dt.label | translated }}
  </button>
</ng-template>
<ng-container *ngIf="loader.error">
  <div *rbLoading="loader"></div>
</ng-container>
