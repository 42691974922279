<h1 class="mt-3">{{ 'widget.dashboard.dashboards' | translate }}</h1>

<ng-container *rbLoading="dashboards">
  <ng-container *ngIf="dashboards.result?.length">
    <div *ngFor="let dc of dashboards.result" class="mb-3">
      <div class="d-flex justify-content-between align-items-center">
        <div>
          <strong>{{ dc.name }}</strong>
          <br />
          <span>{{ dc.label }}</span>
        </div>
        <button
          id="dashboard-selection_{{ dc.name }}"
          class="btn btn-primary ml-3"
          type="button"
          (click)="selectDashboard(dc.name)"
        >
          {{ 'widget.dashboard.select' | translate }}
        </button>
      </div>
      <hr />
    </div>
  </ng-container>
  <ng-container *ngIf="!dashboards.result?.length">
    <centered-message>{{ 'widget.dashboard.noDashboardsAvailable' | translate }}</centered-message>
  </ng-container>
</ng-container>
