<div class="p-2">
  <h5>{{ 'collectionSelector.categories.collectionCategories' | translate }}</h5>
  <div class="d-flex align-items-center mb-2">
    <span class="rb-ic rb-ic-lg rb-ic-document mr-2"></span>
    <span>{{ 'collectionSelector.customData' | translate }}</span>
  </div>
  <p>{{ 'collectionSelector.categories.processedDataExplanation' | translate }}</p>
  <div class="d-flex align-items-center mb-2">
    <span class="rb-ic rb-ic-lg rb-ic-clock mr-2"></span>
    <span>{{ 'collectionSelector.timeseries' | translate }}</span>
  </div>
  <p>{{ 'collectionSelector.categories.timeSeriesExplanation' | translate }}</p>
  <div class="d-flex align-items-center mb-2">
    <span class="rb-ic rb-ic-lg rb-ic-history mr-2"></span>
    <span>{{ 'collectionSelector.historyAndLogs' | translate }}</span>
  </div>
  <p>{{ 'collectionSelector.categories.historyAndLogsExplanation' | translate }}</p>
  <div class="d-flex align-items-center mb-2">
    <span class="rb-ic rb-ic-lg rb-ic-server mr-2"></span>
    <span>{{ 'collectionSelector.internal' | translate }}</span>
  </div>
  <p>{{ 'collectionSelector.categories.systemInternalExplanation' | translate }}</p>
</div>
