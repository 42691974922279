import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { LoadingEntity } from '@inst-iot/bosch-angular-ui-components';
import { isEmpty } from 'lodash-es';
import { LocalStorageService } from 'ngx-localstorage';
import { CollectionOverview } from '../../shared/api-model';
import { PropertyPathType, SearchInput } from '../data-selection/data-info-util';
import { FilterValues } from '../dynamic-filter/dynamic-filter.model';
import { ConditionChip } from '../query-condition-input/models/condition-chip.model';
import { SyntaxNode } from '../query-condition-input/models/syntax-node.model';
import { InputObject } from '../query-condition-input/models/util-objects.model';
import { QueryConditionBase } from '../query-condition-input/query-condition-base';
import {
  assembleFindQuery,
  chipsToQueryParams
} from '../query-condition-input/utils/query-assembler.util';
import { checkSyntax } from '../query-condition-input/utils/syntax-error-handler.util';
import {
  parseInput,
  typifySyntaxNodes
} from '../query-condition-input/utils/syntax-tree-builder.util';
import { SimpleSearchEditorComponent } from './simple-search-editor/simple-search-editor.component';

type ChangeEvent = 'DELETE' | 'INSERT' | 'UPDATE' | 'INPUT';

@Component({
  selector: 'simple-search',
  templateUrl: './simple-search.component.html',
  styleUrls: ['./simple-search.component.scss']
})
export class SimpleSearchComponent extends QueryConditionBase implements OnInit, OnDestroy {
  queryLoader = new LoadingEntity<any>();

  protected readonly PropertyPathType = PropertyPathType;

  @Input() hideSimpleSearchEditor = false;

  @Input()
  disabled = false;

  @Input()
  defaultJoiner: 'and' | 'or' = 'and';

  @Input()
  searchInput: SearchInput;

  @Input()
  storageKey: string;

  @Input()
  collection?: CollectionOverview;

  @Input()
  showSearchButton = true;

  @Input()
  initialFilterParams: string;

  /**
   * Set to true if queryChange should always emit on init (even if query is empty).
   * Useful for components that reload data on query changes to allow a single trigger for the initial load.
   * (would otherwise trigger twice if query is not empty and loading is kept in onInit of parent component  as well)
   */
  @Input()
  emitOnInit = false;

  @Output()
  queryChange = new EventEmitter<Record<string, any>>();

  /**
   * @deprecated Use the queryChange output instead, as this output will be removed in INS-6468.
   */
  @Output()
  valueChange = new EventEmitter<FilterValues>();

  // will emit even when search/apply button is not clicked
  @Output()
  pendingChange = new EventEmitter<{ params: Record<string, string>; query: string }>();

  @ViewChild(SimpleSearchEditorComponent)
  simpleSearchEditor: SimpleSearchEditorComponent;

  queryChanged = true;

  get chipsAreEmpty() {
    return !this.chips.length;
  }

  get showSimpleSearchEditor(): boolean {
    return (
      (this.chips.length > 1 || !!this.syntaxErrors.length || !this.syntaxTree.childNodes.length) &&
      !this.hideSimpleSearchEditor
    );
  }

  get loadFromLocalStorage() {
    return !!this.localStorageService.get(this.storageKey);
  }

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private localStorageService: LocalStorageService
  ) {
    super();
  }

  ngOnInit(): void {
    this.queryParams = this.getValidChipParams(this.route.snapshot.queryParams);
    if (this.initialFilterParams) {
      this.loadChipsFromInitialParams();
    } else if (Object.keys(this.queryParams)?.length >= 1) {
      this.loadChipsFromQueryParams();
    } else if (this.loadFromLocalStorage) {
      this.loadChipsFromLocalStorage();
    } else if (this.emitOnInit) {
      this.queryChange.emit();
    }
  }
  ngOnDestroy(): void {
    this.queryLoader.complete();
  }

  loadChipsFromQueryParams() {
    if (this.searchInput?.type === 'filter') {
      this.convertFilterQueryParamsToChips();
    } else {
      this.convertPathParamsToChips();
    }

    this.parseSyntaxTree();
    this.buildQueryStatement();
  }

  updateSimpleSearchEditor(changeEvent?: ChangeEvent, input?: ConditionChip | string) {
    this.queryChanged = true;

    if (this.simpleSearchEditor) {
      this.updateSimpleLogic(changeEvent, input);
    } else {
      this.initSimpleLogic();
    }

    this.resetSyntax();
    this.parseSyntaxTree();
  }

  parseSyntaxTree() {
    this.syntaxTree = parseInput(this.chips, this.syntaxErrors, this.simpleLogic || '');
  }

  updateSimpleLogic(changeEvent: ChangeEvent, input: ConditionChip | string) {
    if (changeEvent === 'INPUT') {
      this.simpleLogic = input as string;
      return;
    }

    const chip = input as ConditionChip;
    const logic = this.simpleSearchEditor.simpleLogicInput.nativeElement.textContent;

    this.validateChangeEvent(changeEvent, chip, logic);
  }

  buildQueryStatement(ignoreQueryAndLocalStorage = false) {
    const query = assembleFindQuery(this.syntaxTree);
    if (!this.simpleLogic) {
      this.initSimpleLogic();
    }
    if (!ignoreQueryAndLocalStorage) {
      this.router.navigate(['.'], {
        relativeTo: this.route,
        queryParams: chipsToQueryParams(
          this.chips,
          this.simpleLogic,
          this.collection?.name
        ).toParams()
      });
      this.updateLocalStorage();
    }

    this.emitChangeValue(this.getSimpleSearchValues());
    this.queryChange.emit(query);
    this.queryChanged = false;
  }

  clearSimpleSearch() {
    this.chips = [];
    this.simpleLogic = '';
    this.selectChip();
    this.resetSyntax();
    this.resetQueryParams();
    this.updateLocalStorage(true);
    this.emitChangeValue({});
    this.queryChange.emit();
  }

  updateLocalStorage(clearStorage = false) {
    if (this.storageKey) {
      if (clearStorage) {
        this.localStorageService.remove(this.storageKey);
      } else {
        this.localStorageService.set(this.storageKey, {
          syntaxTree: this.syntaxTree,
          chips: this.chips,
          collection: this.collection?.name
        });
      }
    }
  }

  loadChipsFromLocalStorage() {
    const storageObject: any = this.localStorageService.get(this.storageKey);
    this.extractChipsFromLocalStorage(storageObject.chips);
    this.extractSyntaxFromLocalStorage(storageObject.syntaxTree);
    this.buildQueryStatement();
  }

  private loadChipsFromInitialParams() {
    this.convertPathParamsToChips(JSON.parse(this.initialFilterParams));
    this.parseSyntaxTree();
    this.buildQueryStatement(true);
  }

  validateSyntaxTree() {
    this.syntaxErrors = [];
    if (this.syntaxTree.childNodes.length) {
      // In case no Syntax is stored
      typifySyntaxNodes(this.syntaxTree);
      checkSyntax(this.syntaxTree, this.chips, this.syntaxErrors);
    }
  }

  extractSyntaxFromLocalStorage(storedSyntax: any) {
    this.syntaxTree = storedSyntax as SyntaxNode;
    this.validateSyntaxTree();
  }

  extractChipsFromLocalStorage(storedChips: any) {
    storedChips.forEach((chip: ConditionChip) => {
      this.chips.push(
        new ConditionChip(chip.index, {
          value: chip.value,
          type: chip.type,
          searchType: chip.searchType,
          input: chip.input,
          i18nLabel: chip.i18nLabel,
          operatorID: chip.operatorID,
          fieldName: chip.fieldName,
          customValuePath: chip.customValuePath,
          valueLabel: chip.valueLabel
        })
      );
    });
  }

  resetSyntax() {
    this.syntaxTree = null;
    this.syntaxErrors = [];
  }

  resetQueryParams() {
    // Do not lose the selected collection when resetting the query
    this.queryParams = this.collection ? { collection: this.collection.name } : {};
    this.router.navigate(['.'], { relativeTo: this.route, queryParams: this.queryParams });
  }

  createChip(inputs: InputObject, index?: number) {
    const chipIndexCounter = this.chips.length
      ? Math.max(...this.chips.map((c) => c.index)) + 1
      : 0;
    const chip = new ConditionChip(index || chipIndexCounter, inputs);
    this.chips = [...this.chips, chip];
    this.chipChanged('INSERT', chip);
  }

  updateChip(inputs: InputObject) {
    super.updateChip(inputs);
    this.chips = [...this.chips];
    this.chipChanged('UPDATE');
  }

  removeChip(chip: ConditionChip) {
    super.removeChip(chip);
    this.chipChanged('DELETE', chip);
    this.resetQueryParams();

    if (!this.chips.length) {
      this.queryChange.emit();
      this.emitChangeValue({});
      this.resetSyntax();
      this.updateLocalStorage(true);
    }
  }

  private chipChanged(changeEvent?: ChangeEvent, input?: ConditionChip) {
    this.selectChip();
    this.updateSimpleSearchEditor(changeEvent, input);
    this.updateLocalStorage();
    this.emitChangeValue(this.getSimpleSearchValues());
  }

  selectChip(chip?: ConditionChip) {
    if (!chip || chip instanceof ConditionChip) {
      this.activeChip = chip;
    }
  }

  /**
   * filters out invalid query params from a Params Object and returns it
   */
  getValidChipParams(params: Params): Params {
    return Object.keys(params).reduce((reduced, currentValue) => {
      if (currentValue === 'simpleLogic' || Number.isFinite(parseInt(currentValue))) {
        reduced[currentValue] = params[currentValue];
      }
      return reduced;
    }, {});
  }

  private convertFilterQueryParamsToChips(): void {
    this.chips = [];
    Object.keys(this.queryParams).forEach((key) => {
      if (key === 'simpleLogic') {
        this.simpleLogic = this.queryParams[key];
      } else {
        const param = JSON.parse(this.queryParams[key]);
        let filterName = Object.keys(param)[0];
        let fieldName;

        if (filterName.startsWith('metaData.') || filterName.startsWith('queryParameters.')) {
          fieldName = filterName.split('.')[1];
          filterName = filterName.split('.')[0];
        }

        const filter = this.searchInput.filters.find((f) => f.name === filterName);
        if (filter) {
          const value = fieldName ? param[`${filterName}.${fieldName}`] : param[filterName];
          const option = filter.selectOptions?.find((option) => option.value === value);
          this.createChip(
            {
              input: filterName,
              searchType: 'filter',
              type: filterName !== 'type' ? param.type || filter.type : filter.type,
              value,
              operatorID: param.operator,
              i18nLabel: filter.i18nLabel,
              fieldName,
              customValuePath: option?.customValuePath,
              valueLabel: option?.label
            },
            Number(key) - 1 // we receive visual index, minus one to make the real one
          );
        }
      }
    });
  }

  private convertPathParamsToChips(params = this.queryParams) {
    this.chips = [];
    this.simpleLogic = '';
    Object.keys(params).forEach((key) => {
      if (key === 'simpleLogic') {
        this.simpleLogic = params[key];
      } else {
        try {
          const param = JSON.parse(params[key]);
          const path = Object.keys(param)[0];

          if (!isEmpty(param)) {
            this.createChip(
              {
                input: path,
                searchType: 'path',
                type: param.type,
                value: param[path],
                operatorID: param.operator
              },
              Number(key) - 1 // we receive visual index, minus one to make the real one
            );
          }
        } catch (e) {
          return;
        }
      }
    });
  }

  private getSimpleSearchValues() {
    return this.chips.reduce((acc, chip) => {
      const filterName = chip.fieldName ? `${chip.input}.${chip.fieldName}` : chip.input;
      if (!acc[filterName]) {
        acc[filterName] = [chip.value];
      } else {
        acc[filterName].push(chip.value);
      }
      return acc;
    }, {});
  }

  emitChangeValue(value = this.getSimpleSearchValues()) {
    this.pendingChange.emit({
      params: chipsToQueryParams(this.chips, this.simpleLogic, this.collection?.name).toParams(),
      query: this.syntaxTree ? assembleFindQuery(this.syntaxTree) : null
    });
    this.valueChange.emit(value);
  }
}
