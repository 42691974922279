import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
  SkipSelf,
  ViewEncapsulation
} from '@angular/core';
import { ControlContainer } from '@angular/forms';
import { DateRangePickerPreset } from '@inst-iot/bosch-angular-ui-components';
import { FilterParameterConfig } from '../../../dashboards/widgets/filter-widget/filter-widget.model';
import { FieldConfig } from '../../../dashboards/widgets/input-widget/models/input.model';
import { DatePickerService } from '../../../shared/services/date-picker.service';
import { PathInfo } from '../../data-selection/data-info-util';
import { DsParameter, DsParameterValue } from '../../data-widgets/data-widgets-common';
import { ColorRanges, DataFormattingInputType } from '../data-conditional-formatting.model';

@Component({
  selector: 'color-range',
  templateUrl: './color-range.component.html',
  styleUrls: ['./color-range.component.scss'],
  viewProviders: [
    {
      provide: ControlContainer,
      useFactory: (container: ControlContainer) => container,
      deps: [[new SkipSelf(), ControlContainer]]
    }
  ],
  encapsulation: ViewEncapsulation.None
})
export class ColorRangeComponent implements OnInit {
  @Input()
  askForLabels = false;

  @Input() filterParameters: FilterParameterConfig[] = [];
  @Input() inputParameters: FieldConfig[] = [];

  @Input() paths: PathInfo[];

  @HostBinding('class') class = 'color-range';

  @Input()
  dataType = DataFormattingInputType.NUMBER;

  @Input()
  colorRangeConfig: ColorRanges<DsParameter>;

  dataTypeEnum = DataFormattingInputType;

  @Output() configChange = new EventEmitter<ColorRanges<DsParameter>>();

  colorRangeModel: ColorRanges<DsParameter>;
  uniqueId = Math.random();
  defaultPresets: DateRangePickerPreset[];

  constructor(private datePickerService: DatePickerService) {
    this.defaultPresets = this.datePickerService.getDefaultPresets();
  }

  ngOnInit() {
    this.colorRangeModel = this.colorRangeConfig;

    if (!this.colorRangeModel) {
      this.colorRangeModel = { defaultColor: { value: '#656565' }, colorRanges: [] };
      this.notifyChange();
    }
  }

  notifyChange() {
    this.configChange.emit(this.colorRangeModel);
  }

  addColorRange() {
    const defaultValue = {
      color: {
        value: '#FFFFFF'
      }
    };
    if (this.dataType === 'number') {
      Object.assign(defaultValue, {
        min: {
          value: 0
        },
        max: {
          value: 10
        }
      });
    }
    if (this.dataType === 'datetime') {
      Object.assign(defaultValue, {
        min: 0,
        max: 0
      });
    }
    this.colorRangeModel.colorRanges.push(defaultValue);
    this.notifyChange();
  }

  changeParameter(parameter: DsParameterValue, index?: number, modelName?: string) {
    if (parameter) {
      index = typeof index !== 'undefined' ? index : 0;
      if (modelName === 'color') {
        this.colorRangeModel.colorRanges[index].color = parameter;
      } else if (modelName === 'min') {
        this.colorRangeModel.colorRanges[index].min = parameter;
      } else if (modelName === 'max') {
        this.colorRangeModel.colorRanges[index].max = parameter;
      } else if (modelName === 'regex') {
        this.colorRangeModel.colorRanges[index].equals = parameter;
      } else if (modelName === 'timeRange') {
        this.colorRangeModel.colorRanges[index].min = parameter['value'][0];
        this.colorRangeModel.colorRanges[index].max = parameter['value'][1];
      }
      this.notifyChange();
    }
  }

  removeColorRange(range) {
    const index = this.colorRangeModel.colorRanges.indexOf(range);
    if (index !== -1) {
      this.colorRangeModel.colorRanges.splice(index, 1);
    }
    this.notifyChange();
  }
}
