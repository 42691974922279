import { DashboardDataService } from './../../../dashboards/services/dashboard-data.service';
import {
  Component,
  ElementRef,
  HostListener,
  Input,
  OnChanges,
  SimpleChanges,
  TemplateRef
} from '@angular/core';
import { LinkDefinition } from '../../data-widgets/data-widgets-common/lib/hyperlink.service';
import {
  ActionButtonConfiguration,
  ActionButtonStyle
} from '../../rest-request/models/rest-request.model';
import { checkDisabledCondition, validateInputReference } from '../action-config-util';
import { WidgetPlaceholderContext } from '../../data-widgets/data-widgets-common';
import { DashboardStateService } from '../../../dashboards/services/dashboard-state.service';

interface TableContext {
  row: number;
  column: string;
}

@Component({
  selector: 'action-link',
  templateUrl: './action-link.component.html',
  styleUrls: ['../action-buttons.scss'],
  providers: [DashboardDataService, DashboardStateService]
})
export class ActionLinkComponent implements OnChanges {
  @Input() linkOptions: LinkDefinition;
  @Input() customCssClasses = '';
  @Input() linkContent: TemplateRef<any>;
  @Input() disabled = false;
  @Input() isGridLayout = false;
  @Input() data: any;
  @Input() context: WidgetPlaceholderContext;
  @Input() actionConfiguration = {} as ActionButtonConfiguration;
  @Input() actionButtonStyling: ActionButtonStyle;
  @Input() tableContext: TableContext;
  @Input() stopPropagation = false;

  disabledByCondition: boolean;

  closePopoverAfterMs = 1000;

  constructor(
    private dashboardDataService: DashboardDataService,
    private dashboardStateService: DashboardStateService,
    public elementRef: ElementRef
  ) {}

  @HostListener('click', ['$event']) handleClick(event: MouseEvent) {
    if (
      !validateInputReference(
        this.dashboardDataService,
        this.dashboardStateService,
        this.actionConfiguration
      )
    ) {
      event.preventDefault();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data || changes.context) {
      this.disabledByCondition = checkDisabledCondition(
        this.actionConfiguration?.disableConditionsChips?.syntaxTree,
        this.data,
        this.context
      );
    }
  }

  get disabledAnyhow(): boolean {
    return this.disabled || this.disabledByCondition;
  }

  linkClicked($event: Event) {
    if (this.stopPropagation) {
      $event.stopPropagation();
    }
  }
}
