import { Component, OnInit } from '@angular/core';
import { LocalStorageService } from 'ngx-localstorage';
import { ModalService } from '@inst-iot/bosch-angular-ui-components';

@Component({
  selector: 'csv-setting-name-dialog',
  templateUrl: './csv-setting-name-dialog.component.html'
})
export class CsvSettingNameDialogComponent implements OnInit {
  localCsvDownloadSettings: any[] = [];

  name = '';

  constructor(public modalService: ModalService, private localStorage: LocalStorageService) {}

  ngOnInit() {
    const csvDownloadSetting = this.localStorage.get('localCsvDownloadSetting');
    if (csvDownloadSetting) {
      this.localCsvDownloadSettings = csvDownloadSetting as any[];
    }
  }

  cancel() {
    this.modalService.close();
  }

  isSettingNameUsed(name: string) {
    return this.localCsvDownloadSettings.find((x) => x.label === name);
  }

  submitForm() {
    const postSettingName: string = this.name;
    if (!this.isSettingNameUsed(postSettingName)) {
      this.modalService.close(postSettingName);
    }
  }
}
