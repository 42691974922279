import {
  Component,
  DoCheck,
  EventEmitter,
  Input,
  IterableDiffer,
  IterableDiffers,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import { Observable, of } from 'rxjs';
import { cloneDeep } from 'lodash-es';
import { DeviceTypeDefinition, DeviceTypeFeature } from '../../../devices/models/device-types';
import {
  DeviceProperty,
  DevicePropertyGroup
} from '../../../dashboards/widgets/filter-widget/filter-widget-edit/filter-widget-edit.model';
import { DeviceTypesService } from '../../../devices/services/device-types.service';
import { FeatureDefinitionsService } from '../../../devices/services/feature-definitions.service';
import { getFeatureProperties } from '../device.utils';
import { translate } from '../../../shared/translation-util';

@Component({
  selector: 'device-property-search',
  templateUrl: './device-property-search.component.html',
  styleUrls: ['./device-property-search.component.scss']
})
// eslint-disable-next-line @angular-eslint/no-conflicting-lifecycle
export class DevicePropertySearchComponent implements OnChanges, DoCheck {
  @Input() deviceTypeDefinition: DeviceTypeDefinition;
  @Input() devicePropertyToFilterOut: DeviceProperty[] = [];
  @Input() placeholder = translate('widget.filter.deviceAttributeSearch.placeholder');

  @Output() devicePropertySelected = new EventEmitter<DeviceProperty>();

  private itDiffer: IterableDiffer<unknown>;
  deviceProperty = '';
  deviceFeatureProperties: DevicePropertyGroup[];
  filteredDeviceFeatureProperties: DevicePropertyGroup[];
  autoCompleteSearchFunction: (value: string) => Observable<string[]>;

  constructor(
    private deviceTypesService: DeviceTypesService,
    private itDiffers: IterableDiffers,
    private featureDefinitionsService: FeatureDefinitionsService
  ) {
    this.autoCompleteSearchFunction = this.autoCompleteSearch.bind(this);
    this.itDiffer = itDiffers.find([]).create(null);
  }

  // eslint-disable-next-line @angular-eslint/no-conflicting-lifecycle
  ngOnChanges(changes: SimpleChanges) {
    if (changes.deviceTypeDefinition) {
      this.deviceProperty = '';
      this.calculateProperties();
    }
  }

  // eslint-disable-next-line @angular-eslint/no-conflicting-lifecycle
  ngDoCheck() {
    // TODO replace this method to fix eslint errors
    const parameterChanges = this.itDiffer.diff(this.devicePropertyToFilterOut);
    if (parameterChanges) {
      this.calculateProperties();
    }
  }

  calculateProperties() {
    if (!this.deviceTypeDefinition) {
      this.deviceFeatureProperties = [];
    } else {
      this.deviceFeatureProperties = getFeatureProperties(
        this.deviceTypeDefinition,
        this.devicePropertyToFilterOut
      );
      this.filteredDeviceFeatureProperties = cloneDeep(this.deviceFeatureProperties);
    }
  }

  autoCompleteSearch(text = ''): Observable<DevicePropertyGroup[]> {
    const filteredDeviceProperties = cloneDeep(this.deviceFeatureProperties).map((item) => {
      item.properties = item.properties.filter(
        (prop) =>
          prop && prop.propertyName.toLocaleLowerCase().indexOf(text.toLocaleLowerCase()) >= 0
      );
      return item;
    });
    this.filteredDeviceFeatureProperties = filteredDeviceProperties.filter(
      (item) => item.properties.length !== 0
    );
    return of(this.filteredDeviceFeatureProperties);
  }

  updateDeviceProperty(deviceProperty: DeviceProperty) {
    this.deviceProperty = '';
    this.devicePropertySelected.next(deviceProperty);
  }

  getFeatureName(defaultName: string) {
    const deviceFeature: DeviceTypeFeature = this.deviceTypeDefinition.features.find(
      (feature) => feature.defaultName === defaultName
    );
    return this.featureDefinitionsService.getDeviceTypeFeatureTitle(deviceFeature);
  }
}
