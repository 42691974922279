import { Component, ContentChildren, Input, QueryList } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { emptyFunction } from '../translated-text-input/translated-text-input.component';
import { createEditorInstance, EditorElement } from './slate.util';
import { formatHtmlToSlateObject } from './transform.util';
import { PathInfo } from '../../shared-modules/data-selection/data-info-util';
import { FormValidationMessageDirective } from '@inst-iot/bosch-angular-ui-components';

@Component({
  selector: 'slate-richtext',
  styleUrls: ['./slate-richtext.component.scss'],
  templateUrl: './slate-richtext.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: SlateRichtextComponent,
      multi: true
    }
  ]
})
export class SlateRichtextComponent implements ControlValueAccessor {
  @Input()
  value: EditorElement[];

  @Input()
  readonly: boolean;

  @Input()
  paths: PathInfo[];

  @Input()
  editorClass: string;

  @Input()
  editorHeight: string;

  @ContentChildren(FormValidationMessageDirective)
  messages: QueryList<FormValidationMessageDirective>;

  onChange = emptyFunction;
  onTouched = emptyFunction;

  editor = createEditorInstance();
  imageSizeError: boolean;

  registerOnChange(fn: () => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  writeValue(value: EditorElement[] | string): void {
    if (typeof value === 'string') {
      this.value = formatHtmlToSlateObject(value);
      this.onChange(this.value);
    } else {
      this.value = value;
    }
  }

  onImageSizeError(errorOccurred) {
    this.imageSizeError = errorOccurred;
  }
}
