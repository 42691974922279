<div
  class="widget-container"
  [ngClass]="'widget-' + widgetInstance.id"
  [attr.data-loaded]="widgetInstance.rendered | async"
>
  <div
    *ngIf="showLoadingStatusInTooltip$ | async"
    class="tooltip"
    [rbTooltip]="loadingStatusTpl"
    activateLoadingStatusTooltip
  ></div>
  <!-- Widget Header -->
  <div class="widget-header" [ngClass]="[layoutCssClass]" *ngIf="showWidgetHeader">
    <div class="header-content" [attr.data-cy]="'widgetContainerHeader-' + widgetInstance.id">
      <div class="widget-title" #widgetTitle>
        <widget-title
          *ngIf="showWidgetTitle"
          [widgetInstance]="widgetInstance"
          [widgetData]="dataLoader?.result"
          [layout]="currentLayout"
          [marginBottom]="titleWhitespace"
        ></widget-title>
      </div>
      <!-- data-type="controls" is used by the toggleControlsOnHover directive to select the element -->
      <div
        *ngIf="isGridLayout"
        class="controls"
        [class.-open]="contextMenuOpen"
        data-type="controls"
      >
        <span
          class="text-truncate"
          *ngIf="isWidgetReloadable && (showLoadingStatusInTooltip$ | async) === false"
        >
          <widget-loading-status></widget-loading-status>
        </span>
        <span class="menu-button">
          <rb-context-menu
            *ngIf="showWidgetMenu && hasSelectableOptions"
            [attr.data-cy]="'widgetContainerControls-' + widgetInstance.id"
            [options]="{ iconSize: 30 }"
            [menuItems]="extraControls"
            (open)="contextMenuOpen = true"
            (close)="contextMenuOpen = false"
          >
            <widget-control
              *ngIf="showWidgetControls"
              [showWidgetEditOptions]="userCanEdit"
              [isWidgetMaximizable]="isWidgetMaximizable"
              [isWidgetReloadable]="isWidgetReloadable"
              [layout]="currentLayout"
            ></widget-control>
          </rb-context-menu>
        </span>
      </div>
    </div>
  </div>

  <!-- Widget Body -->
  <div
    [attr.data-id]="widgetInstance.id"
    class="widget-body"
    [ngClass]="[layoutCssClass, customCSSClasses]"
    [class.h-100]="dashboardDataService.isSpecificWidgetRoute"
    #widgetBody
  >
    <ng-content></ng-content>
  </div>

  <!-- Widget Footer -->
  <widget-control *ngIf="currentLayout === 'columnBased' && showWidgetControls">
    <ng-container *ngIf="widgetControls">
      <rb-render-tpl [tpl]="widgetControls"></rb-render-tpl>
    </ng-container>
  </widget-control>
</div>

<ng-template #loadingStatusTpl>
  <widget-loading-status [showInTooltip]="true"></widget-loading-status>
</ng-template>
