import { AfterViewInit, Directive, ElementRef, Input, Renderer2, inject } from '@angular/core';

/**
 * Directive to highlight a device history change.
 * @param: isHighlighted - boolean - whether to highlight the element
 * @param: childElementSelector - string - optional selector to highlight a child element
 */
@Directive({ selector: '[deviceHistoryChangeHighlight]' })
export class DeviceHistoryChangeHighlightDirective implements AfterViewInit {
  @Input('deviceHistoryChangeHighlight') isHighlighted = false;
  @Input() childElementSelector: string;

  private readonly highlightClass = 'device-history-change-highlight';
  private elementRef = inject(ElementRef);
  private renderer = inject(Renderer2);

  ngAfterViewInit(): void {
    if (!this.isHighlighted) {
      return;
    }

    if (this.childElementSelector) {
      const element = this.elementRef.nativeElement.querySelector(this.childElementSelector);
      this.renderer.addClass(element, this.highlightClass);
      return;
    }

    this.renderer.addClass(this.elementRef.nativeElement, this.highlightClass);
  }
}
