import { Editor, Element, Transforms } from 'slate';
import {
  DsParameter,
  encodeUriWithModifiers,
  resolvePlaceholders,
  resolveStringWithModifiers
} from '../../../shared-modules/data-widgets/data-widgets-common';
import { EditorElement } from '../slate.util';
import {
  RichTextImageSettings,
  RichTextImageType
} from '../components/image-properties-editor/image-properties-editor.component';
import { getImageNode } from './image';
import { AbstractPlaceholderResolver } from '../placeholder-resolver';
import { ValueFormattingBaseConfig } from '../../../shared-modules/data-conditional-formatting/data-conditional-formatting.model';
import {
  ArrayValueSeparatorForArrayTooltips,
  calculateColor,
  resolveDataFormattingValues
} from '../../../shared-modules/data-conditional-formatting/data-conditional-formatting-utils';
import { attachmentUrlToImgResolution } from '../../../devices/models/device';
import { handleTooltip } from '../tooltip.util';
import { TooltipConfig } from '../models/tooltip.model';
import { isArray } from 'lodash-es';

export type CustomText = {
  bold?: boolean;
  italic?: boolean;
  code?: boolean;
  text: string;
};

export type ReferenceElement = {
  type: 'reference';
  path?: string;
  markTypes?: Record<string, any>;
  children: CustomText[];
  subTypeInfo?: any;
  text?: string;
  valueFormatting?: ValueFormattingBaseConfig<DsParameter>;
  tooltip?: string;
};

export type ResolvedEditorElement = {
  editor: EditorElement[];
  editorInstance: any;
};

export function withReferences(editor) {
  const { isInline, isVoid } = editor;

  editor.isInline = (element) => {
    return element.type === 'reference' ? true : isInline(element);
  };

  editor.isVoid = (element) => {
    return element.type === 'reference' ? true : isVoid(element);
  };

  return editor;
}

export function insertImageReference(editor, imageInfo: RichTextImageSettings) {
  insertReference(editor, imageInfo.pathValue, null, imageInfo);
}

export function insertReference(
  editor: Editor,
  path: string,
  valueFormatting?: ValueFormattingBaseConfig<DsParameter>,
  metaData?: any,
  tooltip?: string
) {
  const reference: ReferenceElement = {
    type: 'reference',
    path,
    markTypes: {},
    children: [{ text: '' }],
    valueFormatting,
    subTypeInfo: metaData,
    tooltip
  };
  Transforms.insertNodes(editor, reference);
  Transforms.move(editor);
}

export class ReferenceResolver extends AbstractPlaceholderResolver {
  extractEditorValues(nodes: Element[] | CustomText[], values = []) {
    nodes.forEach((node) => {
      const isReference = node.type === 'reference';
      if (isReference) {
        values.push(node.path);
      }
      if (node.children) {
        this.extractEditorValues(node.children, values);
      }
    });
    return values.map((v) => '${' + v + '}');
  }

  replaceValues(values = [], nodes: Element[] | CustomText[], iteratorIndex): EditorElement[] {
    const nodesToChanges = [];

    // Iterate through the nodes and identify changes
    nodes.forEach((node, index) => {
      const isReference = node.type === 'reference';
      const imageDataSourceRefType =
        isReference && (node?.subTypeInfo?.imageType as RichTextImageType);

      if (isReference && !imageDataSourceRefType) {
        const element = this.convertReferenceToEditorElement(values.shift(), node, iteratorIndex);

        if (Array.isArray(element)) {
          nodesToChanges.push({ index, element });
        } else {
          nodesToChanges.push({ index, element: [element] });
        }
      } else if (imageDataSourceRefType) {
        const res = this.convertReferenceValueToImage(
          values.shift(),
          imageDataSourceRefType,
          node.subTypeInfo as RichTextImageSettings,
          iteratorIndex
        );
        if (res) {
          nodesToChanges.push({ index, element: [res] });
        }
      }

      if (node.children) {
        node.children = this.replaceValues(values, node.children, iteratorIndex);
      }
    });

    // Apply all changes after the loop
    let offset = 0; // Track the offset due to array splicing
    nodesToChanges.forEach(({ index, element }) => {
      nodes.splice(index + offset, 1, ...element);
      offset += element.length - 1; // Adjust the offset based on the number of elements inserted
    });

    return nodes as EditorElement[];
  }

  /*
   * Adds spaces in the beginning and at the end of every line.
   * Because of padding, on the first line it adds only to the end, and on the last line only to the beginning.
   * Workaround to adding padding to array values.
   */
  private addLeadingAndTrailingSpaces(inputString: string) {
    const lines = inputString.split('\n');

    for (let i = 1; i < lines.length - 1; i++) {
      lines[i] = ' ' + lines[i] + ' ';
    }

    lines[0] = lines[0] + ' ';

    lines[lines.length - 1] = ' ' + lines[lines.length - 1];

    return lines.join('\n');
  }

  private isMultilineString(inputString: string) {
    return inputString.includes('\n');
  }

  private convertReferenceValueToImage(
    resolvedValue: string,
    imageDataSourceRefType: RichTextImageType,
    config: RichTextImageSettings,
    index?: number
  ) {
    if (imageDataSourceRefType === RichTextImageType.BASE_64) {
      const base64Img = resolvedValue; // should we verify that it starts with data:image/?
      return getImageNode(base64Img, config);
    }
    if (imageDataSourceRefType === RichTextImageType.FROM_URL) {
      const formattedUrl = resolvePlaceholders(
        config.imageSrc,
        index,
        encodeUriWithModifiers,
        this.dataSource,
        this.context
      );
      return getImageNode(formattedUrl, config);
    }
    if (imageDataSourceRefType === RichTextImageType.DEVICE_IMAGE) {
      const attachmentUrl = this.extractUrlFromObject(resolvedValue);
      const imgResolutionUrl = attachmentUrlToImgResolution(attachmentUrl, 256);

      return getImageNode(imgResolutionUrl, config);
    }
    return null;
  }

  private extractUrlFromObject(value: any): string {
    if (typeof value === 'object' && value?.url) {
      return value.url;
    }
    if (Array.isArray(value) && value.length > 0) {
      return this.extractUrlFromObject(value[0]);
    }
    try {
      if (typeof value === 'string' && JSON.parse(value)) {
        return this.extractUrlFromObject(JSON.parse(value));
      }
    } catch (ex) {
      // dont care.
    }
    return value;
  }

  private convertReferenceToEditorElement(value: string, node: any, iteratorIndex: number): any {
    const resolvedDataFormatting = resolveDataFormattingValues(
      node.valueFormatting,
      this.context,
      this.dataSource
    );
    const arrayValue: any[] = resolvedDataFormatting.arrayValueSeparator ? value.split('\n') : [];
    const tooltipParams: TooltipConfig = {
      tooltipText: node.tooltip,
      resolvePlaceholdersFn: (placeholder: string) =>
        resolvePlaceholders(
          placeholder,
          iteratorIndex,
          resolveStringWithModifiers,
          this.dataSource,
          this.context
        ),
      isMultipleValues: resolvedDataFormatting.multipleValues,
      arrayValue
    };

    const tooltip = handleTooltip(tooltipParams);

    if (isArray(tooltip) && resolvedDataFormatting.multipleValues) {
      return this.createMultipleElements(arrayValue, tooltip, resolvedDataFormatting, node);
    } else {
      return this.createSingleElement(value, tooltip as string, resolvedDataFormatting, node);
    }
  }

  private createMultipleElements(
    arrayValue: any[],
    tooltip: string[],
    resolvedDataFormatting: any,
    node: any
  ): any[] {
    const elements = [];
    const showInPopover = resolvedDataFormatting['showInPopover'];
    const isPaddingNeeded =
      resolvedDataFormatting.automaticPadding && node.markTypes?.['font-bg-color'];

    tooltip.forEach((t, tooltipIdx) => {
      const transformedText = this.dataConditionalFormattingPipe.transform(arrayValue[tooltipIdx], {
        ...resolvedDataFormatting,
        arrayValueSeparator: null
      });

      const text =
        this.isMultilineString(transformedText) && isPaddingNeeded
          ? this.addLeadingAndTrailingSpaces(transformedText)
          : transformedText;

      elements.push({
        text: ArrayValueSeparatorForArrayTooltips[resolvedDataFormatting.arrayValueSeparator](
          text,
          tooltip.length - 1 === tooltipIdx
        ),
        'font-bg-color': calculateColor(arrayValue[tooltipIdx], resolvedDataFormatting),
        isArrayTooltip: true,
        ...(showInPopover ? { showInPopover, fullText: arrayValue[tooltipIdx] } : {}),
        ...(isPaddingNeeded ? { padding: '8px' } : {}),
        ...(node.markTypes || {}),
        ...(t ? { tooltip: t } : {})
      });
    });

    return elements;
  }

  private createSingleElement(
    value: string,
    tooltip: string | null,
    resolvedDataFormatting: any,
    node: any
  ): any {
    const showInPopover = resolvedDataFormatting['showInPopover'];
    const isPaddingNeeded =
      resolvedDataFormatting.automaticPadding && node.markTypes?.['font-bg-color'];
    const transformedText = this.dataConditionalFormattingPipe.transform(
      value,
      resolvedDataFormatting
    );

    return {
      text:
        this.isMultilineString(transformedText) && isPaddingNeeded
          ? this.addLeadingAndTrailingSpaces(transformedText)
          : transformedText,
      'font-bg-color': calculateColor(value, resolvedDataFormatting),
      ...(showInPopover ? { showInPopover, fullText: value } : {}),
      ...(isPaddingNeeded ? { padding: '8px' } : {}),
      ...(node.markTypes || {}),
      ...(tooltip ? { tooltip } : {})
    };
  }
}
