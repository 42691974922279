import { AfterViewInit, Directive, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { WidgetInstanceService } from '../../../dashboards/services/widget-instance.service';

@Directive({
  selector: '[isColumnFixed]'
})
export class CustomColumnFixedDirective implements AfterViewInit, OnInit, OnDestroy {
  readonly TABLE_LOADING_TIME = 300;
  @Input() isColumnFixed: boolean;
  @Input() inEditMode: boolean;

  private subscription = new Subscription();

  constructor(private el: ElementRef, private widgetInstance: WidgetInstanceService) {}

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  ngOnInit(): void {
    this.subscription.add(
      this.widgetInstance.rendered.subscribe((rendered: boolean) => {
        if (this.isColumnFixed && !this.inEditMode && rendered) {
          this.initializeCustomColumnFixed();
        }
      })
    );
  }

  ngAfterViewInit() {
    if (this.isColumnFixed && this.inEditMode) {
      this.initializeCustomColumnFixed();
    }
  }

  private initializeCustomColumnFixed() {
    setTimeout(() => {
      const el = this.el.nativeElement as HTMLElement;
      const { x } = el.getBoundingClientRect();
      const table = el.closest('.m-table')?.getBoundingClientRect();
      el.classList.add('fixed-col');
      el.style.left = table ? `${x - table.x}px` : '0px';
    }, this.TABLE_LOADING_TIME);
  }
}
