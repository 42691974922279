import { Directive, forwardRef, OnInit } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';
import { LocalStorageService } from 'ngx-localstorage';

@Directive({
  selector: '[csvSettingNameValidator]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => CsvSettingNameValidatorDirective),
      multi: true
    }
  ]
})
export class CsvSettingNameValidatorDirective implements Validator, OnInit {
  downloadSettings = [];

  constructor(private localStorage: LocalStorageService) {}

  ngOnInit() {
    const csvDownloadSetting = this.localStorage.get('localCsvDownloadSetting');
    if (csvDownloadSetting) {
      this.downloadSettings = csvDownloadSetting as any[];
    }
  }

  validate(c: AbstractControl): ValidationErrors | null {
    if (c.value && this.isSettingNameUsed(c.value)) {
      return { used: true };
    }
    return null;
  }

  isSettingNameUsed(name: string) {
    return this.downloadSettings.find((x) => x.label === name);
  }
}
