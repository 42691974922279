<div class="row d-flex align-items-end" *rbLoading="collectionsLoader">
  <div class="col-sm-12 col-md-4 pr-md-0">
    <a
      class="align-self-center"
      href="#"
      style="font-size: small"
      *ngIf="showCollectionCategoryExplanation"
      [rbPopover]="collectionCategoriesExplanation"
      popoverStyle="max-width: 500px"
      position="bottom"
    >
      {{ 'collectionSelector.explainCategories' | translate }}
    </a>

    <rb-form-custom-select
      class="category-selector mb-3 mb-md-0"
      name="collectionCategory"
      [class.static]="selectedCollectionCategory === collectionCategories.Internal"
      [label]="'collectionSelector.collectionCategory' | translate"
      [(ngModel)]="selectedCategory"
      [disabled]="disabled || disableCategorySelection"
      (ngModelChange)="categoryChanged()"
    >
      <rb-form-select-option
        *ngFor="let category of availableCollectionCategories"
        [value]="category"
      >
        <div *ngIf="category === collectionCategories.All" class="d-flex align-items-center">
          <span class="rb-ic rb-ic-batch mr-2"></span>
          <span class="text-truncate">{{ 'collectionSelector.all' | translate }}</span>
        </div>
        <div *ngIf="category === collectionCategories.CustomData" class="d-flex align-items-center">
          <span class="rb-ic rb-ic-document mr-2"></span>
          <span class="text-truncate">{{ 'collectionSelector.customData' | translate }}</span>
        </div>
        <div
          *ngIf="category === collectionCategories.ProcessedDataTimeSeries"
          class="d-flex align-items-center"
        >
          <span class="rb-ic rb-ic-clock mr-2"></span>
          <span class="text-truncate">{{ 'collectionSelector.timeseries' | translate }}</span>
        </div>
        <div
          *ngIf="category === collectionCategories.HistoryAndLogs"
          class="d-flex align-items-center"
        >
          <span class="rb-ic rb-ic-history mr-2"></span>
          <span class="text-truncate">{{ 'collectionSelector.historyAndLogs' | translate }}</span>
        </div>
        <div *ngIf="category === collectionCategories.Internal" class="d-flex align-items-center">
          <span class="rb-ic rb-ic-server mr-2"></span>
          <span class="text-truncate">{{ 'collectionSelector.internal' | translate }}</span>
        </div>
      </rb-form-select-option>
    </rb-form-custom-select>
  </div>
  <div class="col-sm-12 col-md-8 pl-md-0 mt-2 mt-md-0 position-relative">
    <collection-retention-time
      *ngIf="showRetentionTime"
      [selectedCollection]="_selectedCollection"
    ></collection-retention-time>

    <rb-form-input
      class="input-ellipsis"
      label="{{ title }}"
      data-cy="collection-input"
      [class.static]="selectedCollectionCategory === collectionCategories.Internal"
      [placeholder]="'collectionSelector.collectionName' | translate"
      [rbInitialOpen]="true"
      [spellcheck]="false"
      [disabled]="disabled"
      [(ngModel)]="searchBoxText"
      [rbFormInputAutocomplete]="getMatchingCollections"
      [rbAutocompleteList]="autoCompleteCollectionList"
      [maxHeight]="'50vh'"
      (keydown.enter)="autocompleteCollection()"
      (focusout)="matchInputWithSelectedCollection()"
      (focusin)="isCollapsed = false"
      (ngModelChange)="isCollapsed = false; selectedCollection = getExactCollectionOfInput()"
    ></rb-form-input>
  </div>
</div>
<rb-notification-bar
  class="mb-3 w-100 d-block"
  *ngIf="selectedCollectionCategory === collectionCategories.Internal"
  [type]="'warning'"
>
  {{ 'collectionSelector.internalSystemWarning' | translate }}
</rb-notification-bar>

<ng-template #autoCompleteCollectionList let-active="active" let-select="select">
  <a
    class="rb-dropdown-item"
    *ngFor="let col of matchingCollectionStrings"
    (click)="select(col)"
    [class.active]="active === col"
    [attr.data-cy]="col"
    [(ngbCollapse)]="isCollapsed"
  >
    {{ col }}
  </a>
  <a *ngIf="matchingCollectionStrings.length === 0" class="rb-dropdown-item disabled">
    {{ 'collectionSelector.noCollectionFound' | translate }}
  </a>
</ng-template>
<ng-template #collectionCategoriesExplanation>
  <collection-categories-popover></collection-categories-popover>
</ng-template>
