import { Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { EntryCell } from '../table-view.model';
import { ArrayValueSeparatorType } from '../../../data-conditional-formatting/data-conditional-formatting.model';
import { TranslateService } from '@ngx-translate/core';
import { translate } from '../../../../shared/translation-util';

@Component({
  selector: 'table-array-display',
  templateUrl: './table-array-display.component.html',
  styleUrls: ['./table-array-display.component.scss']
})
export class TableArrayDisplayComponent implements OnInit {
  @Input() cell: EntryCell;

  @Output() updatePath = new EventEmitter<string[]>();

  arrayValueSeparator = ArrayValueSeparatorType;

  tooltipArray: any[] = [];

  private translateService: TranslateService = inject(TranslateService);

  ngOnInit() {
    this.setTooltipArray();
  }

  private setTooltipArray() {
    if (!this.cell.tooltip?.array) {
      return;
    }

    if (!this.isTooltipArrayValid()) {
      this.cell.tooltip.text = this.translateService.instant(
        translate('tableTooltip.invalidTooltip')
      );
      return;
    }

    this.tooltipArray = this.cell.tooltip.array[0];
  }

  private isTooltipArrayValid() {
    return (
      this.cell.tooltip.array.length === 1 &&
      this.cell.tooltip.array[0].length === this.cell.value.length
    );
  }
}
