import { Component, Input } from '@angular/core';

@Component({
  selector: 'device-info-block-icon',
  template: `
    <span class="rb-ic {{ icon }} mr-2 {{ class }}" style="font-size: 1.5rem"></span>
  `
})
export class DeviceInfoBlockIconComponent {
  @Input() icon: string;
  @Input() class: string;
}
