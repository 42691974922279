<b class="p-1">{{ selectedRowAndColumn }}</b>

<div class="table-selector">
  <table>
    <tbody>
      <tr *ngFor="let row of tableData; let rowIndex = index">
        <td
          [attr.data-cy]="'richtext-table-selector-' + rowIndex + '-' + colIndex"
          *ngFor="let cell of row; let colIndex = index"
          [class.table-selector-selected]="isSelected(rowIndex, colIndex)"
          [class.table-selector-hover]="isHovered(rowIndex, colIndex)"
          (mouseenter)="onMouseEnter(rowIndex, colIndex)"
          (click)="selectCell(rowIndex, colIndex)"
        >
          {{ cell }}
        </td>
      </tr>
    </tbody>
  </table>
</div>
