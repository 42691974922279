<div class="global-toolbar p-2">
  <div *ngFor="let group of toolbarItemGroups" class="d-flex">
    <div *ngFor="let toolbarItem of groupedToolbarItems[group]">
      <button
        *ngIf="!toolbarItem.templateRef"
        type="button"
        class="toolbar-button"
        [innerHTML]="toolbarItem.iconHtml"
        [title]="toolbarItem.title | translate"
        [class.mr-2]="toolbarItem.break"
        [class.active]="toolbarItem.active(toolbarItem.format)"
        (mousedown)="$event.preventDefault(); toolbarItem.action(toolbarItem.format)"
      ></button>

      <ng-container
        [ngTemplateOutlet]="toolbarItem.templateRef"
        [ngTemplateOutletContext]="{toolbarItem}"
      ></ng-container>
    </div>
  </div>
</div>

<ng-template #tableBorderButton let-toolbarItem="toolbarItem">
  <button
    class="toolbar-button"
    type="button"
    [title]="toolbarItem.title | translate"
    [class.mr-2]="toolbarItem.break"
    [class.d-none]="isTableActive()"
    (click)="$event.preventDefault(); this.saveSelection()"
    [innerHTML]="toolbarItem.iconHtml"
    [rbDropdown]="tableBorderDropdown"
    [autoClose]="false"
  ></button>

  <ng-template #tableBorderDropdown>
    <button
      *ngFor="let button of tableButtons | slice: 0:6"
      (click)="tableAction(button.action, button.position)"
      type="button"
      [attr.data-cy]="'richtext-table-popover-' + button.action"
      class="rb-btn rb-link rb-dropdown-item"
    >
      <span *ngIf="button.iconClass" class="rb-ic mr-1" [ngClass]="button.iconClass"></span>
      <img [src]="button.imgSrc" [alt]="button.label | translate" />
      {{ button.label | translate }}
    </button>
  </ng-template>
</ng-template>

<ng-template #tableSizeButton let-toolbarItem="toolbarItem">
  <button
    class="toolbar-button"
    type="button"
    [title]="toolbarItem.title | translate"
    [class.mr-2]="toolbarItem.break"
    data-cy="toolbarTableCreateButton"
    (click)="$event.preventDefault(); this.saveSelection()"
    [innerHTML]="toolbarItem.iconHtml"
    [rbDropdown]="tableSizeDropdown"
    [autoClose]="true"
  ></button>

  <ng-template #tableSizeDropdown>
    <table-selector
      (selectedRowsAndColumns)="selectedRowsAndColumnsChange($event)"
    ></table-selector>
  </ng-template>
</ng-template>

<ng-template #tableAddRowAndColumnButton let-toolbarItem="toolbarItem">
  <button
    class="toolbar-button"
    type="button"
    [title]="toolbarItem.title | translate"
    [class.mr-2]="toolbarItem.break"
    [class.d-none]="isTableActive()"
    (click)="$event.preventDefault(); this.saveSelection()"
    [innerHTML]="toolbarItem.iconHtml"
    [rbDropdown]="tableAddRowAndColumnDropdown"
    [autoClose]="false"
  ></button>

  <ng-template #tableAddRowAndColumnDropdown>
    <button
      *ngFor="let button of tableButtons | slice: 6:10"
      (click)="tableAction(button.action, button.position)"
      type="button"
      [attr.data-cy]="'richtext-table-context-' + button.action"
      class="rb-btn rb-link rb-dropdown-item"
    >
      <span *ngIf="button.iconClass" class="rb-ic mr-1" [ngClass]="button.iconClass"></span>
      {{ button.label | translate }}
    </button>
  </ng-template>
</ng-template>

<ng-template #tableRemoveRowAndColumnButton let-toolbarItem="toolbarItem">
  <button
    class="toolbar-button"
    type="button"
    [title]="toolbarItem.title | translate"
    [class.mr-2]="toolbarItem.break"
    [class.d-none]="isTableActive()"
    (click)="$event.preventDefault(); this.saveSelection()"
    [innerHTML]="toolbarItem.iconHtml"
    [rbDropdown]="tableRemoveRowAndColumnDropdown"
    [autoClose]="false"
  ></button>

  <ng-template #tableRemoveRowAndColumnDropdown>
    <button
      *ngFor="let button of tableButtons | slice: 10:tableButtons.length"
      (click)="tableAction(button.action, button.position)"
      type="button"
      [attr.data-cy]="'richtext-table-context-' + button.action"
      class="rb-btn rb-link rb-dropdown-item"
    >
      <span *ngIf="button.iconClass" class="rb-ic mr-1" [ngClass]="button.iconClass"></span>
      <img *ngIf="button.imgSrc" [src]="button.imgSrc" [alt]="button.label | translate" />
      {{ button.label | translate }}
    </button>
  </ng-template>
</ng-template>

<ng-template #imageButton let-toolbarItem="toolbarItem">
  <ng-container *ngIf="!paths?.length">
    <button
      class="toolbar-button"
      data-cy="toolbarImageButton"
      [title]="toolbarItem.title | translate"
      [class.mr-2]="toolbarItem.break"
      [innerHTML]="toolbarItem.iconHtml"
      (click)="$event.preventDefault(); saveSelection(); uploadButton.click()"
    ></button>
    <input
      #uploadButton
      type="file"
      accept="image/*"
      style="display: none"
      (change)="onFilePicked($event)"
    />
  </ng-container>

  <ng-container *ngIf="paths?.length">
    <button
      class="toolbar-button"
      data-cy="toolbarImageButton"
      type="button"
      [title]="toolbarItem.title | translate"
      [class.mr-2]="toolbarItem.break"
      [innerHTML]="toolbarItem.iconHtml"
      (click)="$event.preventDefault(); saveSelection()"
      [rbDropdown]="imagePathSuggestionPopOver"
      [autoClose]="false"
    ></button>

    <ng-template #imagePathSuggestionPopOver let-close="close">
      <div class="p-3">
        <image-properties-editor
          [paths]="paths"
          (closePopover)="close()"
          (uploadFromFile)="addImage($event.files, $event.config)"
          (settingsChanged)="addImageUsingDataSource($event)"
        ></image-properties-editor>
      </div>
    </ng-template>
  </ng-container>
</ng-template>

<ng-template #colorPickerButton let-toolbarItem="toolbarItem">
  <button
    class="toolbar-button"
    type="button"
    [title]="toolbarItem.title | translate"
    [class.mr-2]="toolbarItem.break"
    (click)="$event.preventDefault(); this.saveSelection()"
    [innerHTML]="toolbarItem.iconHtml"
    [rbDropdown]="colorDropdown"
    [autoClose]="false"
  ></button>

  <ng-template #colorDropdown>
    <color-picker
      [config]="{ color: undefined }"
      (configChange)="updateColorConfig($event, toolbarItem.format)"
    ></color-picker>
  </ng-template>
</ng-template>

<ng-template #linkButton let-toolbarItem="toolbarItem">
  <button
    class="toolbar-button"
    type="button"
    data-cy="toolbarLinkCreateButton"
    [title]="toolbarItem.title | translate"
    [class.mr-2]="toolbarItem.break"
    (click)="$event.preventDefault(); this.saveSelection()"
    [innerHTML]="toolbarItem.iconHtml"
    [rbDropdown]="linkPopover"
    [autoClose]="false"
  ></button>
  <ng-template #linkPopover let-close="close">
    <link-popover
      [linkInfo]="hyperlink"
      (closePopover)="close()"
      (updateLink)="updateLink($event)"
    ></link-popover>
  </ng-template>
</ng-template>

<ng-template #fontSizeTemplate let-toolbarItem="toolbarItem">
  <rb-form-input
    #fontSizeInput
    class="font-size-selector"
    (keydown.enter)="$event.preventDefault(); fontSizeInput.onBlur()"
    [title]="toolbarItem.title | translate"
    [placeholder]="'slateEditor.toolbar.fontSizePlaceholder' | translate"
    [ngModel]="fontSizeDisplayString"
    (ngModelChange)="updateFontSize($event, toolbarItem.format)"
    [ngModelOptions]="{ updateOn: 'blur' }"
    (focusin)="$event.preventDefault(); this.fontSizeFocused = true; this.saveSelection()"
    (focusout)="this.fontSizeFocused = false"
    [rbDropdown]="dropDownContent"
  ></rb-form-input>
  <ng-template #dropDownContent>
    <div class="font-size-dropdown">
      <a
        class="rb-dropdown-item"
        *ngFor="let size of fontSizeValues"
        (click)="updateFontSize(size, toolbarItem.format)"
        [class.active]="fontSize === size"
      >
        {{ size + 'px' }}
      </a>
    </div>
  </ng-template>
</ng-template>
