import { KeyValue } from '../../../../shared-modules/key-value-editor/key-value';
import { MultiselectFieldConfig } from '../models/input.model';
import {
  DataSourceConfig,
  ThingDataSource
} from '../../../../shared-modules/data-widgets/data-widgets-common';

export function getSelectedValuesAsArray(config: MultiselectFieldConfig) {
  if (config?.defaultValue) {
    const selectedKeys = Object.keys(config?.defaultValue).filter(
      (key) => config.defaultValue[key]
    );

    return (
      (config.selectionValues as KeyValue[])
        .filter((item) => selectedKeys.includes(item.key))
        .map((item) => item.value) || []
    );
  }
  return [];
}

export function getSelectedValuesAsObject(
  multiSelectionArray: string[],
  config: MultiselectFieldConfig
) {
  return (config.selectionValues as KeyValue[]).reduce((acc, item) => {
    acc[item.key] = multiSelectionArray?.includes(item.value);
    return acc;
  }, {});
}

export function isFilterWidgetReferencedInSource(sourceConfig: DataSourceConfig) {
  return sourceConfig.sources.some(
    (source) =>
      ThingDataSource.isThingDataSource(source) && source.thingId?.['ref']?.includes('filterParams')
  );
}
