<form
  name="tooltipRowForm"
  #tooltipRowForm="ngForm"
  novalidate
  cdkDropList
  (cdkDropListDropped)="drop($event)"
>
  <p *ngIf="showEnableTooltipHint">
    <ellipsis-text>
      {{ 'widgets.dataTooltip.enableTooltipOne' | translate }}
      <span class="rb-ic rb-ic-material" style="text-decoration: line-through"></span>
      {{ 'widgets.dataTooltip.enableTooltipTwo' | translate }}
    </ellipsis-text>
  </p>
  <div class="centered-container centered-container-big shaded" *ngIf="tooltips.length === 0">
    {{ 'widgets.dataTooltip.noTooltipsSpecified' | translate }}
  </div>
  <div
    *ngFor="let tooltipRow of tooltips; let i = index"
    class="TooltipRow"
    [ngModelGroup]="'tooltipRow' + i"
    #cdFormGroup="ngModelGroup"
    cdkDrag
  >
    <div class="d-flex justify-content-end align-items-center TooltipRowStatusBar" cdkDragHandle>
      <span
        class="rb-ic rb-ic-alert-warning-filled TooltipRowStatusBar_warning"
        *ngIf="!cdFormGroup.valid"
      ></span>
      <span class="rb-ic rb-ic-drag-handle TooltipRowStatusBar_handle"></span>
    </div>
    <div class="d-flex">
      <rb-form-select
        [label]="'widgets.table.typeLabel' | translate"
        [(ngModel)]="tooltipRow.type"
        (ngModelChange)="notifyChange()"
        class="TooltipRow_column"
        [required]="true"
        [name]="'columnType'"
      >
        <option [value]="'json'">{{ 'widgets.table.jsonPropertyOption' | translate }}</option>
        <option [value]="'hyperlink'">{{ 'widgets.table.hyperlinkOption' | translate }}</option>
        <option [value]="'text'">Text</option>
      </rb-form-select>
    </div>
    <ng-container *ngIf="tooltipRow.type === 'hyperlink'">
      <ellipsis-text>{{ 'widgets.table.urlHint' | translate }}</ellipsis-text>
      <rb-form-input
        [label]="'widgets.table.linkLabel' | translate"
        [(ngModel)]="tooltipRow.path"
        (ngModelChange)="notifyChange()"
        [required]="true"
        [name]="'linkLabel' + i"
        placeholder="http://bosch-iot-insights.com/ui/project/datapath=${[i].payload.datapath}"
        urlSchemeValidator
      >
        <ng-template rbFormValidationMessage="schemeMatchError" let-errorData="data">
          <url-scheme-error [schemes]="errorData"></url-scheme-error>
        </ng-template>
      </rb-form-input>
    </ng-container>
    <div class="d-flex" *ngIf="tooltipRow.type === 'hyperlink'">
      <rb-form-input
        [label]="'widgets.table.linkDisplayTextLabel' | translate"
        class="TooltipRow_column TooltipRow_rightMargin"
        [maxlength]="15"
        [(ngModel)]="tooltipRow.label"
        (ngModelChange)="notifyChange()"
        [name]="'linkDisplayText' + i"
      ></rb-form-input>
      <rb-form-select
        [label]="'widgets.table.actionLabel' | translate"
        [(ngModel)]="tooltipRow.linkTarget"
        (ngModelChange)="notifyChange()"
        class="TooltipRow_column TooltipRow_leftMargin"
        [name]="'linkAction' + i"
        [required]="true"
      >
        <option [value]="'_blank'">{{ 'widgets.table.openNewTabOption' | translate }}</option>
        <option [value]="'_self'">{{ 'widgets.table.openSameTabOption' | translate }}</option>
      </rb-form-select>
    </div>
    <div class="d-flex" *ngIf="tooltipRow.type === 'json'">
      <rb-form-input
        [label]="'widgets.dataTooltip.label' | translate"
        class="TooltipRow_column TooltipRow_rightMargin"
        [maxlength]="15"
        [(ngModel)]="tooltipRow.label"
        [attr.data-cy]="'dataTooltipLabel-' + i"
        (ngModelChange)="notifyChange()"
        [name]="'valueLabel' + i"
        style="min-width: 100px"
      ></rb-form-input>
      <data-selector-input
        class="flex-grow-1 TooltipRow_pathColumn"
        [paths]="paths"
        [requireIteration]="false"
        [attr.data-cy]="'dataTooltipPath-' + i"
        [label]="'widgets.table.valueLabel' | translate"
        [(ngModel)]="tooltipRow.path"
        (ngModelChange)="updatePathValue($event, tooltipRow)"
        [name]="'value' + i"
        [required]="true"
      ></data-selector-input>
    </div>
    <div class="d-flex" *ngIf="tooltipRow.type === 'text'">
      <rb-form-input
        [label]="'widgets.tooltips.text' | translate"
        class="TooltipRow_column TooltipRow_rightMargin"
        [maxlength]="15"
        [required]="true"
        [(ngModel)]="tooltipRow.text"
        (ngModelChange)="notifyChange()"
        [name]="'valueLabel' + i"
      ></rb-form-input>
    </div>
    <div class="d-flex justify-content-end align-items-center">
      <button
        (click)="removeTooltipRow(tooltipRow)"
        class="rb-btn rb-link TooltipRow_removeButton mr-1"
      >
        <span class="rb-ic rb-ic-delete"></span>
        <span class="vlign-icons">{{ 'widgets.table.deleteButton' | translate }}</span>
      </button>
    </div>
  </div>

  <div class="text-right TooltipRow_addButton">
    <button
      class="rb-btn rb-link"
      type="button"
      data-cy="tooltipAddButton"
      (click)="addTooltipRow()"
      [disabled]="tooltips.length >= 10"
    >
      <span class="rb-ic rb-ic-add mr-1"></span>
      <span class="vlign-icons">{{ 'widgets.dataTooltip.addTooltipRow' | translate }}</span>
    </button>
  </div>
</form>
