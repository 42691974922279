import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { FieldSelectOption } from '../../shared/filter-model/filter.model';
import { ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { emptyFunction, FormInputComponent } from '@inst-iot/bosch-angular-ui-components';
import { collectionTypes } from '../../shared-projects/services/collections.service';

export interface DataSetSelection {
  collection: string;
  id: string;
}

@Component({
  selector: 'select-dataset-filter',
  templateUrl: './select-dataset-filter.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectDatasetFilterComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => SelectDatasetFilterComponent),
      multi: true
    }
  ]
})
export class SelectDatasetFilterComponent implements OnInit, ControlValueAccessor {
  value: DataSetSelection;
  disabled = false;

  collectionTypes = collectionTypes;

  @Output() validChange = new EventEmitter<boolean>();

  @Input() collectionOptions: FieldSelectOption[];

  @Input() requiredId = false;

  @Input() customClass = '';

  pattern = /^[a-fA-F0-9]{24}|\{.+\}$/;

  onChange = emptyFunction;
  onTouched = emptyFunction;
  @ViewChild('dataIdControl') dataIdComponent: FormInputComponent;

  ngOnInit() {
    if (!this.value && this.collectionOptions && this.collectionOptions.length) {
      this.value = {
        collection: this.collectionOptions[0].value,
        id: ''
      };
      setTimeout(() => {
        this.onChange(this.value);
      });
    }
  }

  get collection() {
    if (!this.value) {
      return '';
    }
    return this.value.collection;
  }

  set collection(collectionName: string) {
    if (!this.value) {
      this.value = { collection: '', id: '' };
    }
    this.value.collection = collectionName;
    this.onChange(this.value);
  }

  get dataId() {
    if (!this.value) {
      return '';
    }
    return this.value.id;
  }

  set dataId(dataId: string) {
    if (!this.value) {
      this.value = { collection: '', id: '' };
    }
    this.value.id = dataId;
    this.onChange(this.value);
  }

  writeValue(value: any): void {
    if (!value && this.collectionOptions && this.collectionOptions.length) {
      this.value = {
        collection: this.collectionOptions[0].value,
        id: ''
      };
    } else if (typeof value !== 'string') {
      this.value = value;
    }
  }

  validate() {
    if (this.dataIdComponent.ngControl.valid) {
      return null;
    }
    return this.dataIdComponent.ngControl.errors;
  }

  registerOnChange(fn: (_: any) => void): void {
    this.onChange = fn;
    this.onChange(this.value);
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = false;
  }
}
