import { map } from 'rxjs/operators';
import { Observable, OperatorFunction } from 'rxjs';
import { getValueByPath, PathItemType } from '../../data-selection/data-info-util';
import moment from 'moment';

export type TableContextType =
  | 'rootArray' // using the array at root to represent rows
  | 'firstInArray' // using an array out of the first object to represent rows
  | 'firstToRows'; // converting the first object to rows with key and value
export type ChartUsageType = 'category' | 'number' | 'time' | 'point';

export const dataTypesDefinition: { value: PathItemType; label: string; defaultFormat: string }[] =
  [
    { value: 'string', label: 'String', defaultFormat: 'string' },
    { value: 'number', label: 'Number', defaultFormat: 'decimal1' },
    { value: 'boolean', label: 'Boolean', defaultFormat: 'truefalse' },
    { value: 'array', label: 'Array', defaultFormat: 'sum' },
    { value: 'map', label: 'Map', defaultFormat: null },
    { value: 'null', label: 'Null', defaultFormat: null }
  ];

export const formattingsDefinition = [
  { value: 'string', label: 'String', requiredTypes: null, usages: ['category'] },
  { value: 'int', label: 'Integer', requiredTypes: ['number', 'string'], usages: ['number'] },
  {
    value: 'decimal1',
    label: 'Decimal 1',
    requiredTypes: ['number', 'string'],
    usages: ['number']
  },
  {
    value: 'decimal2',
    label: 'Decimal 2',
    requiredTypes: ['number', 'string'],
    usages: ['number']
  },
  {
    value: 'decimal3',
    label: 'Decimal 3',
    requiredTypes: ['number', 'string'],
    usages: ['number']
  },
  { value: 'date', label: 'Date', requiredTypes: ['number', 'string'], usages: ['time'] },
  { value: 'dateTime', label: 'Date Time', requiredTypes: ['number', 'string'], usages: ['time'] },
  { value: 'time', label: 'Time', requiredTypes: ['number', 'string'], usages: ['time'] },
  { value: 'minute', label: 'Minute', requiredTypes: ['number', 'string'], usages: ['category'] },
  { value: 'hour', label: 'Hour', requiredTypes: ['number', 'string'], usages: ['category'] },
  {
    value: 'hourOfDay',
    label: 'Hour of Day',
    requiredTypes: ['number', 'string'],
    usages: ['category']
  },
  { value: 'day', label: 'Day', requiredTypes: ['number', 'string'], usages: ['category'] },
  {
    value: 'dayOfWeek',
    label: 'Day of Week',
    requiredTypes: ['number', 'string'],
    usages: ['category']
  },
  { value: 'week', label: 'Week', requiredTypes: ['number', 'string'], usages: ['category'] },
  { value: 'month', label: 'Month', requiredTypes: ['number', 'string'], usages: ['category'] },
  { value: 'percent', label: 'Percent', requiredTypes: ['number', 'string'], usages: ['number'] },
  // {value: 'percentOfTotal', label: 'Percent of total', requiredTypes: ['number', 'string'], usages: ['number']},
  {
    value: 'yesNo',
    label: 'Yes/No',
    requiredTypes: ['number', 'boolean'],
    usages: ['number', 'category']
  },
  {
    value: 'trueFalse',
    label: 'True/False',
    requiredTypes: ['number', 'boolean'],
    usages: ['number', 'category']
  },
  {
    value: 'binary',
    label: '1/0',
    requiredTypes: ['number', 'boolean'],
    usages: ['number', 'category']
  },
  // {value: 'color', label: 'Color', requiredTypes: ['number', 'boolean', 'string'], usages: ['category']},
  { value: 'sum', label: 'Sum', requiredTypes: ['array'], usages: ['number'] },
  { value: 'max', label: 'Max', requiredTypes: ['array'], usages: ['number'] },
  { value: 'min', label: 'Min', requiredTypes: ['array'], usages: ['number'] },
  { value: 'length', label: 'Length', requiredTypes: ['array', 'string'], usages: ['number'] },
  { value: 'geoLonLat', label: 'Lon/Lat', requiredTypes: ['array'], usages: ['coordinates'] },
  { value: 'geoLatLon', label: 'Lat/Lon', requiredTypes: ['array'], usages: ['coordinates'] }
];

export const dateFormattingOptions = [
  'l LT',
  'LT',
  'l',
  'll LT',
  'MMM Do',
  'MMM Do HH:mm',
  'MMM Do HH:mm:ss',
  'YYYY-MM-DD HH:mm',
  'YYYY-MM-DD HH:mm:ss',
  'YYYY-MM-DD',
  'HH:mm',
  'HH:mm:ss',
  'ddd',
  'ddd HH:mm',
  'ddd, Do HH:mm'
];

export const onlyDateFormattingOptions = ['l', 'L', 'll', 'MMM Do', 'YYYY-MM-DD', 'ddd', 'ddd, Do'];

export const onlyTimeFormattingOptions = [
  'LT',
  'HH\\h',
  'HH:mm',
  'HH:mm:ss',
  'HH:mm:ss.S',
  'HH:mm:ss.SS',
  'HH:mm:ss.SSS',
  'ss',
  'ss.SSS'
];

export interface FormattingOptions {
  suffix?: string;
  prefix?: string;
  format?: string;
  thousandsSeparator?: boolean;
}

export interface TableColumnConfig {
  /**
   * Path within the row object
   */
  path: string[];

  /**
   * Expected data type from the data
   */
  dataType: PathItemType;

  /**
   * Format type into which the input should be transformed
   */
  format: string;

  /**
   * Formatting options for the human readable format
   */
  formatOptions?: FormattingOptions;

  /**
   * Label of the column
   */
  label: string;
}

export interface TableContextConfig {
  /**
   * Type of the context defined how the data is transformed
   */
  type: TableContextType;

  /**
   * What path is used as context.
   * For type = rootArray this is the path in each entry
   * For type = firstInArray this is the path in the object to an array
   */
  path: string[];

  /**
   * Only for type = firstInArray.
   * Zips in additional arrays based on the index of the array defined in path
   */
  zipPaths?: string[][];

  /**
   * The columns of the table
   */
  columns: TableColumnConfig[];
}

export class TableContext {
  constructor(public config: TableContextConfig, public localeConfig: string) {
    if (!config.path) {
      config.path = [];
    }
  }

  isColumnConfigValid(col: TableColumnConfig) {
    return col.path && col.format && col.dataType;
  }

  getColumnByPathId(pathId: string): TableColumnConfig {
    return this.config.columns.find((col) => col.path.join('.') === pathId);
  }

  getColumnByIndex(index: number): TableColumnConfig {
    return this.config.columns[index];
  }

  getColumnIndex(col: TableColumnConfig) {
    return this.config.columns.indexOf(col);
  }

  /**
   * Transforms the source data into an array that can be used as rows for a table
   */
  extractRows(): OperatorFunction<any, any[]> {
    return (source: Observable<any>): Observable<any[]> => {
      return source.pipe(
        map((data) => {
          if (this.config.type === 'rootArray') {
            return this.transformRootArray(data);
          }
          if (this.config.type === 'firstInArray') {
            return this.transformFirstInArray(data);
          }
          if (this.config.type === 'firstToRows') {
            return this.transformFirstToRows(data);
          }
          return [];
        })
      );
    };
  }

  getFormattedCell(rowData: any, col: TableColumnConfig) {
    const value = getValueByPath(rowData, col.path);
    return this.formatRawOutputValue(value, col.dataType, col.format, col.formatOptions);
  }

  getValidColumns(): TableColumnConfig[] {
    return this.config.columns.filter((col) => this.isColumnConfigValid(col));
  }

  getFormattedRow(rowData: any) {
    return this.getValidColumns().map((col) => {
      return this.getFormattedCell(rowData, col);
    });
  }

  getRenderFormattedRow(rowData: any) {
    return this.getValidColumns().map((col) => {
      const value = getValueByPath(rowData, col.path);
      const result = this.formatRenderValue(value, col.dataType, col.format, col.formatOptions);
      if (col.format === 'decimal1' && isNaN(result)) {
        console.log('invalid value ', { col: col, input: value, result: result });
      }
      return result;
    });
  }

  formatRawOutputValue(
    value: any,
    dataType: string,
    format: string,
    formatOptions: FormattingOptions
  ) {
    const renderValue = this.formatRenderValue(value, dataType, format, formatOptions);
    return this.formatOutputValue(renderValue, format, formatOptions);
  }

  /**
   * Format for human readability from render formatted value
   */
  formatOutputValue(value: any, format: string, formatOptions: FormattingOptions) {
    if (!formatOptions) {
      formatOptions = {};
    }
    if (format === 'string') {
      return value;
    }
    if (format === 'dateTime') {
      value = moment(value).format(formatOptions.format ? formatOptions.format : 'l LT');
    }
    if (format === 'date') {
      value = moment(value).format(formatOptions.format ? formatOptions.format : 'l');
    }
    if (format === 'time') {
      value = moment(value).format(formatOptions.format ? formatOptions.format : 'hh:mm:ss.SS');
    }
    if (format === 'int' && typeof value === 'number') {
      value = this.formatNumberValue(value, formatOptions, null);
    }
    if (format === 'decimal1' && typeof value === 'number') {
      value = this.formatNumberValue(value, formatOptions, 1);
    }
    if (format === 'decimal2' && typeof value === 'number') {
      value = this.formatNumberValue(value, formatOptions, 2);
    }
    if (format === 'decimal3' && typeof value === 'number') {
      value = this.formatNumberValue(value, formatOptions, 4);
    }
    if (format === 'percent' && typeof value === 'number') {
      value = (value * 100).toFixed(1) + '%';
    }
    if (format === 'yesNo') {
      value = value ? 'Yes' : 'No';
    }
    if (format === 'trueFalse') {
      value = value ? 'True' : 'False';
    }
    if (format === 'binary') {
      value = value ? '1' : '0';
    }
    if (formatOptions) {
      if (formatOptions.prefix) {
        value = formatOptions.prefix + value;
      }
      if (formatOptions.suffix) {
        value = value + formatOptions.suffix;
      }
    }
    return value;
  }

  formatNumberValue(value, options, numDigits) {
    if (options.thousandsSeparator === true) {
      return value.toLocaleString(this.localeConfig, {
        minimumFractionDigits: numDigits,
        maximumFractionDigits: numDigits
      });
    }
    return value.toLocaleString(this.localeConfig, {
      minimumFractionDigits: numDigits,
      maximumFractionDigits: numDigits,
      useGrouping: false
    });
  }
  /**
   * Format for rendering in a chart library
   */
  formatRenderValue(value, dataType: string, format: string, formatOptions: FormattingOptions) {
    if (format === 'string') {
      value = String(value);
      if (formatOptions) {
        if (formatOptions.prefix) {
          value = formatOptions.prefix + value;
        }
        if (formatOptions.suffix) {
          value = value + formatOptions.suffix;
        }
      }
      return value;
    }
    if (format === 'int') {
      if (isNaN(value) || value === null) {
        return null;
      }
      return parseInt(value, 10);
    }
    if (format === 'decimal1' || format === 'decimal2' || format === 'decimal3') {
      if (isNaN(value) || value === null) {
        return null;
      }
      return parseFloat(value);
    }
    if (format === 'dateTime' || format === 'date' || format === 'time') {
      return new Date(value);
    }
    if (format === 'minute') {
      return moment(value).startOf('minute').format('hh:mm');
    }
    if (format === 'hour') {
      return moment(value).startOf('hour').format('ddd, hA');
    }
    if (format === 'hourOfDay') {
      return moment(value).startOf('hour').format('hh');
    }
    if (format === 'day') {
      return moment(value).startOf('day').format('ll');
    }
    if (format === 'dayOfWeek') {
      return moment(value).startOf('day').format('ddd');
    }
    if (format === 'week') {
      return moment(value).startOf('week').format('WW YYYY');
    }
    if (format === 'month') {
      return moment(value).startOf('month').format('MMM YYYY');
    }
    if (format === 'length') {
      return value ? value.length : 0;
    }
    if (format === 'yesNo' || format === 'trueFalse' || format === 'binary') {
      return value ? 1 : 0;
    }
    if (format === 'sum') {
      return Array.isArray(value) ? value.reduce((sum, e) => sum + e, 0) : 0;
    }
    return value;
  }

  private transformRootArray(data) {
    if (!Array.isArray(data)) {
      return null;
    }
    return data.map((d) => getValueByPath(d, this.config.path));
  }

  private transformFirstInArray(data) {
    if (!Array.isArray(data) || !data.length) {
      return [];
    }
    const firstData = data[0];
    const paths = [this.config.path, ...this.config.zipPaths];
    const pathNames = paths.map((path) => path[path.length - 1]);
    const uniquePathNames = pathNames.map((name, i) => {
      const count = countInArray(pathNames.slice(0, i), name);
      return count ? name + '-' + count : name;
    });

    const dataByPath = {};
    paths.forEach((path, pi) => {
      dataByPath[uniquePathNames[pi]] = getValueByPath(firstData, path);
    });

    const primaryArray = dataByPath[uniquePathNames[0]];
    if (!primaryArray) {
      return [];
    }
    return primaryArray.map((d, i) => {
      const entry = {};
      paths.forEach((path, pi) => {
        entry[uniquePathNames[pi]] = dataByPath[uniquePathNames[pi]][i];
      });
      return entry;
    });
  }

  private transformFirstToRows(data) {
    if (!Array.isArray(data) || !data.length) {
      return [];
    }
    const firstData = data[0];
    if (!firstData) {
      return [];
    }
    return Object.keys(firstData).map((key) => ({ key: key, value: firstData[key] }));
  }
}

function countInArray(arr: string[], name: string) {
  return arr.reduce((count, n) => (n === name ? count + 1 : count), 0);
}
