<div class="d-flex">
  <button
    class="rb-btn ColorPickerInputButton"
    [rbDropdown]="colorDropdown"
    [style.backgroundColor]="colorConfig.color"
    [autoClose]="false"
  >
    &nbsp;
  </button>
  <rb-form-input
    class="flex-grow-1"
    [label]="label"
    [ngModel]="colorConfig.color"
    (ngModelChange)="updateValue($event)"
    [required]="required"
  ></rb-form-input>
</div>

<ng-template #colorDropdown>
  <color-picker
    [config]="colorConfig"
    [enableAlpha]="enableAlpha"
    (configChange)="updateConfig($event)"
  ></color-picker>
</ng-template>
