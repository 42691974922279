import { FilterParameterConfig } from '../widgets/filter-widget/filter-widget.model';
import {
  DeviceDashboardParameter,
  MultiDeviceDashboardParameter
} from '../models/device-dashboard-parameter';
import {
  DateTimeRangeDashboardParameter,
  MultiDateTimeRangeDashboardParameter
} from '../models/date-time-range-dashboard-parameter';
import { DashboardParameter, DashboardParameterOptions } from '../models/dashboard-parameter';
import { RangeDashboardParameter } from '../models/range-dashboard-parameter';
import { DevicesService } from '../../devices/services/devices.service';
import {
  MultiPrimitiveDashboardParameter,
  PrimitiveDashboardParameter,
  PrimitiveDashboardParameterValue
} from '../models/primitive-dashboard-parameter';
import { FieldType } from '../../shared-modules/static-filter/static-filter.model';
import {
  FieldConfig,
  INPUT_WIDGET_LOCAL_STORAGE_KEY,
  InputFieldType,
  mapFieldTypeToDashboardParameter
} from '../widgets/input-widget/models/input.model';
import { isNull } from 'lodash-es';

type ConverterMap = Record<FieldType, (defaultValue: any) => DashboardParameter>;

function getConverter(
  param: FilterParameterConfig,
  widgetId: string,
  devicesService: DevicesService,
  options: DashboardParameterOptions
): (defaultValue: any) => DashboardParameter {
  const multiParameterConverter: Partial<ConverterMap> = {
    device: (defaultParamValue) =>
      new MultiDeviceDashboardParameter(
        param.name,
        param.i18nLabel,
        widgetId,
        devicesService,
        defaultParamValue,
        param.valuePath,
        options
      ),
    datetimerange2: (defaultParamValue) =>
      new MultiDateTimeRangeDashboardParameter(
        param.name,
        param.i18nLabel,
        widgetId,
        defaultParamValue,
        options
      ),
    checkbox: (defaultParamValue) =>
      new MultiPrimitiveDashboardParameter(
        param.name,
        param.i18nLabel,
        'boolean',
        widgetId,
        defaultParamValue,
        options
      ),
    text: (defaultParamValue) =>
      new MultiPrimitiveDashboardParameter(
        param.name,
        param.i18nLabel,
        'string',
        widgetId,
        defaultParamValue,
        options
      )
  };
  multiParameterConverter.date = multiParameterConverter.datetimerange2;

  const scalarParameterConverter: Partial<ConverterMap> = {
    device: (defaultParamValue) =>
      new DeviceDashboardParameter(
        param.name,
        param.i18nLabel,
        widgetId,
        devicesService,
        defaultParamValue,
        param.valuePath,
        options
      ),
    datetimerange2: (defaultParamValue) =>
      new DateTimeRangeDashboardParameter(
        param.name,
        param.i18nLabel,
        widgetId,
        defaultParamValue,
        options
      ),
    checkbox: (defaultParamValue) =>
      new PrimitiveDashboardParameter(
        param.name,
        param.i18nLabel,
        'boolean',
        widgetId,
        defaultParamValue,
        options
      ),
    number: (defaultParamValue) =>
      new RangeDashboardParameter(
        param.name,
        param.i18nLabel,
        'number',
        widgetId,
        defaultParamValue,
        options
      ),
    text: (defaultParamValue) =>
      new PrimitiveDashboardParameter(
        param.name,
        param.i18nLabel,
        'string',
        widgetId,
        defaultParamValue,
        options
      )
  };
  scalarParameterConverter.date = scalarParameterConverter.datetimerange2;

  const converters = param.multipleValues ? multiParameterConverter : scalarParameterConverter;
  return converters[param.type] ?? converters.text;
}

export function convertFilterParameterToDashboardParameter(
  param: FilterParameterConfig,
  devicesService: DevicesService,
  widgetId: string,
  useLocalStorage: boolean,
  setDefaultValueOnlyIfParamIsRequired = true
): DashboardParameter {
  const options = {
    skipWidgetPrefix: true,
    isLocalStorage: useLocalStorage,
    isQueryParam: true,
    isGlobal: param.global
  };
  const defaultParamValue =
    param.required || !setDefaultValueOnlyIfParamIsRequired ? param.defaultValue : undefined;

  const converter = getConverter(param, widgetId, devicesService, options);
  if (param.multipleValues) {
    return converter(defaultParamValue ? [defaultParamValue] : []);
  } else {
    return converter(defaultParamValue);
  }
}

export function convertInputParameterToDashboardParameter(
  { type, label, technicalName }: FieldConfig,
  widgetId: string,
  value?: PrimitiveDashboardParameterValue | PrimitiveDashboardParameterValue[],
  storageKeyParam?: PrimitiveDashboardParameter
): DashboardParameter {
  const options = {
    isQueryParam: true,
    isLocalStorage: !isNull(storageKeyParam.resolvedValue),
    skipWidgetPrefix: true
  };
  const name = INPUT_WIDGET_LOCAL_STORAGE_KEY + technicalName;

  // we need to also use DateTimeRangeDashboardParameter and RangeDashboardParameter for the date and number fields
  // unfortunately this will require a change of the param format which will break the existing dashboards
  if (type === 'multiSelection') {
    return new MultiPrimitiveDashboardParameter(
      name,
      label,
      mapFieldTypeToDashboardParameter(type),
      widgetId,
      value as PrimitiveDashboardParameterValue[],
      options
    );
  }

  return new PrimitiveDashboardParameter(
    name,
    label,
    mapFieldTypeToDashboardParameter(type),
    widgetId,
    value as PrimitiveDashboardParameterValue,
    options
  );
}
