<ng-container *ngIf="action; else other">
  <span
    class="d-inline-block badge badge-pill"
    [ngClass]="action.class"
    [rbTooltip]="action.tooltip | translate"
  >
    {{ action.title | translate }}
  </span>
</ng-container>

<ng-template #other>
  <span class="d-inline-block badge badge-pill badge-info">
    {{ actionType }}
  </span>
</ng-template>
