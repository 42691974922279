import { Component, Injector, OnInit } from '@angular/core';
import {
  CSVDownloadPreset,
  downloadOptions,
  DownloadParameter,
  presets
} from './csv-download-model';
import { CsvSettingNameDialogComponent } from './user-csv-setting/csv-setting-name-dialog.component';
import { LocalStorageService } from 'ngx-localstorage';
import { ModalService } from '@inst-iot/bosch-angular-ui-components';

@Component({
  selector: 'download-dialog',
  templateUrl: './download-dialog.component.html'
})
export class DownloadDialogComponent implements OnInit {
  // The download parameter which are being used to download
  parameter = new DownloadParameter();

  // The preset that is selected on top
  selectedPreset: CSVDownloadPreset = null;

  options = downloadOptions;

  presets = presets;

  localPresetSettingName: string = null;

  showDetails = false;

  settingsName: string = null;

  localCsvDownloadSettings: CSVDownloadPreset[] = [];

  constructor(
    private localStorage: LocalStorageService,
    private modalService: ModalService,
    private injector: Injector
  ) {}

  customizeButtonAction = () => {
    this.toggleDetails();
  };

  ngOnInit() {
    const csvDownloadSetting =
      this.localStorage.get<CSVDownloadPreset[]>('localCsvDownloadSetting');
    if (csvDownloadSetting) {
      this.localCsvDownloadSettings = csvDownloadSetting;
      this.presets = this.presets.concat(this.localCsvDownloadSettings);
    }
    const selectedPresetLabel = this.localStorage.get<string>('csvDownloadSelectedLabel');
    const preset = this.findPresetByLabel(selectedPresetLabel) || presets[0];
    this.loadPreset(preset.label);
  }

  findPresetByLabel(label: string): CSVDownloadPreset {
    return this.presets.find((preset) => preset.label === label);
  }

  saveAsClicked() {
    this.modalService
      .openComponent(CsvSettingNameDialogComponent, { stacked: true }, this.injector)
      .result.then((label) => {
        if (label) {
          this.saveAs(label);
        }
      });
  }

  saveChanges() {
    const retrievedSetting = DownloadParameter.fromObject(this.parameter);
    retrievedSetting.label = this.localPresetSettingName;
    this.updateLocalSettingsList(retrievedSetting);
  }

  updateLocalSettingsList(retrievedSetting) {
    const label = retrievedSetting.label;
    const setting = this.findSettingByLabel(label, this.localCsvDownloadSettings);
    if (setting) {
      Object.assign(setting, retrievedSetting);
      this.storeLocalCsvDownloadSetting();
    }
  }

  storeLocalCsvDownloadSetting() {
    console.log('storeLocalCsvDownloadSetting', this.localCsvDownloadSettings);
    this.localStorage.set('localCsvDownloadSetting', this.localCsvDownloadSettings);
  }

  loadPreset(preset: string) {
    this.selectedPreset = this.findPresetByLabel(preset);
    Object.assign(this.parameter, this.selectedPreset);
    // if charset is limited and option is not in the list, select the first one available
    if (!this.options.charset.map((item) => item.value).includes(this.parameter.charset)) {
      this.parameter.charset = this.options.charset[0].value;
    }
    this.localPresetSettingName = this.findSettingByLabel(
      this.selectedPreset.label,
      this.localCsvDownloadSettings
    )
      ? this.selectedPreset.label
      : null;
    this.localStorage.set('csvDownloadSelectedLabel', this.selectedPreset.label);
  }

  isPresetActive(preset) {
    return this.selectedPreset === preset;
  }

  toggleDetails() {
    this.showDetails = !this.showDetails;
  }

  findSettingByLabel(label: string, settings: any[]): CSVDownloadPreset {
    return settings.find((setting) => setting.label === label);
  }

  saveAs(uiSettingsName: string) {
    this.settingsName = uiSettingsName;
    const setting = DownloadParameter.fromObject(this.parameter);
    setting.label = uiSettingsName;
    this.localCsvDownloadSettings.push(setting);
    this.presets.push(setting);
    this.storeLocalCsvDownloadSetting();
    this.loadPreset(setting.label);
    this.toggleDetails();
  }

  findIndexByLabel(label: string, settings: any[]): number {
    return settings.findIndex((item) => item.label === label);
  }

  deleteDownloadSetting(settingName: string) {
    const downloadSettingIndex = this.findIndexByLabel(settingName, this.localCsvDownloadSettings);
    if (downloadSettingIndex !== -1) {
      this.localCsvDownloadSettings.splice(downloadSettingIndex, 1);
    }

    const presetIndex = this.findIndexByLabel(settingName, this.presets);
    if (presetIndex !== -1) {
      this.presets.splice(presetIndex, 1);
    }

    this.localStorage.set('localCsvDownloadSetting', this.localCsvDownloadSettings);
    this.toggleDetails();
    this.loadPreset(this.presets[0].label);
  }
}
