import { AbstractDashboardParameter, DashboardParameterOptions } from './dashboard-parameter';
import { Device } from '../../devices/models/device';
import { DeviceSearchResult, DevicesService } from '../../devices/services/devices.service';
import { getValueByPath } from '../../shared-modules/data-selection/data-info-util';
import { LoadingEntity } from '@inst-iot/bosch-angular-ui-components';
import { ParamMap } from '@angular/router';
import { UrlSearchParamMap } from './url-search-param-map';

export interface DeviceParameterValue {
  thingId: string;
  value?: string;
  device?: Device;
}

export class DeviceDashboardParameter extends AbstractDashboardParameter<
  string,
  DeviceParameterValue
> {
  private devicesService: DevicesService;
  private valuePath: string;
  private deviceLoader = new LoadingEntity<Device>();
  private loadingThingId: string = null;

  constructor(
    name: string,
    i18nLabel: string,
    widgetId: string,
    deviceService: DevicesService,
    value?: string,
    valuePath?: string,
    options?: DashboardParameterOptions
  ) {
    super(name, i18nLabel, 'ThingId', widgetId, value, undefined, options);
    this.devicesService = deviceService;
    this.valuePath = valuePath;
    this.writeValue(value);
  }

  writeValue(thingId: string, writeOnlyOnChange = false) {
    if (!thingId || typeof thingId !== 'string') {
      super.writeValue(undefined);
      return;
    }
    if (thingId === this.loadingThingId) {
      if (!writeOnlyOnChange && this.resolvedValue !== undefined) {
        this.valueChanges.next(this.value);
        this.resolvedValueChanges.next(this.resolvedValue);
      }
      return;
    }
    this.loadingThingId = thingId;
    this.valueChanges.next(thingId);
    this.resolvedValueChanges.next(undefined);
    this.deviceLoader.run(this.devicesService.getDevice(thingId)).subscribe({
      next: (device) => {
        if (!device) {
          this.resolvedValueChanges.next(null);
          return;
        }
        const valuePath = this.valuePath || 'thingId';
        const value = getValueByPath(device, valuePath.split(/[./]/));
        this.resolvedValueChanges.next({ thingId, device, value });
      },
      error: () => {
        this.resolvedValueChanges.next(null);
      }
    });
  }

  toFilterParams(): Record<string, any> {
    return {
      [this.name]: this.resolvedValue?.value,
      [this.name + 'ThingId']: this.value
    };
  }

  toParams(): ParamMap {
    const params = new URLSearchParams();
    if (this.value !== undefined) {
      params.set(this.paramKey, this.value ?? null);
    }

    return new UrlSearchParamMap(params);
  }

  fromParams(params: ParamMap, writeOnlyOnChange = false) {
    if (!params.has(this.paramKey)) {
      return;
    }
    this.writeValue(params.get(this.paramKey), writeOnlyOnChange);
  }
}

export class MultiDeviceDashboardParameter extends AbstractDashboardParameter<
  string[],
  DeviceParameterValue[]
> {
  private devicesService: DevicesService;
  private valuePath: string;
  private deviceLoader = new LoadingEntity<DeviceSearchResult>();
  private loadingThingIds = [];

  constructor(
    name: string,
    i18nLabel: string,
    widgetId: string,
    deviceService: DevicesService,
    value?: string[],
    valuePath?: string,
    options?: DashboardParameterOptions
  ) {
    super(name, i18nLabel, 'ThingId[]', widgetId, value ?? [], undefined, options);
    this.devicesService = deviceService;
    this.valuePath = valuePath;
    this.writeValue(value);
  }

  writeValue(thingIds: string[], writeOnlyOnChange = false) {
    if (!thingIds || !Array.isArray(thingIds)) {
      super.writeValue(undefined, writeOnlyOnChange);
      return;
    } else if (!thingIds.length || typeof thingIds[0] !== 'string') {
      super.writeValue([], writeOnlyOnChange);
      return;
    }
    if (this.loadingThingIds.join(',') === thingIds.join(',')) {
      if (!writeOnlyOnChange && this.resolvedValue !== undefined) {
        this.valueChanges.next(this.value);
        this.resolvedValueChanges.next(this.resolvedValue);
      }
      return;
    }
    this.loadingThingIds = thingIds;
    this.valueChanges.next(thingIds);
    this.resolvedValueChanges.next(undefined);
    const thingsIdsWithQuotes = thingIds.map((thingId) => `'${thingId}'`).join(',');
    this.deviceLoader
      .run(this.devicesService.findDevices(`in(thingId,${thingsIdsWithQuotes})`, null, 200))
      .subscribe({
        next: (result: DeviceSearchResult) => {
          if (!result) {
            this.resolvedValueChanges.next([]);
            return;
          }
          const valuePath = (this.valuePath || 'thingId').split(/[./]/);

          this.resolvedValueChanges.next(
            thingIds.map((thingId) => {
              const device = result.items.find((d) => d.thingId === thingId);
              return { thingId, device, value: getValueByPath(device, valuePath) };
            })
          );
        },
        error: () => {
          this.resolvedValueChanges.next([]);
        }
      });
  }

  toFilterParams(): Record<string, any> {
    return {
      [this.name]: this.resolvedValue
        ?.map((item) => item.value)
        .filter((value) => value !== undefined),
      [this.name + 'ThingId']: this.value
    };
  }

  toParams(): ParamMap {
    const params = new URLSearchParams();
    if (this.value !== undefined && Array.isArray(this.value) && this.value.length) {
      for (const thingId of this.value) {
        params.append(this.paramKey, thingId);
      }
    }

    return new UrlSearchParamMap(params);
  }

  fromParams(params: ParamMap, writeOnlyOnChange = false) {
    if (!params.has(this.paramKey)) {
      return;
    }
    this.writeValue(params.getAll(this.paramKey), writeOnlyOnChange);
  }
}
