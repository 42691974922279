<slate-children
  [children]="children"
  [context]="childrenContext"
  [viewContext]="viewContext"
></slate-children>
<div
  slateElement
  class="d-inline"
  [context]="context"
  [viewContext]="viewContext"
  [style.padding-left]="resolveIndent(element)"
  (click)="evaluateEdit()"
>
  <img
    [src]="element.url"
    alt=""
    [style.max-height]="element['maxHeight']"
    [style.max-width]="element['maxWidth']"
    [class.outline]="selection"
  />
</div>
