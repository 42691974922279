<div class="d-md-flex">
  <div class="ml-md-auto" style="font-size: small" *ngIf="retentionTimes.length > 0">
    <a
      position="bottom"
      class="align-self-center"
      href="#"
      [rbPopover]="retentionTimePopover"
      popoverStyle="max-width: 400px"
    >
      <span *ngIf="retentionTimes.length === 1">
        {{ retentionTimes[0] | duration: 'suitableUnit' }}
        {{ 'collectionSelector.retentionTimes.hint' | translate }}
      </span>
      <span *ngIf="retentionTimes.length > 1">
        {{ 'collectionSelector.retentionTimes.hintMultiple' | translate }}
      </span>
      <i class="rb-ic rb-ic-info-i"></i>
    </a>
  </div>
  <ng-template #retentionTimePopover>
    <collection-retention-time-popover
      [retentionTimes]="retentionTimes"
      [retentionTimeProperty]="retentionTimeProperties"
    ></collection-retention-time-popover>
  </ng-template>
</div>
