import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'tooltipContent'
})
export class TooltipContentPipe implements PipeTransform {
  transform(element: any, readonly: boolean): string | null {
    if (!readonly) {
      return null;
    }

    return element.tooltip;
  }
}
