import { AfterViewInit, Directive, ElementRef, Input, OnDestroy, Renderer2 } from '@angular/core';
import { debounceTime, fromEvent } from 'rxjs';
import { selectAndHighlightTableCell } from '../table.util';
import { adjustTableCellSize } from '../plugins/table';
import { Editor } from 'slate';

@Directive({
  selector: '[resizable]'
})
export class ResizableDirective implements AfterViewInit, OnDestroy {
  @Input() tableElementKey: string;
  @Input() readonly: boolean;
  @Input() editor: Editor;
  mutationObserver: MutationObserver;

  startX = 0;
  startY = 0;
  startWidth = 0;
  startHeight = 0;
  mouseDownListener: any;

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngAfterViewInit() {
    if (!this.readonly) {
      this.handleTableResize();

      // Observe changes to the table and re-attach resize events when it changes
      this.mutationObserver = new MutationObserver(() => this.handleTableResize());
      this.mutationObserver.observe(this.el.nativeElement, { childList: true, subtree: true });
    }
  }

  ngOnDestroy() {
    if (this.mouseDownListener) {
      this.mouseDownListener();
    }

    if (this.mutationObserver) {
      this.mutationObserver.disconnect();
    }
  }

  private handleTableResize() {
    const table = this.el.nativeElement as HTMLTableElement;
    const tds = table.querySelectorAll('td');
    const lastColumnIndex = table.rows[0].cells.length - 1;

    tds.forEach((td) => {
      this.renderer.setStyle(td, 'position', 'relative');
      const widthSizer = td.querySelector('.col-resize');
      const heightSizer = td.querySelector('.row-resize');

      this.handleMouseDown(widthSizer, td, this.mouseMoveHandler.bind(this, td, lastColumnIndex));
      this.handleMouseDown(heightSizer, td, this.mouseMoveHandler.bind(this, td, lastColumnIndex));
    });
  }

  private getAllTdsInSameColumn(columnIndex: number) {
    const table = this.el.nativeElement as HTMLTableElement;
    return table.querySelectorAll(`tr > td:nth-child(${columnIndex + 1})`);
  }

  private resizeColumn(allTdsInSameColumn: NodeListOf<Element>, dx: number) {
    allTdsInSameColumn.forEach((tdInSameColumn) => {
      this.renderer.setStyle(tdInSameColumn, 'width', `${this.startWidth + dx}px`);
    });
  }

  private handleLastColumnResize(td: Element, ev: MouseEvent, tr: Element, tableRowKey: string) {
    const table = this.el.nativeElement as HTMLTableElement;
    const tableWidth = ev.clientX - table.getBoundingClientRect().x;
    const tds = Array.from(table.rows[0].cells); // Get all td elements in the first row

    const originalSumOfTds = tds.reduce((acc, val) => acc + val.clientWidth, 0); // Original sum of all td widths (before resize)
    const tdWidthRatio = tds.map((val) => val.clientWidth / originalSumOfTds); // Calculate the ratio of each td width, i.e. [0.25, 0.25, 0.5]

    tds.forEach((td, idx) => {
      const tdWidthInPx = Math.max(tableWidth * tdWidthRatio[idx], 10);
      this.renderer.setStyle(td, 'width', `${tdWidthInPx}px`);

      const allTdsInSameColumn = this.getAllTdsInSameColumn(idx);
      const allDataKeysInSameColumn = Array.from(allTdsInSameColumn).map((td) =>
        td.getAttribute('data-key')
      );

      this.onResize(
        allDataKeysInSameColumn,
        tableRowKey,
        tdWidthInPx,
        this.startHeight + (ev.clientY - this.startY)
      );
    });
  }

  private mouseMoveHandler(td: Element, lastColumnIndex: number, ev: MouseEvent) {
    const dx = ev.clientX - this.startX; // Scale down the change in width
    const dy = ev.clientY - this.startY;
    const tr = td.parentElement;

    if (tr?.children) {
      const columnIndex = Array.from(tr.children).indexOf(td);
      const targetElement = ev.target as HTMLElement;
      const tableRowKey = tr.getAttribute('data-key');

      if (columnIndex !== lastColumnIndex || targetElement.classList.contains('row-resize')) {
        const allTdsInSameColumn = this.getAllTdsInSameColumn(columnIndex);
        const allDataKeysInSameColumn = Array.from(allTdsInSameColumn).map((td) =>
          td.getAttribute('data-key')
        );
        this.resizeColumn(allTdsInSameColumn, dx);
        this.onResize(
          allDataKeysInSameColumn,
          tableRowKey,
          this.startWidth + dx,
          this.startHeight + dy
        );
        this.renderer.setStyle(tr, 'height', `${this.startHeight + dy}px`);
      }

      if (columnIndex === lastColumnIndex) {
        this.handleLastColumnResize(td, ev, tr, tableRowKey);
      }
    }
  }

  private onResize(tableCellKeys: string[], tableRowKey: string, width: number, height: number) {
    selectAndHighlightTableCell(this.editor, this.tableElementKey);
    adjustTableCellSize(tableCellKeys, tableRowKey, this.editor, width, height);
  }

  private handleMouseDown(sizer: any, td: Element, mousemove: (event: MouseEvent) => void) {
    this.mouseDownListener = this.renderer.listen(sizer, 'mousedown', (ev: MouseEvent) => {
      this.startX = ev.clientX;
      this.startY = ev.clientY;
      this.startWidth = td.clientWidth;
      this.startHeight = td.clientHeight;

      const mouseMoveSubscription = fromEvent<MouseEvent>(document, 'mousemove')
        .pipe(debounceTime(3))
        .subscribe(mousemove);
      const mouseUpSubscription = fromEvent<MouseEvent>(document, 'mouseup').subscribe(() => {
        mouseMoveSubscription.unsubscribe();
        mouseUpSubscription.unsubscribe();
      });
    });
  }
}
