import { Injectable, OnDestroy } from '@angular/core';
import {
  buildDashboardParamId,
  DashboardParameter,
  DashboardParameterOptions
} from '../models/dashboard-parameter';
import { ActivatedRoute, ParamMap, Params } from '@angular/router';
import { LocalStorageService } from 'ngx-localstorage';
import { BehaviorSubject, combineLatest, merge, Observable, of, Subscription } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  skipWhile,
  switchMap
} from 'rxjs/operators';
import { ProjectsService } from '../../shared-projects/services/projects.service';
import { DashboardDataService } from './dashboard-data.service';
import { DashboardConfig } from '../models/DashboardConfig';
import { FilterParameterConfig } from '../widgets/filter-widget/filter-widget.model';
import { DevicesService } from '../../devices/services/devices.service';
import { UrlSearchParamMap } from '../models/url-search-param-map';
import {
  convertFilterParameterToDashboardParameter,
  convertInputParameterToDashboardParameter
} from './dashboard-param-utils';
import { PrimitiveDashboardParameter } from '../models/primitive-dashboard-parameter';
import { flatten, isArray, isEmpty, isEqual, isNull } from 'lodash-es';
import { DashboardWidgetConfig } from '../models/DashboardWidgetConfig';
import {
  FieldConfig,
  FieldType,
  INPUT_WIDGET_LOCAL_STORAGE_KEY,
  mapFieldTypeToDashboardParameter,
  MultiselectFieldConfig
} from '../widgets/input-widget/models/input.model';
import { getSelectedValuesAsArray } from '../widgets/input-widget/utils/input-config-util';
import { FilterWidgetGlobalParametersService } from '../widgets/filter-widget/filter-widget-edit/filter-widget-global-parameters.service';
import { NavigationBackService } from './navigation-back.service';
import { GLOBAL_PARAMETERS_PREFIX } from '../widgets/filter-widget/filter-widget-edit/filter-widget-edit.util';

export interface ParameterChangeInfo {
  parameter: DashboardParameter;
  valueChanged: boolean;
  resolvedValueChanged: boolean;
  lastValue: any;
  lastResolvedValue: any;
  value: any;
  resolvedValue: any;
  wait: boolean; // wait for resolve
}

export type ParameterFilterFunction = (p: DashboardParameter) => boolean;
const alwaysTrue: ParameterFilterFunction = () => true;

/**
 * Provided by the dashboard.component to manage the state of parameters
 */
@Injectable()
export class DashboardStateService implements OnDestroy {
  parameters$ = new BehaviorSubject<Map<string, DashboardParameter>>(new Map());

  private localStorageKey;
  private localStorageState = {};

  private subs = new Subscription();

  private dashboardUpdateSub = null;
  private dashboardInputUpdateSub = null;

  private lastParamUpdated: DashboardParameter;
  private initialFilterEmitted = false;
  private initialInputEmitted = false;
  private inputWidgets: DashboardWidgetConfig[];

  constructor(
    private route: ActivatedRoute,
    private localStorage: LocalStorageService,
    private dashboardDataService: DashboardDataService,
    private projectsService: ProjectsService,
    private devicesService: DevicesService,
    private globalParametersService: FilterWidgetGlobalParametersService,
    private navigationBackService: NavigationBackService
  ) {
    this.subs.add(
      this.dashboardDataService.configChange.subscribe((config) => {
        this.localStorageKey = `dashboardState_${this.projectsService.projectName}_${config.name}`;
        this.localStorageState = {
          ...(this.localStorage.get(this.localStorageKey) || {}),
          ...this.getFilterWidgetGlobalParametersFromLocalStorage(config)
        };
        this.registerFilterParameters(config);
        this.registerInputParameters(config);
      })
    );
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
    if (this.dashboardUpdateSub) {
      this.dashboardUpdateSub.unsubscribe();
    }
  }

  get queryParamsKeys(): string[] {
    return Object.keys(this.route.snapshot.queryParams);
  }

  get parameters(): Map<string, DashboardParameter> {
    return this.parameters$.value;
  }

  /**
   * Called by a widget to register a new parameter for the currently loaded dashboard.
   * This should trigger the loaded of saved state from local storage and from the query parameters.
   */
  registerParameter(
    p: DashboardParameter,
    multipleValues = false,
    useLocalStorageStateProperty = true
  ) {
    const newParams = new Map(this.parameters);
    newParams.set(buildDashboardParamId(p.widgetId, p.name), p);
    if (p.options.isLocalStorage) {
      const localStorage = this.localStorage.get(this.localStorageKey) || {};
      let value = useLocalStorageStateProperty ? this.localStorageState[p.id] : localStorage[p.id];
      // in case there is change in format of persisted data(multi values -> single and vice versa)
      if ((multipleValues && !Array.isArray(value)) || (!multipleValues && Array.isArray(value))) {
        value = p.value;
      }
      if (Array.isArray(value) && value.length === 0) {
        // Avoid disabled Multi-Select Filters to be wrongly initialized
        p.writeValue(undefined);
      } else if (value !== undefined) {
        p.writeValue(value);
      }

      this.syncLocalStorage(p, useLocalStorageStateProperty);
    }
    if (p.options.isQueryParam) {
      p.fromParams(this.route.snapshot.queryParamMap);
    }

    this.parameters$.next(newParams); // emit that a new parameter was added
  }

  updateParameterFromQueryParams(params: ParamMap) {
    for (const p of this.parameters.values()) {
      if (p.options.isQueryParam) {
        p.fromParams(params, true);
      }
    }
  }

  getParameter(name: string, widgetId: string) {
    return this.parameters.get(buildDashboardParamId(widgetId, name));
  }

  /**
   * Emits a parameter, when its value has changed.
   * Use this in the widget that needs to react on parameter changes.
   */
  getParameterChanges(widgetId: string, ...names: string[]): Observable<DashboardParameter> {
    const parameterObservables = this.getParameterChangeObservables(widgetId, ...names);
    return merge(...parameterObservables);
  }

  /**
   * Emits when one of the parameters storage value changes
   */
  getParametersAfterValueChanges(
    pFilter: ParameterFilterFunction = alwaysTrue
  ): Observable<DashboardParameter[]> {
    return this.parameters$.pipe(
      switchMap((params) =>
        combineLatest(
          [...params.values()]
            .filter((p) => pFilter(p))
            .map((p) =>
              p.valueChanges.pipe(
                distinctUntilChanged(isEqual),
                map(() => p)
              )
            )
        )
      )
    );
  }

  /**
   * Emits when one of the parameters resolved value changes
   */
  getParametersAfterResolvedValueChanges(
    pFilter: ParameterFilterFunction = alwaysTrue
  ): Observable<DashboardParameter[]> {
    return this.parameters$.pipe(
      switchMap((params) =>
        combineLatest(
          [...params.values()]
            .filter((p) => pFilter(p))
            .map((p) =>
              p.resolvedValueChanges.pipe(
                filter((v) => (p.value !== undefined && v !== undefined) || p.value === undefined),
                distinctUntilChanged(isEqual),
                map(() => p)
              )
            )
        )
      )
    );
  }

  /**
   * Emits each time a value has been written or resolved
   */
  getParameterChangeEvents(p: DashboardParameter): Observable<ParameterChangeInfo> {
    let lastValue = p.value;
    let lastResolvedValue = p.resolvedValue;

    return combineLatest([p.valueChanges, p.resolvedValueChanges]).pipe(
      debounceTime(1), // makes sure simultaneous changes to both don't result in 2 emits
      map(([value, resolvedValue]) => {
        const newState: ParameterChangeInfo = {
          parameter: p,
          lastValue,
          lastResolvedValue,
          value,
          resolvedValue,
          valueChanged: !isEqual(lastValue, value),
          resolvedValueChanged: !isEqual(lastResolvedValue, resolvedValue),
          wait: false
        };

        // if the value is set but no resolvedValue, it is still resolving
        if (p.isResolving) {
          newState.wait = true;
          return newState;
        }

        lastValue = value;
        lastResolvedValue = resolvedValue;
        return newState;
      })
    );
  }

  /**
   * Emits the complete state of all relevant parameters, if one of them changes, but only after all are resolved
   */
  getParameterStateChanges(
    pFilter: ParameterFilterFunction = alwaysTrue,
    debounceTimeMs = 10
  ): Observable<Record<string, ParameterChangeInfo>> {
    let newState: Record<string, ParameterChangeInfo> = {};
    let lastState: Record<string, ParameterChangeInfo> = {};
    return this.parameters$.pipe(
      switchMap((paramsMap) => {
        // parameter constellation changed (new param added/removed)
        const relevantParams = [...paramsMap.values()].filter((p) => pFilter(p));
        newState = {};
        lastState = {};

        return merge(of(null), ...relevantParams.map((p) => this.getParameterChangeEvents(p))).pipe(
          map((c: ParameterChangeInfo) => {
            if (!c) {
              return newState;
            }
            lastState[c.parameter.id] = c;
            newState[c.parameter.id] = c;
            return newState;
          }),
          skipWhile(() => Object.keys(lastState).length < relevantParams.length) // skip until all parameters have emitted at least once
        );
      }),
      debounceTime(debounceTimeMs), // avoid multiple emits for parallel parameter changes
      filter((newStates) => Object.values(newStates).every((s) => s.wait === false)), // if one is waiting for resolving, skip
      map((newStates) => {
        const newCompleteState: Record<string, ParameterChangeInfo> = newStates;
        newState = {};
        for (const info of Object.values(lastState)) {
          if (info.parameter.id in newCompleteState) {
            continue;
          }
          newCompleteState[info.parameter.id] = {
            parameter: info.parameter,
            lastValue: info.parameter.value,
            lastResolvedValue: info.parameter.resolvedValue,
            value: info.parameter.value,
            resolvedValue: info.parameter.resolvedValue,
            valueChanged: false,
            resolvedValueChanged: false,
            wait: false
          };
        }
        return newCompleteState;
      })
    );
  }

  /**
   * Same as getParameterStateChanges, but only a list of parameters
   */
  getParametersAfterStateChanges(
    pFilter: ParameterFilterFunction = alwaysTrue,
    debounceTimeMs = 10
  ): Observable<DashboardParameter[]> {
    return this.getParameterStateChanges(pFilter, debounceTimeMs).pipe(
      map((state) => {
        return Object.values(state).map((s) => s.parameter);
      })
    );
  }

  /**
   * Emits if one of the query params parameter changes
   * Used in the dashboard.component to update the location
   */
  getQueryParameters(): Observable<Params> {
    return this.getParametersAfterValueChanges((p) => p.options.isQueryParam).pipe(
      map((params: DashboardParameter[]) => {
        const result = new URLSearchParams();
        params.forEach((p) => {
          const queryParams = p.toParams();
          for (const key of queryParams.keys) {
            for (const value of queryParams.getAll(key)) {
              result.append(key, value);
            }
          }
        });
        return new UrlSearchParamMap(result).toParamsWithNulls(this.getQueryParametersKeys());
      })
    );
  }

  updateParameter(
    name: string,
    widgetId: string,
    value: any,
    syncGlobalStorage = true,
    updateOnlyOnChange = false
  ) {
    const parameter = this.getParameter(name, widgetId);
    if (!parameter) {
      throw new Error(
        `Dashboard parameter with name ${name} in widget ${widgetId} is not available.`
      );
    }
    parameter.writeValue(value, updateOnlyOnChange);
    if (parameter.options.isLocalStorage) {
      this.syncLocalStorage(parameter, syncGlobalStorage);
    }
    this.lastParamUpdated = parameter;
  }

  /**
   * Updates all named parameters based on the values. If the values object does not contain a required name, the
   * value will be set to undefined
   */
  updateParameters(names: string[], widgetId: string, values: Record<string, any>) {
    for (const name of names) {
      this.updateParameter(name, widgetId, values[name]);
    }
  }

  updateParameterOptions(
    name: string,
    widgetId: string,
    options: Partial<DashboardParameterOptions>
  ) {
    const parameter = this.getParameter(name, widgetId);
    if (!parameter) {
      throw new Error(
        `Dashboard parameter with name ${name} in widget ${widgetId} is not available.`
      );
    }
    parameter.options = {
      ...parameter.options,
      ...options
    };
  }

  /**
   * Reset all dashboard parameters. Used when switching between dashboards in order to avoid parameter duplication.
   */
  resetDashboardState() {
    this.initialFilterEmitted = false;
    this.initialInputEmitted = false;
    if (this.dashboardUpdateSub) {
      this.dashboardUpdateSub.unsubscribe();
    }
    if (this.dashboardInputUpdateSub) {
      this.dashboardInputUpdateSub.unsubscribe();
    }
    this.parameters$.next(new Map());
  }

  private getQueryParametersKeys() {
    return [...this.parameters.values()].reduce((acc, param) => {
      acc = [...acc, ...param.queryKeys];
      return acc;
    }, []);
  }

  private getParameterChangeObservables(
    widgetId: string,
    ...names: string[]
  ): Observable<DashboardParameter>[] {
    const paramIds = names.map((n) => buildDashboardParamId(widgetId, n));
    return [...this.parameters]
      .filter(([paramId]) => paramIds.includes(paramId))
      .map(([_, p]) =>
        p.valueChanges.pipe(
          distinctUntilChanged(),
          map(() => p)
        )
      );
  }

  private syncLocalStorage(parameter: DashboardParameter, syncGlobalParams = true) {
    const isGlobal = parameter.options?.isGlobal;
    const value = isGlobal && parameter.value === undefined ? null : parameter.value;

    if (isGlobal && syncGlobalParams) {
      this.localStorage.set(this.globalParametersService.localStorageKey, {
        ...Object.assign({}, this.localStorage.get(this.globalParametersService.localStorageKey)),
        [parameter.name]: value
      });
    }
    this.localStorage.set(this.localStorageKey, {
      ...Object.assign({}, this.localStorage.get(this.localStorageKey)),
      [parameter.id]: value
    });
  }

  private resetWidgetLocalStorage(widgetId: string) {
    const newStorage = {};
    const storedLocalValue = this.localStorage.get(this.localStorageKey);
    if (storedLocalValue) {
      for (const [paramKey, value] of Object.entries(storedLocalValue)) {
        if (
          !paramKey.startsWith(buildDashboardParamId(widgetId, '')) ||
          paramKey.includes(GLOBAL_PARAMETERS_PREFIX)
        ) {
          newStorage[paramKey] = value;
        }
      }
    }
    this.localStorage.set(this.localStorageKey, newStorage);
    this.localStorageState = newStorage;
  }

  private registerFilterParameters(config: DashboardConfig) {
    const filterWidget = config.widgets.find((widget) => widget.type === 'filter');

    if (!filterWidget) {
      this.dashboardDataService.filterParams.next({});
      return;
    }

    const filterConfig = filterWidget.properties['filterConfig'];

    const filterWidgetParams: FilterParameterConfig[] = [
      ...filterConfig.parameters,
      ...(filterConfig.deviceParameters || []),
      ...this.globalParametersService.getGlobalParameters(filterConfig)
    ];
    const storageKey = filterConfig.storageKey;

    const storageKeyParam = new PrimitiveDashboardParameter(
      'storageKey',
      'Storage Key',
      'string',
      filterWidget.id,
      storageKey,
      { isQueryParam: false, isLocalStorage: true, skipWidgetPrefix: false }
    );
    const storedStorageKey = this.localStorageState[storageKeyParam.id];

    if (storageKey && storedStorageKey && storedStorageKey !== storageKey) {
      this.resetWidgetLocalStorage(filterWidget.id);
    }
    this.registerParameter(storageKeyParam);

    // when dashboard is opened and at least one of the query params is equal to filter param, we want to ignore the local storage values
    // i.e. ?name=Name, should not be extended to ?name=Name&thingId=ThingId if thingId is persisted in local storage
    const isQueryParamUsed = this.isQueryParamUsed(filterWidgetParams);

    for (const param of filterWidgetParams) {
      if (param.global) {
        this.handleGlobalParameterRegister(param, filterWidget.id);
        continue;
      }

      this.registerFilterParameter(param, filterWidget.id, storageKey && !isQueryParamUsed);
    }

    this.registerFilterParamStateEmitter(filterWidget.id, filterWidgetParams);
  }

  registerInputParameters(config: DashboardConfig) {
    this.inputWidgets = config.widgets.filter((widget) => widget.type === 'input');
    if (!this.inputWidgets?.length) {
      this.dashboardDataService.inputFields.next({});
      return;
    }

    this.inputWidgets.forEach((widget: DashboardWidgetConfig) => {
      const fieldDefinitions: FieldConfig[] = flatten(widget.properties['fieldDefinitions']);
      let fieldParameter = '';
      const storageKey = widget.properties['storageKey'];

      const storageKeyParam = new PrimitiveDashboardParameter(
        INPUT_WIDGET_LOCAL_STORAGE_KEY + 'storageKey',
        'Storage Key',
        'string',
        widget.id,
        storageKey,
        { isQueryParam: false, isLocalStorage: true, skipWidgetPrefix: false }
      );

      const storedStorageKey = this.localStorageState[storageKeyParam.id];
      if (isEmpty(storageKey) || storedStorageKey !== storageKey) {
        this.resetWidgetLocalStorage(widget.id);
      }

      this.registerParameter(storageKeyParam);

      fieldDefinitions.forEach((config: FieldConfig) => {
        if (String(config.defaultValue).startsWith('${') && !isArray(config.resolvedPlaceholder)) {
          fieldParameter = config.resolvedPlaceholder;
        } else {
          fieldParameter = config.defaultValue as any;
        }

        if (config.type === FieldType.MULTI_SELECTION && !config['dynamicValues']) {
          fieldParameter = !isNull(fieldParameter)
            ? (getSelectedValuesAsArray(config as MultiselectFieldConfig) as any)
            : '';
        }

        const isMultipleValueParam = [
          FieldType.MULTI_SELECTION,
          FieldType.NUMBER_RANGE,
          FieldType.DATE_RANGE
        ].includes(config.type);
        const dashboardParameter = convertInputParameterToDashboardParameter(
          config,
          widget.id,
          fieldParameter,
          storageKeyParam
        );

        this.registerParameter(dashboardParameter, isMultipleValueParam);
      });

      this.registerInputParamStateEmitter();
    });
  }

  private handleGlobalParameterRegister(param: FilterParameterConfig, filterWidgetId: string) {
    const useLocalStorage =
      this.isNewGlobalParameter(param) || !this.isGlobalParamInQueryParams(param);
    this.registerFilterParameter(param, filterWidgetId, useLocalStorage);
  }

  private isGlobalParamInQueryParams(param: FilterParameterConfig): boolean {
    return param.global && this.queryParamsKeys.includes(param.name);
  }

  private isQueryParamUsed(filterWidgetParams: FilterParameterConfig[]): boolean {
    return filterWidgetParams.some((filter) => this.queryParamsKeys.includes(filter.name));
  }

  private isNewGlobalParameter(param: FilterParameterConfig): boolean {
    return !this.isGlobalParamInQueryParams(param) && this.navigationBackService.isLocationBackUsed;
  }

  private registerInputParamStateEmitter() {
    if (this.dashboardInputUpdateSub) {
      this.dashboardInputUpdateSub.unsubscribe();
    }
    const namePrefix = INPUT_WIDGET_LOCAL_STORAGE_KEY;
    this.dashboardInputUpdateSub = this.getParameterStateChanges((p) =>
      p.name.startsWith(namePrefix)
    ).subscribe((paramsChangeInfo) => {
      const inputValues = {};
      for (const info of Object.values(paramsChangeInfo)) {
        for (const [key, value] of Object.entries(info.parameter.toFilterParams())) {
          // key always starts with 'inputFields.';
          const nameWithoutPrefix = key.slice(namePrefix.length);

          inputValues[nameWithoutPrefix] =
            nameWithoutPrefix.includes('numrange') && !Array.isArray(value)
              ? value.split(',')
              : value;
        }
      }
      const paramChanged = Object.keys(paramsChangeInfo)
        .map((param) => paramsChangeInfo[param])
        .some((param) => param.resolvedValueChanged);

      // only emit input params if initial load on dashboard, a parameter was changed or update was caused by filter (and not by other widgets)
      if (
        !this.initialInputEmitted ||
        (this.lastParamUpdated &&
          this.inputWidgets.some((widget) => widget.id === this.lastParamUpdated.widgetId)) ||
        paramChanged
      ) {
        this.initialInputEmitted = true;
        this.dashboardDataService.inputFields.next(inputValues);
      }
    });
  }

  private registerFilterParamStateEmitter(
    filterWidgetId: string,
    filterWidgetParams: FilterParameterConfig[]
  ) {
    if (this.dashboardUpdateSub) {
      this.dashboardUpdateSub.unsubscribe();
    }
    this.dashboardUpdateSub = this.getParameterStateChanges(
      parameterFilter(filterWidgetId, ...filterWidgetParams.map((f) => f.name))
    ).subscribe((paramsChangeInfo) => {
      const paramChanged = Object.keys(paramsChangeInfo)
        .map((param) => paramsChangeInfo[param])
        .some((param) => param.resolvedValueChanged);

      // only emit filter params if initial load on dashboard, a parameter was changed or update was caused by filter (and not by other widgets)
      if (
        !this.initialFilterEmitted ||
        (this.lastParamUpdated && this.lastParamUpdated.widgetId === filterWidgetId) ||
        paramChanged
      ) {
        this.initialFilterEmitted = true;
        const filterParams = {};
        for (const info of Object.values(paramsChangeInfo)) {
          Object.assign(filterParams, info.parameter.toFilterParams());
        }
        this.dashboardDataService.filterParams.next(filterParams);
      }
    });
  }

  private registerFilterParameter(
    param: FilterParameterConfig,
    widgetId: string,
    useLocalStorage: boolean
  ) {
    const dashboardParameter = convertFilterParameterToDashboardParameter(
      param,
      this.devicesService,
      widgetId,
      useLocalStorage,
      true
    );
    const localStorage: any = this.localStorage.get(this.localStorageKey) || {};
    this.registerParameter(
      dashboardParameter,
      param.multipleValues,
      param.required ? true : `filter.${param.name}` in localStorage
    );
  }

  private getFilterWidgetGlobalParametersFromLocalStorage(
    config: DashboardConfig
  ): Record<string, unknown> {
    const globalParamsLocalStorageState = this.localStorage.get<Record<string, unknown>>(
      this.globalParametersService.localStorageKey
    );
    const filterWidget = config.widgets.find((widget) => widget.type === 'filter');

    if (
      !filterWidget?.properties?.['filterConfig']?.globalParameters?.length ||
      !globalParamsLocalStorageState
    ) {
      return {};
    }

    const filterWidgetGlobalParameters: string[] =
      filterWidget.properties['filterConfig'].globalParameters;

    return filterWidgetGlobalParameters.reduce((acc, id) => {
      if (id in globalParamsLocalStorageState) {
        const filterId = `filter.${id}`;
        acc[filterId] = globalParamsLocalStorageState[id];
      }
      return acc;
    }, {});
  }
}

export function parameterFilter(widgetId: string, ...names: string[]): ParameterFilterFunction {
  const paramIds = widgetId ? names.map((n) => buildDashboardParamId(widgetId, n)) : null;
  return (p) => !paramIds || paramIds.includes(p.id);
}
