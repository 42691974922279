import { Injectable } from '@angular/core';

@Injectable()
export class ValueViewService {
  compareDates(value: string, min: string, max: string, rule: string) {
    const valueDate = new Date(value);
    const minDate = new Date(min);
    const maxDate = new Date(max);
    if (rule === 'green' && maxDate > valueDate && minDate < valueDate) {
      return true;
    }
    return rule === 'red' && (valueDate < minDate || valueDate > maxDate);
  }

  compareNumbers(value: number, min: string, max: string, rule: string) {
    if (rule === 'green' && parseFloat(min) <= value && value <= parseFloat(max)) {
      return true;
    }
    return rule === 'red' && (value < parseFloat(min) || value > parseFloat(max));
  }

  compareString(value, regEx) {
    try {
      const regex = new RegExp(regEx);
      if (regex.test(value)) {
        return true;
      }
    } catch (e) {
      // do nothing
    }
    return false;
  }

  compareBoolean(value: boolean, rule: string, format: string) {
    switch (format) {
      case 'binary':
        if (rule === '1') {
          rule = 'true';
        } else if (rule === '0') {
          rule = 'false';
        }
        break;
      case 'yesNo':
        if (rule.toLowerCase() === 'yes') {
          rule = 'true';
        } else if (rule.toLowerCase() === 'no') {
          rule = 'false';
        }
        break;
      default:
        rule = rule.toLowerCase();
    }
    return value.toString() === rule;
  }
}
