<ng-container>
  <div class="mt-4 mb-4">
    <!-- backdate checkbox -->
    <div class="mb-4">
      <ellipsis-text>
        {{
          isNewDevice
            ? ('devices.createDeviceWithBackdating' | translate)
            : ('devices.backdatingExplanation' | translate)
        }}
      </ellipsis-text>
      <rb-form-checkbox
        name="backDateCheckbox"
        class="static mb-4"
        data-cy="backdateChangeCheckbox"
        [disabled]="disabled"
        [(ngModel)]="backdate"
        (ngModelChange)="checkBoxChanged($event)"
      >
        <ng-container *ngIf="isNewDevice">{{ 'devices.creationDate' | translate }}</ng-container>
        <ng-container *ngIf="isNewLinkedDevice">
          {{ 'devices.creationAndLinkedDate' | translate }}
        </ng-container>
        <ng-container *ngIf="!isNewDevice && !isNewLinkedDevice">
          {{ 'devices.backdateChange' | translate }}
        </ng-container>
        {{
          disabled && !isNewDevice
            ? ' (' + ('devices.noTrackedPropertyChanged' | translate) + ' )'
            : null
        }}
      </rb-form-checkbox>
    </div>
    <!-- ./backdate checkbox -->

    <rb-callout
      *ngIf="!backdate && noEntryFound && !isNewDevice && !isNewLinkedDevice"
      type="warning"
    >
      {{ 'devices.noChangeLogged' | translate }}
    </rb-callout>

    <!-- backdating info -->
    <div *ngIf="backdate && !disabled" class="responsive-width shaded-container" child-form>
      <div *rbLoading="entryLoader">
        <!-- explanation and history link -->
        <div class="mb-2" *ngIf="lastEntry">
          {{ 'devices.lastChange' | translate }}:
          <a
            target="_blank"
            [noFix]="true"
            class="rb-link"
            [rbTooltip]="'devices.historyEntry' | translate"
            [routerLink]="routing.deviceHistory + '/' + lastEntry._id | projectUrl"
            [queryParams]="{ thingId: lastEntryRelevantThingId }"
          >
            {{ lastEntry.validityBegin | date: 'short' }}
          </a>
          <span
            class="rb-ic rb-ic-info-i ml-1"
            [rbTooltip]="'devices.backdatingTimestampExplanation' | translate"
          ></span>
        </div>
        <!-- ./explanation and history link -->

        <!-- custom date -->
        <rb-form-date-input
          required
          class="white required"
          name="start"
          data-cy="backdateChangeCustomDate"
          [label]="'devices.timestamp' | translate"
          [(ngModel)]="selectedTimestamp"
          (ngModelChange)="backdateChanged()"
          [options]="{
            maxDate: currentTimestamp,
            minDate: earliestBackdateTimestamp,
            enableTime: true
          }"
          style="flex-grow: 1"
        ></rb-form-date-input>
        <!-- ./custom date -->

        <!-- labels and comment -->
        <rb-form-chips-input
          style="background: white"
          class="white"
          [(ngModel)]="backdateLabels"
          name="chipsInput"
          data-cy="backdateChangeCustomLabels"
          [label]="'deviceHistoryDetails.labels.label' | translate"
          (ngModelChange)="backdateChanged()"
          maxlength="10"
        >
          <ng-template rbFormValidationMessage="maxlength">
            {{ 'devices.tooManyLabels' | translate }}
          </ng-template>
        </rb-form-chips-input>
      </div>
    </div>
  </div>
</ng-container>
