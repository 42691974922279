export function elementUtils(element) {
  const hasChildOfType = (...type: string[]): boolean => {
    if (!element.children?.length) {
      return false;
    }
    return element.children.some((child) => type.indexOf(child.type) > -1);
  };

  return {
    hasChildOfType,
    hasImageChild: (): boolean => {
      return hasChildOfType(element, 'image');
    },
    hasReferenceChild: (): boolean => {
      return hasChildOfType(element, 'reference');
    }
  };
}
