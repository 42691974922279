<rb-dialog
  [dialogTitle]="'downloadDialog.csvDownload' | translate"
  [optionalButtonLabel]="'downloadDialog.customize' | translate"
  [optionalButtonAction]="customizeButtonAction.bind(this)"
  [optionalButtonCloseOnClick]="false"
  [cancelButtonLabel]="'downloadDialog.cancel' | translate"
  [confirmationButtonLabel]="'downloadDialog.download' | translate"
>
  <rb-form-select
    id="preset"
    name="preset"
    [label]="'downloadDialog.preset' | translate"
    [ngModel]="selectedPreset?.label"
    (ngModelChange)="loadPreset($event)"
  >
    <option *ngFor="let preset of presets" [value]="preset.label">{{ preset.label }}</option>
  </rb-form-select>

  <div data-cy="downloadDialogPresetOptions" [hidden]="!showDetails">
    <hr />

    <rb-form-select
      id="csvCharset"
      name="charset"
      [(ngModel)]="parameter.charset"
      [label]="'downloadDialog.charset' | translate"
    >
      <option *ngFor="let opt of options.charset" [value]="opt.value">{{ opt.label }}</option>
    </rb-form-select>
    <rb-form-select
      id="csvLocale"
      name="locale"
      [(ngModel)]="parameter.locale"
      [label]="'downloadDialog.locale' | translate"
    >
      <option *ngFor="let opt of options.locale" [value]="opt.value">{{ opt.label }}</option>
    </rb-form-select>
    <rb-form-select
      id="csvHeader"
      name="header"
      [(ngModel)]="parameter.header"
      [label]="'downloadDialog.header' | translate"
    >
      <option *ngFor="let opt of options.header" [value]="opt.value">{{ opt.label }}</option>
    </rb-form-select>
    <rb-form-select
      id="csvLineBreak"
      name="lineBreak"
      [(ngModel)]="parameter.lineBreak"
      [label]="'downloadDialog.lineBreak' | translate"
    >
      <option *ngFor="let opt of options.lineBreak" [value]="opt.value">{{ opt.label }}</option>
    </rb-form-select>
    <rb-form-select
      id="csvColumnSeparator"
      name="columnSeparator"
      [(ngModel)]="parameter.columnSeparator"
      [label]="'downloadDialog.columnSeparator' | translate"
    >
      <option *ngFor="let opt of options.columnSeparator" [value]="opt.value">
        {{ opt.label }}
      </option>
    </rb-form-select>
    <rb-form-select
      id="csvMasking"
      name="masking"
      [(ngModel)]="parameter.masking"
      [label]="'downloadDialog.masking' | translate"
    >
      <option *ngFor="let opt of options.masking" [value]="opt.value">{{ opt.label }}</option>
    </rb-form-select>
    <rb-form-select
      id="csvDecimalSeparator"
      name="decimalSeparator"
      [(ngModel)]="parameter.decimalSeparator"
      [label]="'downloadDialog.decimalSeparator' | translate"
    >
      <option *ngFor="let opt of options.decimalSeparator" [value]="opt.value">
        {{ opt.label }}
      </option>
    </rb-form-select>
    <rb-form-select
      id="csvDdateTimeFormat"
      name="dateTimeFormat"
      [(ngModel)]="parameter.dateTimeFormat"
      [label]="'downloadDialog.dateTimeFormat' | translate"
    >
      <option *ngFor="let opt of options.dateTimeFormat" [value]="opt.value">
        {{ opt.label }}
      </option>
    </rb-form-select>

    <div class="mb-3">
      <button type="button" class="rb-btn rb-tiny" (click)="saveAsClicked()">
        {{ 'downloadDialog.saveAs' | translate }}
      </button>
      &nbsp;
      <button
        class="rb-btn rb-primary rb-tiny"
        [hidden]="!localPresetSettingName"
        type="button"
        (click)="saveChanges()"
      >
        {{ 'downloadDialog.saveChanges' | translate }}
      </button>
      &nbsp;
      <button
        [hidden]="!localPresetSettingName"
        type="button"
        class="rb-btn rb-tiny rb-danger"
        (click)="deleteDownloadSetting(localPresetSettingName)"
      >
        {{ 'downloadDialog.delete' | translate }}
      </button>
    </div>
  </div>
</rb-dialog>
