<div *ngIf="widgetInstance.properties.title">
  <h4 class="m-0" *ngIf="dashboardDataService.isGridLayout(layout)">
    <ng-container *ngTemplateOutlet="widgetName"></ng-container>
  </h4>
  <h2 *ngIf="layout === 'columnBased'">
    <ng-container *ngTemplateOutlet="widgetName"></ng-container>
  </h2>
</div>

<ng-template #widgetName>
  <span
    class="widget-title"
    [class]="'mb-' + marginBottom"
    [attr.data-cy]="'widgetTitle-' + widgetInstance.widgetType.type"
  >
    {{ widgetInstance.resolvedTitle }}
  </span>
</ng-template>
