import { BaseTextComponent } from 'slate-angular';
import { ChangeDetectorRef, Component, ElementRef, Renderer2 } from '@angular/core';
import { calculateMarkStyles } from '../../slate.util';

@Component({
  selector: 'span[textMark][data-slate-node=text]',
  templateUrl: './text-mark.component.html'
})
export class TextMarkComponent extends BaseTextComponent {
  attributes = [];

  constructor(public elementRef: ElementRef, public renderer2: Renderer2, cdr: ChangeDetectorRef) {
    super(elementRef, cdr);
  }

  applyTextMark() {
    this.attributes.forEach((attr) => {
      this.renderer2.removeAttribute(this.elementRef.nativeElement, attr);
    });
    this.attributes = [];
    for (const key in this.text) {
      if (
        Object.prototype.hasOwnProperty.call(this.text, key) &&
        key !== 'text' &&
        !!this.text[key]
      ) {
        const attr = `slate-${key}`;
        this.renderer2.setAttribute(this.elementRef.nativeElement, attr, 'true');
        this.attributes.push(attr);
      }
    }
    const styles = calculateMarkStyles(this.text);
    Object.keys(styles).forEach((style) => {
      this.renderer2.setStyle(this.elementRef.nativeElement, style, styles[style]);
      if (this.text?.text && style === 'background-color') {
        this.renderer2.addClass(this.elementRef.nativeElement, 'text-background-rounded');
      }
    });
  }

  onContextChange() {
    super.onContextChange();
    this.applyTextMark();
  }
}
