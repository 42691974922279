<div
  *ngIf="layout === 'columnBased'"
  class="d-flex align-items-start visible-on-hover pt-1"
  data-cy="widgetControlWrapper"
>
  <button
    [title]="'queryResult.refreshDiagram' | translate"
    class="rb-btn rb-link mr-1 refresh-button"
    (click)="refresh()"
    [disableOnClick]="5000"
    type="button"
  >
    <em class="rb-ic rb-ic-refresh" [class.rb-ic-spin]="isLoading"></em>
  </button>

  <button
    *ngIf="hasAutoRefreshing"
    [title]="'queryResult.toggleAutoRefresh' | translate"
    class="rb-btn rb-link mr-1"
    (click)="toggleAutoRefresh()"
    type="button"
  >
    <span class="rb-ic" [ngClass]="isAutoRefreshing ? 'rb-ic-stop' : 'rb-ic-play'"></span>
  </button>

  <button
    *ngIf="isExportableWidget()"
    type="button"
    (click)="openExportInfoPopup()"
    class="rb-btn rb-link mr-1"
    [title]="'widget.exportInfo.showInfoBtn' | translate"
  >
    <span class="rb-ic rb-ic-info-i"></span>
  </button>

  <button
    type="button"
    (click)="toggleMaximize()"
    class="rb-btn rb-link mr-1"
    [title]="'queryResult.toggleMaximize' | translate"
  >
    <span class="rb-ic" [ngClass]="maximized ? 'rb-ic-fullscreen-exit' : 'rb-ic-fullscreen'"></span>
  </button>

  <ng-content></ng-content>

  <widget-loading-status></widget-loading-status>
</div>

<div *ngIf="layout === dashboardLayout.GRID || layout === dashboardLayout.GridFreeFloating">
  <ng-container *ngIf="showWidgetEditOptions">
    <div class="a-menu-item">
      <a class="a-menu-item__link" (click)="editWidget()" data-cy="widgetControlEditButton">
        <em class="a-icon rb-ic rb-ic-notepad-edit"></em>
        <span>
          {{ 'queryResult.editWidget' | translate }}
        </span>
      </a>
    </div>

    <div class="a-menu-item" *ngIf="isRelayoutButtonVisible">
      <a
        class="a-menu-item__link"
        (click)="
          dashboardActionService.triggerAction(dashboardAction.START_RELAYOUT, relayoutContext)
        "
        data-cy="widgetControlRelayoutButton"
      >
        <em class="a-icon rb-ic rb-ic-layout"></em>
        <span>
          {{ 'queryResult.relayout' | translate }}
        </span>
      </a>
    </div>
    <div
      class="a-menu-item"
      *ngIf="!isRelayoutButtonVisible"
      data-cy="widgetControlSaveRelayoutButton"
    >
      <a
        class="a-menu-item__link"
        (click)="
          dashboardActionService.triggerAction(dashboardAction.SAVE_RELAYOUT, relayoutContext)
        "
      >
        <em class="a-icon rb-ic rb-ic-layout"></em>
        <span>
          {{ 'queryResult.saveRelayout' | translate }}
        </span>
      </a>
    </div>
  </ng-container>

  <div class="a-menu-item" *ngIf="isWidgetReloadable">
    <a class="a-menu-item__link" (click)="refresh()" [disableOnClick]="5000">
      <em class="a-icon rb-ic rb-ic-refresh" [class.rb-ic-spin]="isLoading"></em>
      <span>
        {{ 'queryResult.refreshDiagram' | translate }}
      </span>
    </a>
  </div>

  <div class="a-menu-item" *ngIf="hasAutoRefreshing">
    <a class="a-menu-item__link" (click)="toggleAutoRefresh()">
      <em [ngClass]="isAutoRefreshing ? 'rb-ic-stop' : 'rb-ic-play'" class="a-icon rb-ic"></em>
      <span class="a-menu-item__label">{{ 'queryResult.toggleAutoRefresh' | translate }}</span>
    </a>
  </div>

  <div class="a-menu-item" *ngIf="isWidgetMaximizable">
    <a (click)="toggleMaximize()" class="a-menu-item__link">
      <em
        class="a-icon rb-ic"
        [ngClass]="maximized ? 'rb-ic-fullscreen-exit' : 'rb-ic-fullscreen'"
      ></em>
      <span>{{ 'queryResult.toggleMaximize' | translate }}</span>
    </a>
  </div>

  <div class="a-menu-item" *ngIf="canEdit">
    <button
      type="button"
      class="a-menu-item__link"
      [confirmTitle]="'widgetControl.removeConfirmTitle' | translate: { widgetName }"
      [confirmation]="'widgetControl.removeConfirm' | translate: { widgetName }"
      (confirmed)="removeWidget()"
    >
      <i class="a-icon rb-ic rb-ic-delete"></i>
      <span>{{ 'widget.buttons.remove' | translate }}</span>
    </button>
  </div>

  <div class="a-menu-item" *ngIf="isExportableWidget()">
    <a class="a-menu-item__link" (click)="openExportInfoPopup()">
      <em class="a-icon rb-ic rb-ic-info-i"></em>
      <span class="a-menu-item__label">{{ 'widget.exportInfo.showInfoBtn' | translate }}</span>
    </a>
  </div>
</div>
