import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable, InjectionToken, Optional, Provider } from '@angular/core';
import { LocalStorageService } from 'ngx-localstorage';
import { forkJoin, Observable, of } from 'rxjs';
import { map, shareReplay, take, tap } from 'rxjs/operators';
import {
  CollectionDefinitionAttributes,
  CollectionDetails,
  CollectionOverview,
  IndexDefinitionAttributes,
  IndexDetails
} from '../../shared/api-model';
import { ProjectsService } from './projects.service';

export const STORAGE_KEY = new InjectionToken('collectionsStorageKey');

// type position is used for ordering of the collections
export enum collectionTypes {
  'ProcessedData' = 'processed-data',
  'ProcessedDataStandard' = 'processed-data-standard',
  'ProcessedDataTimeSeries' = 'processed-data-timeseries',
  'InputData' = 'input-data',
  'ThingHistory' = 'thing-history',
  'CalendarEvents' = 'calendar-events',
  'Attachments' = 'attachments',
  'MasterData' = 'master-data',
  'Tours' = 'tours',
  'QueryHistory' = 'query-history',
  'Reprocessing' = 'reprocessing',
  'ProcessingStatus' = 'processing-status',
  'DecodingRequests' = 'decoding-requests',
  'DeviceAttachments' = 'device-attachments',
  'Activities' = 'activities',
  'UserLog' = 'user-log',
  'Config' = 'config',
  'Conditions' = 'conditions',
  'DecoderSpecs' = 'decoder-specs',
  'InputDataChunks' = 'input-data-chunks',
  'ConsentDocuments' = 'consents',
  'Embeddings' = 'embeddings',
  'DeviceTypes' = 'device-types',
  'DashboardConfigs' = 'dashboard-configs'
}

@Injectable()
export class CollectionsService {
  private loaded = false;

  private collectionByType = {};

  private collections$;

  private queryableCollections$;

  get projectName(): string {
    return this.projectsService.projectName;
  }

  get serviceUrl(): string {
    return `/project-management-service/v1/projects/${this.projectName}`;
  }

  get inputDataServiceUrl() {
    return `/project-management-service/v1/projects/${this.projectName}/input-data-retention`;
  }

  private readonly collectionCategoryKey = (path) =>
    `${path}_selectedCollectionCategory_${this.projectsService.projectName}`;

  constructor(
    private localStorage: LocalStorageService,
    private projectsService: ProjectsService,
    private http: HttpClient,
    @Optional() @Inject(STORAGE_KEY) public storageKey: string
  ) {
    this.projectsService.projectConfigEvents.subscribe((event) => {
      if (event?.config) {
        this.resetObservables();
      }
    });
  }

  static withKey(storageKey): Provider[] {
    return [
      { provide: STORAGE_KEY, useValue: storageKey },
      { provide: CollectionsService, useClass: CollectionsService }
    ];
  }

  loadStoredData(projectName) {
    if (this.storageKey && !this.loaded) {
      this.loaded = true;
      const storedData = this.localStorage.get('collection_' + this.storageKey);
      if (storedData && storedData[projectName]) {
        this.collectionByType = storedData[projectName];
      }
    }
  }

  storeSettings(projectName) {
    if (this.storageKey) {
      const storedData = this.localStorage.get('collection_' + this.storageKey) || {};
      storedData[projectName] = this.collectionByType;
      this.localStorage.set('collection_' + this.storageKey, storedData);
    }
  }

  getCollections(onlyQueryable = false, withIndexes = false): Observable<CollectionOverview[]> {
    if (this.collections$ && !onlyQueryable && !withIndexes) {
      return this.collections$;
    }
    if (this.queryableCollections$ && onlyQueryable && !withIndexes) {
      return this.queryableCollections$;
    }

    const params = new HttpParams()
      .set('onlyQueryable', onlyQueryable)
      .set('withIndexes', withIndexes);

    const collectionRequest = forkJoin([
      this.http.get<CollectionOverview[]>(`${this.serviceUrl}/collections`, { params }),
      this.http.get(this.inputDataServiceUrl)
    ]).pipe(
      map(([databaseJobsCollections, inputDataCollection]) => {
        const collections = databaseJobsCollections.filter(
          (collection) => collection.type !== 'input-data'
        );
        collections.push(
          this.generateInputDataCollectionEntry(inputDataCollection['inputDataRetentionDays'])
        );

        return collections;
      }),
      shareReplay(1)
    );

    if (onlyQueryable) {
      this.queryableCollections$ = collectionRequest;
    } else {
      this.collections$ = collectionRequest;
    }
    return collectionRequest;
  }

  getCollectionsByType(type: string): Observable<CollectionOverview[]> {
    return this.getCollections().pipe(
      map((collections) => {
        return collections.filter((coll) => coll.type === type);
      })
    );
  }

  getCollectionsByTypesOrdered(
    onlyQueryable: boolean,
    withIndexes = false,
    types?: string[]
  ): Observable<CollectionOverview[]> {
    return this.getCollections(onlyQueryable, withIndexes).pipe(
      map((collections) => {
        if (!types) {
          types = [...new Set(collections.map((c) => c.type))];
        }
        types = this.sortTypes(types);
        let collectionResult: CollectionOverview[] = [];
        types.forEach((type) => {
          collectionResult = collectionResult.concat(
            collections.filter((coll) => {
              if (
                coll.type === collectionTypes.ProcessedData &&
                !types.includes(collectionTypes.ProcessedData)
              ) {
                if (type === collectionTypes.ProcessedDataStandard) {
                  return !coll.timeseries;
                }
                if (type === collectionTypes.ProcessedDataTimeSeries) {
                  return coll.timeseries !== null;
                }
              }
              return coll.type === type;
            })
          );
        });
        return collectionResult;
      })
    );
  }

  getCollectionByTechnicalName(
    name: string,
    onlyQueryable = false,
    withIndexes = false
  ): Observable<CollectionOverview> {
    return this.getCollections(onlyQueryable, withIndexes).pipe(
      take(1),
      map((collections) => {
        return collections.find((collection) => collection.name === name);
      })
    );
  }

  getSelectedCollection(type: string, withIndexes = false): Observable<CollectionOverview> {
    const projectName = this.projectsService.projectName;
    this.loadStoredData(projectName);
    return this.getCollections(false, withIndexes).pipe(
      map((collections) => {
        if (this.collectionByType[type]) {
          const found = collections.find((coll) => coll.name === this.collectionByType[type]);
          if (found) {
            return found;
          }
        }
        if (collections.length) {
          const found = collections.find((coll) => coll.type === type);
          if (found) {
            return found;
          }
        }
        return null;
      })
    );
  }

  getCollectionCount(collection: string): Observable<number> {
    if (!collection) {
      return of(undefined);
    }
    return this.getCollectionDetails(collection).pipe(map((collection) => collection.stats.count));
  }

  setSelectedCollection(type: string, name: string) {
    this.collectionByType[type] = name;
    this.storeSettings(this.projectName);
  }

  storeSelectedCollectionCategory(path: string, category: string) {
    this.localStorage.set(this.collectionCategoryKey(path), category);
  }

  getSelectedCollectionCategory(path: string): string {
    return this.localStorage.get(this.collectionCategoryKey(path));
  }

  getCollectionDetails(name: string): Observable<CollectionDetails> {
    const url = `${this.serviceUrl}/collections/${name}`;
    return this.http.get<CollectionDetails>(url);
  }

  getIndexes(collection: string): Observable<IndexDetails[]> {
    const url = `${this.serviceUrl}/collections/${collection}/indexes`;
    return this.http.get<IndexDetails[]>(url);
  }

  addIndex(index: IndexDefinitionAttributes, collection: string): Observable<any> {
    const url = `${this.serviceUrl}/collections/${collection}/indexes`;
    return this.http.post<any>(url, index);
  }

  deleteIndex(index: string, collection: string): Observable<any> {
    const url = `${this.serviceUrl}/collections/${collection}/indexes/${index}`;
    return this.http.delete(url);
  }

  addCollection(
    collection: CollectionDefinitionAttributes
  ): Observable<CollectionDefinitionAttributes> {
    const url = `${this.serviceUrl}/collections`;
    return this.http
      .post<CollectionDefinitionAttributes>(url, collection)
      .pipe(tap(() => this.resetObservables()));
  }

  deleteCollection(collection: string): Observable<any> {
    const url = `${this.serviceUrl}/collections/${collection}`;
    return this.http.delete(url).pipe(tap(() => this.resetObservables()));
  }

  updateCollection(
    collection: CollectionDefinitionAttributes
  ): Observable<CollectionDefinitionAttributes> {
    const url = `${this.serviceUrl}/collections`;
    return this.http.put<CollectionDefinitionAttributes>(url, collection);
  }

  bootstrapProject(): Observable<any> {
    const url = `${this.serviceUrl}/bootstrap `;
    return this.http.put(url, {});
  }

  private resetObservables() {
    this.collections$ = null;
    this.queryableCollections$ = null;
  }

  private sortTypes(types: string[]): string[] {
    const order: string[] = Object.values(collectionTypes);

    if (types.includes(collectionTypes.ProcessedData)) {
      types.splice(types.indexOf(collectionTypes.ProcessedData), 1);
      if (!types.includes(collectionTypes.ProcessedDataStandard)) {
        types.push(collectionTypes.ProcessedDataStandard);
      }
      if (!types.includes(collectionTypes.ProcessedDataTimeSeries)) {
        types.push(collectionTypes.ProcessedDataTimeSeries);
      }
    }

    types.sort((a, b) => {
      if (order.indexOf(a) < order.indexOf(b)) {
        return -1;
      }
      if (order.indexOf(a) > order.indexOf(b)) {
        return 1;
      }
      return 0;
    });

    return types;
  }

  private generateInputDataCollectionEntry = (retentionDays: number) => ({
    name: `${this.projectName}_input_data`,
    label: 'Input Data',
    description: 'Internal collection which contains raw input-data',
    type: 'input-data',
    defaultTTLIndex: {
      name: 'deletedAt',
      description: 'Input data deleted time',
      expiresAfterSeconds: retentionDays ? retentionDays * 24 * 60 * 60 : undefined,
      minTTLSeconds: 24 * 60 * 60, // 1 day minimum
      maxTTLSeconds: 9999 * 24 * 60 * 60 // 9999 days maximum
    }
  });
}
