import { BasePoint, Editor, Node, NodeEntry, Path, Transforms } from 'slate';
import { addSelection, Cell, Col, removeSelection, Row, TableContent } from './plugins/table';
import { v4 as uuid } from 'uuid';

export const getColumnData: (
  editor: Editor,
  table: NodeEntry,
  startKey?: string | undefined
) => {
  tableDepth?: number;
  tableGrid: Col[][];
  getCol: (match?: (node: Col) => boolean) => Col[];
} = (editor, table, startKey) => {
  const tableDepth = table[1].length;

  const cells = Array.from(
    Editor.nodes<Cell>(editor, {
      at: table[1],
      match: (n) => n['type'] === 'table-cell'
    }),
    ([cell, path]) => ({ cell, path, realPath: [...path] })
  );

  const tableGrid = processCells(cells, tableDepth, startKey);
  const getColFunc = getCol(tableGrid);

  return {
    tableGrid,
    tableDepth,
    getCol: getColFunc
  };
};

export function isInSameTable(editor: Editor): boolean {
  if (!editor.selection) {
    console.warn('No selection found in the editor.');
    return false;
  }

  const [start, end] = Editor.edges(editor, editor.selection);
  const [startTable] = Editor.nodes(editor, {
    at: start,
    match: (n) => n['type'] === 'table'
  });

  const [endTable] = Editor.nodes(editor, {
    at: end,
    match: (n) => n['type'] === 'table'
  });

  if (startTable && endTable) {
    const [, startPath]: [any, Path] = startTable;
    const [, endPath]: [any, Path] = endTable;

    if (Path.equals(startPath, endPath)) {
      return true;
    }
  }

  return false;
}

export function createCell({
  elements,
  colspan,
  rowspan,
  height,
  width
}: {
  elements?: Node[];
  height?: number;
  width?: number;
  colspan?: number;
  rowspan?: number;
} = {}): Cell {
  const content = createContent(elements);

  return {
    type: 'table-cell',
    key: `cell_${uuid()}`,
    children: [content],
    border: { top: true, right: true, bottom: true, left: true },
    width: width,
    height: height,
    colspan,
    rowspan
  };
}

export function createRow(columns: number): Row {
  const cellNodes = [...new Array(columns)].map(() => createCell());

  return {
    type: 'table-row',
    key: `row_${uuid()}`,
    data: {},
    children: cellNodes
  };
}

export function createContent(elements?: Node[]): TableContent {
  return {
    type: 'table-content',
    children: elements || [{ type: 'paragraph', children: [{ text: '' }] }]
  };
}

export function getSelectionData(editor: Editor, table: NodeEntry) {
  const { selection } = editor;

  if (!selection || !table) {
    console.warn('No selection found in the editor.');
    return null;
  }

  const yIndex = table[1].length;
  const xIndex = table[1].length + 1;
  const { getCol } = getColumnData(editor, table);
  const [start, end] = Editor.edges(editor, selection);

  const { startNode, endNode } = getStartAndEndNodes(editor, start, end);
  if (!startNode || !endNode) {
    return null;
  }

  const { startCell, endCell } = getStartAndEndCells(getCol, startNode, endNode);

  return { yIndex, xIndex, getCol, startCell, endCell };
}

export function getSelectedColumns(
  getCol: (match: (node: Col) => boolean) => Col[],
  yIndex: number,
  xIndex: number,
  startCell: Col,
  endCell: Col
) {
  const [yStart, yEnd] = [startCell.path[yIndex], endCell.path[yIndex]];
  const [xStart, xEnd] = [startCell.path[xIndex], endCell.path[xIndex]];

  const originalTableCells = [] as Col[];
  const selectedColumns = getCol((n: Col) => {
    const { cell, path } = n;
    const [y, x] = path.slice(yIndex, xIndex + 1);

    if (cell.selectedCell || (y >= yStart && y <= yEnd && x >= xStart && x <= xEnd)) {
      findOriginalTableCells(n, getCol, originalTableCells);
      return true;
    }

    return false;
  });

  selectedColumns.push(...originalTableCells);

  return getFilteredCols(selectedColumns);
}

export function divideAndInsertCells(
  editor: Editor,
  filteredCols: { [key: string]: Col },
  yIndex: number
) {
  Object.values(filteredCols).forEach((col: Col) => {
    const { cell, isReal, originPath } = col;
    const { rowspan = 1, colspan = 1, children } = cell;

    if (isReal && (rowspan !== 1 || colspan !== 1)) {
      Transforms.delete(editor, {
        at: originPath
      });

      for (let i = 0; i < rowspan; i++) {
        for (let j = 0; j < colspan; j++) {
          createAndInsertNewCell(i, j, originPath, yIndex, children, colspan, editor);
        }
      }
    }
  });
}

export function divideTableCel(table: NodeEntry, editor: Editor) {
  const selectionData = getSelectionData(editor, table);
  if (!selectionData) {
    return;
  }

  const filteredCols = getSelectedColumns(
    selectionData.getCol,
    selectionData.yIndex,
    selectionData.xIndex,
    selectionData.startCell,
    selectionData.endCell
  );

  divideAndInsertCells(editor, filteredCols, selectionData.yIndex);
}

export function modifyMark(
  key: string,
  action: 'add' | 'remove',
  editor: Editor,
  editorMark: (key: string, value?: any) => void,
  value?: string
) {
  if (editor.selection) {
    let isTableContext = false;
    const lastSelection = editor.selection;
    const selectedCells = Editor.nodes(editor, {
      match: (n) => n['selectedCell'],
      at: []
    });
    for (const cell of selectedCells) {
      if (!isTableContext) {
        isTableContext = true;
      }
      const [content] = Editor.nodes(editor, {
        match: (n) => n['type'] === 'table-content',
        at: cell[1]
      });

      if (Editor.string(editor, content[1]) !== '') {
        Transforms.setSelection(editor, Editor.range(editor, cell[1]));
        if (action === 'add') {
          editorMark(key, value);
        } else if (action === 'remove') {
          editorMark(key);
        }
      }
    }
    if (isTableContext) {
      Transforms.select(editor, lastSelection);
      return;
    }
  }
  if (action === 'add') {
    editorMark(key, value);
  } else if (action === 'remove') {
    editorMark(key);
  }
}

export function canInsertColumnAtPosition(
  tableGrid: Col[][],
  getCol: (match: (node: Col) => boolean) => Col[],
  xPosition: number,
  position: string,
  tableLength: number
) {
  const tableMap = new Map<string, Col>();
  let isInsertable = true;

  tableGrid.forEach((row: Col[]) => {
    const col = row[xPosition];
    const [originCol] = getCol((n: Col) => n.cell.key === col.cell.key && n.isReal);
    const { cell, path } = originCol;
    if (position === 'left') {
      if (col.isReal || path[tableLength + 1] === xPosition) {
        tableMap.set(cell.key, originCol);
      } else {
        isInsertable = false;
      }
    } else {
      if (!row[xPosition + 1] || (col.isReal && (!col.cell.colspan || col.cell.colspan === 1))) {
        tableMap.set(cell.key, originCol);
      } else if (path[tableLength + 1] + (cell.colspan || 1) - 1 === xPosition) {
        tableMap.set(cell.key, originCol);
      } else {
        isInsertable = false;
      }
    }
  });

  return { isInsertable, tableMap };
}

export function canInsertRowAtPosition(
  tableGrid: Col[][],
  getCol: (match: (node: Col) => boolean) => Col[],
  yPosition: number,
  aboveYIndex: number,
  type: string,
  position: string,
  tableLength: number
) {
  const tableMap = new Map<string, Col>();
  let isInsertable = true;

  // Use the new functions in your code
  if (type === 'table-row' && position === 'bottom') {
    isInsertable = canInsertRowAtBottom(tableGrid, getCol, yPosition, tableLength, tableMap);
  } else if (type === 'table-row' && position === 'top') {
    isInsertable = canInsertRowAtTop(tableGrid, getCol, aboveYIndex, tableLength, tableMap);
  }

  return { isInsertable, tableMap };
}

export function selectAndHighlightTableCell(editor: Editor, tableElementKey: string) {
  const selectedCells = document.querySelectorAll('.selectedCell');

  const [startNode] = Editor.nodes(editor, {
    match: (n) => {
      return n['key'] === tableElementKey;
    },
    at: []
  });

  if (startNode && selectedCells.length <= 1) {
    removeSelection(editor);
    Transforms.select(editor, startNode[1]);
    Transforms.setNodes(editor, { selectedCell: true } as any, { at: startNode[1] });
  }
}

export function processTableCellSelection(
  event: MouseEvent,
  editor: Editor,
  tableElementKey: string
) {
  const cell = (event.target as HTMLBaseElement).closest('td');
  if (cell && tableElementKey) {
    const endKey = cell.getAttribute('data-key');

    const [startNode] = Editor.nodes(editor, {
      match: (n) => {
        return n['key'] === tableElementKey;
      },
      at: []
    });

    const [endNode] = Editor.nodes(editor, {
      match: (n) => n['key'] === endKey,
      at: []
    });

    addSelection(
      editor,
      Editor.path(editor, startNode[1]),
      Editor.path(editor, endNode[1] || startNode[1])
    );
  }
}

function getFilteredCols(selectedColumns: Col[]) {
  return selectedColumns.reduce((p: { [key: string]: Col }, c: Col) => {
    if (c.isReal) {
      p[c.cell.key] = c;
    }
    return p;
  }, {}) as { [key: string]: Col };
}

function getStartAndEndCells(
  getCol: (match: (node: Col) => boolean) => Col[],
  startNode: NodeEntry,
  endNode: NodeEntry
) {
  const [startCell] = getCol((n: Col) => n.cell.key === startNode[0]['key']);
  const [endCell] = getCol((n: Col) => n.cell.key === endNode[0]['key']);

  return { startCell, endCell };
}

function getStartAndEndNodes(editor: Editor, start: BasePoint, end: BasePoint) {
  const [startNode] = Editor.nodes(editor, {
    match: (n) => n['type'] === 'table-cell',
    at: start
  });

  const [endNode] = Editor.nodes(editor, {
    match: (n) => n['type'] === 'table-cell',
    at: end
  });

  return { startNode, endNode };
}

function findOriginalTableCells(
  n: Col,
  getCol: (match: (node: Col) => boolean) => Col[],
  originalTableCells: Col[]
) {
  const { cell, isReal } = n;
  if (!isReal) {
    const [sourceCell] = getCol((s: Col) => s.isReal && s.cell.key === cell.key);
    originalTableCells.push(sourceCell);
  }
}

function createAndInsertNewCell(
  i: number,
  j: number,
  originPath: Path,
  yIndex: number,
  children: Node[],
  colspan: number,
  editor: Editor
) {
  const newPath = [...originPath];
  newPath[yIndex] += i;
  const nodeChildren = i === 0 && j === colspan - 1 ? children[0]['children'] : null;
  const newCell = createCell({
    width: 0,
    height: 0,
    elements: nodeChildren
  });

  Transforms.insertNodes(editor, newCell, {
    at: newPath
  });
}

function createGridCell(
  cell: Cell,
  realPath: number[],
  tableDepth: number,
  yIndex: number,
  xIndex: number,
  y: number,
  x: number
) {
  const { rowspan = 1, colspan = 1 } = cell;
  return {
    cell,
    path: [...realPath.slice(0, tableDepth), yIndex, xIndex],
    isReal: (rowspan === 1 && colspan === 1) || (yIndex === y && xIndex === x),
    originPath: realPath
  };
}

function setInsertPosition(
  cell: Cell,
  startKey: string,
  tableGrid: Col[][],
  yIndex: number,
  xIndex: number
) {
  if (cell.key === startKey) {
    tableGrid[yIndex][xIndex].isInsertPosition = true;
  }
}

function processCells(
  cells: { cell: Cell; path: Path; realPath: number[] }[],
  tableDepth: number,
  startKey: string
) {
  const tableGrid: Col[][] = [];
  for (const { cell, path, realPath } of cells) {
    const { rowspan = 1, colspan = 1 } = cell;
    const y = path[tableDepth];
    let x = path[tableDepth + 1];

    tableGrid[y] = tableGrid[y] || [];
    while (tableGrid[y][x]) {
      x++;
    }

    for (let j = 0; j < rowspan; j++) {
      for (let k = 0; k < colspan; k++) {
        const yIndex = y + j;
        const xIndex = x + k;

        tableGrid[yIndex] = tableGrid[yIndex] || [];
        tableGrid[yIndex][xIndex] = createGridCell(
          cell,
          realPath,
          tableDepth,
          yIndex,
          xIndex,
          y,
          x
        );
        setInsertPosition(cell, startKey, tableGrid, yIndex, xIndex);
      }
    }
  }
  return tableGrid;
}

function getCol(tableGrid: Col[][]) {
  return (match?: (node: Col) => boolean): Col[] =>
    tableGrid.flat().filter((col) => !match || match(col));
}

function canInsertRowAtBottom(
  tableGrid: Col[][],
  getCol: (match: (node: Col) => boolean) => Col[],
  yPosition: number,
  tableLength: number,
  tableMap: Map<string, Col>
) {
  let isInsertable = true;
  tableGrid[yPosition].forEach((col: Col) => {
    const [originCol] = getCol((n: Col) => n.cell.key === col.cell.key && n.isReal);
    const { cell, path } = originCol;
    if (!tableGrid[yPosition + 1] || path[tableLength] + (cell.rowspan || 1) - 1 === yPosition) {
      tableMap.set(cell.key, originCol);
    } else {
      isInsertable = false;
    }
  });
  return isInsertable;
}

function canInsertRowAtTop(
  tableGrid: Col[][],
  getCol: (match: (node: Col) => boolean) => Col[],
  aboveYIndex: number,
  tableLength: number,
  tableMap: Map<string, Col>
) {
  let isInsertable = true;
  tableGrid[aboveYIndex].forEach((col: Col) => {
    if (!col.isReal) {
      const [originCol] = getCol((c: Col) => c.isReal && c.cell.key === col.cell.key);
      if (originCol.path[tableLength] === aboveYIndex) {
        tableMap.set(originCol.cell.key, originCol);
      } else {
        isInsertable = false;
      }
    } else {
      tableMap.set(col.cell.key, col);
    }
  });
  return isInsertable;
}
