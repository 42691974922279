import { isArrayString } from '../functional-utils';
import { TooltipConfig } from './models/tooltip.model';
import { placeholderExp } from '../../shared-modules/data-widgets/data-widgets-common';

export function handleTooltip(config: TooltipConfig): string | string[] | null {
  const { tooltipText, resolvePlaceholdersFn, isMultipleValues, arrayValue } = config;

  if (!tooltipText) {
    return null;
  }

  const placeholders = extractPlaceholders(tooltipText);

  if (!placeholders) {
    return tooltipText;
  }

  const resolvedPlaceholders = resolvePlaceholders(placeholders, resolvePlaceholdersFn);

  if (isSomePlaceholderInvalid(resolvedPlaceholders)) {
    printWarning('Invalid placeholder. Tooltip will not be displayed', resolvedPlaceholders);
    return null;
  }

  if (isMultipleValues) {
    return handleMultipleValuesTooltip(resolvedPlaceholders, tooltipText, arrayValue);
  }

  return replacePlaceholdersWithResolved(tooltipText, resolvedPlaceholders);
}

function handleMultipleValuesTooltip(
  resolvedPlaceholders: string[],
  tooltipText: string,
  arrayValue: any[]
): string | string[] | null {
  if (isMoreThanOneArrayInResolvedPlaceholders(resolvedPlaceholders)) {
    printWarning(
      'Multiple arrays are not supported. Tooltip will not be displayed',
      resolvedPlaceholders
    );
    return null;
  }

  const resolvedTooltipText = replacePlaceholdersWithResolved(tooltipText, [
    ...resolvedPlaceholders
  ]);

  const unparsedTooltipArray = resolvedPlaceholders.find((placeholder) =>
    isArrayString(placeholder)
  );

  if (!unparsedTooltipArray) {
    return resolvedTooltipText;
  }

  const tooltipArray = JSON.parse(unparsedTooltipArray);

  if (isMatchingLengths(tooltipArray, arrayValue)) {
    return arrayValue.map((_, index) =>
      resolvedTooltipText.replace(JSON.stringify(tooltipArray), tooltipArray[index])
    );
  }

  return null;
}

function isMatchingLengths(tooltipArray: any[], arrayValue: any[]): boolean {
  return tooltipArray.length === arrayValue.length;
}

function extractPlaceholders(text: string): string[] | null {
  return text.match(placeholderExp);
}

function replacePlaceholdersWithResolved(text: string, resolvedPlaceholders: string[]): string {
  return text.replace(placeholderExp, () => resolvedPlaceholders.shift());
}

function isMoreThanOneArrayInResolvedPlaceholders(resolvedPlaceholders: string[]): boolean {
  return resolvedPlaceholders.filter((placeholder) => isArrayString(placeholder)).length > 1;
}

function isSomePlaceholderInvalid(resolvedPlaceholders: string[]): boolean {
  return resolvedPlaceholders.some((placeholder) => !placeholder);
}

function resolvePlaceholders(
  placeholders: string[],
  resolvePlaceholdersFn: (placeholder: string) => string
): string[] {
  return placeholders.map((placeholder: string) => resolvePlaceholdersFn(placeholder));
}

function printWarning(message: string, resolvedPlaceholders: string[]) {
  console.warn(`${message}. Resolved placeholders: `, resolvedPlaceholders.join(','));
}
