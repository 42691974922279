import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TranslatedString } from '../models/project.model';
import { ProjectConfigEvent, ProjectsService } from './projects.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

export interface CustomWidgetsComponent {
  name: string;
  displayName: TranslatedString;
  description: TranslatedString;
  previewImage: string;
}
@Injectable({ providedIn: 'root' })
export class CustomWidgetsComponentsService {
  private customWidgets: BehaviorSubject<CustomWidgetsComponent[]> = new BehaviorSubject<
    CustomWidgetsComponent[]
  >(null);

  constructor(private http: HttpClient, private projectsService: ProjectsService) {
    this.subscribeToProjectChange();
  }

  getCustomWidgetsForProject(projectName: string): Observable<CustomWidgetsComponent[]> {
    if (this.customWidgets.value) {
      return this.customWidgets.asObservable();
    }

    return this.http
      .get<CustomWidgetsComponent[]>(`/project-management-service/customwidgets/${projectName}`)
      .pipe(
        map((res) => {
          res = res || [];
          this.customWidgets.next(res);

          return res;
        })
      );
  }

  clearCustomWidgets() {
    this.customWidgets.next(null);
  }

  private subscribeToProjectChange() {
    this.projectsService.projectConfigEvents
      .pipe(takeUntilDestroyed())
      .subscribe((change: ProjectConfigEvent) => {
        if (change === null) {
          this.clearCustomWidgets();
        }
      });
  }
}
