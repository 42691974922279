<div class="position-relative" #listWrapper>
  <div #suggestionList class="reference-suggestion-list" data-cy="reference-suggestion-list">
    <a
      href="#"
      *ngFor="let item of suggestions; let i = index"
      (mousedown)="mousedown($event, item)"
      class="rb-dropdown-item"
      [class.active]="i === activeIndex"
      [attr.data-cy]="'reference-suggestion-' + i"
    >
      {{ item }}
    </a>
  </div>
</div>
