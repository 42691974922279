<div
  [ngClass]="noData ? 'no-data-container' : ''"
  class="centered-container w-100 centered-container-big shaded"
  [class.shaded]="!_loading"
  [class.p-0]="_loading"
  [style.height]="_height"
>
  <div class="w-100 h-100" *ngIf="_loading">
    <ng-container #container></ng-container>
    <ng-container *ngIf="!widgetPlaceholder">
      <ng-container *rbLoading="_loading"></ng-container>
    </ng-container>
    <query-status-message
      class="widget-loading-status-message"
      *ngIf="queryStatus?.status"
      [status]="queryStatus?.status"
    ></query-status-message>
  </div>

  <div *ngIf="!_loading && warning">
    <i class="rb-ic rb-ic-alert-warning"></i>
    <query-status-message *ngIf="queryStatus?.status" [status]="queryStatus?.status">
      <p class="font-size-big">{{ warning.message }}</p>
    </query-status-message>
  </div>

  <ng-container *ngIf="noData">
    <div
      data-cy="widgetLoadingNoDataMessage"
      class="loading-content-no-data"
      *teleportTo="'[data-id=\'' + widgetId + '\']'; position: 'beforebegin'"
    >
      <i class="rb-ic rb-ic-abort-frame m-0"></i>
      <p class="font-size-big m-0" data-cy="widgetNoDataFound">
        {{ 'widget.preview.noDataFound' | translate }}
      </p>
    </div>
  </ng-container>

  <rb-callout
    class="mb-auto w-100"
    *ngIf="!_loading && _error && !isNotFoundOrNoAccess && !isQueueFull"
  >
    <rb-error-format [error]="_error" class="text-left"></rb-error-format>
  </rb-callout>

  <div *ngIf="!_loading && isNotFoundOrNoAccess">
    <i class="rb-ic rb-ic-keys-user-access"></i>
    <p class="font-size-big">{{ 'widget.preview.accessDenied' | translate }}</p>
  </div>

  <div *ngIf="!_loading && isQueueFull">
    <i class="rb-ic rb-ic-denied"></i>
    <p>
      {{ 'widget.preview.requestDenied' | translate }}
      <br />
      {{ 'widget.preview.queueFull' | translate }}
    </p>
    <i
      class="rb-btn rb-link rb-ic rb-ic-refresh"
      (click)="triggerReload()"
      [rbTooltip]="'widget.preview.reload' | translate"
    ></i>
  </div>
</div>
