import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { Condition } from './models/condition.model';
import { PathInfo } from '../data-selection/data-info-util';

@Component({
  selector: 'condition-input',
  templateUrl: './condition-input.component.html',
  styleUrls: ['./condition-input.component.scss']
})
export class ConditionInputComponent implements OnInit, OnChanges {
  @Input() paths: PathInfo[];

  @Input() condition: Condition;

  @Output() conditionChange = new EventEmitter<Condition>();

  filter = '';

  displayPaths: PathInfo[];

  _condition: Condition;

  ngOnInit() {
    if (!this._condition) {
      this._condition = { path: '', op: 'equals', value: '' };
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.paths) {
      if (!changes.paths.currentValue) {
        this.paths = [];
      } else {
        this.paths = changes.paths.currentValue;
      }
      this.updateFilter(this.filter);
    }
    if (changes.codition && changes.condition.currentValue) {
      this._condition = { ...changes.condition.currentValue };
    }
  }

  selectPath(path: string) {
    this._condition.path = path;
    this.notifyChange();
  }

  updateOp(op) {
    this._condition.op = op;
    this.notifyChange();
  }

  updateValue(value: any) {
    this._condition.value = value;
    this.notifyChange();
  }

  updateFilter(value) {
    this.filter = value;
    this.displayPaths = this.paths.filter((p) => p.path.indexOf(this.filter) !== -1);
  }

  notifyChange() {
    this.conditionChange.next(this._condition);
  }
}
