import { ParamMap } from '@angular/router';
import { UrlSearchParamMap } from './url-search-param-map';
import { DashboardParameterOptions, SyncAbstractDashboardParameter } from './dashboard-parameter';

export type PrimitiveDashboardParameterValue = string | boolean | number;
export type PrimitiveDashboardParameterType = 'string' | 'number' | 'boolean';
export type MultiPrimitiveDashboardParameterType = 'string[]' | 'number[]' | 'boolean[]';

export class PrimitiveDashboardParameter extends SyncAbstractDashboardParameter<PrimitiveDashboardParameterValue> {
  declare type: PrimitiveDashboardParameterType;

  constructor(
    name: string,
    i18nLabel: string,
    type: PrimitiveDashboardParameterType,
    widgetId: string,
    value?: PrimitiveDashboardParameterValue,
    options?: DashboardParameterOptions
  ) {
    super(name, i18nLabel, type, widgetId, value, options);
  }

  static getParameterFactory(
    widgetId: string,
    isQueryParam = true,
    isLocalStorage = true,
    skipWidgetPrefix = false
  ): (
    name: string,
    i18nLabel: string,
    value?: any,
    type?: PrimitiveDashboardParameterType
  ) => PrimitiveDashboardParameter {
    return (
      name: string,
      i18nLabel: string,
      value?: any,
      type: PrimitiveDashboardParameterType = 'string'
    ) =>
      new PrimitiveDashboardParameter(name, i18nLabel, type, widgetId, value, {
        isQueryParam,
        isLocalStorage,
        skipWidgetPrefix
      });
  }

  toParams(): ParamMap {
    const params = new URLSearchParams();
    if (this.value !== undefined) {
      params.set(this.paramKey, String(this.value));
    }

    return new UrlSearchParamMap(params);
  }

  fromParams(params: ParamMap, writeOnlyOnChange = false) {
    if (!params.has(this.paramKey)) {
      return;
    }
    this.writeValue(getValueFromParam(this.type, params.get(this.paramKey)), writeOnlyOnChange);
  }
}

export class MultiPrimitiveDashboardParameter extends SyncAbstractDashboardParameter<
  PrimitiveDashboardParameterValue[]
> {
  declare type: MultiPrimitiveDashboardParameterType;
  singleType: PrimitiveDashboardParameterType;

  constructor(
    name: string,
    i18nLabel: string,
    type: PrimitiveDashboardParameterType,
    widgetId: string,
    value?: PrimitiveDashboardParameterValue[],
    options?: DashboardParameterOptions
  ) {
    super(
      name,
      i18nLabel,
      `${type}[]` as MultiPrimitiveDashboardParameterType,
      widgetId,
      value ?? [],
      options
    );
    this.singleType = type;
  }

  toParams(): ParamMap {
    const params = new URLSearchParams();
    if (this.value !== undefined && Array.isArray(this.value) && this.value.length) {
      this.value.forEach((v) => params.append(this.paramKey, String(v)));
    }

    return new UrlSearchParamMap(params);
  }

  fromParams(params: ParamMap, writeOnlyOnChange = false) {
    if (!params.has(this.paramKey)) {
      return;
    }
    const values = [];
    for (const value of params.getAll(this.paramKey)) {
      values.push(getValueFromParam(this.singleType, value));
    }
    this.writeValue(values, writeOnlyOnChange);
  }
}

function getValueFromParam(
  type: PrimitiveDashboardParameterType,
  value: string
): PrimitiveDashboardParameterValue {
  switch (type) {
    case 'number':
      return parseFloat(value);
    case 'boolean':
      if (value === 'true') {
        return true;
      } else if (value === 'false') {
        return false;
      }
      return undefined;
    default:
      return value;
  }
}
