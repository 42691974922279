import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LoadingEntity } from '@inst-iot/bosch-angular-ui-components';
import moment from 'moment';
import { combineLatest, Observable, of } from 'rxjs';
import { Constants } from '../../../../constants';
import { DeviceHistory } from '../../models/device-history';
import { DeviceHistoryService } from '../../services/device-history.service';

@Component({
  selector: 'backdate-change',
  templateUrl: './backdate-change.component.html',
  styleUrls: ['./backdate-change.component.scss']
})
export class BackdateChangeComponent implements OnInit {
  @Input() disabled: boolean;
  @Input() isNewDevice = false;
  @Input() isNewLinkedDevice = false;
  @Input() set deviceId(value: string) {
    this._deviceId = value;
    if (!this.backdate) {
      return;
    }
    this.getLastEntry();
  }

  get deviceId() {
    return this._deviceId;
  }

  @Input() set linkedDeviceId(value: string) {
    this._linkedDeviceId = value;
    this.entryLoader
      .run(this.getEntryOfLinkedDevice())
      .subscribe((linkedDeviceEntry: DeviceHistory) => {
        this.initialDeviceEntry = linkedDeviceEntry;
        if (linkedDeviceEntry) {
          this.updateLastEntryDetails(linkedDeviceEntry);
        }
      });
  }

  @Output() backdateChange = new EventEmitter<{ backdate: string; labels: string[] }>();

  initialDeviceEntry: DeviceHistory;
  lastEntry: DeviceHistory;
  entryLoader = new LoadingEntity<DeviceHistory[] | DeviceHistory>();

  backdate = false;
  selectedTimestamp: string;
  backdateLabels: string[] = [];

  currentTimestamp: Date;
  earliestBackdateTimestamp = '';

  // id of the relevant device of the last history entry
  lastEntryRelevantThingId = '';
  noEntryFound = false;

  readonly routing = Constants.routing;

  private _deviceId: string | undefined;
  private _linkedDeviceId: string | undefined;

  constructor(private historyService: DeviceHistoryService) {}

  ngOnInit() {
    this.currentTimestamp = new Date();
  }

  /**
   * If checkbox is now active get the last relevant history entry
   * @param $event
   */
  checkBoxChanged($event: boolean) {
    if ($event) {
      this.getLastEntry();
    } else {
      this.backdateChange.next(null);
    }
  }

  private getLastEntry() {
    this.entryLoader
      .run(
        combineLatest([
          this.historyService.getLastEntryWithDeviceId(this.deviceId),
          this.getEntryOfLinkedDevice()
        ])
      )
      .subscribe(([entry, linkedDeviceEntry]: DeviceHistory[]) => {
        this.initialDeviceEntry = entry;
        this.lastEntry = entry;

        if (!entry) {
          this.noEntryFound = true;
          if (!this.isNewDevice && !this.isNewLinkedDevice) {
            this.backdate = false;
          }
        }

        if (linkedDeviceEntry) {
          this.updateLastEntryDetails(linkedDeviceEntry);
        } else if (entry) {
          this.updateLastEntryDetails(entry);
        } else {
          // No History Entries for new Devices
          this.updateLastEntryDetails();
        }
      });
  }

  /**
   * Updates the earliest backdating timestamp and the thingId for the link
   *
   * @param newEntry
   */
  updateLastEntryDetails(newEntry?: DeviceHistory) {
    if (!this.lastEntry) {
      this.lastEntry = newEntry;
      this.lastEntryRelevantThingId = this._linkedDeviceId;
    } else {
      this.compareTimestampChange(newEntry);
    }
    this.earliestBackdateTimestamp = this.lastEntry
      ? moment(this.lastEntry.validityBegin).add(1, 'm').toISOString()
      : null;
    this.selectedTimestamp =
      new Date(this.earliestBackdateTimestamp) <= new Date(this.selectedTimestamp)
        ? this.selectedTimestamp
        : '';
  }

  /**
   * Compares the timestamp of the new history entry against the currently used and the original one.
   *
   * @param {DeviceHistory} newEntry
   */
  compareTimestampChange(newEntry: DeviceHistory): void {
    const isAfter = (timeA: DeviceHistory, timeB: DeviceHistory) => {
      return timeA.validityBegin > timeB.validityBegin;
    };

    const newEntryAfterLastEntry = isAfter(newEntry, this.lastEntry);
    const newEntryAfterDefaultEntry = isAfter(newEntry, this.initialDeviceEntry);
    if (newEntryAfterLastEntry || newEntryAfterDefaultEntry) {
      this.lastEntry = newEntry;
      this.lastEntryRelevantThingId = this._linkedDeviceId;
    } else if (!newEntryAfterDefaultEntry) {
      this.lastEntry = this.initialDeviceEntry;
      this.lastEntryRelevantThingId = this.deviceId;
    }
  }

  backdateChanged() {
    this.backdateChange.next({ backdate: this.selectedTimestamp, labels: this.backdateLabels });
  }

  getEntryOfLinkedDevice(): Observable<DeviceHistory> {
    if (!this._linkedDeviceId || this.deviceId === this._linkedDeviceId) {
      // Avoid duplicate requests for identical deviceIDs
      return of(null);
    }
    return this.historyService.getLastEntryWithDeviceId(this._linkedDeviceId);
  }
}
