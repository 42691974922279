<div disabled="disabled??" class="multiple-types-container">
  <p class="hint-label">
    {{ 'valueFormatting.subtitle' | translate }}
  </p>

  <h5 class="mb-1">{{ 'valueFormatting.generalHandling' | translate }}</h5>

  <div class="d-flex mb-3 align-items-end">
    <rb-form-custom-select
      class="col-6 static pl-0"
      [label]="'valueFormatting.type' | translate"
      [(ngModel)]="selectionType"
      data-cy="valueFormattingType"
      (ngModelChange)="updateSelectionType($event)"
    >
      <rb-form-select-option
        *ngFor="let param of selectTypesList"
        [value]="param.value"
        [attr.data-cy]="'valueFormattingType-' + param.value"
      >
        {{ param.label | translate }}
      </rb-form-select-option>
    </rb-form-custom-select>
    <div class="col-6 pl-0">
      <ellipsis-text>{{ 'valueFormatting.multipleValuesHint' | translate }}</ellipsis-text>
      <rb-form-checkbox
        data-cy="valueFormattingMultipleValues"
        name="multipleValues"
        class="static"
        [label]="'valueFormatting.multipleValuesLabel' | translate"
        [(ngModel)]="viewModelBaseConfig.multipleValues"
        (ngModelChange)="updateValue({ multipleValues: $event })"
      ></rb-form-checkbox>
    </div>
  </div>

  <rb-form-custom-select
    *ngIf="viewModelBaseConfig.multipleValues"
    [label]="'valueFormatting.arrayValueSeparator' | translate"
    data-cy="valueFormattingArrayValueSeparator"
    [(ngModel)]="viewModelBaseConfig.arrayValueSeparator"
    (ngModelChange)="updateValue({ arrayValueSeparator: $event })"
  >
    <rb-form-select-option
      *ngFor="let separator of arrayValueSeparatorList"
      [value]="separator.value"
    >
      {{ separator.label | translate }}
    </rb-form-select-option>
  </rb-form-custom-select>

  <value-or-reference-input
    name="'prefix'"
    data-cy="valueFormattingPrefix"
    (ngModelChange)="updateValue({ prefix: $event })"
    [(ngModel)]="viewModelBaseConfig.prefix"
    [paths]="paths"
    [label]="'valueFormatting.prefix' | translate"
    [filterParameters]="filterParameters"
    [inputParameters]="inputParameters"
  ></value-or-reference-input>

  <value-or-reference-input
    name="'suffix'"
    data-cy="valueFormattingSuffix"
    (ngModelChange)="updateValue({ suffix: $event })"
    [(ngModel)]="viewModelBaseConfig.suffix"
    [paths]="paths"
    [label]="'valueFormatting.suffix' | translate"
    [inputParameters]="inputParameters"
    [filterParameters]="filterParameters"
  ></value-or-reference-input>

  <div class="row justify-content-between">
    <div class="col">
      <ellipsis-text>
        {{ 'valueFormatting.hideValueCheckboxDescription' | translate }}
      </ellipsis-text>
      <rb-form-checkbox
        class="no-margin-top"
        [label]="'valueFormatting.hideValueCheckbox' | translate"
        (ngModelChange)="updateValue({ hidden: $event })"
        [(ngModel)]="viewModelBaseConfig.hidden"
        name="hideValueCheckbox"
      ></rb-form-checkbox>
    </div>

    <div class="col">
      <ellipsis-text>
        {{ 'valueFormatting.automaticPaddingDescription' | translate }}
      </ellipsis-text>
      <rb-form-checkbox
        class="no-margin-top"
        [label]="'valueFormatting.automaticPadding' | translate"
        [(ngModel)]="viewModelBaseConfig.automaticPadding"
        (ngModelChange)="updateValue({ automaticPadding: $event })"
        name="automaticPadding"
      ></rb-form-checkbox>
    </div>
  </div>

  <h5 class="mb-1" *ngIf="selectionType !== dataFormattingInputType.STRING || !hideWrapText">
    {{ 'valueFormatting.typeSpecificOptions' | translate }}
  </h5>

  <ng-container *ngIf="selectionType === dataFormattingInputType.BOOLEAN">
    <rb-form-input
      data-cy="valueFormattingTrueTextReplacement"
      name="trueTextInput"
      [label]="'valueFormatting.hintLabelTrueTextReplacement' | translate"
      [(ngModel)]="valueFormattingBoolean.trueText"
      (ngModelChange)="updateValue({ trueText: $event })"
    ></rb-form-input>
    <rb-form-input
      data-cy="valueFormattingFalseTextReplacement"
      name="falseTextInput"
      [label]="'valueFormatting.hintLabelFalseTextReplacement' | translate"
      [(ngModel)]="valueFormattingBoolean.falseText"
      (ngModelChange)="updateValue({ falseText: $event })"
    ></rb-form-input>
  </ng-container>

  <ng-container *ngIf="selectionType === dataFormattingInputType.NUMBER">
    <ellipsis-text>{{ 'valueFormatting.hintLabelThousandsSeparator' | translate }}</ellipsis-text>
    <rb-form-checkbox
      class="no-margin-top"
      [label]="'valueFormatting.thousandsSeparatorCheckbox' | translate"
      (ngModelChange)="updateValue({ thousandsSeparator: $event })"
      [(ngModel)]="valueFormattingNumber.thousandsSeparator"
      name="thousandsSeparatorCheckbox"
      data-cy="valueFormattingThousandsSeparator"
    ></rb-form-checkbox>

    <rb-form-custom-select
      [label]="'valueFormatting.decimalPoints' | translate"
      (ngModelChange)="updateValue({ decimalPoints: $event })"
      [(ngModel)]="valueFormattingNumber.decimalPoints"
      data-cy="valueFormattingDecimalPoints"
    >
      <rb-form-select-option *ngFor="let number of decimalPoints" [value]="number">
        {{ number }}
      </rb-form-select-option>
    </rb-form-custom-select>
  </ng-container>

  <ng-container *ngIf="selectionType === dataFormattingInputType.STRING && !hideWrapText">
    <ellipsis-text>{{ 'valueFormatting.hintWrapTextCheckbox' | translate }}</ellipsis-text>
    <rb-form-checkbox
      class="no-margin-top"
      [label]="'valueFormatting.wrapTextCheckbox' | translate"
      (ngModelChange)="updateValue({ wrapText: $event })"
      [(ngModel)]="valueFormattingString.wrapText"
      name="wrapTextCheckbox"
    ></rb-form-checkbox>
  </ng-container>

  <ng-container *ngIf="selectionType === dataFormattingInputType.DATETIME">
    <ellipsis-text>{{ 'valueFormatting.hintLabelConvertTimezone' | translate }}</ellipsis-text>
    <rb-form-checkbox
      class="no-margin-top"
      [label]="'valueFormatting.convertTimezoneCheckbox' | translate"
      (ngModelChange)="updateValue({ convertTimezone: $event })"
      [(ngModel)]="valueFormattingDatetime.convertTimezone"
      name="convertTimezoneCheckbox"
      data-cy="valueFormattingConvertTimezone"
    ></rb-form-checkbox>

    <rb-form-custom-select
      [label]="'valueFormatting.timeFormatCheckbox' | translate"
      data-cy="valueFormattingTimeFormatSelector"
      (ngModelChange)="updateValue({ dateFormat: $event })"
      [(ngModel)]="valueFormattingDatetime.dateFormat"
    >
      <rb-form-select-option *ngFor="let time of timeFormats" [value]="time.value">
        {{ time.label }}
      </rb-form-select-option>
    </rb-form-custom-select>
  </ng-container>

  <ng-container *ngIf="selectionType === dataFormattingInputType.JSON">
    <ellipsis-text>{{ 'valueFormatting.hintAutoFormatJson' | translate }}</ellipsis-text>
    <rb-form-checkbox
      class="no-margin-top"
      [label]="'valueFormatting.autoFormat' | translate"
      (ngModelChange)="updateValue({ autoFormat: $event })"
      [(ngModel)]="valueFormattingJson.autoFormat"
      name="autoFormatCheckbox"
      data-cy="valueFormattingAutoFormat"
    ></rb-form-checkbox>

    <ellipsis-text>{{ 'valueFormatting.hintShowJsonInPopover' | translate }}</ellipsis-text>
    <rb-form-checkbox
      class="no-margin-top"
      [label]="'valueFormatting.showJsonInPopover' | translate"
      (ngModelChange)="updateValue({ showInPopover: $event })"
      [(ngModel)]="valueFormattingJson.showInPopover"
      name="showInPopoverCheckbox"
      data-cy="valueFormattingShowInPopover"
    ></rb-form-checkbox>

    <rb-form-input
      type="number"
      rbNumberConverter
      [label]="'valueFormatting.jsonEditorMaxLines' | translate"
      [rbMin]="1"
      data-cy="valueFormattingJsonMaxLines"
      [(ngModel)]="valueFormattingJson.jsonEditorMaxLines"
      (ngModelChange)="updateValue({ jsonEditorMaxLines: $event })"
    >
      <ng-template rbFormValidationMessage="rbMin">
        {{ 'validation.minValue1' | translate }}
      </ng-template>
    </rb-form-input>
  </ng-container>
</div>
