import { Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { WidgetInstanceService } from '../../services/widget-instance.service';
import { DialogCloseEventWithResult, ModalService } from '@inst-iot/bosch-angular-ui-components';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { readBlobAsJson } from '../../../shared-modules/action-buttons/action-config-util';
import { GlobalAlertService } from '../../../core/services/global-alert.service';
import { translate } from '../../../../app/shared/translation-util';
import { UserAuthService } from '../../../../app/core/services/user-auth.service';
import { AZURE_ERROR_CODE } from '../../../shared-modules/rest-request/models/rest-request.model';

@Component({
  selector: 'widget-loading-status',
  styleUrls: ['./widget-loading-status.component.scss'],
  templateUrl: './widget-loading-status.component.html'
})
export class WidgetLoadingStatusComponent implements OnInit, OnDestroy {
  isLoading = false;
  error = null;
  hasSessionExpired: boolean;

  @Input() showInTooltip = false;

  @ViewChild('errorTpl') errorTpl: TemplateRef<any>;

  private destroy = new Subject<null>();

  constructor(
    private widgetInstance: WidgetInstanceService,
    private userAuthService: UserAuthService,
    private alertService: GlobalAlertService,
    private modal: ModalService
  ) {}

  get lastUpdate() {
    return (
      this.widgetInstance.lastDataGenerated ||
      this.widgetInstance.lastData ||
      this.widgetInstance.lastLoading
    );
  }

  ngOnInit(): void {
    this.widgetInstance.loading.pipe(takeUntil(this.destroy)).subscribe((state) => {
      this.isLoading = state;
    });

    this.widgetInstance.loadingError.pipe(takeUntil(this.destroy)).subscribe((err) => {
      this.error = err;
      this.handleAzureSessionError();
    });
  }

  ngOnDestroy() {
    this.destroy.next(null);
  }

  openErrorDialog() {
    if (this.hasSessionExpired) {
      this.showAzureSessionExpiredMessage();
    } else {
      this.modal.open(this.errorTpl);
    }
  }

  private showAzureSessionExpiredMessage() {
    this.alertService
      .showWarning(
        translate('widget.azureSessionExpiredMessage'),
        translate('refreshBrowserWarning.refreshSessionButton'),
        translate('error.reload'),
        translate('confirmation.abort')
      )
      .then((response: DialogCloseEventWithResult) => {
        if (response.event === 'confirmed') {
          this.userAuthService.performLoginAndRetainLocation();
        }
      });
  }

  private handleAzureSessionError() {
    if (this.error?.error !== null) {
      readBlobAsJson(this.error?.error).then((errorJson) => {
        if (errorJson?.message?.includes(AZURE_ERROR_CODE)) {
          this.hasSessionExpired = true;
          this.showAzureSessionExpiredMessage();
        } else {
          this.hasSessionExpired = false;
        }
      });
    }
  }
}
