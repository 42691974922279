<div class="richtext-container">
  <toolbar [editor]="editor" [paths]="paths" (imageSizeError)="onImageSizeError($event)"></toolbar>
  <rb-callout customClass="mb-0" *ngIf="this.imageSizeError" [type]="'error'" [closeable]="true">
    {{ 'slateRichtext.imageTooLargeError' | translate }}
  </rb-callout>
  <slate-editor
    [readonly]="false"
    [editor]="editor"
    [paths]="paths"
    [value]="value"
    (valueChange)="onChange($event)"
    [ngClass]="editorClass"
    [style.height]="editorHeight"
  ></slate-editor>
</div>

<rb-form-errors [messages]="messages"></rb-form-errors>
