export interface ContainerNode extends HTMLSpanElement {
  regexPos: number;

  add(el: Node | string);
}

/**
 * AST handler.
 * Rewrite from: https://github.com/DmitrySoshnikov/regexp-tree/blob/master/src/generator/index.js
 */
const Generator = {
  highlightGroups: undefined,

  RegExp(node) {
    const container = createContainer(node);
    container.add(generateDom(node.body));
    return container;
  },

  Alternative(node) {
    const container = createContainer(node);
    (node.expressions || []).forEach((n) => container.add(generateDom(n)));
    return container;
  },

  Disjunction(node) {
    const container = createContainer(node);
    container.add(generateDom(node.left));
    container.add('|');
    container.add(generateDom(node.right));
    return container;
  },

  Group(node) {
    const container = createContainer(node);
    if (!this.highlightGroups || this.highlightGroups.includes(node.number)) {
      container.classList.add('group-' + node.number);
    }

    const expression = generateDom(node.expression);

    if (node.capturing) {
      // A named group.
      if (node.name) {
        container.add(`(?<${node.nameRaw || node.name}>`);
        container.add(expression);
        container.add(')');
        return container;
      }

      container.add(`(`);
      container.add(expression);
      container.add(')');
      return container;
    }

    container.add(`(?:`);
    container.add(expression);
    container.add(')');
    return container;
  },

  Backreference(node) {
    const container = createContainer(node);

    switch (node.kind) {
      case 'number':
        container.add(`\\${node.reference}`);
        return container;
      case 'name':
        container.add(`\\k<${node.referenceRaw || node.reference}>`);
        return container;
      default:
        throw new TypeError(`Unknown Backreference kind: ${node.kind}`);
    }
  },

  Assertion(node) {
    const container = createContainer(node);
    switch (node.kind) {
      case '^':
      case '$':
      case '\\b':
      case '\\B':
        container.add(node.kind);
        return container;

      case 'Lookahead': {
        const assertion = generateDom(node.assertion);

        if (node.negative) {
          container.add('(?!');
          container.add(assertion);
          container.add(')');
          return container;
        }

        container.add('(?=');
        container.add(assertion);
        container.add(')');
        return container;
      }

      case 'Lookbehind': {
        const assertion = generateDom(node.assertion);

        if (node.negative) {
          container.add('(?<!');
          container.add(assertion);
          container.add(')');
          return container;
        }

        container.add('(?<=');
        container.add(assertion);
        container.add(')');
        return container;
      }

      default:
        throw new TypeError(`Unknown Assertion kind: ${node.kind}`);
    }
  },

  CharacterClass(node) {
    const container = createContainer(node);
    container.add('[');

    if (node.negative) {
      container.add('^');
    }
    for (const e of node.expressions) {
      container.add(generateDom(e));
    }

    container.add(']');

    return container;
  },

  ClassRange(node) {
    const container = createContainer(node);
    container.add(generateDom(node.from));
    container.add('-');
    container.add(generateDom(node.to));
    return container;
  },

  Repetition(node) {
    const container = createContainer(node);
    container.add(generateDom(node.expression));
    container.add(generateDom(node.quantifier));
    return container;
  },

  Quantifier(node) {
    let quantifier;
    const greedy = node.greedy ? '' : '?';

    switch (node.kind) {
      case '+':
      case '?':
      case '*':
        quantifier = node.kind;
        break;
      case 'Range':
        // Exact: {1}
        if (node.from === node.to) {
          quantifier = `{${node.from}}`;
        } else if (!node.to) {
          // Open: {1,}
          quantifier = `{${node.from},}`;
        } else {
          // Closed: {1,3}
          quantifier = `{${node.from},${node.to}}`;
        }
        break;
      default:
        throw new TypeError(`Unknown Quantifier kind: ${node.kind}`);
    }

    return document.createTextNode(`${quantifier}${greedy}`);
  },

  Char(node) {
    const value = node.value;

    switch (node.kind) {
      case 'simple': {
        if (node.escaped) {
          return document.createTextNode(`\\${value}`);
        }
        return document.createTextNode(value);
      }

      case 'hex':
      case 'unicode':
      case 'oct':
      case 'decimal':
      case 'control':
      case 'meta':
        return document.createTextNode(value);

      default:
        throw new TypeError(`Unknown Char kind: ${node.kind}`);
    }
  },

  UnicodeProperty(node) {
    const escapeChar = node.negative ? 'P' : 'p';
    let namePart;

    if (!node.shorthand && !node.binary) {
      namePart = `${node.name}=`;
    } else {
      namePart = '';
    }

    return document.createTextNode(`\\${escapeChar}{${namePart}${node.value}}`);
  }
};

function createSpan(className: string, addEl?: Node | string): HTMLSpanElement {
  const container = document.createElement('span');
  container.className = className;
  if (addEl !== undefined) {
    addElement.call(container, addEl);
  }
  return container;
}

function createContainer(node): ContainerNode {
  const container = createSpan('regex-' + node.type) as ContainerNode;
  container.regexPos = node.loc.start.offset;
  container.add = addElement;
  return container;
}

function addElement(el: Node | string) {
  this.appendChild(typeof el === 'string' ? document.createTextNode(el) : el);
}

/**
 * Helper `gen` function calls node type handler.
 */
export function generateDom(node, highlightGroups?: number[]): HTMLElement {
  if (highlightGroups) {
    Generator.highlightGroups = highlightGroups;
  }
  return node ? Generator[node.type](node) : document.createElement('span');
}
