import { EditorElement } from './slate.util';
import {
  extractChildren,
  replacePlaceholdersWithResolver,
  resolveStringWithModifiers,
  ValueFormatter
} from '../../shared-modules/data-widgets/data-widgets-common';
import { cloneDeep, isObject } from 'lodash-es';
import { Element } from 'slate';
import { CustomText } from './plugins/reference';
import { DataConditionalFormattingPipe } from '../../shared-modules/data-conditional-formatting/data-conditional-formatting.pipe';
import { identity } from 'rxjs';
import { getValueByPath } from '../../shared-modules/data-selection/data-info-util';

export abstract class AbstractPlaceholderResolver {
  public dataSource: any[];
  public context: any;
  public valueFormatter?: ValueFormatter;
  public dataConditionalFormattingPipe: DataConditionalFormattingPipe;

  constructor(
    dataSource: any[],
    context = {},
    valueFormatter: ValueFormatter = resolveStringWithModifiers,
    dataConditionalFormattingPipe?: DataConditionalFormattingPipe
  ) {
    this.dataSource = dataSource;
    this.context = context;
    this.valueFormatter = valueFormatter;
    this.dataConditionalFormattingPipe = dataConditionalFormattingPipe;
  }

  /**
   * Resolve slate editor values when multiple sources are available.
   * @param {EditorElement[]} editorElements - Slate elements tree to resolve
   * @param {number} idx - Index to use for resolving the placeholder values.
   * In case of table for example, each row placeholder will have a different index.
   */
  resolveSlateNodesWithFixedIndex(editorElements: EditorElement[], idx = 0): EditorElement[] {
    let resolvedElements = editorElements;

    if (!editorElements) {
      return resolvedElements;
    }

    const values = this.extractEditorValues(editorElements);
    if (!values.length) {
      return resolvedElements;
    }
    const elements = extractChildren(editorElements);
    const referenceElements = elements.filter((element) => element['type'] === 'reference');

    const resolvedValues = values.map((placeHolder, i) => {
      // we need to 'stringify' JSON data types
      const isDataTypeJson = referenceElements[i]?.['valueFormatting']?.dataType === 'json';

      if (isObject(placeHolder)) {
        return Object.keys(placeHolder).reduce(
          (acc, key) => ({
            ...acc,
            [key]: this.resolvePlaceholdersForSlate(
              placeHolder[key],
              idx,
              isDataTypeJson ? resolveStringWithModifiers : this.valueFormatter,
              this.context,
              this.dataSource
            )
          }),
          {}
        );
      } else {
        return this.resolvePlaceholdersForSlate(
          placeHolder,
          idx,
          isDataTypeJson ? resolveStringWithModifiers : this.valueFormatter,
          this.context,
          this.dataSource
        );
      }
    });

    resolvedElements = this.replaceValues(resolvedValues, cloneDeep(editorElements), idx);
    return resolvedElements;
  }

  resolvePlaceholdersForSlate(
    content: string,
    index?: number,
    valueFormatter: ValueFormatter = identity,
    ...dataSource: any[]
  ) {
    return replacePlaceholdersWithResolver(
      content,
      index,
      (path: string[], modifier?: string[]) => {
        for (const ds of dataSource) {
          const value = getValueByPath(ds, path);
          if (value === undefined) {
            continue;
          }
          return valueFormatter(value, path, modifier);
        }
        console.warn(`Failed to resolve placeholders for ${content}`);
        return valueFormatter(undefined, path, modifier);
      },
      false
    );
  }

  abstract extractEditorValues(
    nodes: Element[] | CustomText[],
    values?: string[] | Record<string, string>[]
  ): string[] | Record<string, string>[];

  abstract replaceValues(
    values: string[] | Record<string, string>[],
    nodes: Element[] | CustomText[],
    idx: number
  ): EditorElement[];
}
