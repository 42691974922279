<div class="container" *ngIf="retentionTimes">
  <p *ngIf="retentionTimes.length === 1">
    {{ 'collectionSelector.retentionTimes.popover.text' | translate }}
    {{ ' ' + retentionTimes[0] | duration: 'suitableUnit' }}
    {{ 'collectionSelector.retentionTimes.popover.basedOn' | translate }}
    <span class="font-italic">{{ retentionTimeProperty[0] }}.</span>
  </p>
  <div *ngIf="retentionTimes.length > 1">
    {{ 'collectionSelector.retentionTimes.popover.textMultiple' | translate }}
    <ul class="mt-2">
      <li *ngFor="let item of retentionTimes; index as i">
        {{ retentionTimes[i] | duration: 'suitableUnit' }}
        {{ 'collectionSelector.retentionTimes.popover.basedOn' | translate }}
        <span class="font-italic">{{ retentionTimeProperty[i] }}</span>
      </li>
    </ul>
  </div>
  <a
    *hasProjectRole="roles.powerUser"
    target="_blank"
    [routerLink]="routing.adminRetentionConfig | projectUrl"
    class="rb-link rb-forward"
  >
    {{ 'collectionSelector.retentionTimes.popover.link' | translate }}
  </a>
</div>
