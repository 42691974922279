import { ProjectsService } from '../../../shared-projects/services/projects.service';
import { Pipe, PipeTransform } from '@angular/core';
import { CustomWidgetsComponentsService } from '../../../shared-projects/services/custom-widgets-component.service';
import { TranslatedString } from '../../../shared-projects/models/project.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Pipe({
  name: 'customWidgetsDisplayName'
})
export class CustomWidgetsDisplayNamePipe implements PipeTransform {
  constructor(
    private customWidgetsComponentsService: CustomWidgetsComponentsService,
    private projectsService: ProjectsService
  ) {}

  transform(name: string): Observable<TranslatedString> {
    return this.customWidgetsComponentsService
      .getCustomWidgetsForProject(this.projectsService.projectName)
      .pipe(
        map((customWidgets) => {
          return customWidgets?.find((cw) => cw.name === name)?.displayName;
        })
      );
  }
}
