import {
  AfterViewInit,
  Directive,
  ElementRef,
  HostListener,
  inject,
  Renderer2
} from '@angular/core';

@Directive({
  selector: '[toggleControlsOnHover]',
  standalone: true
})
export class ToggleControlsOnHoverDirective implements AfterViewInit {
  private el = inject(ElementRef);
  private renderer = inject(Renderer2);
  private controlsEl: HTMLElement;

  ngAfterViewInit() {
    this.controlsEl = this.el.nativeElement.querySelector('[data-type="controls"]');
  }

  @HostListener('mouseenter') onMouseEnter() {
    if (!this.controlsEl) {
      return;
    }

    this.renderer.addClass(this.controlsEl, 'hover');
  }

  @HostListener('mouseleave') onMouseLeave() {
    if (!this.controlsEl) {
      return;
    }

    this.renderer.removeClass(this.controlsEl, 'hover');
  }
}
