import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import {
  ActionType,
  actionTypeDetails,
  ActionTypeMapping,
  SimpleActionType
} from '../../../devices/models/device-history-change';

@Component({
  selector: 'device-change-badge',
  templateUrl: './device-change-badge.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeviceChangeBadgeComponent implements OnInit {
  @Input({ required: true }) actionType: ActionType;

  protected action: {
    class: string;
    title: string;
    tooltip: string;
  };

  private actionTypeMapping = ActionTypeMapping;

  ngOnInit(): void {
    const mappedType: SimpleActionType | ActionType = this.actionTypeMapping[this.actionType];
    this.action = actionTypeDetails[mappedType ?? this.actionType];
  }
}
