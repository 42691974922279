import { Component, forwardRef, Input, TemplateRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ColorConfig } from '../../color-picker/models/color-picker.model';

function emptyFunction(arg) {
  // empty function
}

@Component({
  selector: 'color-picker-input',
  templateUrl: './color-picker-input.component.html',
  styleUrls: ['./color-picker-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ColorPickerInputComponent),
      multi: true
    }
  ]
})
export class ColorPickerInputComponent implements ControlValueAccessor {
  @Input() name: string;
  @Input() label: string | TemplateRef<any> = null;
  @Input() required: string;
  @Input() enableAlpha = true;

  colorConfig: ColorConfig = { color: '#000000' };

  onChange = emptyFunction;
  onTouched = emptyFunction;

  updateValue(value) {
    if (value === '') {
      value = null;
    }
    this.colorConfig = { color: value };
    this.onChange(value);
  }

  updateConfig(colorConfig: ColorConfig) {
    this.colorConfig = colorConfig;
    this.onChange(colorConfig.color);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  writeValue(obj: any): void {
    this.colorConfig = { color: obj };
  }
}
