import {
  ComponentRef,
  createComponent,
  EnvironmentInjector,
  Injectable,
  Injector
} from '@angular/core';
import { WidgetTypeDefinition } from '../models/widget-registry.model';
import { WidgetEditComponent } from '../widget-edit/widget-edit.component';

@Injectable()
export class WidgetRemovalService {
  componentRef: ComponentRef<WidgetEditComponent>;

  constructor(private injector: Injector, private environmentFactory: EnvironmentInjector) {}

  deleteWidget(widgetTypeDefinition: WidgetTypeDefinition, widgetId: string): void {
    this.createWidgetEditComponent(widgetId, widgetTypeDefinition);
    this.componentRef.instance.remove(false);
  }

  private createWidgetEditComponent(
    widgetId: string,
    widgetTypeDefinition: WidgetTypeDefinition
  ): void {
    this.componentRef = createComponent(WidgetEditComponent, {
      environmentInjector: this.environmentFactory,
      elementInjector: this.injector
    });
    this.componentRef.instance.type = widgetTypeDefinition.type;
    this.componentRef.instance.id = widgetId;
    this.componentRef.instance.parseDashboardConfigAndSetProperties();
    this.componentRef.instance.createWidgetEditComponentRef();
    this.componentRef.instance.editComponentRef.instance.ngOnInit();
  }
}
