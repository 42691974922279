import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { OptionDefinition, OptionValues } from '../lib/options.model';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'options-configuration-form',
  templateUrl: './options-configuration-form.component.html',
  styleUrls: ['./options-configuration-form.component.scss']
})
export class OptionsConfigurationFormComponent implements OnDestroy {
  @Input() layout: 'vertical' | 'col-6' | 'col-4' = 'vertical';

  _options: OptionDefinition[] = [];
  _values: OptionValues;
  @Output() valuesChange = new EventEmitter<OptionValues>();

  form: UntypedFormGroup;

  private destroy = new Subject<null>();

  @Input() set values(v: OptionValues) {
    this._values = v;
    if (this.form) {
      this.form.patchValue(v, { emitEvent: false });
    }
  }

  @Input() set options(o: OptionDefinition[]) {
    this._options = o;
    if (!this.form) {
      this.createForm();

      if (this._values) {
        this.form.patchValue(this._values, { emitEvent: false });
      }
    }
  }

  ngOnDestroy(): void {
    this.destroy.next(null);
  }

  createForm() {
    // types: 'int' | 'string' | 'decimal' | 'boolean' | 'color'

    const formControls = {};

    for (const option of this._options) {
      formControls[option.name] = new UntypedFormControl(option.defaultValue);
    }

    this.form = new UntypedFormGroup(formControls);
    this.form.valueChanges.pipe(takeUntil(this.destroy)).subscribe((change) => {
      this.valuesChange.next(change);
    });
  }

  toggleValue(name: string, active: boolean) {
    if (!active) {
      this.form.get(name).setValue(null);
    }
  }

  protected readonly Infinity = Infinity;
}
