<ng-container *ngIf="!isBatchActionButton; else batchActionButton">
  <div
    class="d-inline"
    [hideTooltipIfContentIsFalsey]="true"
    [rbTooltip]="disabledByCondition ? disabledByConditionTooltip : null"
  >
    <button
      type="button"
      [disabled]="disabled || disabledByCondition"
      class="rb-btn rb-link"
      (click)="$event.stopPropagation(); makeRestCall()"
      [ngStyle]="actionButtonStyling"
    >
      <span class="rb-ic {{ icon }}"></span>
      <span class="vlign-icons px-2">{{ action.buttonLabel }}</span>
      <span *ngIf="responseLoader.loading">
        <em class="rb-ic rb-ic-refresh ml-1" [class.fa-spin]="responseLoader.loading"></em>
      </span>
      <span
        *ngIf="statusText !== undefined && !responseLoader.loading"
        class="rb-ic rb-1-3x mr-1"
        [ngClass]="{
          'rb-ic-alert-error-filled red': error,
          'rb-ic-alert-success-filled green': !error
        }"
      ></span>
    </button>
  </div>
  <div *ngIf="status" class="d-flex align-items-center pt-1">
    <span class="placeholder"></span>
    <span (click)="$event.stopPropagation()">{{ status }}: {{ statusText }}</span>
    <span
      *ngIf="error || (response && popoverContent)"
      (click)="$event.stopPropagation()"
      class="rb-ic rb-ic-alert-info ml-1"
      [rbPopover]="popover"
      style="cursor: pointer"
    ></span>
  </div>

  <ng-template #popover>
    <rb-callout class="no-margin" *ngIf="error" [type]="'error'">
      <rb-error-format
        class="response-container"
        [error]="
          error === 'whitelist' ? ('widgets.actionButton.whitelistError' | translate) : error
        "
      ></rb-error-format>
    </rb-callout>
    <div *ngIf="response" class="response-container p-1" style="min-width: 35rem">
      <div *ngTemplateOutlet="popoverContent"></div>
    </div>
  </ng-template>
</ng-container>

<ng-template #batchActionButton>
  <div class="d-inline position-relative">
    <button
      [disabled]="disabled || disabledByCondition"
      class="rb-btn rb-link"
      type="button"
      [ngStyle]="actionButtonStyling"
    >
      <span class="rb-ic {{ icon }}"></span>
      <span class="vlign-icons px-2">{{ action.buttonLabel }}</span>
      <span *ngIf="responseLoader.loading">
        <em class="rb-ic rb-ic-refresh ml-1" [class.fa-spin]="responseLoader.loading"></em>
      </span>
    </button>
    <div
      *ngIf="!disabled && !disabledByCondition"
      data-cy="restRequestActionButton"
      [rbPopover]="response"
      [position]="'bottom'"
      class="cursor-pointer absolute-placeholder"
      (click)="makeRestCall(); $event.stopPropagation()"
    ></div>
    <div
      class="absolute-placeholder"
      data-cy="restRequestActionButton"
      *ngIf="disabledByCondition"
      [noFix]="false"
      [rbTooltip]="disabledByConditionTooltip"
      (click)="$event.stopPropagation()"
    ></div>
    <div class="absolute-placeholder" *ngIf="disabled" data-cy="restRequestActionButton"></div>
  </div>

  <!-- Response from Rest Request-->
  <ng-template #response>
    <div class="p-2">
      <http-response-status
        [loading]="responseLoader.loading"
        [restResponse]="action.restResponse"
        [responsePopoverContent]="popoverContent"
      ></http-response-status>
    </div>
  </ng-template>
</ng-template>

<ng-template #disabledByConditionTooltip>
  <div class="p-1 mw-500">
    {{
      action?.disableConditionsChips?.chips?.length === 1
        ? ('widgets.actionButton.disabledMessage.disabledByCondition'
          | translate
            : {
                property: action.disableConditionsChips.chips[0].input,
                value: action.disableConditionsChips.chips[0].value
              })
        : ('widgets.actionButton.disabledMessage.disabledByConditionGeneric' | translate)
    }}
  </div>
</ng-template>
