import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { ConditionChip } from '../../query-condition-input/models/condition-chip.model';
import { generateDom } from '../../query-condition-input/utils/dom-generator.util';
import {
  getOffsetFromSelection,
  positionCaret
} from '../../regex-editor/regex-input/regex-input.component';
import { SyntaxNode } from '../../query-condition-input/models/syntax-node.model';
import { LocalStorageService } from 'ngx-localstorage';

@Component({
  selector: 'simple-search-editor',
  templateUrl: './simple-search-editor.component.html',
  styleUrls: ['./simple-search-editor.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SimpleSearchEditorComponent implements OnInit, OnChanges {
  previousSimpleLogic: string;

  @Input()
  simpleLogic: string;

  @Input()
  syntaxTree: SyntaxNode;

  @Input()
  syntaxErrors: SyntaxError[];

  @Input()
  chips: ConditionChip[];

  @Input()
  storageKey: string;

  @Input()
  saveToLocalStorage = true;

  @Output()
  logicInputChanged: EventEmitter<string> = new EventEmitter();

  @ViewChild('simpleLogicInput', { static: true })
  simpleLogicInput: ElementRef;

  constructor(private localStorageService: LocalStorageService) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.simpleLogic) {
      this.previousSimpleLogic = changes.simpleLogic.previousValue;
      if (
        this.localStorageService.get(this.storageKey) instanceof Object &&
        this.saveToLocalStorage
      ) {
        this.localStorageService.set(this.storageKey, {
          ...Object.assign(this.localStorageService.get(this.storageKey)),
          syntaxTree: this.syntaxTree,
          chips: this.chips
        });
      }
      this.updateEditorLogic();
    }
  }

  ngOnInit() {
    this.updateDomByAbstractTree();
  }

  onInputChanged(event: Event) {
    this.logicInputChanged.emit((event.target as HTMLElement).textContent);
  }

  updateEditorLogic() {
    const caret = this.getCurrentSelection();
    this.updateDomByAbstractTree();
    this.setCaretPosition(caret.position, caret.selection);
  }

  updateDomByAbstractTree() {
    const spanContainer = generateDom(this.syntaxTree);
    this.simpleLogicInput.nativeElement.innerHTML = '';
    spanContainer.appendChild(document.createElement('span'));
    this.simpleLogicInput.nativeElement.appendChild(spanContainer);
  }

  setCaretPosition(position: number, selection: Selection) {
    const caretPosition = positionCaret(
      position,
      this.simpleLogicInput.nativeElement.childNodes[0]
    );
    if (caretPosition) {
      selection.setPosition(caretPosition.node, caretPosition.offset);
    }
  }

  getCurrentSelection() {
    const selection = document.getSelection();
    const position = this.calculatePosition(selection);

    return {
      selection: selection,
      position: position
    };
  }

  calculatePosition(selection: Selection) {
    if (this.previousSimpleLogic && selection.anchorNode.nodeType !== Node.TEXT_NODE) {
      return this.getStringDiffIndex(this.previousSimpleLogic, this.simpleLogic);
    } else {
      this.rectifySelection(selection);
      return getOffsetFromSelection(selection, this.simpleLogicInput.nativeElement);
    }
  }

  rectifySelection(selection: Selection) {
    const selectionIsOutsideEditor = !this.simpleLogicInput.nativeElement.contains(
      selection.anchorNode
    );
    if (selectionIsOutsideEditor) {
      selection.setPosition(this.simpleLogicInput.nativeElement);
    }
  }

  // From RegexInputComponent
  getStringDiffIndex(previousValue: string, currentValue: string) {
    const minLength = Math.min(previousValue.length, currentValue.length);

    for (let i = 0; i < minLength; i++) {
      if (previousValue[i] !== currentValue[i]) {
        return i;
      }
    }
    return minLength;
  }
}
