<div data-cy="colorRangeSettings">
  <div class="d-flex align-items-start flex-wrap mb-4">
    <value-or-reference-input
      [name]="'defaultcolor' + uniqueId"
      [dataType]="'color'"
      class="ColorRange_colorPickerField"
      [(ngModel)]="colorRangeModel.defaultColor"
      (ngModelChange)="notifyChange()"
      [paths]="paths"
      [label]="'dataConditionalFormatting.defaultColor' | translate"
      [filterParameters]="filterParameters"
      [inputParameters]="inputParameters"
    ></value-or-reference-input>

    <div class="ColorRange_spacer">&nbsp;</div>
  </div>

  <div
    class="d-flex align-items-start justify-content-between flex-column mb-4"
    *ngFor="let range of colorRangeModel.colorRanges; index as i"
  >
    <div class="d-flex justify-content-between w-100">
      <value-or-reference-input
        [name]="'color' + i + uniqueId"
        [dataType]="'color'"
        [ngModel]="range.color"
        class="ColorRange_colorPickerField"
        (ngModelChange)="changeParameter($event, i, 'color')"
        [paths]="paths"
        [label]="'dataConditionalFormatting.color' | translate"
        [inputParameters]="inputParameters"
        [filterParameters]="filterParameters"
      ></value-or-reference-input>

      <button (click)="removeColorRange(range)" class="rb-btn rb-link ColorRange_removeButton">
        <span class="rb-ic rb-ic-delete"></span>
      </button>
    </div>

    <ng-container
      *ngTemplateOutlet="
        fieldSelection;
        context: { rangeModel: range, disabled: false, nameSuffix: i + uniqueId, index: i }
      "
    ></ng-container>
  </div>

  <div class="text-right">
    <button
      class="rb-btn rb-link ColorRange_addButton"
      data-cy="colorRangeAdd"
      type="button"
      (click)="addColorRange()"
      [disabled]="colorRangeModel.colorRanges.length >= 25"
    >
      <span class="rb-ic rb-ic-add mr-1"></span>
      <span class="vlign-icons">{{ 'dataConditionalFormatting.addRange' | translate }}</span>
    </button>
  </div>

  <ng-template
    #fieldSelection
    let-rangeModel="rangeModel"
    let-index="index"
    let-disabled="disabled"
    let-nameSuffix="nameSuffix"
  >
    <ng-container *ngIf="dataType === dataTypeEnum.NUMBER">
      <ng-container
        *ngTemplateOutlet="
          numberFields;
          context: {
            rangeModel: rangeModel,
            disabled: disabled,
            nameSuffix: nameSuffix,
            index: index
          }
        "
      ></ng-container>
    </ng-container>

    <ng-container *ngIf="dataType === dataTypeEnum.DATETIME">
      <ng-container
        *ngTemplateOutlet="
          datetimeFields;
          context: {
            rangeModel: rangeModel,
            disabled: disabled,
            nameSuffix: nameSuffix,
            index: index
          }
        "
      ></ng-container>
    </ng-container>

    <ng-container *ngIf="dataType === dataTypeEnum.STRING">
      <ng-container
        *ngTemplateOutlet="
          stringField;
          context: {
            rangeModel: rangeModel,
            disabled: disabled,
            nameSuffix: nameSuffix,
            index: index
          }
        "
      ></ng-container>
    </ng-container>
  </ng-template>

  <ng-template
    #numberFields
    let-rangeModel="rangeModel"
    let-index="index"
    let-disabled="disabled"
    let-nameSuffix="nameSuffix"
  >
    <div class="d-flex w-100">
      <value-or-reference-input
        [name]="'numberMin' + nameSuffix"
        class="ColorRange_rangeField"
        data-cy="colorRangeNumberMin"
        [dataType]="'int'"
        [(ngModel)]="rangeModel.min"
        (ngModelChange)="changeParameter($event, index, 'min')"
        [paths]="paths"
        [disabled]="disabled"
        [label]="'dataConditionalFormatting.from' | translate"
        [inputParameters]="inputParameters"
        [filterParameters]="filterParameters"
      ></value-or-reference-input>

      <value-or-reference-input
        [name]="'numberMax' + nameSuffix"
        class="ColorRange_rangeField"
        data-cy="colorRangeNumberMax"
        [(ngModel)]="rangeModel.max"
        [dataType]="'int'"
        (ngModelChange)="changeParameter($event, index, 'max')"
        [paths]="paths"
        [disabled]="disabled"
        [label]="'dataConditionalFormatting.to' | translate"
        [inputParameters]="inputParameters"
        [filterParameters]="filterParameters"
      ></value-or-reference-input>
    </div>
  </ng-template>

  <ng-template
    #datetimeFields
    let-rangeModel="rangeModel"
    let-index="index"
    let-disabled="disabled"
    let-nameSuffix="nameSuffix"
    class="d-flex"
  >
    <div class="d-flex flex-column w-100">
      <value-or-reference-input
        class="ColorRange_rangeField w-100"
        data-cy="colorRangeDateTimeRange"
        [name]="'dateRange' + nameSuffix"
        [label]="
          ('dataConditionalFormatting.from' | translate) +
          ' - ' +
          ('dataConditionalFormatting.to' | translate)
        "
        [startLabel]="'dataConditionalFormatting.from' | translate"
        [endLabel]="'dataConditionalFormatting.to' | translate"
        [dataType]="'datetimerange'"
        [disabled]="disabled"
        [presets]="defaultPresets"
        [filterParameters]="filterParameters"
        [inputParameters]="inputParameters"
        [ngModel]="rangeModel"
        (ngModelChange)="changeParameter($event, index, 'timeRange')"
      ></value-or-reference-input>
    </div>
  </ng-template>

  <ng-template
    #stringField
    let-rangeModel="rangeModel"
    let-disabled="disabled"
    let-nameSuffix="nameSuffix"
    let-index="index"
  >
    <value-or-reference-input
      [name]="'equals' + nameSuffix"
      class="ColorRange_rangeField w-100"
      [(ngModel)]="rangeModel.equals"
      [paths]="paths"
      (ngModelChange)="changeParameter($event, index, 'regex')"
      [disabled]="disabled"
      [label]="'dataConditionalFormatting.equalsLabel' | translate"
      [filterParameters]="filterParameters"
      [inputParameters]="inputParameters"
    ></value-or-reference-input>
  </ng-template>
</div>
