import { ChangeDetectorRef, Component, ElementRef, HostBinding, OnDestroy } from '@angular/core';
import { BaseLeafComponent } from 'slate-angular';

@Component({
  template: `
    <span [rbTooltip]="context.leaf | tooltipContent: true" [hideTooltipIfContentIsFalsey]="true">
      <span slateString [context]="context" [viewContext]="viewContext"></span>
    </span>
  `
})
export class TooltipLeafComponent extends BaseLeafComponent implements OnDestroy {
  @HostBinding('attr.data-slate-leaf') slateLeaf = true;

  constructor(public elementRef: ElementRef, public cdr: ChangeDetectorRef) {
    super(elementRef, cdr);
  }

  onContextChange(): void {
    super.onContextChange();
    this.renderPlaceholder();
  }

  ngOnDestroy(): void {
    this.destroyPlaceholder();
  }
}
