import { Component, Input, TemplateRef } from '@angular/core';
import { RestReqResponse } from '../../rest-request/models/rest-request.model';
import { isEmpty } from 'lodash-es';

@Component({
  selector: 'http-response-status',
  templateUrl: './http-response-status.component.html',
  styleUrls: ['../action-buttons.scss']
})
export class HttpResponseStatusComponent {
  @Input() restResponse: RestReqResponse;

  @Input() loading: boolean;

  @Input() responsePopoverContent: TemplateRef<any>;

  get status() {
    return this.restResponse?.status;
  }

  get statusText() {
    if (
      this.restResponse?.statusText === 'unknown' &&
      this.restResponse?.status >= 200 &&
      this.restResponse?.status < 300
    ) {
      return 'ok';
    }
    return this.restResponse?.statusText;
  }

  get response() {
    return this.restResponse?.response;
  }

  get error() {
    return !isEmpty(this.restResponse?.error) ? this.restResponse?.error : null;
  }
}
