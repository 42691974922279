import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { ColorConfig, ColorRule, ColorRuleCondition } from './models/color-picker.model';
import { PathInfo } from '../data-selection/data-info-util';
import { Condition } from '../condition-input/models/condition.model';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'color-picker',
  templateUrl: './color-picker.component.html',
  styleUrls: ['./color-picker.component.scss']
})
export class ColorPickerComponent implements OnInit, OnChanges {
  @Input() config: ColorConfig;

  @Input() enableAlpha = false;

  @Input() enableRules = true;

  @Input() paths: PathInfo[];

  @Output() configChange = new EventEmitter<ColorConfig>();

  _config: ColorConfig;

  currentColor: string;

  currentColorSource: 'primary' | ColorRule = 'primary';

  private subject: Subject<ColorConfig> = new Subject();

  get rules() {
    return this._config.rules || [];
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.config && changes.config.currentValue) {
      this._config = changes.config.currentValue;
      this.currentColor = this.config.color;
      this.currentColorSource = 'primary';
    }
  }

  ngOnInit(): void {
    if (!this._config) {
      this._config = { color: '#005691' };
    }
    if (!this.currentColor) {
      this.currentColor = this._config.color;
      this.currentColorSource = 'primary';
    }
    this.subject.pipe(debounceTime(100)).subscribe((config) => {
      this.configChange.emit(config);
    });
  }

  updateCurrentColor(color: string) {
    this.currentColor = color;
    if (this.currentColorSource === 'primary') {
      this._config.color = color;
    } else {
      this.currentColorSource.color = color;
    }
    this.notifyChange();
  }

  addRule() {
    if (!this._config.rules) {
      this._config.rules = [];
    }
    this._config.rules.push({
      color: this._config.color,
      cond: {
        path: '',
        op: 'equals',
        value: ''
      }
    });
    this.notifyChange();
  }

  removeRule(rule: ColorRule) {
    this._config.rules.splice(this._config.rules.indexOf(rule), 1);
    this.notifyChange();
  }

  activateRule(rule: ColorRule | 'primary') {
    this.currentColorSource = rule;
    if (rule === 'primary') {
      this.currentColor = this._config.color;
    } else {
      this.currentColor = rule.color;
    }
  }

  ruleChange(rule: ColorRule, conf: Condition) {
    rule.cond = conf;
    this.notifyChange();
  }

  notifyChange() {
    this.subject.next(this._config);
  }
}
