<div>
  <ng-container *ngIf="cell.value.imageType === imageType.DEVICE_IMAGE; else defaultDisplay">
    <device-img
      [device]="cell.value.device"
      [deviceTypes]="deviceTypes"
      [maxHeight]="cell.value.maxHeight + 'px'"
      [maxWidth]="cell.value.maxWidth + 'px'"
      [customSrc]="cell.value.imageSrc"
      [useCustomSrc]="true"
    ></device-img>
  </ng-container>
  <ng-template #defaultDisplay>
    <img
      *ngIf="showImage(cell.value.imageSrc)"
      [src]="cell.value.imageSrc"
      [style.max-height]="cell.value.maxHeight + 'px'"
      [style.max-width]="cell.value.maxWidth + 'px'"
      [alt]="cell.value.imageType"
      [attr.data-cy]="'tableImageDisplay-' + cell.value.imageType"
    />
    <ng-container *ngIf="cell.value.imageType === imageType.BASE_64">
      <div *ngIf="!cell.value.imageSrc">
        {{ 'widgets.table.imageNotAvailable' | translate }}
      </div>
      <div *ngIf="cell.value.imageSrc && !isValidBase64Image(cell.value.imageSrc)">
        {{ 'widgets.table.invalidImageFormat' | translate }}
      </div>
    </ng-container>
  </ng-template>
</div>
