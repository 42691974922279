import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

/*
 * Used primarily to track the use of "location.back()" for filter parameters.
 * The value will then be used to check if a new global parameter has been added,
 * so that the value can be pulled from the local storage.
 */
@Injectable({
  providedIn: 'root'
})
export class NavigationBackService {
  private locationBackUsed = new BehaviorSubject<boolean>(false);

  get isLocationBackUsed() {
    return this.locationBackUsed.getValue();
  }

  setLocationBackUsed(value: boolean) {
    this.locationBackUsed.next(value);
  }
}
