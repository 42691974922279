import {
  ChangeDetectorRef,
  ComponentRef,
  Directive,
  DoCheck,
  EmbeddedViewRef,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewContainerRef
} from '@angular/core';
import { DetailedError, LoadingEntity } from '@inst-iot/bosch-angular-ui-components';
import { combineLatest, Subscription } from 'rxjs';
import { WidgetInstanceService } from '../../services/widget-instance.service';
import { WidgetLoadingContentComponent } from './widget-loading-content.component';
import { DashboardGridService } from '../../services/dashboard-grid.service';
import { DashboardDataService } from '../../services/dashboard-data.service';

@Directive({
  selector: '[widgetLoading]'
})
export class WidgetLoadingDirective implements DoCheck, OnInit, OnDestroy {
  @Input() widgetLoading: LoadingEntity<any>;
  @Input() widgetLoadingHeight?: number;
  @Input() widgetLoadingIgnoreEmptyResult = false;

  private loadingRef: ComponentRef<WidgetLoadingContentComponent> = null;
  private viewRef: EmbeddedViewRef<WidgetLoadingContentComponent> = null;

  private visible = false;
  private subscriptions = new Subscription();
  private minimumLoadingHeightInPx = 100;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private widgetInstance: WidgetInstanceService,
    private changeDetector: ChangeDetectorRef,
    private dashboardGridService: DashboardGridService,
    private dashboardDataService: DashboardDataService
  ) {}

  ngOnInit() {
    this.subscriptions.add(
      this.widgetInstance.visible.subscribe((isVisible: boolean) => {
        if (isVisible) {
          this.visible = isVisible;
        }
      })
    );

    this.subscriptions.add(
      combineLatest([this.widgetInstance.loading, this.widgetInstance.loadingError]).subscribe(
        ([loading, loadingError]) => {
          if (!loading || loadingError) {
            this.changeDetector.detectChanges();
          }
        }
      )
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  ngDoCheck(): void {
    if (this.showLoading || this.showError || !this.hasData) {
      this.updateView(true);
    } else {
      this.updateView(false);
    }
  }

  get showLoading(): boolean {
    const isLoadingFirstResult =
      this.widgetLoading?.loading && this.widgetLoading?.runs === 1 && !this.error;
    return (
      (this.widgetInstance.loading.value && !this.widgetInstance.lastData) ||
      isLoadingFirstResult ||
      !this.visible
    );
  }

  get showError(): boolean {
    return (
      (!this.widgetInstance.loading.value &&
        this.widgetInstance.loadingError.value &&
        !this.widgetInstance.lastData) ||
      this.widgetLoading?.error
    );
  }

  get error() {
    return this.widgetInstance.loadingError.value ?? this.widgetLoading?.error;
  }

  get hasData(): boolean {
    if (!this.widgetLoading || this.widgetLoadingIgnoreEmptyResult) {
      return true;
    }
    if (Array.isArray(this.widgetLoading.result)) {
      return this.widgetLoading.result.length > 0;
    }
    return this.widgetLoading.result !== null && this.widgetLoading.result !== undefined;
  }

  updateView(showLoadingOrError: boolean) {
    if (showLoadingOrError) {
      if (!this.loadingRef) {
        this.viewContainer.clear();
        this.viewRef = null;
        this.loadingRef = this.viewContainer.createComponent(WidgetLoadingContentComponent);
      }
      const instance = this.loadingRef.instance;
      instance.noData = !this.showLoading && !this.showError && !this.hasData;
      instance.loading = this.showLoading;
      instance.error = this.error;
      instance.height = this.getWidgetLoadingHeight();
      instance.queryStatus = this.widgetInstance.queryStatus;
      instance.widgetPlaceholder = this.widgetInstance.widgetType?.loadingPlaceholder;
      instance.widgetType = this.widgetInstance.properties?.dynChartConfig?.traces[0].type;
      instance.widgetId = this.widgetInstance.id;
      if (instance.noData && !this.viewRef) {
        this.viewRef = this.viewContainer.createEmbeddedView(this.templateRef);
      }
      this.subscriptions.add(
        instance.reload.subscribe(() => {
          this.widgetInstance.reload.next(null);
        })
      );

      if (this.error instanceof DetailedError) {
        const detailedError: DetailedError = this.error;
        if (['NO_TICKET', 'EXPIRED', 'POLLING_TIMEOUT'].includes(detailedError.details.status)) {
          instance.warning = this.error;
          instance.error = null;
        }
      }
    } else {
      if (!this.viewRef) {
        this.viewContainer.clear();
        this.loadingRef = null;
        this.viewRef = this.viewContainer.createEmbeddedView(this.templateRef);
      } else if (this.viewRef && this.loadingRef) {
        this.loadingRef.destroy();
        this.loadingRef = null;
      }
    }
  }

  private getWidgetLoadingHeight() {
    if (this.dashboardDataService.isSpecificWidgetRoute) {
      return '100%';
    }
    if (
      this.dashboardGridService.widgets &&
      this.dashboardGridService.gridster &&
      !this.widgetLoadingHeight
    ) {
      const currentWidgetConfig = this.dashboardGridService.widgets.find(
        (config) => config.id === this.widgetInstance.id
      );
      if (
        currentWidgetConfig &&
        currentWidgetConfig.rows &&
        this.dashboardGridService.gridster.curRowHeight
      ) {
        this.widgetLoadingHeight =
          (currentWidgetConfig.rows - 2) * this.dashboardGridService.gridster.curRowHeight - 5; // leave room for title and bottom margin, '-5' prevents scrollbar
      }
    }
    return Math.max(this.widgetLoadingHeight, this.minimumLoadingHeightInPx) + 'px';
  }
}
