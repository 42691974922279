<div *ngIf="path.length" class="path-container mb-2">
  <span *ngFor="let path of paths">
    <a href="#" [class.active]="path.active" (click)="updatePath(path.path)">
      {{ path.key }}
    </a>
  </span>
</div>

<div class="row px-3">
  <div class="flex-grow-1">
    <pagination-bar
      #paginationBar
      *ngIf="showPaginationBar"
      [showRefresh]="false"
      [showSettings]="true"
      [pageData]="pageData"
      [inputLimits]="limits"
      [layout]="layout"
      (action)="storePaginationSetting($event); updateData($event.number)"
    ></pagination-bar>
  </div>

  <table-filter-bar
    *ngIf="filtersApplied()"
    class="my-auto"
    (clearFiltersClicked)="clearFilters()"
  ></table-filter-bar>

  <button
    *ngIf="
      _tableWidgetOptions.enableColumnSelection &&
      _tableWidgetOptions.showCustomColumnDefinition &&
      _tableWidgetOptions.customColumnDefinitionConfig?.length &&
      !noDataFound
    "
    class="rb-btn rb-link rb-dropdown-toggle ml-2 ml-md-0"
    type="button"
    data-cy="tableColumnsSelection"
    [rbDropdown]="multiselection"
    [position]="'right'"
    [autoClose]="false"
  >
    {{ 'tableFilter.selectColumns' | translate }}
  </button>

  <button
    *ngIf="
      _tableWidgetOptions.showBatchSelection &&
      _tableWidgetOptions.batchActionButtonDefinitionConfig?.length &&
      columns.length
    "
    data-cy="tableSelectMultipleRows"
    class="rb-btn rb-link"
    type="button"
    (click)="isRowSelectionEnabled = !isRowSelectionEnabled; triggerColumnsResetAndResize()"
  >
    {{ 'widgets.table.selectMultipleRows' | translate }}
  </button>

  <ng-container
    *ngIf="
      _tableWidgetOptions.showBatchSelection &&
      _tableWidgetOptions.batchActionButtonDefinitionConfig?.length &&
      columns.length
    "
  >
    <ng-container
      *ngFor="
        let batchActionButton of _tableWidgetOptions.batchActionButtonDefinitionConfig;
        let i = index
      "
    >
      <action-table-button
        class="align-self-center"
        [disabled]="!isRowSelectionEnabled"
        [data]="batchActionButtonData"
        [context]="context"
        [action]="batchActionButton.actionConfiguration"
        [popoverContent]="responsePopover"
        [isBatchActionButton]="true"
        [icon]="batchActionButton.icon"
        [attr.data-cy]="'tableBatchActionButton-' + i"
        (actionExecutionFinished)="uncheckCheckboxesAndResetBatchActionButtonData()"
      ></action-table-button>
      <ng-template #responsePopover>
        <json-editor
          [text]="batchActionButton.actionConfiguration.restResponse?.response"
          [readOnly]="true"
          [options]="{ minLines: 10, maxLines: 25 }"
        ></json-editor>
      </ng-template>
    </ng-container>
  </ng-container>

  <div
    *ngIf="_tableWidgetOptions.showGlobalFilter && !noDataFound"
    class="position-relative ml-2 ml-md-0 table-global-search"
  >
    <rb-form-input
      class="static"
      data-cy="tableGlobalFilter"
      [(ngModel)]="filterService.globalFilterTerm"
      (keydown.enter)="search()"
      [label]="'tableFilter.globalTableFilter' | translate"
    ></rb-form-input>
    <span
      class="rb-ic rb-ic-lg rb-ic-search mr-3 cursor-pointer"
      data-cy="tableGlobalFilterIcon"
      (click)="search()"
    ></span>
  </div>
</div>

<ng-container *ngIf="columns.length">
  <table
    #tableView
    class="m-table"
    *rbTable="
      undefined;
      maxHeight: tableMaxHeight;
      fixedHeaderWidth: _tableWidgetOptions.setFixedColumnWidth
    "
    [rbSort]="sort"
    (rbSortChange)="updateSort($event)"
    [class.active]="shouldShowTableHover"
    [class.default-cursor]="shouldShowDefaultCursor"
    [class.table-striped]="_tableWidgetOptions.showTableStriped"
    [class.hide-horizontal-grid-lines]="!_tableWidgetOptions.showHorizontalGridLines"
    [class.show-vertical-grid-lines]="_tableWidgetOptions.showVerticalGridLines"
    [class.show-vertical-grid-lines-in-header]="
      _tableWidgetOptions.showFilter || _tableWidgetOptions.showVerticalGridLines
    "
  >
    <thead [hidden]="_tableWidgetOptions.showHeader === false">
      <tr>
        <th [isColumnFixed]="hasFixedColumn()" *ngIf="isRowSelectionEnabled"></th>
        <th
          *ngFor="let key of columns; let idx = index"
          [isColumnFixed]="isColumnHeaderFixed(idx)"
          [inEditMode]="inEditMode"
          [hidden]="hiddenRowActionColumn === key"
          [ngStyle]="isCustomMinWidthSet(idx) && { 'min-width': getCustomMinWidth(idx) + 'px' }"
          class="p-0"
        >
          <div
            class="d-flex align-items-center table-header"
            [class.pr-0]="filterAllowed(idx, key)"
          >
            <span class="flex-grow-1 key" [rb-sort-header]="key">
              <span
                [hidden]="isColumnHeaderHidden(idx)"
                [attr.data-cy]="
                  'tableView-columnHeader-' + key.replace(' ', '-').toLowerCase() + '-' + idx
                "
              >
                {{ key }}
              </span>
            </span>
            <table-filter-button
              *ngIf="filterAllowed(idx, key)"
              [filterApplied]="filterApplied(key)"
              [dataCy]="'tableView-filterButton-' + key.replace(' ', '-').toLowerCase() + '-' + idx"
              (filterClicked)="openFilter(key, $event)"
            ></table-filter-button>
          </div>
        </th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngIf="entries?.length || noDataFound; else noResults">
        <tr (click)="handleRowClick(rowIndex)" *ngFor="let cells of entries; let rowIndex = index">
          <td
            [isColumnFixed]="hasFixedColumn()"
            [style.background-color]="cells[0].backgroundColor"
            [class.fixed-col-striped-even]="isFixedColumnStriped(cells[0]) && rowIndex % 2 === 0"
            [class.fixed-col-striped-odd]="isFixedColumnStriped(cells[0]) && rowIndex % 2 !== 0"
            *ngIf="isRowSelectionEnabled && cells?.length"
            class="checkbox pr-0 pt-0 pb-0"
          >
            <div class="d-flex">
              <rb-form-checkbox
                [attr.data-cy]="'tableBatchActionCheckbox-' + rowIndex"
                label="&nbsp;"
                [(ngModel)]="cells[0].checked"
                (ngModelChange)="buildBatchActionButtonData($event, cells, rowIndex)"
              ></rb-form-checkbox>
            </div>
          </td>
          <td
            *ngFor="let cell of cells"
            [hidden]="hiddenRowActionColumn === cell.path[0]"
            [style.background-color]="
              cell.valueFormattingConfig?.multipleValues || cell.valueFormattingConfig?.backgroundColor?.['target'] === BackgroundColorTarget.CHIP ? null : cell.backgroundColor
            "
            [style.color]="cell.textColor"
            [class.fixed-col-striped-even]="isFixedColumnStriped(cell) && rowIndex % 2 === 0"
            [class.fixed-col-striped-odd]="isFixedColumnStriped(cell) && rowIndex % 2 !== 0"
            [isColumnFixed]="cell?.fixedHorizontal"
            [inEditMode]="inEditMode"
            [ngClass]="{
              'show-vertical-grid-lines-fixed-col': _tableWidgetOptions.showVerticalGridLines
            }"
            [class.text-break]="cell.valueFormattingConfig['wrapText']"
            [class.text-wrap]="cell.valueFormattingConfig['wrapText']"
          >
            <ng-container *ngIf="cell.type === 'atomic'">
              <ng-container *ngIf="showJsonInPopover(cell.valueFormattingConfig); else noPopover">
                <span
                  [rbTooltip]="cell.tooltip?.text"
                  [hideTooltipIfContentIsFalsey]="true"
                  class="text-break text-wrap rb-btn rb-link"
                  [rbPopover]="jsonEditorPopover"
                  [style.color]="cell.textColor"
                  [chip]="{
                    backgroundColor: cell.backgroundColor,
                    valueFormatting: cell.valueFormattingConfig
                  }"
                >
                  {{ cell.value | dataConditionalFormattingPipe: cell.valueFormattingConfig }}
                </span>
                <ng-template #jsonEditorPopover>
                  <json-editor
                    [text]="cell.value"
                    [readOnly]="true"
                    [options]="{
                      minLines: 1,
                      maxLines: jsonEditorMaxLines(cell.valueFormattingConfig)
                    }"
                    [style]="{ width: '500px' }"
                  ></json-editor>
                </ng-template>
              </ng-container>
              <ng-template #noPopover>
                <ng-container
                  *ngIf="cell.valueFormattingConfig?.dataType === 'json'; else defaultAtomic"
                >
                  <json-editor
                    [text]="cell.value"
                    [readOnly]="true"
                    [options]="{
                      minLines: 1,
                      maxLines: jsonEditorMaxLines(cell.valueFormattingConfig)
                    }"
                  ></json-editor>
                </ng-container>
                <ng-template #defaultAtomic>
                  <span
                    [rbTooltip]="cell.tooltip?.text"
                    [hideTooltipIfContentIsFalsey]="true"
                    [chip]="{
                      backgroundColor: cell.backgroundColor,
                      valueFormatting: cell.valueFormattingConfig
                    }"
                  >
                    {{ cell.value | dataConditionalFormattingPipe: cell.valueFormattingConfig }}
                  </span>
                </ng-template>
              </ng-template>
            </ng-container>
            <ng-container *ngIf="cell.type === 'richText'">
              <slate-editor
                [editor]="cell.value.editorInstance"
                [value]="cell.value.editor"
                [wrapText]="isWrapTextSet(cell)"
              ></slate-editor>
            </ng-container>
            <ng-container *ngIf="cell.type === 'array'">
              <table-array-display
                [cell]="cell"
                (updatePath)="updatePath($event)"
              ></table-array-display>
            </ng-container>
            <ng-container *ngIf="cell.type === 'object'">
              <a
                [rbTooltip]="cell.tooltip?.text"
                [hideTooltipIfContentIsFalsey]="true"
                href="#"
                (click)="updatePath(cell.path)"
              >
                [Object]
              </a>
            </ng-container>
            <ng-container *ngIf="cell.type === 'link'">
              <action-link
                #actionLinks
                [rbTooltip]="cell.tooltip?.text"
                [hideTooltipIfContentIsFalsey]="true"
                [tableContext]="{ row: rowIndex, column: cell.columnName }"
                customCssClasses="text-break text-wrap"
                [linkOptions]="cell.value"
                [actionButtonStyling]="cell.textColor ? $any({ color: cell.textColor }) : null"
                [stopPropagation]="true"
              ></action-link>
            </ng-container>
            <ng-container *ngIf="cell.type === 'image'">
              <table-image-display
                [rbTooltip]="cell.tooltip?.text"
                [hideTooltipIfContentIsFalsey]="true"
                [cell]="cell"
                [deviceTypes]="deviceTypeService.deviceTypes"
              ></table-image-display>
            </ng-container>
            <ng-container *ngIf="cell.type === 'button'">
              <action-table-button
                #actionTableButtons
                [rbTooltip]="cell.tooltip?.text"
                [hideTooltipIfContentIsFalsey]="true"
                [data]="data[cell.dataIndex]"
                [tableContext]="{ row: rowIndex, column: cell.columnName }"
                [context]="context"
                [action]="cell.value"
                [popoverContent]="hasTextResponse(cell) ? responsePopover : undefined"
                [attr.data-cy]="'tableActionButton-' + rowIndex"
                [actionButtonStyling]="cell.textColor ? $any({ color: cell.textColor }) : null"
              >
                <ng-template #responsePopover>
                  <json-editor
                    [text]="cell.value.restResponse.response"
                    [readOnly]="true"
                    [options]="{ minLines: 10, maxLines: 25 }"
                  ></json-editor>
                </ng-template>
              </action-table-button>
            </ng-container>
            <ng-container *ngIf="cell.type === 'undefined'"></ng-container>
          </td>
        </tr>
      </ng-container>
      <ng-container *ngIf="noDataFound">
        <tr *ngFor="let row of [].constructor(noDataOverlayTableRows)">
          <td *ngFor="let cell of columns"></td>
        </tr>
      </ng-container>
    </tbody>
    <tfoot *ngIf="_tableWidgetOptions.footerContent !== footerContents.NONE">
      <tr class="footerContent">
        <td class="footerContent" *ngFor="let f of calculatedFooter; let i = index">
          <span
            [attr.data-cy]="'tableViewFooterColumn-' + i"
            [title]="
              _tableWidgetOptions.footerContent === footerContents.SUM
                ? ('widgets.table.footerContentSum' | translate)
                : ('widgets.table.footerContentAverage' | translate)
            "
          >
            {{ f | number: '1.0-2' }}
          </span>
        </td>
      </tr>
    </tfoot>
  </table>
</ng-container>

<rb-empty-table
  *ngIf="
    (!columns.length && !noDataFound) ||
    (columns.length === 1 && hiddenRowActionColumn === columns[0])
  "
>
  <div class="d-flex flex-column justify-content-center">
    <i class="rb-ic rb-ic-alert-info" style="font-size: xxx-large"></i>
    <div>{{ 'widgets.table.noColumnSelected' | translate }}</div>
  </div>
</rb-empty-table>

<table-view-widget-placeholder
  *ngIf="noDataFound && !columns.length"
  [customCssClass]="'no-animation'"
></table-view-widget-placeholder>

<pagination-bar
  #paginationBar
  *ngIf="showPaginationBar"
  [showRefresh]="false"
  [pageData]="pageData"
  [layout]="layout"
  (action)="updateData($event.number)"
></pagination-bar>

<ng-template #multiselection>
  <ng-container *ngFor="let item of columnSelectorStates; index as i">
    <rb-form-multi-checkbox
      class="list-item-input"
      [ngModel]="item.state"
      [attr.data-cy]="'multiSelectionItem-' + i"
      (ngModelChange)="updateStateOfItem(i, $event)"
    >
      {{ item.item }}
    </rb-form-multi-checkbox>
  </ng-container>
</ng-template>

<ng-template #noResults>
  <tr class="no-results-entry">
    <td *ngFor="let cell of columns; let idx = index">
      {{ idx === 0 ? ('tableFilter.noResults' | translate) : '' }}
    </td>
  </tr>
</ng-template>
