<div class="p-3" style="max-width: 500px">
  <form #form="ngForm">
    <data-selector-input
      class="flex-grow-1 selector-container"
      [paths]="paths"
      [requireIteration]="false"
      [label]="'slateEditor.image.valueLabel' | translate"
      [(ngModel)]="value.path"
      name="value"
      [required]="true"
    ></data-selector-input>

    <article class="mb-2">
      <a
        #detailsToggle="rbDetailsToggle"
        class="p-0"
        data-cy="valueFormattingToggle"
        href="#"
        rbDetailsToggle
      >
        {{ 'valueFormatting.title' | translate }}
        <span
          [ngClass]="detailsToggle.open === true ? 'rb-ic rb-ic-up' : 'rb-ic rb-ic-down'"
        ></span>
      </a>
      <value-formatting-component
        *ngIf="detailsToggle.open"
        [paths]="paths"
        [(ngModel)]="value.valueFormatting"
        [filterParameters]="filterParameters"
        [inputParameters]="inputParameters"
        [hideWrapText]="true"
        [automaticPadding]="false"
        name="valueFormatting"
      ></value-formatting-component>
    </article>

    <article>
      <toggler
        data-cy="referenceConfigurationTooltipToggle"
        [title]="'tableTooltip.title' | translate"
        #tooltipToggle
      ></toggler>
      <div *ngIf="tooltipToggle.isOpen">
        <p class="hint-label">{{ 'referenceConfigTooltip.hint' | translate }}</p>
        <rb-form-input
          data-cy="tooltipToggleText"
          [label]="'tableTooltip.label' | translate"
          [(ngModel)]="value.tooltip"
          name="tooltip"
        ></rb-form-input>
      </div>
    </article>

    <div class="d-flex mt-3">
      <button type="button" class="rb-btn rb-secondary mr-auto" (click)="cancelled.emit()">
        {{ 'slateEditor.action.cancel' | translate }}
      </button>
      <button
        type="button"
        class="rb-btn rb-primary ml-3"
        data-cy="referenceConfigurationSave"
        [disabled]="form.invalid || !form.dirty"
        (click)="changeValue()"
      >
        {{ 'slateEditor.action.save' | translate }}
      </button>
    </div>
  </form>
</div>
