import { CollectionOverview } from '../../shared/api-model';
import { collectionTypes } from '../../shared-projects/services/collections.service';

export enum CollectionCategories {
  'All' = 'all',
  'CustomData' = 'custom-data',
  'ProcessedDataTimeSeries' = 'processed-data-timeseries',
  'HistoryAndLogs' = 'history-and-logs',
  'Internal' = 'internal'
}

export function getCategoryOfCollection(collection: CollectionOverview): CollectionCategories {
  if (
    [collectionTypes.ProcessedData, collectionTypes.Tours].includes(
      collection.type as collectionTypes
    )
  ) {
    return collection.timeseries
      ? CollectionCategories.ProcessedDataTimeSeries
      : CollectionCategories.CustomData;
  } else if (
    [
      collectionTypes.ThingHistory,
      collectionTypes.QueryHistory,
      collectionTypes.Reprocessing,
      collectionTypes.ProcessingStatus,
      collectionTypes.DecodingRequests,
      collectionTypes.DeviceAttachments,
      collectionTypes.Activities,
      collectionTypes.CalendarEvents,
      collectionTypes.UserLog
    ].includes(collection.type as collectionTypes)
  ) {
    return CollectionCategories.HistoryAndLogs;
  } else {
    return CollectionCategories.Internal;
  }
}

export function sortAvailableCollectionCategories(categories: string[]): string[] {
  const processedDataStandard = categories.indexOf(collectionTypes.ProcessedDataStandard);
  if (processedDataStandard !== -1) {
    categories.splice(processedDataStandard, 1);
    categories.unshift(collectionTypes.ProcessedDataStandard);
  }

  const internal = categories.indexOf('internal');
  if (internal !== -1) {
    categories.splice(internal, 1);
    categories.push('internal');
  }

  return categories;
}
