import { FieldConfig } from '../../dashboards/widgets/input-widget/models/input.model';
import { DsParameter, DsParameterRef, DsParameterValue } from '../data-widgets/data-widgets-common';
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { ColorRanges, ColorSettings, SelectionType } from './data-conditional-formatting.model';
import { cloneDeep } from 'lodash-es';
import { translate } from '../../shared/translation-util';
import { FilterParameterConfig } from '../../dashboards/widgets/filter-widget/filter-widget.model';
import { PathInfo } from '../data-selection/data-info-util';
import { ColumnDefinitionType } from '../../dashboards/widgets/table-view-widget/table-view-widget-edit/table-view-widget.model';

@Component({
  selector: 'data-conditional-formatting',
  templateUrl: './data-conditional-formatting.component.html',
  styleUrls: ['./data-conditional-formatting.component.scss']
})
export class DataConditionalFormattingComponent implements OnChanges {
  selectionTypeEnum = SelectionType;
  selectionTypes = [
    {
      type: SelectionType.STATIC,
      label: translate('dataConditionalFormatting.static')
    },
    {
      type: SelectionType.RANGE,
      label: translate('dataConditionalFormatting.range')
    }
  ];
  selectionType = SelectionType.STATIC;
  uniqueId = Math.random();
  viewModel: ColorSettings<DsParameter>;

  @Input() config: ColorSettings<DsParameter>;

  @Input() paths: PathInfo[] = [];

  @Input() filterParameters: FilterParameterConfig[] = [];
  @Input() inputParameters: FieldConfig[] = [];

  @Input() dataType;

  @Input() columnType: ColumnDefinitionType;

  @Output() configChange = new EventEmitter<ColorSettings<DsParameter>>();

  ngOnChanges(changes: SimpleChanges) {
    if (changes.config && changes.config.firstChange) {
      this.initData();
      this.notifyChange();
    } else if (changes.config && !changes.config.firstChange) {
      this.initData();
    }
  }

  private initData() {
    if (!this.config) {
      this.config = {};
    }

    if (this.config.colorRangeSettings) {
      this.selectionType = SelectionType.RANGE;
    } else if (this.config.staticColor) {
      this.selectionType = SelectionType.STATIC;
    }
    this.viewModel = cloneDeep(this.config);
  }

  changeStaticColor(parameter: DsParameterValue | DsParameterRef) {
    if (parameter) {
      this.viewModel.staticColor = parameter;
      this.notifyChange();
    }
  }

  updateRangeConfig(colorRangesConfig: ColorRanges<DsParameter>) {
    setTimeout(() => {
      this.viewModel.colorRangeSettings = colorRangesConfig;
      this.notifyChange();
    }, 0);
  }

  notifyChange() {
    const cleanedConfig = this.getCleanedUpConfig();
    this.configChange.emit(cleanedConfig);
  }

  private getCleanedUpConfig() {
    const clone = cloneDeep(this.viewModel);
    if (this.selectionType === SelectionType.STATIC) {
      delete clone.colorRangeSettings;
    } else {
      delete clone.staticColor;
    }
    return clone;
  }

  protected readonly ColumnDefinitionType = ColumnDefinitionType;
}
