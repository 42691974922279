import { ContainerNode } from '../../regex-editor/regex-input/regex-dom-generator';
import {
  badgeTypes,
  LogicalOperator,
  NodeType,
  regex,
  SyntaxNode
} from '../models/syntax-node.model';
import { ConditionChip } from '../models/condition-chip.model';

/**
 * AST handler.
 * Rewrite from regex-dom-generator.ts and
 * https://github.com/DmitrySoshnikov/regexp-tree/blob/master/src/generator/index.js
 */

const Generator: Record<NodeType, (node: SyntaxNode) => HTMLElement> = {
  ConditionChip(node: SyntaxNode) {
    const container = createContainer(node);
    container.add(createChipSpan(node.body as ConditionChip));
    return container;
  },

  SyntaxRootNode(node: SyntaxNode): HTMLElement {
    let container = createContainer(node);
    container.spellcheck = false;
    node.childNodes.forEach((child) => {
      container.add(generateDom(child));
    });
    container = addOptionalWhitespace(container);
    return container;
  },

  LogicalOperator(node: SyntaxNode): HTMLElement {
    const container = createContainer(node);
    container.add(
      createBadgeSpan(
        node.positionInDom,
        node.body instanceof LogicalOperator ? node.body.type : null
      )
    );
    return container;
  },

  GroupMarkerOpen(node: SyntaxNode): HTMLElement {
    const container = createContainer(node);
    container.add('(');
    return container;
  },

  GroupMarkerClose(node: SyntaxNode): HTMLElement {
    const container = createContainer(node);
    container.add(')');
    return container;
  },

  Group(node: SyntaxNode): HTMLElement {
    const container = createContainer(node);
    container.add(`(`);
    node.childNodes.forEach((child) => {
      container.add(generateDom(child));
    });
    container.add(')');
    return container;
  }
};

function addOptionalWhitespace(container: ContainerNode) {
  const firstNode = container?.firstChild?.firstChild as ContainerNode;
  let extendedContainer = container;

  if (firstNode?.classList?.contains('badge')) {
    extendedContainer = createSpan('SyntaxRootNode') as ContainerNode;
    extendedContainer.appendChild(createWhiteSpaceContainer());
    extendedContainer.appendChild(container);
  }
  return extendedContainer;
}

function createWhiteSpaceContainer() {
  const container = createSpan('whitespace-container');
  container.textContent = ' ';
  return container;
}

function createContainer(node: SyntaxNode): ContainerNode {
  const container = createSpan(node.type) as ContainerNode;
  container.add = addElement;
  return container;
}

function createSpan(className: string): HTMLElement {
  const container = document.createElement('span');
  container.className = className;
  return container;
}

function addElement(el: Node | string) {
  this.appendChild(typeof el === 'string' ? document.createTextNode(el) : el);
}

function createBadgeSpan(elementID, type: string): HTMLElement {
  const badgeSpan = document.createElement('span');
  badgeSpan.classList.add('badge', 'badge-primary', 'custom-badge');
  badgeSpan.textContent = type;
  badgeSpan.contentEditable = 'false';
  badgeSpan.setAttribute('id', 'operatorID' + elementID);
  badgeSpan.addEventListener('mousedown', () => switchLogicalOperator(badgeSpan));
  return badgeSpan;
}

function switchLogicalOperator(operatorElement: HTMLElement) {
  const compoundEditorElement = findParentCompoundEditorElement(operatorElement);
  operatorElement.textContent = regex.isAND.exec(operatorElement.textContent)
    ? badgeTypes.or
    : badgeTypes.and;
  // DispatchEvent updates the SyntaxTree
  compoundEditorElement.dispatchEvent(new InputEvent('input'));
}

function findParentCompoundEditorElement(element: HTMLElement) {
  let parent = element;
  // Using ContentEditable Attribute to get to the Editor Input
  while (parent) {
    parent = parent.parentElement;
    if (parent.contentEditable === 'true') {
      // by default contentEditable is set to inherit
      break;
    }
  }
  return parent;
}

function createChipSpan(chipBody: number | ConditionChip): HTMLElement {
  // ChipBody Type: number | ConditionChip
  const textSpan = document.createElement('span');
  if (typeof chipBody === 'number') {
    textSpan.innerText = String(chipBody);
  } else {
    textSpan.innerText = chipBody.visualIndex;
  }
  return textSpan;
}

export function generateDom(node: SyntaxNode): HTMLElement {
  return node ? Generator[node.type](node) : document.createElement('span');
}
