import { jsx } from 'slate-hyperscript';

export function formatHtmlToSlateObject(html: string) {
  if (!html) {
    return [];
  }
  const document = new DOMParser().parseFromString(html, 'text/html');

  return deserialize(document.body);
}

function deserialize(el, markAttributes = {}) {
  if (el.nodeType === Node.TEXT_NODE) {
    return jsx('text', markAttributes, el.textContent);
  } else if (el.nodeType !== Node.ELEMENT_NODE) {
    return null;
  }

  const nodeAttributes = { ...markAttributes };

  // define attibutes for text nodes
  switch (el.nodeName) {
    case 'STRONG':
      nodeAttributes['bold'] = true;
      break;
    case 'EM':
      nodeAttributes['italic'] = true;
      break;
    case 'U':
      nodeAttributes['underlined'] = true;
      break;
    case 'S':
      nodeAttributes['strike'] = true;
      break;
    case 'PRE':
      nodeAttributes['code-line'] = true;
      break;
  }

  if (el.className?.includes('ql-color-')) {
    nodeAttributes['font-color'] = getClassValueByPrefix(el.className, 'ql-color-');
  }
  if (el.className?.includes('ql-bg-')) {
    nodeAttributes['font-bg-color'] = getClassValueByPrefix(el.className, 'ql-bg-');
  }

  const children = (
    Array.from(el.childNodes).map((node) => deserialize(node, nodeAttributes)) as any
  ).flat();

  if (
    children.length === 0 ||
    ((el.nodeName === 'A' || el.nodeName === 'P') &&
      el.childNodes[el.childNodes.length - 1]?.nodeName === 'IMG')
  ) {
    children.push(jsx('text', nodeAttributes, ''));
  }

  const extraAttributes = {};
  if (el.className?.includes('ql-indent-')) {
    extraAttributes['indent'] = getClassValueByPrefix(el.className, 'ql-indent-');
  }
  if (el.className?.includes('ql-align-')) {
    extraAttributes['align'] = getClassValueByPrefix(el.className, 'ql-align-');
  }

  switch (el.nodeName) {
    case 'BODY':
      return jsx('fragment', {}, children);
    case 'BLOCKQUOTE':
      return jsx('element', { type: 'block-quote', ...extraAttributes }, children);
    case 'P':
    case 'PRE':
      return jsx('element', { type: 'paragraph', ...extraAttributes }, children);
    case 'A':
      return jsx(
        'element',
        { type: 'link', url: el.getAttribute('href'), ...extraAttributes },
        children
      );
    case 'H1':
      return jsx('element', { type: 'heading-one', ...extraAttributes }, children);
    case 'H2':
      return jsx('element', { type: 'heading-two', ...extraAttributes }, children);
    case 'OL':
      return jsx('element', { type: 'numbered-list', ...extraAttributes }, children);
    case 'UL':
      return jsx('element', { type: 'bulleted-list', ...extraAttributes }, children);
    case 'LI':
      return jsx('element', { type: 'list-item', ...extraAttributes }, children);
    case 'IMG':
      return jsx('element', { type: 'image', url: el.src, ...extraAttributes }, children);
    default:
      return children;
  }
}

function getClassValueByPrefix(classNames: string, prefix: string) {
  return classNames
    .split(' ')
    .find((c) => c.startsWith(prefix))
    .split(prefix)[1];
}
