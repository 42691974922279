<form #form="ngForm">
  <div class="row px-3">
    <ng-container *ngIf="simpleSearchInput.propertyPathType === PropertyPathType.PREDEFINED_PATH">
      <rb-form-input
        class="required flex-grow-1 mb-1 col p-0 input-name"
        name="path"
        data-cy="simpleSearchInputPath"
        isNotWhitespaceValidator
        required
        [label]="simpleSearchInput.searchInputLabel | translate"
        [(ngModel)]="simpleSearchInput.inputLabel.displayLabel"
        [rbFormInputAutocomplete]="simpleSearchInput.search.bind(simpleSearchInput)"
        [rbAutocompleteList]="autoCompleteList"
        [rbInitialOpen]="true"
        [maxHeight]="'50vh'"
        [disabled]="
          simpleSearchInput.searchType === 'filter' &&
          simpleSearchInput.dataTypeInput &&
          !!activeChip
        "
        [readonly]="simpleSearchInput.searchType === 'filter'"
        (ngModelChange)="simpleSearchInput.detectDataType($event)"
        (keydown.enter)="handleEnterEvent()"
      ></rb-form-input>

      <rb-form-input
        name="fieldName"
        *ngIf="simpleSearchInput.showFieldName"
        class="required col p-0 field-name"
        data-cy="simpleSearchFieldName"
        [required]="true"
        [(ngModel)]="simpleSearchInput.fieldName"
        [label]="'simpleSearch.fieldName' | translate"
      ></rb-form-input>
    </ng-container>

    <rb-form-input
      *ngIf="simpleSearchInput.propertyPathType === PropertyPathType.MANUAL_PATH"
      data-cy="simpleSearchInputPath"
      name="propertyPath"
      class="required col p-0"
      required
      manualPropertyPathValidator
      [(ngModel)]="simpleSearchInput.inputLabel.displayLabel"
      [label]="'restReq.propertyPath' | translate"
    >
      <ng-template rbFormValidationMessage="invalidPropertyPath">
        {{ 'restReq.invalidPropertyPath' | translate }}
      </ng-template>
    </rb-form-input>

    <div class="my-0 mx-5 my-lg-0">
      <operator-selector
        name="operator"
        data-cy="simpleSearchOperatorSelector"
        [(ngModel)]="simpleSearchInput.operatorInput"
        [disabled]="!simpleSearchInput.showOperator"
      ></operator-selector>
    </div>

    <div
      class="p-0 col p-0 type-select"
      *ngIf="
        simpleSearchInput.searchType === 'path' ||
        simpleSearchInput.input === 'metaData' ||
        simpleSearchInput.input === 'queryParameters'
      "
    >
      <rb-form-select
        name="type"
        [label]="'simpleSearch.dataTypeSelection' | translate"
        [(ngModel)]="simpleSearchInput.dataTypeInput"
        (ngModelChange)="simpleSearchInput.resetInputValue()"
        [disabled]="
          simpleSearchInput.operatorInput?.technicalID === 'exists' ||
          (simpleSearchInput.searchType === 'filter' && !simpleSearchInput.showFieldName)
        "
        data-cy="simpleSearchInputDataType"
      >
        <option
          *ngFor="let dataType of simpleSearchInput.supportedDataTypes"
          [value]="dataType"
          data-cy="SimpleSearchDataTypeValue"
        >
          {{ dataType }}
        </option>
      </rb-form-select>
    </div>

    <div
      class="col p-0 value-field mr-3"
      [ngSwitch]="simpleSearchInput.dataTypeInput"
      [class.data-set]="['datasetRequiredId', 'dataset'].includes(simpleSearchInput.dataTypeInput)"
    >
      <rb-form-date-input
        name="value"
        *ngSwitchCase="'date'"
        class="required"
        [label]="'simpleSearch.value' | translate"
        [(ngModel)]="simpleSearchInput.inputValue"
        [options]="{ enableTime: true }"
      ></rb-form-date-input>

      <date-time-range-popover
        *ngSwitchCase="'datetimerange'"
        name="value"
        [label]="'simpleSearch.value' | translate"
        [ngModel]="simpleSearchInput.inputValue"
        (ngModelChange)="updateRange($event)"
        [options]="{ enableTime: !simpleSearchInput.currentInput.disableTime }"
      ></date-time-range-popover>

      <ng-container *ngSwitchCase="'boolean'">
        <rb-form-input
          name="value"
          class="required"
          readonly="true"
          [rbDropdown]="dropDown"
          [(ngModel)]="simpleSearchInput.inputValue"
          [required]="true"
          [label]="'simpleSearch.value' | translate"
        ></rb-form-input>

        <ng-template #dropDown>
          <a class="rb-dropdown-item" (click)="setBooleanValue(true)">true</a>
          <a class="rb-dropdown-item" (click)="setBooleanValue(false)">false</a>
        </ng-template>
      </ng-container>

      <select-dataset-filter
        name="value"
        *ngSwitchCase="'datasetRequiredId'"
        [customClass]="'dataset-filter'"
        [(ngModel)]="simpleSearchInput.inputValue"
        [collectionOptions]="simpleSearchInput.currentInput.selectOptions"
        [requiredId]="true"
      ></select-dataset-filter>

      <select-dataset-filter
        name="value"
        *ngSwitchCase="'dataset'"
        [customClass]="'dataset-filter'"
        [(ngModel)]="simpleSearchInput.inputValue"
        [collectionOptions]="simpleSearchInput.currentInput.selectOptions"
        [requiredId]="true"
      ></select-dataset-filter>

      <ng-container *ngSwitchCase="'selection'">
        <ng-container *ngTemplateOutlet="selection"></ng-container>
      </ng-container>

      <ng-container *ngSwitchCase="'boolean-selection-with-exists'">
        <ng-container *ngTemplateOutlet="selection"></ng-container>
      </ng-container>

      <ng-template #selection>
        <rb-form-select
          [label]="
            simpleSearchInput.currentInput?.i18nLabel
              ? (simpleSearchInput.currentInput?.i18nLabel | translate)
              : simpleSearchInput.currentInput.label
          "
          [ngModel]="simpleSearchInput.inputValue"
          (ngModelChange)="simpleSearchInput.selectOption($event)"
          name="value"
          required="true"
        >
          <option
            *ngFor="let opt of simpleSearchInput.currentInput?.selectOptions"
            [value]="opt.value"
          >
            {{ opt.avoidTranslate ? opt.label : (opt.label | translate) }}
          </option>
        </rb-form-select>
      </ng-template>

      <ng-container *ngSwitchCase="'text-autocomplete'">
        <rb-form-input
          name="'text-autocomplete'"
          [label]="
            simpleSearchInput.currentInput?.i18nLabel
              ? (simpleSearchInput.currentInput?.i18nLabel | translate)
              : simpleSearchInput.currentInput.label
          "
          [(ngModel)]="simpleSearchInput.inputValue"
          [rbFormInputAutocomplete]="searchSelectOptions.bind(this)"
          [rbAutocompleteList]="selectOptionsList"
          [rbInitialOpen]="true"
        ></rb-form-input>
      </ng-container>

      <ng-container *ngSwitchCase="'device-selection'">
        <device-search
          class="d-block"
          name="device-input"
          [label]="'simpleSearch.deviceSearch' | translate"
          [(ngModel)]="simpleSearchInput.inputValue"
          (deviceSelected)="simpleSearchInput.selectOption($event.thingId)"
          [filterWidgetQuery]="getAlreadySelectedDevicesFilter()"
        ></device-search>
      </ng-container>

      <rb-form-input
        name="value"
        class="required"
        data-cy="simpleSearchInputValue"
        *ngSwitchDefault
        [type]="simpleSearchInput.dataTypeInput === 'number' ? 'number' : 'text'"
        [label]="'simpleSearch.value' | translate"
        [disabled]="valueInputNotRequired"
        [(ngModel)]="simpleSearchInput.inputValue"
        [required]="!valueInputNotRequired && !isManualPathAndStringType"
        (keydown.enter)="handleEnterEvent()"
      ></rb-form-input>
    </div>

    <div class="button-bar">
      <button
        type="button"
        class="rb-btn rb-secondary"
        data-cy="simpleSearchInputAdd"
        *ngIf="!activeChip; else editMode"
        (click)="createChip()"
        [disabled]="!ngForm?.valid"
      >
        <span class="rb-ic rb-ic-add"></span>
        {{ 'simpleSearch.add' | translate }}
      </button>
    </div>
  </div>
</form>

<ng-template #editMode>
  <button
    type="button"
    class="rb-btn rb-secondary px-lg-2 px-xl-3"
    (click)="updateChip()"
    [disabled]="!ngForm?.valid"
  >
    <span class="rb-ic rb-ic-refresh"></span>
    {{ 'simpleSearch.update' | translate }}
  </button>
  <button
    type="button"
    class="rb-btn rb-secondary px-lg-2 px-xl-3"
    *ngIf="simpleSearchInput.showDuplicateButton"
    (click)="createChip()"
    [disabled]="!ngForm?.valid"
  >
    <span class="rb-ic rb-ic-copy"></span>
    {{ 'simpleSearch.duplicate' | translate }}
  </button>
  <button type="button" class="rb-btn rb-secondary px-lg-2 px-xl-3" (click)="cancelEditMode()">
    {{ 'simpleSearch.cancel' | translate }}
  </button>
</ng-template>

<ng-template #autoCompleteList let-active="active" let-focus="focus" let-select="select">
  <div>
    <a
      class="rb-dropdown-item"
      *ngFor="let label of simpleSearchInput.displayLabels"
      data-cy="simpleSearchInputFilter"
      (click)="select(label)"
      [class.active]="active === label"
      [class.focus]="focus === label"
    >
      {{ label }}
    </a>
  </div>
</ng-template>

<ng-template #selectOptionsList let-active="active" let-focus="focus" let-select="select">
  <!-- SelectOptions are of type FieldSelectOption-->
  <div>
    <a
      class="rb-dropdown-item"
      *ngFor="
        let option of simpleSearchInput.filteredSelectOptions ||
          simpleSearchInput.currentInput.selectOptions
      "
      (click)="select(option.label)"
      [class.active]="active === option"
      [class.focus]="focus === option"
    >
      {{ option.label }}
    </a>
  </div>
</ng-template>
