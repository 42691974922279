import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { BaseSelection, Element, Text, Transforms } from 'slate';
import {
  createEditorInstance,
  EditorElement,
  hotkeyKeydown,
  MarkTypes,
  resolveIndent
} from '../slate.util';
import { TextMarkComponent } from '../components/text-mark/text-mark.component';
import { AngularEditor } from 'slate-angular';
import { HyperLinkInfo, setLink, unwrapLink } from '../plugins/link';
import { LinkMode, LinkPopoverComponent } from '../components/link-popover/link-popover.component';
import { formatHtmlToSlateObject } from '../transform.util';
import { PathInfo } from '../../../shared-modules/data-selection/data-info-util';
import { ReferenceSuggestionListComponent } from '../components/reference-suggestion-list/reference-suggestion-list.component';
import {
  ImagePropertiesEditorComponent,
  RichTextImageDimension,
  RichTextImageSettings,
  RichTextImageType
} from '../components/image-properties-editor/image-properties-editor.component';
import { insertImage } from '../plugins/image';
import { insertImageReference, insertReference } from '../plugins/reference';
import {
  detectPath,
  extendSelectionByLength,
  getSelectionForImageElement,
  getSelectionOfReference,
  selectLink
} from '../plugins/selection';
import { TranslateService } from '@ngx-translate/core';
import { elementUtils } from '../plugins/element';
import { HyperlinkService } from '../../../shared-modules/data-widgets/data-widgets-common/lib/hyperlink.service';
import { ValueFormattingBaseConfig } from '../../../shared-modules/data-conditional-formatting/data-conditional-formatting.model';
import { DsParameter } from '../../../shared-modules/data-widgets/data-widgets-common';
import { TablePopoverComponent } from '../components/table-popover/table-popover.component';
import { TooltipLeafComponent } from '../components/array-tooltip-leaf/tooltip-leaf.component';

export class EditContext {
  hyperlink: HyperLinkInfo;
  inlineImage = {
    url: '',
    imageDimension: {} as RichTextImageDimension,
    reset() {
      this.url = undefined;
      this.imageDimension = undefined;
    }
  };

  referenceImage = {
    imageSettings: {} as RichTextImageSettings
  };
}

@Component({
  selector: 'slate-editor',
  templateUrl: './slate-editor.component.html',
  styleUrls: ['./slate-editor.component.scss']
})
export class SlateEditorComponent implements OnInit {
  @Input()
  value: EditorElement[] | string;

  @Input()
  editor: AngularEditor;

  @Input()
  readonly = true;

  @Input()
  wrapText = true;

  @Input()
  paths: PathInfo[];

  @Output()
  valueChange = new EventEmitter<EditorElement[]>();

  @ViewChild('heading_1', { read: TemplateRef, static: true })
  headingOneTemplate: TemplateRef<any>;

  @ViewChild('heading_2', { read: TemplateRef, static: true })
  headingTwoTemplate: TemplateRef<any>;

  @ViewChild('heading_3', { read: TemplateRef, static: true })
  headingThreeTemplate: TemplateRef<any>;

  @ViewChild('blockquote', { read: TemplateRef, static: true })
  blockquoteTemplate: TemplateRef<any>;

  @ViewChild('ul', { read: TemplateRef, static: true })
  ulTemplate: TemplateRef<any>;

  @ViewChild('ol', { read: TemplateRef, static: true })
  olTemplate: TemplateRef<any>;

  @ViewChild('li', { read: TemplateRef, static: true })
  liTemplate: TemplateRef<any>;

  @ViewChild('p', { read: TemplateRef, static: true })
  pTemplate: TemplateRef<any>;

  @ViewChild('tableTemplate', { read: TemplateRef, static: true })
  tableTemplate: TemplateRef<any>;

  @ViewChild('trTemplate', { read: TemplateRef, static: true })
  trTemplate: TemplateRef<any>;

  @ViewChild('tdTemplate', { read: TemplateRef, static: true })
  tdTemplate: TemplateRef<any>;

  @ViewChild('reference', { read: TemplateRef, static: true })
  referenceTemplate: TemplateRef<any>;

  @ViewChild('link', { read: TemplateRef, static: true })
  linkTemplate: TemplateRef<any>;

  @ViewChild('image', { read: TemplateRef, static: true })
  imageTemplate: TemplateRef<any>;

  @ViewChild(ReferenceSuggestionListComponent)
  referenceSuggestionListComponent: ReferenceSuggestionListComponent;

  @ViewChild(ImagePropertiesEditorComponent, { static: false })
  imageReferenceEditor: ImagePropertiesEditorComponent;
  @ViewChild(LinkPopoverComponent, { static: false }) linkPopoverComponent: LinkPopoverComponent;

  @ViewChild(TablePopoverComponent, { static: false }) tablePopoverComponent: TablePopoverComponent;
  resolveIndent = resolveIndent;

  editContext = new EditContext();
  storedSelection: BaseSelection;

  linkMode = LinkMode;

  closePopoverAfterMs = 1000;

  tableElementKey: string;

  constructor(
    private translateService: TranslateService,
    private hyperLinkService: HyperlinkService
  ) {}

  ngOnInit() {
    if (typeof this.value === 'string') {
      this.value = formatHtmlToSlateObject(this.value);
    }
    if (!this.value) {
      this.value = [
        {
          type: 'paragraph',
          children: [{ text: '' }]
        }
      ];
    }
  }

  valueChanged(content: EditorElement[]) {
    this.valueChange.next(content);
    if (this.paths) {
      this.referenceSuggestionListComponent.checkForReferenceTrigger();
    }
  }

  updateTableElementKey(key: string) {
    this.tableElementKey = key;
  }

  keydown = (event: KeyboardEvent): void => {
    createEditorInstance();
    hotkeyKeydown(this.editor, event);
    this.referenceSuggestionListComponent.onKeydown(event);
  };

  renderElement() {
    return (element: Element & { type: string }) => {
      if (element.type === 'heading-one') {
        return this.headingOneTemplate;
      }
      if (element.type === 'heading-two') {
        return this.headingTwoTemplate;
      }
      if (element.type === 'heading-three') {
        return this.headingThreeTemplate;
      }
      if (element.type === 'block-quote') {
        return this.blockquoteTemplate;
      }
      if (element.type === 'numbered-list') {
        return this.olTemplate;
      }
      if (element.type === 'table') {
        return this.tableTemplate;
      }
      if (element.type === 'table-row') {
        return this.trTemplate;
      }
      if (element.type === 'table-cell') {
        return this.tdTemplate;
      }
      if (element.type === 'bulleted-list') {
        return this.ulTemplate;
      }
      if (element.type === 'list-item') {
        return this.liTemplate;
      }
      if (element.type === 'link') {
        return this.linkTemplate;
      }
      if (element.type === 'image') {
        return this.imageTemplate;
      }
      if (element.type === 'paragraph') {
        return this.pTemplate;
      }
      if (element.type === 'reference') {
        return this.referenceTemplate;
      }
      return null;
    };
  }

  renderText = (text: Text) => {
    if (
      text[MarkTypes.bold] ||
      text[MarkTypes.italic] ||
      text[MarkTypes.strike] ||
      text[MarkTypes.code] ||
      text[MarkTypes.underline] ||
      text[MarkTypes.fontColor] ||
      text[MarkTypes.fontBgColor] ||
      text[MarkTypes.fontSize]
    ) {
      return TextMarkComponent;
    }
    return null;
  };

  renderLeaf = (text: Text) => {
    if (this.shouldRenderTooltipLeaf(text)) {
      return TooltipLeafComponent;
    }

    return null;
  };

  startHyperlinkEdit(event: MouseEvent, context: any, linkEditButton: HTMLButtonElement) {
    const target = event.target || event.currentTarget;

    if (target instanceof HTMLImageElement) {
      return;
    }
    let selection = context.selection;

    this.editContext.hyperlink = new HyperLinkInfo();

    if (!selection) {
      const nodePath = detectPath(this.value as EditorElement[], context.element);
      selection = selectLink(this.editor, nodePath);
      console.warn('selection is calculated based on element');
    }

    this.editContext.hyperlink = {
      tooltip: context.element.tooltip,
      url: context.element.url,
      urlText: context.element.children[0]['text'],
      target: context.element.target
    };
    this.setStoredSelection(selection);
    linkEditButton.click();
  }

  openTableEditMenu(event: MouseEvent, tableEditPopOverButton: HTMLButtonElement) {
    if (!this.readonly) {
      event.preventDefault();
      tableEditPopOverButton.click();
      if (this.tablePopoverComponent) {
        this.tablePopoverComponent.close();
      }
    }
  }

  startReferenceEdit(
    event: MouseEvent,
    referenceElement: any,
    context: any,
    imageReferencePopOverButton: HTMLButtonElement,
    pathReferencePopOverButton: HTMLButtonElement
  ) {
    const isChildOfALink =
      referenceElement.nativeElement.parentNode?.getAttribute('slateLinkContainingReference') ===
      'true';
    if (isChildOfALink) {
      // if the reference is a child node of a link, then this click will also trigger a click on the hyperlink which will open two editors at a time.
      // To avoid this, the event propagation must be stopped.
      event.stopPropagation();
      // and any opened link editor must be closed.
      this.linkPopoverComponent?.close();
    }

    if (!context.selection) {
      return;
    }

    this.setStoredSelection(context.selection);

    // if another reference is being edited then close it.
    this.imageReferenceEditor?.close();

    const element = context.element;
    if (element?.subTypeInfo?.imageType) {
      this.editContext.referenceImage.imageSettings = Object.assign(
        {} as RichTextImageSettings,
        element.subTypeInfo
      );
      imageReferencePopOverButton.click();
    }

    if (!element?.subTypeInfo) {
      pathReferencePopOverButton.click();
    }
  }

  startInlineImageEdit(element: any, selection: BaseSelection, popOverButton: HTMLButtonElement) {
    this.storedSelection = getSelectionForImageElement(this.editor, selection, element);
    const pixelsToNumber = (value: string) => {
      if (!value) {
        return undefined;
      }
      return Number.parseInt(value.replace('px', ''));
    };
    this.editContext.inlineImage.url = element.url;
    this.editContext.inlineImage.imageDimension = {
      maxHeight: pixelsToNumber(element.maxHeight),
      maxWidth: pixelsToNumber(element.maxWidth)
    };
    popOverButton.click();
  }

  finishInlineImageEdit() {
    if (!this.storedSelection || !this.editContext.inlineImage.url) {
      return;
    }
    Transforms.select(this.editor, this.storedSelection);
    insertImage(
      this.editor,
      this.editContext.inlineImage.url,
      this.editContext.inlineImage.imageDimension
    );
    this.editContext.inlineImage.reset();
  }

  setStoredSelection(selection: BaseSelection) {
    this.storedSelection = selection;
  }

  finishHyperlinkEdit(linkInfo: HyperLinkInfo) {
    if (!this.storedSelection) {
      console.error('Link edit unsuccessful due to missing selection information');
      return;
    }

    // select the whole hyperlink
    this.editor.selection = extendSelectionByLength(this.storedSelection, linkInfo.urlText.length);
    this.storedSelection = null;
    setLink(this.editor, linkInfo);
    this.editContext.hyperlink = new HyperLinkInfo();
  }

  finishReferenceImageEdit(changes: RichTextImageSettings) {
    if (!this.storedSelection) {
      return;
    }
    this.editor.selection = getSelectionOfReference(this.editor, this.storedSelection);
    insertImageReference(this.editor, changes);
  }

  finishReferencePathEdit({
    path,
    valueFormatting,
    tooltip
  }: {
    path: string;
    valueFormatting: ValueFormattingBaseConfig<DsParameter>;
    tooltip: string;
  }) {
    if (!this.storedSelection) {
      return;
    }
    this.editor.selection = getSelectionOfReference(this.editor, this.storedSelection);
    insertReference(this.editor, path, valueFormatting, null, tooltip);
  }

  removeLink() {
    unwrapLink(this.editor);
  }

  resolveLink(context: any) {
    const { element } = context;

    const linkDefinition = {
      path: element.url,
      target: element.target
    };

    return this.hyperLinkService.resolveLink(linkDefinition);
  }

  getReferenceDisplayText(element: any) {
    if (!element.subTypeInfo?.imageType) {
      return element.path;
    }
    const config = element.subTypeInfo as RichTextImageSettings;
    const prefix = this.getReferencePrefix(element.subTypeInfo.imageType);
    if (element.subTypeInfo.imageType === RichTextImageType.FROM_URL) {
      return `${prefix}: ${config.imageSrc}`;
    }
    return `${prefix}: ${element.path}`;
  }

  private getReferencePrefix(type: RichTextImageType): string {
    if (type === RichTextImageType.FROM_URL) {
      return this.translateService.instant('slateEditor.image.urlImage');
    } else if (type === RichTextImageType.BASE_64) {
      return this.translateService.instant('slateEditor.image.base64Image');
    } else if (type === RichTextImageType.DEVICE_IMAGE) {
      return this.translateService.instant('slateEditor.image.deviceImage');
    }
    return type;
  }

  hasReferenceOrImageAsChild(element: HTMLBaseElement): boolean {
    return elementUtils(element).hasChildOfType('reference', 'image');
  }

  hasImageAsChild(element: HTMLBaseElement): boolean {
    return elementUtils(element).hasImageChild();
  }

  hasReferenceAsChild(element: HTMLBaseElement): boolean {
    return elementUtils(element).hasReferenceChild();
  }

  shouldRenderTooltipLeaf(text: Text): boolean {
    return text?.['tooltip'] && this.readonly;
  }
}
