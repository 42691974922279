import { Sort } from '@inst-iot/bosch-angular-ui-components/molecules/table/sort.directive';
import {
  DataFormattingInputType,
  ValueFormattingConfig
} from '../../../../shared-modules/data-conditional-formatting/data-conditional-formatting.model';
import { ActionButtonConfiguration } from '../../../../shared-modules/rest-request/models/rest-request.model';
import { HyperlinkTarget } from '../../../../shared-modules/data-widgets/data-widgets-common/lib/hyperlink.service';
import { EditorElement } from '../../../../shared/slate-richtext/slate.util';
import { v4 as uuid } from 'uuid';
import { TranslatedString } from '../../../../shared-projects/models/project.model';

export enum ColumnDefinitionType {
  JSON = 'json',
  HYPERLINK = 'hyperlink',
  ACTION = 'action',
  IMAGE = 'image',
  RICH_TEXT = 'richText'
}

export enum FooterContent {
  NONE = '',
  SUM = 'SUM',
  AVERAGE = 'AVERAGE'
}

export enum RowClickAction {
  INACTIVE = '_inactive',
  HIGHLIGHT = '_highlight'
}

export enum BackgroundColorTarget {
  CELL = 'cell',
  CHIP = 'chip'
}

export interface CustomColumnDefinition {
  label?: TranslatedString;
  type: ColumnDefinitionType;
  pathValue?: string;
  linkValue?: string;
  linkLabel?: string;
  sort?: Sort;
  linkTarget?: string;
  actionConfiguration?: ActionButtonConfiguration;
  imageSettings?: ImageSettings;
  editor?: EditorElement[];
  valueFormatting?: ValueFormattingConfig;
  defaultVisibility?: boolean;
  columnNameHidden?: boolean;
  fixedHorizontal?: boolean;
  columnMinWidth?: boolean;
  columnMinWidthValuePx?: number;
  wrapText?: boolean;
  tooltipText?: string;
  hidden?: boolean;
}
export type TaskType = 'preSave' | 'preRemove';

export interface BatchActionButton {
  id: string;
  icon: string;
  actionConfiguration: ActionButtonConfiguration;
  isValid: boolean;
}

export const defaultBatchActionButton: BatchActionButton = {
  id: uuid(),
  icon: 'rb-ic-start-play-frame',
  actionConfiguration: {} as ActionButtonConfiguration,
  isValid: false
};

export enum TableDefinitions {
  CUSTOM_COLUMN = 'customColumnDefinition',
  BATCH_ACTION_BUTTON = 'batchActionButtonDefinition'
}

export interface ImageSettings {
  maxHeight: number;
  maxWidth: number;
  imageType: ImageType;
  imageSrc?: string;
}

export enum ImageType {
  FROM_URL = 'fromUrl',
  BASE_64 = 'base64',
  DEVICE_IMAGE = 'deviceImage'
}

export interface CustomColumnDefinitionViewModel extends CustomColumnDefinition {
  pathType?: DataFormattingInputType;
  randomId?: number;
}

const commonColumnFields = [
  { key: 'label' },
  { key: 'type' },
  { key: 'randomId' },
  { key: 'defaultVisibility' },
  { key: 'columnNameHidden' },
  { key: 'valueFormatting' }
];
export const columnDefinitionFields: { [key: string]: { key: string; defaultValue?: any }[] } = {
  [ColumnDefinitionType.JSON]: [
    ...commonColumnFields,
    { key: 'pathValue' },
    { key: 'colorSettings' }
  ],
  [ColumnDefinitionType.HYPERLINK]: [
    ...commonColumnFields,
    { key: 'linkValue' },
    { key: 'linkLabel' },
    { key: 'linkTarget', defaultValue: HyperlinkTarget.SELF }
  ],
  [ColumnDefinitionType.ACTION]: [
    ...commonColumnFields,
    { key: 'actionConfiguration', defaultValue: {} }
  ],
  [ColumnDefinitionType.IMAGE]: [
    ...commonColumnFields,
    { key: 'pathValue' },
    {
      key: 'imageSettings',
      defaultValue: { maxWidth: 50, maxHeight: 50 }
    }
  ],
  [ColumnDefinitionType.RICH_TEXT]: [...commonColumnFields, { key: 'editor' }]
};
