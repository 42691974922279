import {
  Component,
  Injector,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges
} from '@angular/core';
import { Subscription } from 'rxjs';
import {
  createWidgetPlaceholderContextStream,
  resolvePlaceholders,
  resolveStringWithModifiers,
  WidgetPlaceholderContext
} from '../../../shared-modules/data-widgets/data-widgets-common';
import { TranslatedPipe } from '../../../shared/pipes/translated.pipe';
import { WidgetInstanceService } from '../../services/widget-instance.service';
import { DashboardLayout } from '../../models/DashboardConfig';
import { DashboardDataService } from '../../services/dashboard-data.service';

@Component({
  selector: 'widget-title',
  templateUrl: './widget-title.component.html'
})
export class WidgetTitleComponent implements OnInit, OnChanges, OnDestroy {
  @Input() widgetInstance: WidgetInstanceService;

  @Input() widgetData: any;

  @Input() marginBottom = 0;

  @Input() layout: DashboardLayout = DashboardLayout.ColumnBased;

  sub: Subscription;

  hasPlaceholder = false;

  hasDataLoaded = false;

  private context: WidgetPlaceholderContext;

  constructor(
    public translatedPipe: TranslatedPipe,
    public injector: Injector,
    public dashboardDataService: DashboardDataService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (this.hasPlaceholder && changes.widgetData) {
      this.hasDataLoaded = true;
      this.updateTitle();
    }
  }

  ngOnInit(): void {
    if (!this.widgetInstance.properties.title) {
      return;
    }

    const translatedTitle = this.translatedPipe.transform(this.widgetInstance.properties.title);

    if (!this.containsPlaceholder(translatedTitle)) {
      this.widgetInstance.resolvedTitle = translatedTitle;
      return;
    }

    this.loadWidgetPlaceholderContext();
  }

  private loadWidgetPlaceholderContext() {
    this.sub = createWidgetPlaceholderContextStream(this.widgetInstance, this.injector).subscribe(
      (ctx) => {
        this.context = ctx;
        this.updateTitle();
      }
    );
  }

  private containsPlaceholder(translatedTitle: string) {
    this.hasPlaceholder = !!translatedTitle.match(/\${.+}/);
    return this.hasPlaceholder;
  }

  replaceDataPlaceholder(translatedTitle: string): string {
    const widgetContextPlaceholder = [
      'dashboardName',
      'widgetId',
      'queryParams',
      'filterParams',
      'inputParams',
      'insights'
    ].join('|');
    // only replace data placeholder
    const regex = new RegExp('\\' + '${((?!/' + widgetContextPlaceholder + '/).)*}', 'g');
    return translatedTitle.replace(regex, '...');
  }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  updateTitle() {
    let translatedTitle = this.translatedPipe.transform(this.widgetInstance.properties.title);
    // replace placeholder until first result to prevent 'undefined' in title
    if (!this.hasDataLoaded) {
      translatedTitle = this.replaceDataPlaceholder(translatedTitle);
    }
    this.widgetInstance.resolvedTitle = resolvePlaceholders(
      translatedTitle,
      0,
      resolveStringWithModifiers,
      ...[this.context, this.widgetData]
    );
  }
}
