import { Component } from '@angular/core';
import { ProjectsService } from '../../../shared-projects/services/projects.service';

@Component({
  selector: 'collection-categories-popover',
  templateUrl: './collection-categories-popover.component.html'
})
export class CollectionCategoriesPopoverComponent {
  constructor(public projectService: ProjectsService) {}
}
