<div class="DataConditionalFormatting w-100">
  <div
    *ngIf="columnType === ColumnDefinitionType.JSON"
    class="DataConditionalFormattingTypeSelection mt-3"
  >
    <!--Radio is hidden for custom-columns not of type json, until range is implemented in INS-5682-->
    <rb-form-radio
      *ngFor="let selectionTypeElement of selectionTypes"
      class="DataConditionalFormattingTypeSelection_radioButton"
      [attr.data-cy]="'dataConditionalFormattingColor-' + selectionTypeElement.type"
      [label]="selectionTypeElement.label | translate"
      [name]="'typeSelection.' + uniqueId"
      (change)="notifyChange()"
      [(ngModel)]="selectionType"
      [value]="selectionTypeElement.type"
      [disabled]="selectionTypeElement.type !== selectionTypeEnum.STATIC && dataType === undefined"
    ></rb-form-radio>
  </div>
  <div
    class="DataConditionalFormatting_staticColorSelection d-flex"
    *ngIf="selectionType === selectionTypeEnum.STATIC"
  >
    <value-or-reference-input
      [dataType]="'color'"
      [name]="'staticColor' + uniqueId"
      [(ngModel)]="viewModel.staticColor"
      (ngModelChange)="changeStaticColor($event)"
      [paths]="paths"
      class="DataConditionalFormattingTypeSelection_staticColorPicker"
      [label]="'valueFormatting.colorCheckbox' | translate"
      [inputParameters]="inputParameters"
      [filterParameters]="filterParameters"
    ></value-or-reference-input>
  </div>
  <ng-container *ngIf="selectionType === selectionTypeEnum.RANGE">
    <color-range
      [dataType]="dataType"
      [paths]="paths"
      [filterParameters]="filterParameters"
      [inputParameters]="inputParameters"
      [colorRangeConfig]="viewModel.colorRangeSettings"
      (configChange)="updateRangeConfig($event)"
    ></color-range>
  </ng-container>
</div>
