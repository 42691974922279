import {
  Component,
  ContentChildren,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostBinding,
  Input,
  OnChanges,
  Output,
  QueryList,
  Renderer2,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator
} from '@angular/forms';
import {
  emptyFunction,
  FormValidationMessageDirective
} from '@inst-iot/bosch-angular-ui-components';
import { parse } from 'regexp-tree';
import { AstRegExp } from 'regexp-tree/ast';
import { HighlightConfig } from '../regex-text-highlighter/regex-text-highlighter.component';
import { ContainerNode, generateDom } from './regex-dom-generator';
import { getGroupsWithParent, GroupInfo } from './regex-input.utils';

@Component({
  selector: 'regex-input',
  templateUrl: './regex-input.component.html',
  styleUrls: ['./regex-input.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => RegexInputComponent), multi: true },
    { provide: NG_VALIDATORS, useExisting: forwardRef(() => RegexInputComponent), multi: true }
  ]
})
export class RegexInputComponent implements ControlValueAccessor, Validator, OnChanges {
  @Input() name: string;
  @Input() placeholder = '';
  @Input() label: string | TemplateRef<any> = null;
  @Input() highlightGroup = 0; // 0 = no highlight, 1 is first group, 2 is second

  @Output() groupsChange = new EventEmitter<GroupInfo[]>();
  @Output() highlightChange = new EventEmitter<HighlightConfig[]>();

  @ViewChild('input', { static: true }) input: ElementRef;

  @HostBinding('class') class = 'regex-input';

  @ContentChildren(FormValidationMessageDirective)
  messages: QueryList<FormValidationMessageDirective>;

  id = 'input.' + Math.random();
  groups: GroupInfo[] = [];
  highlights: HighlightConfig[];
  tree: AstRegExp;

  onChange = emptyFunction;
  onTouched = emptyFunction;

  selection;
  error;
  previousRegex: string;

  constructor(private renderer: Renderer2, private elementRef: ElementRef) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.highlightGroup) {
      this.doHighlightGroup(changes.highlightGroup.currentValue);
    }
  }

  doHighlightGroup(num: number) {
    const groups = this.input.nativeElement.querySelectorAll('.regex-Group');
    for (let i = 0; i < groups.length; i++) {
      const grp = groups[i];
      if (i === num - 1) {
        grp.classList.add('highlight');
      } else {
        grp.classList.remove('highlight');
      }
    }
  }

  writeValue(value: string): void {
    if (value === null || value === undefined) {
      value = '';
    } else {
      value = String(value);
    }
    this.checkValue(value);
    if (value !== '') {
      this.updateGroups(value);
    }
    this.renderRegex(value);
    if (this.highlightGroup) {
      this.doHighlightGroup(this.highlightGroup);
    }
  }

  renderRegex(value: string) {
    this.error = null;

    try {
      const tree = parse('/' + value + '/', { captureLocations: true });
      const highlightGroups = this.groups.map((g) => {
        return g.number;
      });
      const dom = generateDom(tree, highlightGroups);
      this.input.nativeElement.innerHTML = '';
      // prevents cursor from jumping to the beginning
      dom.appendChild(document.createElement('span'));
      this.input.nativeElement.appendChild(dom);
    } catch (e) {
      this.error = e;
    }
  }

  validate(control: AbstractControl): ValidationErrors | null {
    if (this.error) {
      return {
        regexError: this.error
      };
    }
    return null;
  }

  checkValue(value) {
    if (typeof value === 'string' && value.length > 0) {
      this.renderer.addClass(this.elementRef.nativeElement, 'not-empty');
    } else {
      this.renderer.removeClass(this.elementRef.nativeElement, 'not-empty');
    }
  }

  updateGroups(text: string) {
    try {
      this.tree = parse('/' + text + '/', { captureLocations: true });
      this.groups = getGroupsWithParent(this.tree).filter((g) => !g.parentNumber);
      for (const g of this.groups) {
        g.name = g.name ? g.name : g.text;
      }
      this.identifyMatches();
      this.groupsChange.next(this.groups);
    } catch (e) {
      console.error('Could not parse groups');
    }
  }

  identifyMatches() {
    const highlight: HighlightConfig = {
      name: 'A',
      label: 'A',
      focusGroup: this.highlightGroup,
      tree: this.tree,
      groupNames: this.groups.map((g) => g.name)
    };
    this.highlights = [highlight];
    this.highlightChange.emit([highlight]);
  }

  registerOnChange(fn: (_: any) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.renderer.setProperty(this.input.nativeElement, 'disabled', isDisabled);
  }

  updateValueFromEvent(event: Event) {
    this.updateValue((event.target as HTMLElement).textContent);
  }

  updateValue(value: string) {
    let offset;
    const selection = document.getSelection();

    // Firefox doesn't always select the text node, in this case the offset is estimated by the change of the regex string
    if (this.previousRegex && selection.anchorNode.nodeType !== 3) {
      offset = this.getStringDiffIndex(value, this.previousRegex);
    } else {
      offset = getOffsetFromSelection(selection, this.input.nativeElement);
    }
    this.previousRegex = value;

    this.checkValue(value);

    this.updateGroups(value);

    this.renderRegex(value);

    const caretPos = positionCaret(offset, this.input.nativeElement);
    if (caretPos) {
      selection.setPosition(caretPos.node, caretPos.offset);
    }

    this.onChange(value);
  }

  /**
   * compares two strings and returns the index where they differ
   * @param a
   * @param b
   */
  getStringDiffIndex(a: string, b: string) {
    const minLength = Math.min(a.length, b.length);

    for (let i = 0; i < minLength; i++) {
      if (a[i] !== b[i]) {
        return i;
      }
    }
    return minLength;
  }
}

export function positionCaret(offset, node: Node) {
  if (offset === 0) {
    return {
      offset,
      node
    };
  }
  if (node.nodeType === 3 && offset < node.textContent.length) {
    return {
      offset,
      node
    };
  } else if (node.nodeType === 3) {
    return null;
  }
  for (const n of node.childNodes) {
    const pos = positionCaret(offset, n);
    if (pos) {
      return pos;
    }
    offset -= n.textContent.length;
  }
  return null;
}

export function getOffsetFromSelection(selection: Selection, rootElement: Node) {
  if (selection.anchorNode.nodeType === Node.TEXT_NODE) {
    // is text node
    let index = 0;
    let n = selection.anchorNode;

    while (n !== null) {
      n = getNextParentNode(n, rootElement);
      if (!n) {
        break;
      }

      n = n.previousSibling;
      if (n !== null) {
        index += n.nodeType === Node.TEXT_NODE ? (n as Text).length : n.textContent.length;
      }
    }
    return index + selection.anchorOffset;
  } else {
    return (selection.anchorNode as ContainerNode).regexPos;
  }
}
function getNextParentNode(n: Node, rootElement: Node): Node {
  while (hasNoPreviousSiblingButParent(n, rootElement)) {
    n = n.parentNode;
  }
  if (hasNoPreviousSiblingButParent(n, rootElement)) {
    return null;
  }
  return n;
}
function hasNoPreviousSiblingButParent(n: Node, rootElement: Node): boolean {
  return !n.previousSibling && (n.parentNode !== rootElement || !n.parentNode);
}
