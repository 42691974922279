import {
  Component,
  EventEmitter,
  inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { Observable, of, Subscription } from 'rxjs';
import {
  CollectionsService,
  collectionTypes
} from '../../shared-projects/services/collections.service';
import { CollectionOverview } from '../../shared/api-model';
import { LoadingEntity } from '@inst-iot/bosch-angular-ui-components';
import { TranslateService } from '@ngx-translate/core';
import { translate } from '../../shared/translation-util';
import { ActivatedRoute } from '@angular/router';
import {
  CollectionCategories,
  getCategoryOfCollection,
  sortAvailableCollectionCategories
} from './collection.utils';

@Component({
  selector: 'collection-selector',
  templateUrl: './collection-selector.component.html',
  styleUrls: ['collection-selector.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CollectionSelectorComponent implements OnInit, OnDestroy {
  @Input()
  allowedCollectionTypes: collectionTypes[] = [];

  @Input()
  disableCategorySelection = false;

  @Input()
  title?: string = inject(TranslateService).instant(translate('collectionSelector.collectionName'));

  _selectedCollection?: CollectionOverview;
  selectedCollectionCategory: CollectionCategories;

  @Input() set selectedCollection(collection: CollectionOverview | string) {
    if (!collection) {
      return;
    }

    if (typeof collection === 'object') {
      if (!this.isCollectionKnown(collection)) {
        this.collections.unshift(collection);
        this.initSelectorProperties();
      }
      this._selectedCollection = collection;
      this.selectedCollectionCategory = getCategoryOfCollection(this._selectedCollection);
      this.searchBoxText = this.getCollectionDisplay(collection);
      this.selectedCollectionChange.emit(this._selectedCollection);
    } else {
      this.collectionsService
        .getCollectionByTechnicalName(collection)
        .subscribe((foundCollection: CollectionOverview) => {
          this.addFoundCollection(foundCollection);
        });
    }
  }

  @Input()
  disabled: boolean;

  @Input()
  showRetentionTime: boolean;

  @Input()
  showCollectionCategoryExplanation: boolean;

  @Output()
  selectedCollectionChange = new EventEmitter<CollectionOverview>();

  @Output()
  selectedCategoryChange = new EventEmitter<string>();

  collectionsLoader = new LoadingEntity<CollectionOverview[]>();

  filteredCollections: CollectionOverview[];

  searchBoxText: string;

  collections: CollectionOverview[] = [];

  categoryFilteredCollections: CollectionOverview[];

  matchingCollectionStrings: string[];

  getMatchingCollections = this.getFilteredCollections.bind(this);

  selectedCategory: CollectionCategories = CollectionCategories.All;

  availableCollectionCategories: string[] = [CollectionCategories.All];

  collectionTypes = collectionTypes;

  isCollapsed: boolean;

  collectionCategories = CollectionCategories;

  private subscriptions = new Subscription();

  constructor(private collectionsService: CollectionsService, private route: ActivatedRoute) {}

  ngOnInit(): void {
    if (!this.allowedCollectionTypes.length) {
      this.allowedCollectionTypes = Object.values(collectionTypes);
    }
    this.collectionsLoader
      .run(
        this.collectionsService.getCollectionsByTypesOrdered(
          true,
          true,
          this.allowedCollectionTypes
        )
      )
      .subscribe((collections: CollectionOverview[]) => {
        this.collections = collections;
        this.initSelectorProperties();
      });
  }

  ngOnDestroy() {
    this.collectionsLoader.complete();
    this.subscriptions.unsubscribe();
  }

  autocompleteCollection() {
    if (!this.getExactCollectionOfInput() && this.filteredCollections.length > 0) {
      this.isCollapsed = true;
      this.selectedCollection = this.filteredCollections[0];
    }
  }

  getExactCollectionOfInput(): CollectionOverview {
    return this.filteredCollections.find(
      (collection: CollectionOverview) =>
        this.getCollectionDisplay(collection) === this.searchBoxText
    );
  }

  getCollectionDisplay(collection: CollectionOverview): string {
    return `${collection.label} (${collection.name})`;
  }

  getFilteredCollections(text?: string): Observable<string[]> {
    this.matchingCollectionStrings = [];
    this.filteredCollections = [];
    const showAll =
      !text ||
      this.categoryFilteredCollections.some(
        (collection: CollectionOverview) => text === this.getCollectionDisplay(collection)
      );
    this.categoryFilteredCollections.forEach((collection: CollectionOverview) => {
      if (showAll || this.getCollectionDisplay(collection).includes(text)) {
        this.filteredCollections.push(collection);
        this.matchingCollectionStrings.push(this.getCollectionDisplay(collection));
      }
    });
    return of(this.matchingCollectionStrings);
  }

  matchInputWithSelectedCollection() {
    const collectionDisplayText = this.getCollectionDisplay(this._selectedCollection);
    if (this.searchBoxText !== collectionDisplayText) {
      this.searchBoxText = collectionDisplayText;
    }
  }

  categoryChanged() {
    this.updateCollectionNameSearchBox();
    this.filterCollectionsByCategorySelection();
    this.storeSelectedCategory();
  }

  initAvailableCollectionCategories() {
    const categoriesOfCollections = this.collections.map((c) => getCategoryOfCollection(c));
    this.availableCollectionCategories = [...new Set(categoriesOfCollections)];
    this.availableCollectionCategories = sortAvailableCollectionCategories(
      this.availableCollectionCategories
    );
    this.availableCollectionCategories.unshift('all');
  }

  private initSelectorProperties() {
    this.categoryFilteredCollections = this.collections;
    this.initAvailableCollectionCategories();

    if (!this.filteredCollections && !this.searchBoxText && !this._selectedCollection) {
      this.selectedCollection = this.collections[0];
    }
    this.setSelectedCategory();
    this.filterCollectionsByCategorySelection();
  }

  private filterCollectionsByCategorySelection() {
    this.categoryFilteredCollections = this.collections.filter((c) => {
      if (this.selectedCategory === 'all') {
        return true;
      }
      return getCategoryOfCollection(c) === this.selectedCategory;
    });

    this.subscriptions.add(
      this.getFilteredCollections(this.searchBoxText).subscribe(() => this.autocompleteCollection())
    );
    this.storeSelectedCategory();
  }

  private storeSelectedCategory() {
    this.collectionsService.storeSelectedCollectionCategory(
      this.route.parent.snapshot.url[0].path,
      this.selectedCategory
    );
  }

  private updateCollectionNameSearchBox() {
    if (
      this.selectedCategory !== 'all' &&
      getCategoryOfCollection(this._selectedCollection) !== this.selectedCategory
    ) {
      this.searchBoxText = '';
    }
  }

  private setSelectedCategory() {
    const storedCategory = this.collectionsService.getSelectedCollectionCategory(
      this.route.parent.snapshot.url[0].path
    );

    if (getCategoryOfCollection(this._selectedCollection) === storedCategory) {
      this.selectedCategory = storedCategory;
    } else {
      this.selectedCategory = CollectionCategories.All;
    }
  }

  private isCollectionKnown(collectionToBeChecked: CollectionOverview) {
    return this.collections.some((c) => c.name === collectionToBeChecked.name);
  }

  private addFoundCollection(foundCollection: CollectionOverview) {
    this.selectedCollection = foundCollection;
    if (!this.isCollectionKnown(foundCollection)) {
      this.selectedCategory = CollectionCategories.All;
      this.collections.push(foundCollection);
      this.initAvailableCollectionCategories();
    }
  }
}
