import { Injectable, Optional } from '@angular/core';
import { DashboardStateService } from '../../../../dashboards/services/dashboard-state.service';
import { WidgetInstanceService } from '../../../../dashboards/services/widget-instance.service';
import { PaginationSettingsService } from '../../../../shared/pagination-bar/pagination-settings.service';

type TableParameterType = 'columnVisibility' | 'paginationSettings';

@Injectable({
  providedIn: 'root'
})
export class TableWidgetStateService {
  constructor(
    /*
   DashboardStateService only used in Widget Context, not in Data Browser
   * */
    @Optional() private dashboardStateService: DashboardStateService,
    private widgetInstance: WidgetInstanceService,
    private paginationService: PaginationSettingsService
  ) {}

  getColumnVisibilityParameter(parameterName: TableParameterType) {
    return this.getParameterValue(parameterName);
  }

  getTablePaginationSettingsParameter(): { limit: number; limits: number[] } {
    return this.getParameterValue('paginationSettings');
  }

  restorePaginationSettings(defaultTableRowLimit: number) {
    const storedPagination = this.getTablePaginationSettingsParameter();
    const storedPaginationLimit = storedPagination?.limit || defaultTableRowLimit;
    this.detectTableRowLimitChanges(storedPagination, defaultTableRowLimit);
    this.paginationService.updateLimit(storedPaginationLimit);
    return storedPaginationLimit;
  }

  updateTablePaginationSettingsParameter(value: Record<string, any>) {
    this.updateParameter('paginationSettings', value);
  }

  updateColumnVisibilityParameter(value: Record<string, any>) {
    this.updateParameter('columnVisibility', value);
  }

  getPaginationSettingsServiceLimit() {
    return this.paginationService.limit;
  }

  setPaginationSettingsServiceLimit(limit: number) {
    this.paginationService.updateLimit(limit);
  }

  private getParameterValue(parameterName: TableParameterType): any {
    if (this.dashboardStateService && parameterName) {
      return this.dashboardStateService.getParameter(parameterName, this.widgetInstance.id)?.value;
    }
    return null;
  }

  private updateParameter(parameterName: TableParameterType, value: Record<string, any>) {
    if (this.dashboardStateService && parameterName) {
      this.dashboardStateService.updateParameter(parameterName, this.widgetInstance.id, value);
    }
  }

  private detectTableRowLimitChanges(pagination: Record<string, any>, defaultLimit: number) {
    if (pagination && !pagination.limits.includes(defaultLimit)) {
      pagination.limits[0] = defaultLimit;
      this.updateTablePaginationSettingsParameter(pagination);
    }
  }
}
