import { Component, Input } from '@angular/core';
import { CollectionOverview, IndexDetails } from '../../../shared/api-model';

@Component({
  selector: 'collection-retention-time',
  templateUrl: './collection-retention-time.component.html'
})
export class CollectionRetentionTimeComponent {
  retentionTimeProperties: string[] = [];
  retentionTimes: number[] = [];

  _selectedCollection: CollectionOverview;

  @Input() set selectedCollection(collection) {
    this._selectedCollection = collection;
    this.extractRetentionTime();
  }

  private extractRetentionTime() {
    this.retentionTimes.length = 0;
    this.retentionTimeProperties.length = 0;
    this._selectedCollection.indexes?.forEach((index: IndexDetails) => {
      if (index.expiresAfterSeconds > 0) {
        this.retentionTimes.push(index.expiresAfterSeconds * 1000);
        this.retentionTimeProperties.push(index.options.name);
      }
    });
  }
}
