import { HttpErrorResponse } from '@angular/common/http';
import {
  ChangeDetectorRef,
  Component,
  ComponentRef,
  EventEmitter,
  Injector,
  Input,
  Output,
  Type,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { RequestStatusComposite } from '../../../shared/api-model';
import { HttpStatusCode } from '../../../shared/http-utils';
import { DetailedError } from '@inst-iot/bosch-angular-ui-components';
import { processRestRequestError } from '../../../shared-modules/action-buttons/action-config-util';

@Component({
  selector: 'widget-loading-content',
  styleUrls: ['./widget-loading-content.component.scss'],
  templateUrl: './widget-loading-content.component.html'
})
export class WidgetLoadingContentComponent {
  @Input() set loading(isLoading: boolean) {
    if (isLoading && !this._loading) {
      setTimeout(() => {
        this.setPlaceholderComponent();
      });
    }
    this._loading = isLoading;
  }

  @Input() noData = false;
  @Input() widgetPlaceholder?: Type<any>;
  @Input() widgetType?: string;
  @Input() widgetId: string;

  set height(value: number | string) {
    this._height = typeof value === 'string' ? value : value + 'px';
  }

  @Input() warning: DetailedError = null;
  @Input() queryStatus: RequestStatusComposite;

  @Output() reload = new EventEmitter<void>();

  @ViewChild('container', { read: ViewContainerRef }) container: ViewContainerRef;

  _loading = false;

  placeholderComponentRef: ComponentRef<any>;
  _error: HttpErrorResponse = null;

  isNotFoundOrNoAccess = false;
  _height: string;

  isQueueFull = false;

  constructor(private injector: Injector, private changeDetector: ChangeDetectorRef) {}

  @Input()
  set error(value: HttpErrorResponse) {
    if (this._error === value) {
      return;
    }
    if (value && value.error instanceof Blob && value.url.includes('/rest-request-definition/')) {
      processRestRequestError(value).then((errorText) => {
        this._error = value;
        this._error.error.message = errorText;
      });
    } else {
      this._error = value;
      this.isNotFoundOrNoAccess =
        value?.status === HttpStatusCode.notFound || value?.status === HttpStatusCode.forbidden;
      this.isQueueFull = value?.status === HttpStatusCode.queueFull;
    }
  }

  private setPlaceholderComponent() {
    if (this.container && this.widgetPlaceholder) {
      this.placeholderComponentRef?.destroy();
      this.placeholderComponentRef = this.container.createComponent(this.widgetPlaceholder, {
        injector: this.injector
      });
      this.changeDetector.detectChanges();
    }
  }

  triggerReload() {
    this.reload.next();
  }
}
