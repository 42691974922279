import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { LoadingEntity } from '@inst-iot/bosch-angular-ui-components';
import { WidgetInstanceService } from '../../../services/widget-instance.service';
import { calculateSkeletonLines } from '../../../../shared/skeleton-loading-element/skeleton-loading-element.component';

@Component({
  selector: 'table-view-widget-placeholder',
  templateUrl: './table-view-widget-placeholder.component.html'
})
export class TableViewWidgetPlaceholderComponent implements OnInit, AfterViewInit {
  @Input() customCssClass: string;
  fakeLoader = new LoadingEntity<any>();
  columnsCount: number;
  rowCount: number;
  readonly defaultRowCount = 4;

  @ViewChild('textSkeletons', { static: true }) text: ElementRef;
  constructor(private widgetInstance: WidgetInstanceService) {
    this.setColumnCount();
  }

  private setColumnCount() {
    const customColumnsCount = this.widgetInstance.properties?.customColumnDefinitionConfig?.length;
    this.columnsCount = customColumnsCount || 4;
  }

  ngOnInit() {
    const emptyObs = new Observable((subscriber) => {});
    this.fakeLoader.run(emptyObs);
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.rowCount = this.text.nativeElement.offsetHeight
        ? calculateSkeletonLines(this.text.nativeElement.offsetHeight, 3, 0).length - 1
        : this.defaultRowCount;
    });
  }
}
