<div class="rb-custom-input custom-label compound-logic p-0">
  <div class="input-wrapper border p-0 pl-3">
    <label class="my-0 mt-1 text-body">
      {{ 'simpleSearch.compoundLogicEditor' | translate }}
    </label>
    <div
      #simpleLogicInput
      class="input pt-0"
      contenteditable="true"
      data-cy="simpleSearchEditorLogicInput"
      (keydown.space)="$event.preventDefault()"
      (input)="onInputChanged($event)"
    ></div>
  </div>
  <div *ngIf="syntaxErrors.length" class="a-notification -warning">
    <div>
      <div *ngFor="let error of syntaxErrors">{{ error.message }}</div>
    </div>
  </div>
</div>
