import { ParamMap } from '@angular/router';
import { isNil, zip } from 'lodash-es';
import moment from 'moment';
import { DashboardParameterOptions, SyncAbstractDashboardParameter } from './dashboard-parameter';
import { RangeDashboardParameter, RangeParameterValue } from './range-dashboard-parameter';
import { UrlSearchParamMap } from './url-search-param-map';

export class DateTimeRangeDashboardParameter extends RangeDashboardParameter {
  declare type: 'dateTimeRange';

  constructor(
    name: string,
    i18nLabel: string,
    widgetId: string,
    value?: RangeParameterValue,
    options?: DashboardParameterOptions
  ) {
    super(name, i18nLabel, 'dateTimeRange', widgetId, value, options);
  }

  toParams(): ParamMap {
    const params = new URLSearchParams();

    const toQueryValue = (v: string): string => (v === '0' ? '+' + v : v);

    if (this.value !== undefined) {
      params.set(this.queryKeys[0], toQueryValue(this.value.from));
      params.set(this.queryKeys[1], toQueryValue(this.value.to));
    }

    return new UrlSearchParamMap(params);
  }

  fromParams(params: ParamMap, writeOnlyOnChange = false) {
    if (params.has(this.paramKey + 'From') && this.paramKey + 'To') {
      const from = params.get(this.paramKey + 'From');
      const to = params.get(this.paramKey + 'To');

      if (isDateRangeValid(from, to)) {
        const value = { from, to };
        this.writeValue(value, writeOnlyOnChange);
      }
    }
  }
}

export class MultiDateTimeRangeDashboardParameter extends SyncAbstractDashboardParameter<
  RangeParameterValue[]
> {
  declare type: 'dateTimeRange[]';

  constructor(
    name: string,
    i18nLabel: string,
    widgetId: string,
    value?: RangeParameterValue[],
    options?: DashboardParameterOptions
  ) {
    super(name, i18nLabel, 'dateTimeRange[]', widgetId, value ?? [], options);
  }

  get queryKeys(): string[] {
    return [this.paramKey + 'From', this.paramKey + 'To'];
  }

  toParams(): ParamMap {
    const params = new URLSearchParams();

    const toQueryValue = (v: string): string => (v === '0' ? '+' + v : v);

    if (this.value !== undefined && Array.isArray(this.value) && this.value.length) {
      for (const v of this.value) {
        params.append(this.queryKeys[0], toQueryValue(v.from));
        params.append(this.queryKeys[1], toQueryValue(v.to));
      }
    }

    return new UrlSearchParamMap(params);
  }

  fromParams(params: ParamMap, writeOnlyOnChange = false) {
    if (params.has(this.paramKey + 'From') && this.paramKey + 'To') {
      const values = [];
      for (const [from, to] of zip(
        params.getAll(this.paramKey + 'From'),
        params.getAll(this.paramKey + 'To')
      )) {
        if (isDateRangeValid(from, to)) {
          values.push({
            from,
            to
          });
        }
      }
      this.writeValue(values, writeOnlyOnChange);
    }
  }
}

export function isDateRangeValid(from: string, to: string): boolean {
  if (!from || !to) {
    return false;
  }

  const formats = [moment.ISO_8601];
  const relativeDateRegex = /^[+-]\d+$/;
  const isRelativeDate = !isNil(from.match(relativeDateRegex) && to.match(relativeDateRegex));
  const validAbsoluteDate =
    moment(from, formats, true).isValid() &&
    moment(to, formats, true).isValid() &&
    moment(to).isSameOrAfter(from);

  return isRelativeDate || validAbsoluteDate;
}
