import {
  BasePoint,
  Editor,
  NodeEntry,
  Path,
  Transforms,
  Element,
  Node,
  Range,
  Operation
} from 'slate';
import {
  createCell,
  createRow,
  divideTableCel,
  getColumnData,
  canInsertRowAtPosition,
  canInsertColumnAtPosition,
  modifyMark
} from '../table.util';

export interface Row extends Element {
  type: 'table-row';
  key: string;
  data: any;
  children: Cell[];
}

export interface TableContent extends Element {
  type: 'table-content';
  children: any[];
}

interface InsertionData {
  tableGrid: Col[][];
  getCol: (match: (node: Col) => boolean) => Col[];
  xPosition: number;
  yPosition: number;
  aboveYIndex: number;
  tableLength: number;
}

export type Col = {
  cell: Cell;
  isReal: boolean;
  path: Path;
  originPath: Path;
  isInsertPosition?: boolean;
};
export interface Cell extends Element {
  type: 'table-cell';
  key: string;
  rowspan?: number;
  colspan?: number;
  width?: number;
  height?: number;
  border?: any;
  selectedCell?: boolean;
  children: Node[];
}

export const withTables = (editor: Editor) => {
  const { addMark, removeMark, insertFragment, apply } = editor;

  editor.addMark = (key: string, value: string) => {
    modifyMark(key, 'add', editor, addMark, value);
  };

  editor.removeMark = (key: string) => {
    modifyMark(key, 'remove', editor, removeMark, null);
  };

  editor.apply = (operation: Operation) => {
    preventTableCellDeletion(editor, operation, apply);
  };

  editor.insertFragment = (fragment) => {
    const containsTable = fragment.some((node) => node['type'] === 'table');
    const selectionInTable = Editor.above(editor, { match: (n) => n['type'] === 'table' });

    if (containsTable && selectionInTable) {
      const newFragment = fragment.flatMap((node) => {
        if (node['type'] === 'table') {
          return node['children'].flatMap((row) => {
            if (row['type'] === 'table-row') {
              return row['children'].flatMap((cell) => {
                if (cell['type'] === 'table-cell') {
                  return cell['children'].filter((content) => content['type'] === 'table-content');
                } else {
                  return [];
                }
              });
            } else {
              return [];
            }
          });
        } else {
          return [node];
        }
      });
      insertFragment(newFragment);
    } else {
      insertFragment(fragment);
    }
  };

  return editor;
};

export function insertTable(editor: Editor, rows: number, cols: number) {
  if (!editor.selection) {
    console.warn('No selection found in the editor.');
    return;
  }

  const node = Editor.above(editor, {
    match: (n) => n['type'] === 'table'
  });

  const isCollapsed = Range.isCollapsed(editor.selection);

  if (!node && isCollapsed) {
    const table = createTable(rows, cols);
    // workaround to avoid not-removable empty space before the table
    if (editor.children.length === 1 && checkIfParagraphEmpty(editor, [0])) {
      Transforms.insertNodes(editor, table, {
        at: [0]
      });
      Transforms.insertNodes(editor, { type: 'paragraph', children: [{ text: '' }] } as Node, {
        at: [1]
      });
    } else if (checkIfParagraphEmpty(editor, [editor.selection.focus.path[0]])) {
      Transforms.insertNodes(editor, table, {
        at: editor.selection.focus.path
      });
      const nextPath = Path.next([editor.selection.focus.path[0]]);
      Transforms.insertNodes(editor, { type: 'paragraph', children: [{ text: '' }] } as Node, {
        at: nextPath
      });
    } else {
      Transforms.insertNodes(editor, table);
    }
  }
}

export function removeTable(editor: Editor) {
  const table = findTable(editor);

  if (!table || !editor) {
    console.warn('No table found in the editor.');
    return;
  }

  Transforms.removeNodes(editor, {
    match: (n) => n['type'] === 'table',
    at: table[1]
  });
}

export function adjustTableCellSize(
  tableCellKeys: string[],
  tableRowKey: string,
  editor: Editor,
  width: number,
  height: number
) {
  if (!editor.selection) {
    console.warn('No selection found in the editor.');
    return;
  }

  // Adjust cell widths
  adjustSize(editor, tableCellKeys, 'table-cell', 'width', width);

  // Adjust row height
  adjustSize(editor, [tableRowKey], 'table-row', 'height', height);
}

export function adjustSize(
  editor: Editor,
  keys: string[],
  nodeType: string,
  dimension: 'width' | 'height',
  size: number
) {
  keys.forEach((key) => {
    const [nodeEntry] = Editor.nodes(editor, {
      match: (n) => n['type'] === nodeType && n['key'] === key,
      at: []
    });

    if (nodeEntry) {
      const [node, path] = nodeEntry;

      Transforms.setNodes(
        editor,
        {
          ...node,
          [dimension]: size
        } as any,
        {
          at: path,
          match: (n) => n['key'] === key
        }
      );
    }
  });
}

export function modifyBorders(editor: Editor, position: string, active: boolean) {
  const { selection } = editor;

  if (!selection) {
    console.warn('No selection found in the editor.');
    return;
  }

  const selectedCells = Array.from(
    Editor.nodes(editor, {
      match: (n) => n['selectedCell'],
      at: []
    })
  );

  if (selectedCells.length > 0) {
    selectedCells.forEach((cell) => updateCellBorder(editor, cell, position, active));
  } else {
    const [cellEntry] = Array.from(
      Editor.nodes(editor, {
        at: selection.focus.path,
        match: (node) => node['type'] === 'table-cell'
      })
    );

    if (!cellEntry) {
      console.warn('No cell found at the current selection.');
      return;
    }

    updateCellBorder(editor, cellEntry, position, active);
  }
}
export function createTable(rows: number, columns: number): any {
  const rowNodes = [...new Array(rows)].map(() => createRow(columns));

  return {
    type: 'table',
    children: rowNodes,
    data: {}
  };
}

export function removeTableElement(editor: Editor, type: string) {
  const table = findTable(editor);
  const { selection } = editor;
  if (!selection || !table) {
    console.warn('No selection found in the editor.');
    return;
  }

  const { tableGrid, getCol } = getColumnData(editor, table);
  const tableLength = table[1].length;
  const [start, end] = Editor.edges(editor, selection);

  const { startNode, endNode } = getStartAndEndNodes(editor, start, end);
  if (!startNode || !endNode) {
    return;
  }

  const { startCol, endCol } = getStartAndEndCols(getCol, startNode, endNode);
  const startRowPath = startCol.path[tableLength];
  const endRowPath = endCol.path[tableLength];
  const startColPath = startCol.path[tableLength + 1];
  const endColPath = endCol.path[tableLength + 1];

  const topLeftRow = tableGrid[startRowPath][0];
  const bottomRightRow = tableGrid[endRowPath][tableGrid[endRowPath].length - 1];
  const topLeftCol = tableGrid[0][startColPath];
  const bottomRightCol = tableGrid[tableGrid.length - 1][endColPath];

  if (type === 'table-row') {
    Transforms.setSelection(editor, {
      anchor: Editor.point(editor, topLeftRow.originPath),
      focus: Editor.point(editor, bottomRightRow.originPath)
    });
  } else {
    Transforms.setSelection(editor, {
      anchor: Editor.point(editor, topLeftCol.originPath),
      focus: Editor.point(editor, bottomRightCol.originPath)
    });
  }
  divideTableCel(table, editor);
  if (type === 'table-row') {
    const removedCells = tableGrid
      .slice(startRowPath, endRowPath + 1)
      .reduce((p: Col[], c: Col[]) => [...p, ...c], []);

    removeCells(editor, table, removedCells);
  } else {
    const removedCells = tableGrid.reduce((p: Col[], c: Col[]) => {
      const cells = c.slice(startColPath, endColPath + 1);
      return [...p, ...cells];
    }, []);

    removeCells(editor, table, removedCells);
  }

  if (type === 'table-row') {
    const path = [selection.focus.path[0], selection.focus.path[1]];
    Transforms.removeNodes(editor, {
      at: path
    });
  } else {
    const rows = Editor.nodes(editor, {
      at: table[1],
      match: (n) => n['type'] === 'table-row'
    }) as any;

    adjustRowspan(editor, table, rows);
  }
}

export function removeSelection(editor: Editor) {
  Transforms.unsetNodes(editor, 'selectedCell', {
    at: [],
    match: (n) => !!n['selectedCell']
  });
}

export function addSelection(editor: Editor, startPath: Path, endPath: Path): Col[] {
  removeSelection(editor);
  const table = findTable(editor);
  if (!table) {
    return [];
  }
  const { tableGrid, getCol } = getColumnData(editor, table);
  if (!getCol || !tableGrid) {
    return [];
  }
  const [head] = getCol(
    (n: Col) => Path.equals(Editor.path(editor, n.originPath), startPath) && n.isReal
  );
  const [tail] = getCol(
    (n: Col) => Path.equals(Editor.path(editor, n.originPath), endPath) && n.isReal
  );

  if (!tail || !head) {
    return [];
  }

  const { path: tailPath } = tail;
  const { path: headPath } = head;

  headPath.forEach((item: number, index: number) => {
    headPath[index] = Math.min(item, tailPath[index]);
    tailPath[index] = Math.max(item, tailPath[index]);
  });

  const selectedCols: Col[] = [];

  tableGrid.forEach((row: Col[]) => {
    row.forEach((col: Col) => {
      const { path } = col;

      const isOver = path.findIndex((item, index) => {
        return item < headPath[index] || item > tailPath[index];
      });

      if (isOver < 0) {
        selectedCols.push(col);
      }
    });
  });

  selectedCols.forEach(({ originPath }) => {
    Transforms.setNodes(
      editor,
      {
        selectedCell: true
      } as any,
      {
        at: originPath,
        match: (n) => n['type'] === 'table-cell'
      }
    );
  });
  return selectedCols;
}

export function getInsertionData(editor: Editor) {
  const table = findTable(editor);
  const { selection } = editor;
  const { tableGrid, getCol } = getColumnData(editor, table);
  const [startCell] = Editor.nodes(editor, {
    match: (n) => n['type'] === 'table-cell'
  });
  const [targetElement] = getCol((c: Col) => c.cell.key === startCell[0]['key'] && c.isReal);
  const tableLength = table[1].length;
  const xPosition = targetElement.path[tableLength + 1];
  const yPosition = targetElement.path[tableLength] + (targetElement.cell.rowspan || 1) - 1;
  const aboveYIndex = targetElement.path[tableLength];

  if (!selection || !table) {
    console.warn('No selection found in the editor.');
    return null;
  }

  return { tableGrid, getCol, xPosition, yPosition, aboveYIndex, tableLength, startCell };
}

export function canInsertTableElement(
  insertionData: InsertionData,
  type: string,
  position: string
) {
  let isInsertable = true;
  let tableMap = new Map<string, Col>();

  if (type === 'table-row') {
    const result = canInsertRowAtPosition(
      insertionData.tableGrid,
      insertionData.getCol,
      insertionData.yPosition,
      insertionData.aboveYIndex,
      type,
      position,
      insertionData.tableLength
    );
    isInsertable = result.isInsertable;
    tableMap = result.tableMap;
  } else if (type === 'table-cell') {
    const result = canInsertColumnAtPosition(
      insertionData.tableGrid,
      insertionData.getCol,
      insertionData.xPosition,
      position,
      insertionData.tableLength
    );
    isInsertable = result.isInsertable;
    tableMap = result.tableMap;
  }

  return { isInsertable, tableMap };
}

export function insertElement(
  editor: Editor,
  type: string,
  position: string,
  tableMap: Map<string, Col>,
  tableLength: number,
  startCell: NodeEntry
) {
  if (type === 'table-cell') {
    tableMap.forEach((col: Col) => {
      const newCell = createCell({
        rowspan: col.cell.rowspan || 1
      });

      Transforms.insertNodes(editor, newCell, {
        at: position === 'left' ? Path.next(col.originPath) : col.originPath
      });
    });
  } else if (type === 'table-row') {
    const newRow = createRow(tableMap.size);
    [...tableMap.values()].forEach((value, index) => {
      newRow.children[index].colspan = value.cell.colspan || 1;
    });

    const [[, path]] = Editor.nodes(editor, {
      match: (n) => n['type'] === 'table-row'
    });

    if (position === 'bottom') {
      for (let i = 1; i < startCell[0]['rowspan']; i++) {
        path[tableLength] += 1;
      }

      Transforms.insertNodes(editor, newRow, {
        at: Path.next(path)
      });
    } else {
      Transforms.insertNodes(editor, newRow, {
        at: path
      });
    }
  }
}

export function insertTableElement(editor: Editor, position: string, type: string) {
  const insertionData = getInsertionData(editor);
  if (!insertionData) {
    return;
  }

  const { isInsertable, tableMap } = canInsertTableElement(
    {
      tableGrid: insertionData.tableGrid,
      getCol: insertionData.getCol,
      xPosition: insertionData.xPosition,
      yPosition: insertionData.yPosition,
      aboveYIndex: insertionData.aboveYIndex,
      tableLength: insertionData.tableLength
    },
    type,
    position
  );

  if (!isInsertable) {
    return;
  }

  insertElement(
    editor,
    type,
    position,
    tableMap,
    insertionData.tableLength,
    insertionData.startCell
  );
}

export function findTable(editor: Editor): NodeEntry | undefined {
  const [match] = Editor.nodes(editor, {
    match: (node: Node) => node['type'] === 'table'
  });
  return match;
}

function getStartAndEndNodes(editor: Editor, start: BasePoint, end: BasePoint) {
  const [startNode] = Editor.nodes(editor, {
    match: (n) => n['type'] === 'table-cell',
    at: start
  });

  const [endNode] = Editor.nodes(editor, {
    match: (n) => n['type'] === 'table-cell',
    at: end
  });

  return { startNode, endNode };
}

function getStartAndEndCols(
  getCol: (match: (node: Col) => boolean) => Col[],
  startNode: NodeEntry,
  endNode: NodeEntry
) {
  const [startCol] = getCol((n: Col) => n.cell.key === startNode[0]['key']);
  const [endCol] = getCol((n: Col) => n.cell.key === endNode[0]['key']);

  return { startCol, endCol };
}

function removeCells(editor: Editor, table: NodeEntry, removedCells: Col[]) {
  removedCells.forEach((col: Col) => {
    // set forceDelete to true to ignore the preventTableCellDeletion check
    Transforms.setNodes(editor, { forceDelete: true } as any, {
      at: table[1],
      match: (n) => n['key'] === col.cell.key
    });

    Transforms.removeNodes(editor, {
      at: table[1],
      match: (n) => n['key'] === col.cell.key
    });
  });
}

function adjustRowspan(editor: Editor, table: NodeEntry, rows: NodeEntry[]) {
  for (const row of rows) {
    let minRowHeight = Infinity;
    row[0]['children'].forEach((cell: Cell) => {
      const { rowspan = 1 } = cell;
      if (rowspan < minRowHeight) {
        minRowHeight = rowspan;
      }
    });

    if (minRowHeight > 1 && minRowHeight < Infinity) {
      row[0]['children'].forEach((cell: Cell) => {
        Transforms.setNodes(
          editor,
          {
            rowspan: (cell.rowspan || 1) - minRowHeight + 1
          } as any,
          {
            at: table[1],
            match: (n) => n['key'] === cell.key
          }
        );
      });
    }
  }
}

function updateCellBorder(editor: Editor, cell: NodeEntry, position: string, active: boolean) {
  const [cellNode, cellPath] = cell;
  Transforms.setNodes(
    editor,
    {
      ...cellNode,
      border: {
        ...cellNode['border'],
        [position]: active
      }
    },
    { at: cellPath }
  );
}

function isParagraphEmpty(node) {
  return Node.string(node).trim() === '';
}

// Example usage: Check if the current node is a paragraph and is empty
function checkIfParagraphEmpty(editor, path) {
  const node = Node.get(editor, path) as any;
  return node.type === 'paragraph' && isParagraphEmpty(node);
}

// Used to prevent deletion of cells when delete key or backspace key is pressed
function preventTableCellDeletion(
  editor: Editor,
  operation: Operation,
  apply: (operation: Operation) => void
) {
  if (operation.type === 'remove_node') {
    const { node } = operation;
    if (node['type'] === 'table-cell' && !node['forceDelete']) {
      return;
    }
  }
  if (operation.type === 'move_node') {
    const node = Editor.node(editor, operation.path)?.[0];
    if ((node && node['type'] === 'table-cell') || node['type'] === 'table-content') {
      return;
    }
  }

  apply(operation);
}
