import { DashboardGridSingletonService } from '../../../../dashboards/services/dashboard-grid-singleton.service';
import { Injectable, ViewContainerRef } from '@angular/core';
import {
  EntryCell,
  Filter,
  FilterCloseType,
  MatchType,
  OpenFilterConfig,
  SearchTerm
} from '../table-view.model';
import { TableFilterComponent } from '../table-filter/table-filter.component';
import { filter, tap } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import {
  DataFormattingInputType,
  ValueFormattingResolvedConfig
} from '../../../data-conditional-formatting/data-conditional-formatting.model';
import { getAbsoluteFromRelative } from '../../../../shared/date-time-range-popover/date-time-range-popover.model';
import { isRelative, RelativePickerValue } from '@inst-iot/bosch-angular-ui-components';
import { PickerValue } from '@inst-iot/bosch-angular-ui-components/atoms/form-date-fields/date-range-picker/date-range-picker.model';
import { editorToPlainText } from '../../../../shared/slate-richtext/slate.util';
import { DataConditionalFormattingPipe } from '../../../data-conditional-formatting/data-conditional-formatting.pipe';
import { isEmpty, flatten } from 'lodash-es';

@Injectable()
export class TableFilterService {
  private filters = new BehaviorSubject<Record<string, Filter[]>>({});
  private data: Record<string, EntryCell[][]> = {};

  globalFilterTerm: string;

  private handlers = {
    [MatchType.startsWith]: (value: string, searchTerm) => value.startsWith(searchTerm),
    [MatchType.contains]: (value: string, searchTerm) => value.includes(searchTerm),
    [MatchType.endsWith]: (value: string, searchTerm) => value.endsWith(searchTerm),
    [MatchType.greaterThan]: (value: string, searchTerm, dataType: DataFormattingInputType) =>
      TableFilterService.compare(value, searchTerm, dataType, '>'),
    [MatchType.lessThan]: (value: string, searchTerm: string, dataType: DataFormattingInputType) =>
      TableFilterService.compare(value, searchTerm, dataType, '<'),
    [MatchType.greaterOrEqualTo]: (value: string, searchTerm, dataType: DataFormattingInputType) =>
      TableFilterService.compare(value, searchTerm, dataType, '>='),
    [MatchType.lessOrEqualTo]: (
      value: string,
      searchTerm: string,
      dataType: DataFormattingInputType
    ) => TableFilterService.compare(value, searchTerm, dataType, '<='),
    [MatchType.isEqualTo]: (value: string, searchTerm, dataType: DataFormattingInputType) =>
      TableFilterService.compare(value, searchTerm, dataType, '==='),
    [MatchType.isNotEqualTo]: (value: string, searchTerm, dataType: DataFormattingInputType) =>
      TableFilterService.compare(value, searchTerm, dataType, '!=='),
    [MatchType.isEmpty]: (value) => !value,
    [MatchType.isNotEmpty]: (value) => !!value,
    [MatchType.isInRange]: (value, range: [string, string]) =>
      new Date(value).getTime() >= new Date(range[0]).getTime() &&
      new Date(value).getTime() <= new Date(range[1]).getTime(),
    [MatchType.isInArray]: (value: string, searchTerm, dataType: DataFormattingInputType) => {
      if (searchTerm === '') {
        return true;
      }
      return (searchTerm as string[]).some((expectedStr) =>
        TableFilterService.compare(expectedStr, value, dataType, '===')
      );
    }
  };

  private multipleValueHandlers = {
    ...this.handlers,
    [MatchType.isEmpty]: (value) => isEmpty(value)
  };

  constructor(private dataConditionalFormattingPipe: DataConditionalFormattingPipe) {}

  filter(entries: EntryCell[][], path: string): EntryCell[][] {
    const columnFilters = this.filtersByPath(path);
    this.setPathFilters(path, columnFilters);

    // filter the entries using the global filter and then by the column ones
    const filteredEntries = this.applyGlobalFilter(entries).filter((entry) =>
      columnFilters.every((f) => this.filterEntry(entry, f))
    );

    this.data[path] = filteredEntries;
    return filteredEntries;
  }

  filteredData(path: string, entries: EntryCell[][] = [], fromCache = true): EntryCell[][] {
    if (!fromCache) {
      return this.filter(entries, path);
    }
    return this.data[path] || this.filter(entries, path);
  }

  openFilter(
    { anchor, path, key, keyIndex, entries, dataType }: OpenFilterConfig,
    viewContainerRef: ViewContainerRef,
    closeFilterCallback: (filteredData: EntryCell[][]) => void
  ) {
    const popoverPosition = 'bottom';
    const popoverRef = viewContainerRef.createComponent(TableFilterComponent);
    DashboardGridSingletonService.overrideGridsterStyles(viewContainerRef);
    popoverRef.instance.id = `${key.replace(' ', '-').toLowerCase()}-${keyIndex}`;
    popoverRef.instance.primaryPos = popoverPosition;
    popoverRef.instance.anchor = anchor;
    popoverRef.instance.distinctValues = this.getDistinctValues(entries, key);
    popoverRef.instance.matchType = this.filterByKey(path, key)?.matchType || MatchType.contains;
    popoverRef.instance.searchTerm = this.filterByKey(path, key)?.searchTerm || '';
    popoverRef.instance.dataType = dataType;

    popoverRef.instance.close
      .pipe(
        tap(() => {
          popoverRef.destroy();
          setTimeout(() => {
            // Checks if the popover is still open and override gridster overflow and z-index
            if (!document.querySelector('.rb-popover.open')) {
              DashboardGridSingletonService.overrideGridsterStyles(viewContainerRef, true);
            }
          }, 0);
        }),
        filter(({ type }) => type !== FilterCloseType.close)
      )
      .subscribe((payload) => {
        if (payload.type === FilterCloseType.applyFilter) {
          this.addFilter(path, {
            matchType: payload.filter.matchType,
            searchTerm: payload.filter.searchTerm,
            key,
            keyIndex,
            path,
            dataType
          });
        } else if (payload.type === FilterCloseType.clearFilter) {
          this.removeFilter(path, key);
        }

        closeFilterCallback(this.filter(entries, path));
      });

    viewContainerRef.element.nativeElement.classList.add('open');
  }

  filterByKey(path: string, key): Filter {
    return this.filtersByPath(path)?.find((f) => f.key === key);
  }

  filtersByPath(path: string): Filter[] {
    return this.filters.value[path] || [];
  }

  removeFilter(path: string, key: string) {
    this.setPathFilters(
      path,
      this.filtersByPath(path).filter((f) => f.key !== key)
    );
  }

  addFilter(path: string, columnFilter: Filter) {
    // filter without the one currently being closed
    const currentFilters = this.filtersByPath(path).filter((f) => f.key !== columnFilter.key);

    this.setPathFilters(path, [...currentFilters, columnFilter]);
  }

  removeAllFilters(path: string) {
    this.setPathFilters(path, []);
    this.data[path] = [];
  }

  setFilters(filters: Record<string, Filter[]>) {
    this.filters.next(filters);
    this.data = {};
  }

  setPathFilters(path: string, filters: Filter[]) {
    this.filters.next({
      ...this.filters.value,
      [path]: filters
    });
  }

  applyGlobalFilter(entries: EntryCell[][]) {
    if (!this.globalFilterTerm) {
      return entries;
    }

    return entries.filter((entry) =>
      entry.some((cell) =>
        this.filterEntryColumn(cell, this.globalFilterTerm.trim().toLowerCase(), MatchType.contains)
      )
    );
  }

  get filters$() {
    return this.filters;
  }

  private filterEntry(entry: EntryCell[], columnFilter: Filter): boolean {
    const entryColumn = entry[columnFilter.keyIndex];
    if (!entryColumn) {
      this.removeFilter(columnFilter.path, columnFilter.key);
      return true;
    }
    const searchTerm = TableFilterService.getSearchTerm(
      columnFilter.searchTerm,
      columnFilter.dataType
    );

    return this.filterEntryColumn(
      entryColumn,
      searchTerm,
      columnFilter.matchType,
      columnFilter.dataType
    );
  }

  private filterEntryColumn(
    entryColumn: EntryCell,
    searchTerm: SearchTerm,
    matchType: MatchType,
    dataType?: DataFormattingInputType
  ) {
    const valueFormattingConfig = entryColumn?.valueFormattingConfig;
    const handler = this.handlers[matchType];

    switch (entryColumn.type) {
      case 'atomic':
      case 'undefined': {
        const value = this.getValueForApplyingFilter(
          entryColumn.value,
          matchType,
          entryColumn.valueFormattingConfig
        );

        // if value formatter is applied, and if the multi selector filter is active, then a date value is eventually represented as a string. Hence the filter must ignore the original dataType.
        const consideredDataType =
          valueFormattingConfig?.dataType && matchType === MatchType.isInArray
            ? DataFormattingInputType.STRING
            : dataType;
        return handler(value, searchTerm, consideredDataType);
      }
      case 'array': {
        if (valueFormattingConfig?.multipleValues) {
          const value = entryColumn.value
            .filter(({ type }) => type === 'atomic')
            .map((v) =>
              this.getValueForApplyingFilter(v.value, matchType, entryColumn.valueFormattingConfig)
            );

          return this.handleMultipleValues(value, searchTerm, dataType, matchType);
        } else {
          return entryColumn.value
            .filter(({ type }) => type === 'atomic')
            .some(({ value }) => handler(value?.toString()?.toLowerCase(), searchTerm, dataType));
        }
      }
      case 'button': {
        const value = this.getValueForApplyingFilter(entryColumn.value?.buttonLabel, matchType);

        return handler(value, searchTerm, dataType);
      }
      case 'link': {
        const value = this.getValueForApplyingFilter(entryColumn.value?.label, matchType);

        return handler(value, searchTerm, dataType);
      }
      case 'richText': {
        const editorString = editorToPlainText(entryColumn.value.editor) || '';
        const value = this.getValueForApplyingFilter(editorString, matchType);

        return handler(value, searchTerm, dataType);
      }
      default: {
        return false;
      }
    }
  }

  private handleMultipleValues(
    array: [],
    searchTerm: SearchTerm,
    consideredDataType: DataFormattingInputType,
    matchType: MatchType
  ) {
    const handler = this.multipleValueHandlers[matchType];
    if (matchType === MatchType.isEmpty) {
      return handler(array, searchTerm, consideredDataType);
    } else if (matchType === MatchType.isNotEqualTo) {
      return array.every((v) => handler(v, searchTerm, consideredDataType));
    }
    return array.some((v) => handler(v, searchTerm, consideredDataType));
  }

  private static getSearchTerm(
    searchTerm: SearchTerm,
    dataType: DataFormattingInputType
  ): SearchTerm {
    if (dataType === 'datetime') {
      if (isRelative(searchTerm as PickerValue)) {
        searchTerm = getAbsoluteFromRelative(searchTerm as RelativePickerValue, false);
      }
      return searchTerm;
    } else if (Array.isArray(searchTerm)) {
      // for the multi selector, the filter value is shown as it is. So they remain case sensitive.
      return searchTerm.map((term) => (term?.trim ? term.trim() : term));
    } else {
      return searchTerm?.trim().toLowerCase();
    }
  }

  private static compare(
    value: string,
    searchTerm: string,
    dataType: DataFormattingInputType,
    operator: string
  ): boolean {
    let rowValue: string | number = value;
    let search: string | number = searchTerm;

    if (dataType === 'datetime') {
      rowValue = new Date(value).getTime();
      search = new Date(searchTerm).getTime();
    } else if (dataType === 'number') {
      rowValue = Number(value);
      search = Number(searchTerm);
    }

    switch (operator) {
      case '>':
        return rowValue > search;
      case '<':
        return rowValue < search;
      case '>=':
        return rowValue >= search;
      case '<=':
        return rowValue <= search;
      case '===':
        return rowValue === search;
      case '!==':
        return rowValue !== search;
      default:
        return false;
    }
  }

  private getDistinctValues(entries: EntryCell[][], key: string) {
    let isArrayType = false;
    let allValues = entries
      .map((x) => x.find((y) => y.path.indexOf(key) === 0))
      .filter((x) => x)
      .map((x) => {
        switch (x.type) {
          case 'link':
            return x.value.label;
          case 'button':
            return x.value.buttonLabel;
          case 'richText':
            return editorToPlainText(x.value.editor) || '';
          case 'array':
            isArrayType = true;
            return this.convertCellToArray(x);
          default:
            return this.dataConditionalFormattingPipe.transform(x.value, x.valueFormattingConfig);
        }
      });

    if (isArrayType && allValues?.length) {
      allValues = flatten(allValues).filter((x) => x);
    }
    // return only unique items, null and undefined at the end
    return [...new Set(allValues)].sort((a, b) => {
      if (a === null) {
        return 1;
      }
      if (b === null) {
        return -1;
      }
      if (typeof a === 'string' && typeof b === 'string') {
        return a.toLowerCase().localeCompare(b.toLowerCase());
      } else if (typeof a === 'number') {
        return a - b;
      } else {
        return a;
      }
    });
  }

  private convertCellToArray(cell: EntryCell) {
    return cell.value
      .filter((x) => x.type !== 'object')
      .map((el: EntryCell) =>
        this.dataConditionalFormattingPipe.transform(el.value, el.valueFormattingConfig)
      );
  }

  private getValueForApplyingFilter(
    value: any,
    matchType: MatchType,
    valueFormattingConfig?: ValueFormattingResolvedConfig
  ) {
    let formattedValue = value;

    if (
      valueFormattingConfig &&
      valueFormattingConfig.dataType !== 'json' &&
      (valueFormattingConfig.dataType !== 'number' || matchType === MatchType.isInArray)
    ) {
      formattedValue = this.dataConditionalFormattingPipe.transform(value, valueFormattingConfig);
    }

    // For isInArray case, the dropdown shows exact values without converting it to lower case. Hence the comparion must also not convert the value to lower case.
    return matchType === MatchType.isInArray
      ? typeof formattedValue === 'string'
        ? formattedValue.trim()
        : formattedValue
      : formattedValue?.toString().toLowerCase().trim() || '';
  }
}
