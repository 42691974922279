import { Pipe, PipeTransform } from '@angular/core';
import { TableTooltip } from '../table-view.model';

@Pipe({
  name: 'tooltipContent'
})
export class TooltipContentPipe implements PipeTransform {
  transform(tooltip: TableTooltip, tooltipArrayValue?: any): string {
    if (tooltipArrayValue) {
      const tooltipArrayString = JSON.stringify(tooltip.array[0]);
      return tooltip.text.replace(tooltipArrayString, tooltipArrayValue);
    }

    return tooltip?.text || '';
  }
}
