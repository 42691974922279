import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { HyperLinkInfo } from '../../plugins/link';

export enum LinkMode {
  EDIT = 'EDIT',
  CREATE = 'CREATE'
}

@Component({
  selector: 'link-popover',
  templateUrl: 'link-popover.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LinkPopoverComponent {
  @Output() closePopover = new EventEmitter();

  @Output() updateLink = new EventEmitter<HyperLinkInfo>();

  @Output() removeLink = new EventEmitter();

  @Input() linkInfo: HyperLinkInfo;

  @Input() mode = LinkMode.CREATE;

  public close() {
    this.closePopover.emit();
  }
}
