import { createEditor, Editor, Element, Node, NodeEntry, Path, Text, Transforms } from 'slate';
import { isPlainObject } from 'lodash-es';
import { SafeHtml } from '@angular/platform-browser';
import isHotkey from 'is-hotkey';
import { TemplateRef } from '@angular/core';
import { withImages } from './plugins/image';
import { LinkResolver, withInlines } from './plugins/link';
import { ReferenceResolver, ResolvedEditorElement, withReferences } from './plugins/reference';
import { withHistory } from 'slate-history';
import { withAngular } from 'slate-angular';
import { DataConditionalFormattingPipe } from '../../shared-modules/data-conditional-formatting/data-conditional-formatting.pipe';
import {
  resolveStringWithModifiers,
  resolveValueWithModifiersForSlate
} from '../../shared-modules/data-widgets/data-widgets-common';
import {
  insertTableElement,
  modifyBorders,
  removeTable,
  removeTableElement,
  withTables
} from './plugins/table';
import { translate } from '../translation-util';

export const LIST_TYPES = ['numbered-list', 'bulleted-list'];
export const LIST_ITEMS = ['list-item'];
export const TEXT_ALIGN_TYPES = ['left', 'center', 'right', 'justify'];

export const richTextTableButtons = [
  {
    action: 'toggleBorder',
    position: 'top',
    imgSrc: 'assets/img/icons/border-top.svg',
    label: translate('slateEditor.toolbar.borderTop')
  },
  {
    action: 'toggleBorder',
    position: 'right',
    imgSrc: 'assets/img/icons/border-right.svg',
    label: translate('slateEditor.toolbar.borderRight')
  },
  {
    action: 'toggleBorder',
    position: 'bottom',
    imgSrc: 'assets/img/icons/border-bottom.svg',
    label: translate('slateEditor.toolbar.borderBottom')
  },
  {
    action: 'toggleBorder',
    position: 'left',
    imgSrc: 'assets/img/icons/border-left.svg',
    label: translate('slateEditor.toolbar.borderLeft')
  },
  {
    action: 'toggleBorder',
    position: 'all',
    imgSrc: 'assets/img/icons/border-all.svg',
    label: translate('slateEditor.toolbar.setAllBorders')
  },
  {
    action: 'toggleBorder',
    position: 'none',
    imgSrc: 'assets/img/icons/border-none.svg',
    label: translate('slateEditor.toolbar.removeAllBorders')
  },
  {
    action: 'table-row',
    position: 'top',
    iconClass: 'rb-ic rb-ic-table-row-add-above',
    label: translate('slateEditor.toolbar.tableRowAddAbove')
  },
  {
    action: 'table-row',
    position: 'bottom',
    iconClass: 'rb-ic rb-ic-table-row-add-below',
    label: translate('slateEditor.toolbar.tableRowAddBelow')
  },
  {
    action: 'table-cell',
    position: 'right',
    iconClass: 'rb-ic rb-ic-table-column-add-before',
    label: translate('slateEditor.toolbar.tableColumnBefore')
  },
  {
    action: 'table-cell',
    position: 'left',
    iconClass: 'rb-ic rb-ic-table-column-add-after',
    label: translate('slateEditor.toolbar.tableColumnAfter')
  },
  {
    action: 'removeRow',
    iconClass: 'rb-ic rb-ic-table-row-delete',
    label: translate('slateEditor.toolbar.tableDeleteRow')
  },
  {
    action: 'removeColumn',
    imgSrc: 'assets/img/icons/delete-column.svg',
    label: translate('slateEditor.toolbar.tableDeleteColumn')
  },
  {
    action: 'removeTable',
    imgSrc: 'assets/img/icons/delete-table.svg',
    label: translate('slateEditor.toolbar.deleteTable')
  }
];

export type EmptyText = {
  text: string;
};
export enum Position {
  top = 'top',
  right = 'right',
  bottom = 'bottom',
  left = 'left',
  all = 'all',
  none = 'none'
}

export interface ImageElement extends EditorElement {
  type: 'image';
  url: string | ArrayBuffer;
}

export interface TableButton {
  action: string;
  position?: Position;
  imgSrc?: string;
  iconClass?: string;
  label: string;
}

export interface LinkElement extends EditorElement {
  tooltip?: string;
  type: 'link';
  url: string | ArrayBuffer;
  target?: string;
}

export enum MarkTypes {
  bold = 'bold',
  italic = 'italic',
  underline = 'underlined',
  strike = 'strike',
  code = 'code-line',
  fontColor = 'font-color',
  fontBgColor = 'font-bg-color',
  fontSize = 'font-size',
  padding = 'padding',
  border = 'border'
}

export const HOTKEYS = {
  'mod+b': MarkTypes.bold,
  'mod+i': MarkTypes.italic,
  'mod+u': MarkTypes.underline,
  'mod+`': MarkTypes.strike
};

export interface ToolbarElement {
  title: string;
  format: string;
  icon?: string;
  hidden?: boolean;
  // will be sanitized onInit
  iconHtml?: SafeHtml;
  active?: (format: string, blockType?: string) => boolean;
  action?: (format: string) => void;
  // if true, there will be some space between the next item
  break?: boolean;
  templateRef?: TemplateRef<any>;
  group: ToolbarElementGroup;
}

export enum ToolbarElementGroup {
  text = 'text',
  quote = 'quote',
  heading = 'heading',
  list = 'list',
  indent = 'indent',
  color = 'color',
  table = 'table',
  alignment = 'alignment',
  reset = 'reset',
  linkAndImage = 'linkAndImage'
}

export interface EditorElement extends Element {
  type?: string;
  align?: string;
  indent?: number;
  padding?: boolean;
}

declare type EditorNode = Editor | EditorElement | Text;

export function createEditorInstance() {
  return withReferences(
    withImages(withTables(withInlines(withHistory(withAngular(createEditor())))))
  );
}

// most examples are copied from the official examples: https://github.com/ianstormtaylor/slate/blob/main/site/examples/richtext.tsx
// and from the examples of the angular port: https://github.com/worktile/slate-angular/blob/master/demo/app/richtext/richtext.component.ts
export function isElement(value: any): value is Element {
  return isPlainObject(value) && Node.isNodeList(value.children) && !Editor.isEditor(value);
}

export function overwriteMark(editor: Editor, format: string, value: string) {
  const [parentNode, path] = editor.selection
    ? Editor.parent(editor, editor.selection.focus?.path)
    : [];

  if (parentNode && editor.isVoid(parentNode)) {
    const markTypes = parentNode['markTypes'] ? { ...parentNode['markTypes'] } : {};
    markTypes[format] = value;

    Transforms.setNodes<EditorElement>(editor, { markTypes } as Partial<EditorElement>, {
      at: path
    });
  } else {
    Editor.addMark(editor, format, value || true);
  }
}

export function calculateMarkStyles(textElement) {
  const styles = {};
  if (textElement[MarkTypes.fontColor]) {
    styles['color'] = textElement[MarkTypes.fontColor];
  }
  if (textElement[MarkTypes.fontBgColor]) {
    styles['background-color'] = textElement[MarkTypes.fontBgColor];
  }
  if (textElement[MarkTypes.fontSize]) {
    styles['font-size'] = textElement[MarkTypes.fontSize] + 'px';
  }
  if (textElement[MarkTypes.padding]) {
    styles['padding-inline'] = textElement[MarkTypes.padding];
  }
  return styles;
}

export function isMarkActive(editor: Editor, format: string): boolean {
  const marks = getElementMarks(editor);
  return marks ? marks[format] !== undefined : false;
}

export function getElementMarks(editor: Editor) {
  if (editor.selection) {
    const [parentNode] = Editor.parent(editor, editor.selection?.focus?.path);
    if (editor.isVoid(parentNode)) {
      return parentNode['markTypes'] || {};
    }
  }

  return Editor.marks(editor);
}

export function toggleMark(editor: Editor, format: string) {
  const isActive = isMarkActive(editor, format);
  const [parentNode, path] = Editor.parent(editor, editor.selection?.focus?.path);

  if (editor.isVoid(parentNode)) {
    toggleVoidMark(editor, format, parentNode, path);
  } else if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, true);
  }
}

function toggleVoidMark(editor: Editor, format: string, node: Node, path: Path) {
  const markTypes = node['markTypes'] ? { ...node['markTypes'] } : {};

  if (markTypes[format]) {
    delete markTypes[format];
  } else {
    markTypes[format] = true;
  }

  Transforms.setNodes<EditorElement>(editor, { markTypes } as Partial<EditorElement>, {
    at: path
  });
}

export function isBlockActive(editor: Editor, format: string, blockType = 'type'): boolean {
  return !!getBlockMatches(editor, format, blockType);
}

function getBlockMatches(
  editor: Editor,
  format: string,
  blockType = 'type',
  matchFctn = (node) => node[blockType] === format
): NodeEntry<EditorNode> {
  const { selection } = editor;
  if (!selection) {
    return undefined;
  }

  const [match] = Array.from(
    Editor.nodes(editor, {
      at: Editor.unhangRange(editor, selection),
      match: (node) => {
        return !Editor.isEditor(node) && isElement(node) && matchFctn(node);
      }
    })
  );
  return match;
}

export function updateIndent(editor: Editor, format: string): void {
  const blockMatches = getBlockMatches(
    editor,
    format,
    'indent',
    (node) => node['indent'] !== undefined
  );
  const increment = format === 'indent';
  let indent = 0;
  if (blockMatches) {
    indent = (
      blockMatches.find((elem: EditorElement) => elem.indent !== undefined) as EditorElement
    ).indent;
    indent = increment ? indent + 1 : indent - 1;
  } else if (increment) {
    indent++;
  }
  const newProperties: Partial<EditorElement> = {
    indent: indent <= 0 ? undefined : indent
  };

  Transforms.unwrapNodes(editor, {
    match: (n) => {
      return (
        !Editor.isEditor(n) &&
        isElement(n) &&
        LIST_TYPES.includes(n['type']) &&
        LIST_ITEMS.includes(n['type']) &&
        !TEXT_ALIGN_TYPES.includes(format)
      );
    },
    split: true
  });
  Transforms.setNodes<EditorElement>(editor, newProperties);
}

export function resolveIndent(element: EditorElement): string {
  return !element.indent ? '' : element.indent * 3 + 'em';
}

export function toggleBlock(editor: Editor, format: string) {
  const isActive = isBlockActive(
    editor,
    format,
    TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type'
  );
  const isList = LIST_TYPES.includes(format);

  let newProperties: Partial<EditorElement>;
  if (TEXT_ALIGN_TYPES.includes(format)) {
    newProperties = {
      align: isActive ? undefined : format
    };
  } else {
    newProperties = {
      type: isActive ? 'paragraph' : isList ? 'list-item' : format
    };
  }
  updateNodes(editor, format, newProperties);

  if (!isActive && isList) {
    const block = { type: format, children: [] };
    Transforms.wrapNodes(editor, block);
  }
}

function updateNodes(editor: Editor, format: string, newProperties: Partial<EditorElement>) {
  Transforms.unwrapNodes(editor, {
    match: (n) => {
      return (
        !Editor.isEditor(n) &&
        isElement(n) &&
        LIST_TYPES.includes(n['type']) &&
        !TEXT_ALIGN_TYPES.includes(format)
      );
    },
    split: true
  });

  Transforms.setNodes<EditorElement>(editor, newProperties);
}

export function hotkeyKeydown(editor: Editor, event: KeyboardEvent): void {
  for (const hotkey in HOTKEYS) {
    if (isHotkey(hotkey, event as any)) {
      event.preventDefault();
      const mark = HOTKEYS[hotkey];
      toggleMark(editor, mark);
    }
  }
}

export function resetFormatting(editor: Editor) {
  if (editor.selection) {
    const newProperties = {
      align: null,
      indent: null,
      markTypes: null
    };

    const [node, path] = Editor.parent(editor, editor.selection.focus?.path);

    Object.values(MarkTypes).forEach((markKey) => {
      editor.removeMark(markKey);
    });

    if (
      node['type'] === 'heading-one' ||
      node['type'] === 'heading-two' ||
      node['type'] === 'block-quote'
    ) {
      newProperties['type'] = 'paragraph';
    }

    Transforms.setNodes<EditorElement>(editor, newProperties, { at: path });
  }
}

export function editorToPlainText(nodes: Node[]) {
  return nodes.map((n) => Node.string(n)).join('\n');
}

export function resolveSlateNodesWithIteratorIndex(
  editorElements: EditorElement[],
  context: any,
  dataSource: any,
  dataConditionalFormattingPipe: DataConditionalFormattingPipe
): ResolvedEditorElement[] {
  const entries: ResolvedEditorElement[] = [];
  if (!editorElements) {
    return entries;
  }

  dataSource.forEach((ds, idx) => {
    entries.push(
      resolveSlateNodesWithFixedIndex(
        editorElements,
        context,
        dataSource,
        dataConditionalFormattingPipe,
        idx
      )
    );
  });

  return entries;
}

export function resolveSlateNodesWithFixedIndex(
  editorElements: EditorElement[],
  context: any,
  dataSource: any,
  dataConditionalFormattingPipe: DataConditionalFormattingPipe,
  idx = 0
): ResolvedEditorElement {
  const result: ResolvedEditorElement = {
    editor: editorElements,
    editorInstance: createEditorInstance()
  };
  [
    new LinkResolver(dataSource, context, resolveStringWithModifiers),
    new ReferenceResolver(
      dataSource,
      context,
      resolveValueWithModifiersForSlate,
      dataConditionalFormattingPipe
    )
  ].forEach((resolver) => {
    result.editor = resolver.resolveSlateNodesWithFixedIndex(result.editor, idx);
  });

  return result;
}

export function performTableEditAction(editor: Editor, action: string, position?: Position) {
  switch (action) {
    case 'toggleBorder':
      if (position === 'all') {
        ['top', 'right', 'bottom', 'left'].forEach((pos) => modifyBorders(editor, pos, true));
      } else if (position === 'none') {
        ['top', 'right', 'bottom', 'left'].forEach((pos) => modifyBorders(editor, pos, false));
      } else {
        modifyBorders(editor, position, true);
      }
      break;
    case 'table-row':
    case 'table-cell':
      insertTableElement(editor, position, action);
      break;
    case 'removeTable':
      removeTable(editor);
      break;
    case 'removeColumn':
      removeTableElement(editor, 'table-column');
      break;
    case 'removeRow':
      removeTableElement(editor, 'table-row');
      break;
    default:
      console.warn(`Unknown action: ${action}`);
  }
}
