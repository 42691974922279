<rb-board-item [statusIcon]="statusIcon" [class.rb-success]="valid" [class.rb-danger]="!valid">
  <div *rbBoardItemBody>
    <button
      class="toggleLink large-toggleLink rb-btn rb-link rb-details-toggle"
      rbDetailsToggle
      #triggerDetails="rbDetailsToggle"
      data-cy="widgetSettingItemToggleButton"
      (isOpenChange)="openChanged($event)"
      [isOpen]="isOpen"
    >
      <rb-render-tpl [tpl]="label"></rb-render-tpl>
    </button>

    <div class="pl-3 hint-label">
      <rb-render-tpl [tpl]="hint"></rb-render-tpl>
    </div>

    <div class="mt-2 ml-3" *ngIf="triggerDetails.open" data-cy="widgetSettingItemContent">
      <ng-content></ng-content>
    </div>
  </div>
</rb-board-item>

<ng-template #statusIcon>
  <span
    class="rb-ic font-weight-bold"
    [class.rb-ic-checkmark]="valid"
    [class.rb-ic-alert-error]="!valid"
  ></span>
</ng-template>
