<div [ngClass]="customClass">
  <collection-selector
    class="mr-1"
    [title]="'dynamicFilter.collection' | translate"
    [selectedCollection]="value?.collection"
    (selectedCollectionChange)="collection = $event?.name"
    [allowedCollectionTypes]="[collectionTypes.ProcessedData]"
    [showCollectionCategoryExplanation]="false"
    [showRetentionTime]="false"
  ></collection-selector>

  <rb-form-input
    #dataIdControl
    label="Id"
    name="id"
    [(ngModel)]="dataId"
    [pattern]="pattern"
    [required]="requiredId"
  >
    <ng-template rbFormValidationMessage="pattern">
      {{ 'dynamicFilter.invalidId' | translate }}
    </ng-template>
  </rb-form-input>
</div>
