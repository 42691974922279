<ng-container *ngIf="cell.value.length <= 10">
  <ng-container
    *ngIf="
      !cell.valueFormattingConfig.arrayValueSeparator ||
      cell.valueFormattingConfig.arrayValueSeparator === arrayValueSeparator.LINE_BREAK
    "
  >
    <div
      *ngFor="let c of cell.value; let i = index; let last = last"
      class="mb-3"
      [class.mb-0]="last"
    >
      <ng-container *ngIf="c.type === 'atomic'">
        <span
          [attr.data-cy]="'arrayValueSeparator-' + cell.valueFormattingConfig.arrayValueSeparator"
          [style.background-color]="c.backgroundColor"
          [style.color]="c.textColor"
          [ngClass]="{
            'text-background-rounded with-margin-bottom with-padding-y': c.backgroundColor
          }"
          [class.px-2]="c.backgroundColor && cell.valueFormattingConfig.automaticPadding"
          [rbTooltip]="cell.tooltip | tooltipContent: tooltipArray[i]"
          [hideTooltipIfContentIsFalsey]="true"
        >
          {{ c.value | dataConditionalFormattingPipe: cell.valueFormattingConfig }}
        </span>
      </ng-container>
      <ng-container *ngIf="c.type === 'array' && !c.valueFormattingConfig.hidden">
        <span
          [rbTooltip]="cell.tooltip | tooltipContent: tooltipArray[i]"
          [hideTooltipIfContentIsFalsey]="true"
        >
          [Array({{ c.value.length }})]
        </span>
      </ng-container>
      <ng-container *ngIf="c.type === 'object' && !c.valueFormattingConfig.hidden">
        <button
          [rbTooltip]="cell.tooltip | tooltipContent: tooltipArray[i]"
          [hideTooltipIfContentIsFalsey]="true"
          class="rb-btn rb-link"
          (click)="updatePath.emit(c.path)"
        >
          [Object]
        </button>
      </ng-container>
    </div>
  </ng-container>

  <ng-container
    *ngIf="
      cell.valueFormattingConfig.arrayValueSeparator === arrayValueSeparator.COMMA ||
      cell.valueFormattingConfig.arrayValueSeparator === arrayValueSeparator.SEMICOLON ||
      cell.valueFormattingConfig.arrayValueSeparator === arrayValueSeparator.WHITESPACE
    "
  >
    <div
      class="d-inline-block"
      [attr.data-cy]="'arrayValueSeparator-' + cell.valueFormattingConfig.arrayValueSeparator"
      *ngFor="let c of cell.value; let i = index"
    >
      <ng-container *ngIf="i > 0 && !c.valueFormattingConfig.hidden">
        <ng-container
          *ngIf="cell.valueFormattingConfig.arrayValueSeparator === arrayValueSeparator.COMMA"
        >
          <span>,</span>
        </ng-container>
        <ng-container
          *ngIf="cell.valueFormattingConfig.arrayValueSeparator === arrayValueSeparator.SEMICOLON"
        >
          <span>;</span>
        </ng-container>
        <ng-container
          *ngIf="cell.valueFormattingConfig.arrayValueSeparator === arrayValueSeparator.WHITESPACE"
        >
          <span>&nbsp;</span>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="c.type === 'atomic'">
        <span
          class="d-inline-block mb-1"
          *ngIf="!c.valueFormattingConfig.hidden"
          [style.background-color]="c.backgroundColor"
          [style.color]="c.textColor"
          [ngClass]="{ 'text-background-rounded with-padding-y': c.backgroundColor }"
          [rbTooltip]="cell.tooltip | tooltipContent: tooltipArray[i]"
          [hideTooltipIfContentIsFalsey]="true"
          [class.px-2]="c.backgroundColor && cell.valueFormattingConfig.automaticPadding"
        >
          {{ c.value | dataConditionalFormattingPipe: cell.valueFormattingConfig }}
        </span>

        <div
          *ngIf="c.valueFormattingConfig.hidden"
          [style.background-color]="c.backgroundColor"
          [style.color]="c.textColor"
          [ngClass]="{ 'text-background-rounded with-padding-y': c.backgroundColor }"
          [class.px-2]="c.backgroundColor && cell.valueFormattingConfig.automaticPadding"
        >
          <span class="invisible">{{ c.value }}</span>
        </div>
      </ng-container>
      <ng-container *ngIf="c.type === 'array' && !c.valueFormattingConfig.hidden">
        <span
          [rbTooltip]="cell.tooltip | tooltipContent: tooltipArray[i]"
          [hideTooltipIfContentIsFalsey]="true"
        >
          [Array({{ c.value.length }})]
        </span>
      </ng-container>
      <ng-container *ngIf="c.type === 'object' && !c.valueFormattingConfig.hidden">
        <button
          class="rb-btn rb-link"
          [rbTooltip]="cell.tooltip | tooltipContent: tooltipArray[i]"
          [hideTooltipIfContentIsFalsey]="true"
          (click)="updatePath.emit(c.path)"
        >
          [Object]
        </button>
      </ng-container>
    </div>
  </ng-container>
  <ul
    class="p-0"
    [attr.data-cy]="'arrayValueSeparator-' + cell.valueFormattingConfig.arrayValueSeparator"
    *ngIf="cell.valueFormattingConfig.arrayValueSeparator === arrayValueSeparator.LIST_BASED"
  >
    <li *ngFor="let c of cell.value; let i = index" class="mb-2">
      <ng-container *ngIf="c.type === 'atomic'">
        <span
          *ngIf="!c.valueFormattingConfig.hidden"
          [style.background-color]="c.backgroundColor"
          [style.color]="c.textColor"
          [ngClass]="{ 'text-background-rounded with-padding-y': c.backgroundColor }"
          [rbTooltip]="cell.tooltip | tooltipContent: tooltipArray[i]"
          [hideTooltipIfContentIsFalsey]="true"
          [class.px-2]="c.backgroundColor && cell.valueFormattingConfig.automaticPadding"
        >
          {{ c.value | dataConditionalFormattingPipe: cell.valueFormattingConfig }}
        </span>

        <div
          class="d-inline-block"
          *ngIf="c.valueFormattingConfig.hidden"
          [style.background-color]="c.backgroundColor"
          [style.color]="c.textColor"
          [class.px-2]="c.backgroundColor && cell.valueFormattingConfig.automaticPadding"
          [ngClass]="{ 'text-background-rounded with-padding-y': c.backgroundColor }"
        >
          <span class="invisible">{{ c.value }}</span>
        </div>
      </ng-container>
      <ng-container *ngIf="c.type === 'array' && !c.valueFormattingConfig.hidden">
        <span
          [rbTooltip]="cell.tooltip | tooltipContent: tooltipArray[i]"
          [hideTooltipIfContentIsFalsey]="true"
        >
          [Array({{ c.value.length }})]
        </span>
      </ng-container>
      <ng-container *ngIf="c.type === 'object' && !c.valueFormattingConfig.hidden">
        <button
          class="rb-btn rb-link"
          (click)="updatePath.emit(c.path)"
          [rbTooltip]="cell.tooltip | tooltipContent: tooltipArray[i]"
          [hideTooltipIfContentIsFalsey]="true"
        >
          [Object]
        </button>
      </ng-container>
    </li>
  </ul>
</ng-container>

<ng-container *ngIf="cell.value.length > 10 && !cell.valueFormattingConfig.hidden">
  <button
    class="rb-btn rb-link"
    (click)="updatePath.emit(cell.path)"
    [rbTooltip]="cell.tooltip | tooltipContent"
    [hideTooltipIfContentIsFalsey]="true"
  >
    [Array({{ cell.value.length }})]
  </button>
</ng-container>
