import { Directive } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';
import { filterParamsOrDataPlaceholderRegex } from '../services/simple-search-input-path.service';

@Directive({
  selector: '[manualPropertyPathValidator]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: ManualPropertyPathValidatorDirective, multi: true }
  ]
})
export class ManualPropertyPathValidatorDirective implements Validator {
  validate(control: AbstractControl): ValidationErrors | null {
    if (control.value && !filterParamsOrDataPlaceholderRegex.test(control.value)) {
      return { invalidPropertyPath: true };
    }

    return null;
  }
}
