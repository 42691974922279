import { DEFAULT_TABLE_ROW_LIMIT } from '../../../shared-modules/data-inspection/table-view/table-view.component';
import {
  assignWithDefaults,
  WidgetPlaceholderContext
} from '../../../shared-modules/data-widgets/data-widgets-common';
import {
  BatchActionButton,
  CustomColumnDefinition,
  RowClickAction
} from './table-view-widget-edit/table-view-widget.model';

export class TableWidgetOptions {
  tableRowLimit = DEFAULT_TABLE_ROW_LIMIT;
  showHeader = true;
  showHorizontalGridLines = true;
  showVerticalGridLines = false;
  showTableHover = false;
  showTableStriped = false;
  enableColumnSelection = true;
  showCustomColumnDefinition = false;
  customColumnDefinitionConfig: CustomColumnDefinition[] = [];
  showBatchSelection = false;
  batchActionButtonDefinitionConfig: BatchActionButton[] = [];
  setFixedColumnWidth = false;
  showFilter = true;
  showGlobalFilter = false;
  maxVisibleRows: number = null;
  footerContent = '';
  rowClickActionColumnName = RowClickAction.INACTIVE;
  exampleContext: WidgetPlaceholderContext = null;

  constructor(options: Partial<TableWidgetOptions> = {}) {
    this.updateProps(options);
    return this;
  }

  updateProps(options: Partial<TableWidgetOptions>): TableWidgetOptions {
    assignWithDefaults(this, options, {
      tableRowLimit: (value) => (value === '' ? DEFAULT_TABLE_ROW_LIMIT : parseInt(value)),
      maxVisibleRows: (value) => (value === '' ? null : parseInt(value))
    });
    return this;
  }
}
