<ace
  [deviceHistoryChangeHighlight]="isHistoryChanged"
  childElementSelector=".ace_editor"
  [config]="options"
  [mode]="mode"
  [theme]="'eclipse'"
  [disabled]="readOnly"
  [@highlight]="isHighlighted"
  [(value)]="text"
></ace>
