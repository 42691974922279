<slate-editable
  (ngModelChange)="valueChanged($event)"
  [(ngModel)]="value"
  [editor]="editor"
  [keydown]="keydown"
  [ngClass]="readonly ? 'p-0' : 'p-3'"
  [placeholder]="readonly ? '' : ('slateEditor.placeholder' | translate)"
  [readonly]="readonly"
  [renderElement]="renderElement()"
  [renderText]="renderText"
  [renderLeaf]="renderLeaf"
  data-cy="richTextSlateEditable"
>
  <ng-template #heading_1 let-context="context" let-viewContext="viewContext">
    <h1
      [rbPopover]="context.element | popoverContent: readonly"
      [hidePopoverIfFalsyContent]="true"
      [context]="context"
      [style.padding-left]="resolveIndent(context.element)"
      [style.text-align]="context.element.align"
      [viewContext]="viewContext"
      class="mb-0"
      slateElement
    ></h1>
  </ng-template>
  <ng-template #heading_2 let-context="context" let-viewContext="viewContext">
    <h2
      [rbPopover]="context.element | popoverContent: readonly"
      [hidePopoverIfFalsyContent]="true"
      [context]="context"
      [style.padding-left]="resolveIndent(context.element)"
      [style.text-align]="context.element.align"
      [viewContext]="viewContext"
      slateElement
    ></h2>
  </ng-template>
  <ng-template #heading_3 let-context="context" let-viewContext="viewContext">
    <h3
      [rbPopover]="context.element | popoverContent: readonly"
      [hidePopoverIfFalsyContent]="true"
      [context]="context"
      [style.padding-left]="resolveIndent(context.element)"
      [style.text-align]="context.element.align"
      [viewContext]="viewContext"
      slateElement
    ></h3>
  </ng-template>
  <ng-template #blockquote let-context="context" let-viewContext="viewContext">
    <blockquote
      [rbPopover]="context.element | popoverContent: readonly"
      [hidePopoverIfFalsyContent]="true"
      [context]="context"
      [style.padding-left]="resolveIndent(context.element)"
      [style.text-align]="context.element.align"
      [viewContext]="viewContext"
      slateElement
    ></blockquote>
  </ng-template>
  <ng-template #ul let-context="context" let-viewContext="viewContext">
    <ul
      [rbPopover]="context.element | popoverContent: readonly"
      [hidePopoverIfFalsyContent]="true"
      [context]="context"
      [style.padding-left]="resolveIndent(context.element)"
      [style.text-align]="context.element.align"
      [viewContext]="viewContext"
      slateElement
    ></ul>
  </ng-template>
  <ng-template #ol let-context="context" let-viewContext="viewContext">
    <ol
      [rbPopover]="context.element | popoverContent: readonly"
      [hidePopoverIfFalsyContent]="true"
      [context]="context"
      [style.padding-left]="resolveIndent(context.element)"
      [style.text-align]="context.element.align"
      [viewContext]="viewContext"
      slateElement
    ></ol>
  </ng-template>
  <ng-template #li let-context="context" let-viewContext="viewContext">
    <li
      [rbPopover]="context.element | popoverContent: readonly"
      [hidePopoverIfFalsyContent]="true"
      [context]="context"
      [style.padding-left]="resolveIndent(context.element)"
      [style.text-align]="context.element.align"
      [viewContext]="viewContext"
      slateElement
    ></li>
  </ng-template>
  <ng-template #p let-context="context" let-viewContext="viewContext">
    <p
      [rbPopover]="context.element | popoverContent: readonly"
      position="top"
      [hidePopoverIfFalsyContent]="true"
      [context]="context"
      [style.padding-left]="resolveIndent(context.element)"
      [style.text-align]="context.element.align"
      [style.word-break]="wrapText ? 'break-word' : 'normal'"
      [viewContext]="viewContext"
      class="mb-0"
      slateElement
    ></p>
  </ng-template>
  <ng-template #tableTemplate let-context="context" let-viewContext="viewContext">
    <table
      class="richtext-table"
      slateElement
      tableMouseEvents
      resizable
      [readonly]="readonly"
      [editor]="editor"
      [tableElementKey]="tableElementKey"
      (tableElementKeyChange)="updateTableElementKey($event)"
      data-cy="richTextSlateEditableTable"
      [context]="context"
      [viewContext]="viewContext"
    ></table>
  </ng-template>
  <ng-template #trTemplate let-context="context" let-viewContext="viewContext">
    <tr
      slateElement
      [attr.data-key]="context.element.key"
      [context]="context"
      [style.height]="context.element?.height + 'px' || 'auto'"
      [viewContext]="viewContext"
    ></tr>
  </ng-template>
  <ng-template #tdTemplate let-context="context" let-viewContext="viewContext">
    <td
      #tableDataRef
      slateElement
      data-cy="richTextSlateEditableTableData"
      [attr.data-key]="context.element.key"
      (contextmenu)="openTableEditMenu($event, tableEditPopOverButton)"
      [style.width]="context.element?.width + 'px' || 'auto'"
      [ngClass]="{
            'selectedCell': context.element?.selectedCell && !readonly,
            'border-left': context.element?.border?.['left'],
            'border-right': context.element?.border?.['right'],
            'border-top': context.element?.border?.['top'],
            'border-bottom': context.element?.border?.['bottom']
          }"
      [context]="context"
      [viewContext]="viewContext"
    >
      <button
        #tableEditPopOverButton
        class="d-none"
        (click)="$event.stopPropagation()"
        [anchor]="tableDataRef"
        [autoClose]="true"
        [rbPopover]="tableEditPopoverContent"
        [position]="'bottom'"
      ></button>

      <ng-template #tableEditPopoverContent let-close="close">
        <table-popover [editor]="editor" (closePopover)="close()"></table-popover>
      </ng-template>
      <div
        contenteditable="false"
        [style.userSelect]="'none'"
        [ngClass]="!readonly ? 'col-resize' : ''"
      ></div>
      <div
        contenteditable="false"
        [style.userSelect]="'none'"
        [ngClass]="!readonly ? 'row-resize' : ''"
      ></div>
    </td>
  </ng-template>

  <!--reference-->
  <ng-template #reference let-context="context" let-viewContext="viewContext">
    <div class="reference-container">
      <span
        #referenceElement
        (click)="
          startReferenceEdit(
            $event,
            referenceElement,
            context,
            imageReferenceEditPopoverButton,
            pathReferenceEditPopoverButton
          )
        "
        *ngIf="!readonly"
        [attr.slate-bold]="context.element.markTypes['bold']"
        [attr.slate-italic]="context.element.markTypes['italic']"
        [attr.slate-strike]="context.element.markTypes['strike']"
        [attr.slate-underlined]="context.element.markTypes['underlined']"
        [context]="context"
        [ngClass]="{ focus: context.selection }"
        [style.background-color]="context.element.markTypes['font-bg-color']"
        [style.color]="context.element.markTypes['font-color']"
        [viewContext]="viewContext"
        data-cy="slateEditorReference"
        class="reference-view"
        slateElement
      >
        {{ getReferenceDisplayText(context.element) }}

        <!--image reference editor popover-->
        <button
          #imageReferenceEditPopoverButton
          (click)="$event.stopPropagation()"
          [rbPopover]="imageReferenceEditPopover"
          [anchor]="referenceElement"
          [position]="'top'"
          class="d-none"
        >
          <!--image reference editor-->
          <ng-template #imageReferenceEditPopover let-close="close">
            <div class="p-3">
              <image-properties-editor
                (closePopover)="close()"
                (settingsChanged)="finishReferenceImageEdit($event)"
                [hideFilePicker]="true"
                [imageSettings]="editContext.referenceImage.imageSettings"
                [paths]="paths"
              ></image-properties-editor>
            </div>
          </ng-template>
        </button>

        <!--path reference editor popover-->
        <button
          #pathReferenceEditPopoverButton
          (click)="$event.stopPropagation()"
          [rbPopover]="pathReferenceEditPopover"
          [anchor]="referenceElement"
          [position]="'top'"
          class="d-none"
        >
          <!--reference editor-->
          <ng-template #pathReferenceEditPopover let-close="close">
            <reference-configuration
              [path]="context.element.path"
              [valueFormatting]="context.element.valueFormatting"
              [tooltip]="context.element.tooltip"
              (valueChange)="finishReferencePathEdit($event)"
              [paths]="paths"
              (cancelled)="close()"
            ></reference-configuration>
          </ng-template>
        </button>
      </span>
    </div>
  </ng-template>

  <!--link-->
  <ng-template #link let-context="context" let-viewContext="viewContext">
    <ng-container *ngIf="readonly; else editMode">
      <ng-container *ngIf="resolveLink(context) as resolvedLink">
        <ng-template #isNotInternal>
          <a
            *ngIf="!resolvedLink.isCopyToClipboard"
            (click)="resolvedLink.callback($event)"
            [context]="context"
            [hideTooltipIfContentIsFalsey]="true"
            [href]="resolvedLink.targetUrl"
            [rbTooltip]="context.element.tooltip"
            [style.padding-left]="resolveIndent(context.element)"
            [style.text-align]="context.element.align"
            [target]="resolvedLink.target"
            [viewContext]="viewContext"
            slateElement
            unsafeUrlHandler
          ></a>
          <a
            *ngIf="resolvedLink.isCopyToClipboard"
            (click)="resolvedLink.callback($event)"
            [context]="context"
            [hideTooltipIfContentIsFalsey]="true"
            [href]="resolvedLink.targetUrl"
            [rbTooltip]="context.element.tooltip"
            [style.padding-left]="resolveIndent(context.element)"
            [style.text-align]="context.element.align"
            [target]="resolvedLink.target"
            [viewContext]="viewContext"
            [rbPopover]="'copied' | translate"
            [position]="'top'"
            [closePopoverAfter]="closePopoverAfterMs"
            slateElement
            unsafeUrlHandler
          ></a>
        </ng-template>

        <ng-container *ngIf="resolvedLink.isInternal; else isNotInternal">
          <a
            *ngIf="resolvedLink.hasQueryParams && !resolvedLink.isCopyToClipboard"
            [context]="context"
            [hideTooltipIfContentIsFalsey]="true"
            [queryParams]="resolvedLink.queryParams"
            [rbTooltip]="context.element.tooltip"
            [routerLink]="resolvedLink.urlWithoutQueryParams"
            [style.padding-left]="resolveIndent(context.element)"
            [style.text-align]="context.element.align"
            [viewContext]="viewContext"
            slateElement
            unsafeUrlHandler
          ></a>
          <a
            *ngIf="!resolvedLink.hasQueryParams && !resolvedLink.isCopyToClipboard"
            [context]="context"
            [hideTooltipIfContentIsFalsey]="true"
            [rbTooltip]="context.element.tooltip"
            [routerLink]="resolvedLink.internalUrl"
            [style.padding-left]="resolveIndent(context.element)"
            [style.text-align]="context.element.align"
            [viewContext]="viewContext"
            slateElement
            unsafeUrlHandler
          ></a>
          <a
            *ngIf="resolvedLink.hasQueryParams && resolvedLink.isCopyToClipboard"
            [context]="context"
            [hideTooltipIfContentIsFalsey]="true"
            [queryParams]="resolvedLink.queryParams"
            [rbTooltip]="context.element.tooltip"
            [routerLink]="resolvedLink.urlWithoutQueryParams"
            [style.padding-left]="resolveIndent(context.element)"
            [style.text-align]="context.element.align"
            [viewContext]="viewContext"
            [rbPopover]="'copied' | translate"
            [position]="'top'"
            [closePopoverAfter]="closePopoverAfterMs"
            slateElement
            unsafeUrlHandler
          ></a>
          <a
            *ngIf="!resolvedLink.hasQueryParams && resolvedLink.isCopyToClipboard"
            [context]="context"
            [hideTooltipIfContentIsFalsey]="true"
            [rbTooltip]="context.element.tooltip"
            [routerLink]="resolvedLink.internalUrl"
            [style.padding-left]="resolveIndent(context.element)"
            [style.text-align]="context.element.align"
            [viewContext]="viewContext"
            [rbPopover]="'copied' | translate"
            [position]="'top'"
            [closePopoverAfter]="closePopoverAfterMs"
            slateElement
            unsafeUrlHandler
          ></a>
        </ng-container>
      </ng-container>
    </ng-container>

    <ng-template #editMode>
      <a
        #hyperlinkElement
        (click)="startHyperlinkEdit($event, context, linkEditButton)"
        [attr.slateLinkContainingImage]="hasImageAsChild(context.element)"
        [attr.slateLinkContainingReference]="hasReferenceAsChild(context.element)"
        [class.element-link-active]="context.isCollapsed"
        [context]="context"
        [hideTooltipIfContentIsFalsey]="true"
        [href]="context.element.url"
        [rbTooltip]="context.element.tooltip"
        [style.padding-left]="resolveIndent(context.element)"
        [style.text-align]="context.element.align"
        [viewContext]="viewContext"
        slateElement
      >
        <!--invisible button intended to show the popover conditionally-->
        <button
          #linkEditButton
          (click)="$event.stopPropagation()"
          [rbPopover]="linkPopover"
          [anchor]="hyperlinkElement"
          [position]="'top'"
          class="d-none"
        ></button>
        <!--independent link edit button will be shown if the link has child nodes that also have a popover editor.-->
        <!--It is important to add user-select-none and set contenteditable to false. This will avoid some of the internal slate exceptions.-->
        <a
          [class.d-none]="!hasReferenceOrImageAsChild(context.element)"
          class="rb-ic rb-ic-lg rb-ic-link user-select-none"
          contenteditable="false"
        ></a>
      </a>
    </ng-template>

    <!--link edit popover-->
    <ng-template #linkPopover let-close="close">
      <link-popover
        (closePopover)="close()"
        (removeLink)="removeLink()"
        (updateLink)="finishHyperlinkEdit($event)"
        [linkInfo]="editContext.hyperlink"
        [mode]="linkMode.EDIT"
      ></link-popover>
    </ng-template>
  </ng-template>

  <!--image element-->
  <ng-template #image let-context="context" let-viewContext="viewContext">
    <element-image
      #editableImage
      (editTriggered)="startInlineImageEdit(context.element, $event, imageResizePopoverButton)"
      [canEdit]="!readonly"
      [context]="context"
      [viewContext]="viewContext"
    >
      <button
        #imageResizePopoverButton
        [anchor]="editableImage"
        [rbPopover]="imageResizePopover"
        class="d-none"
      >
        <!--image resize popover-->
        <ng-template #imageResizePopover let-close="close">
          <div class="p-3" style="max-width: 300px">
            <image-dimension-editor
              [dimension]="editContext.inlineImage.imageDimension"
              [renderVertically]="true"
            ></image-dimension-editor>
            <div class="d-flex ml-auto mt-3">
              <button
                (click)="close(); finishInlineImageEdit()"
                class="rb-btn rb-primary"
                type="button"
              >
                {{ 'slateEditor.action.save' | translate }}
              </button>
              <button
                (click)="close(); editContext.inlineImage.reset()"
                class="rb-btn rb-secondary ml-3"
                type="button"
              >
                {{ 'slateEditor.action.cancel' | translate }}
              </button>
            </div>
          </div>
        </ng-template>
      </button>
    </element-image>
  </ng-template>
</slate-editable>

<reference-suggestion-list
  *ngIf="!readonly"
  [editor]="editor"
  [paths]="paths"
></reference-suggestion-list>
