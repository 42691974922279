<form class="spacing image-popover" #linkForm="ngForm">
  <div class="d-flex align-items-center">
    <h4>{{ 'toolbar.linkIntroduction' | translate }}</h4>
    <span class="rb-ic rb-ic-info ml-2 mb-2" [rbTooltip]="paramHints" [noFix]="false"></span>
  </div>

  <ng-template #paramHints>
    <pre class="hint-label mb-0 p-2">{{ 'toolbar.linkTooltip' | translate }}</pre>
  </ng-template>

  <rb-form-input
    name="linkUrl"
    data-cy="linkPopoverUrl"
    [label]="'widgets.actionButton.urlLabel' | translate"
    [(ngModel)]="linkInfo.url"
    required="true"
    urlSchemeValidator
  >
    <ng-template rbFormValidationMessage="schemeMatchError" let-errorData="data">
      <url-scheme-error [schemes]="errorData"></url-scheme-error>
    </ng-template>
  </rb-form-input>

  <rb-form-input
    name="linkTooltip"
    [label]="'widgets.tooltips.link' | translate"
    [(ngModel)]="linkInfo.tooltip"
  ></rb-form-input>

  <rb-form-select
    name="linkTarget"
    data-cy="linkPopoverTargetDropdown"
    [label]="'toolbar.action' | translate"
    [(ngModel)]="linkInfo.target"
  >
    <option data-cy="linkTargetNewTab" [value]="'blank'" selected>
      {{ 'toolbar.openLinkInNewTab' | translate }}
    </option>
    <option data-cy="linkTargetSameTab" [value]="'self'">
      {{ 'toolbar.openLinkInSameTab' | translate }}
    </option>
    <option data-cy="linkTargetClipboard" [value]="'clipboard'">
      {{ 'toolbar.copyLinkToClipboard' | translate }}
    </option>
  </rb-form-select>

  <button class="rb-btn rb-secondary mr-3" type="button" (click)="$event.preventDefault(); close()">
    {{ 'toolbar.cancelLink' | translate }}
  </button>
  <button
    *ngIf="mode === 'EDIT'"
    class="rb-btn rb-secondary mr-3"
    type="button"
    (click)="$event.preventDefault(); removeLink.emit(); close()"
  >
    {{ 'toolbar.removeLink' | translate }}
  </button>
  <button
    class="rb-btn rb-primary"
    type="button"
    data-cy="linkPopoverSaveButton"
    [disabled]="linkForm.invalid"
    (click)="$event.preventDefault(); updateLink.emit(linkInfo); close()"
  >
    {{ 'toolbar.saveLink' | translate }}
  </button>
</form>
