import { animate, state, style, transition, trigger } from '@angular/animations';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import 'brace';
import 'brace/ext/searchbox';
import 'brace/mode/json';
import 'brace/theme/eclipse';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { AceComponent } from 'ngx-ace-wrapper';

@Component({
  selector: 'json-editor',
  templateUrl: './json-editor.component.html',
  styles: [
    `
      :host {
        display: block;
      }
    `
  ],
  animations: [
    trigger('highlight', [
      state(
        'active',
        style({
          backgroundColor: '#e20015'
        })
      ),
      state(
        'inactive',
        style({
          backgroundColor: '#fff'
        })
      ),
      transition('active => inactive', [animate('0.3s')]),
      transition('inactive => active', [animate('0.2s')])
    ])
  ]
})
export class JsonEditorComponent implements OnInit, OnDestroy, AfterViewInit {
  obs = new Subject<any>();

  @Output() cursorPosition = new EventEmitter<object>();
  @Output() textChange = new EventEmitter<string>();

  @ViewChild(AceComponent, { static: true }) editorComponent: AceComponent;

  private _text: string;
  public isHighlighted = 'inactive';
  public positionInfo = { column: null, row: null };

  @Input() options: any;
  @Input() readOnly: boolean;
  @Input() mode = 'json';
  @Input() isHistoryChanged = false;

  constructor(private zone: NgZone) {}

  ngOnInit() {
    this.obs.pipe(debounceTime(100)).subscribe((positionInfo) => {
      this.positionInfo = positionInfo;
      this.cursorPosition.emit(this.positionInfo);
    });
  }

  ngAfterViewInit(): void {
    this.getAceEditor().selection.on('changeCursor', this.showPositionDetails);
  }

  ngOnDestroy() {
    this.getAceEditor()?.selection.off('changeCursor', this.showPositionDetails);
  }

  @Input()
  get text(): string {
    return this._text;
  }

  set text(value: string) {
    this._text = String(value);
    this.textChange.emit(this._text);
  }

  @Input()
  set showSearch(show: boolean) {
    if (show) {
      this.getAceEditor()?.execCommand('find');
    } else if (this.getAceEditor()?.searchBox) {
      this.getAceEditor().searchBox.hide();
    }
  }

  getErrors() {
    return this.getAceEditor()
      .getSession()
      .getAnnotations()
      .filter((annotation) => annotation.type === 'error');
  }

  getAceEditor(): any {
    return this.editorComponent.directiveRef.ace();
  }

  highlightEditor() {
    this.isHighlighted = 'active';
    setTimeout(() => {
      this.isHighlighted = 'inactive';
    }, 300);
  }

  highlightSelectedWord(row, col) {
    this.getAceEditor().renderer.scrollToLine(row);
    this.getAceEditor().moveCursorTo(row, col);
    this.getAceEditor().clearSelection();
    this.getAceEditor().selection.selectWordRight();
    this.getAceEditor().setHighlightSelectedWord(true);
  }

  private showPositionDetails = () => {
    const positionInfo = { column: null, row: null };
    this.zone.run(() => {
      const position = this.getAceEditor().getCursorPosition();
      positionInfo.row = position.row + 1;
      positionInfo.column = position.column + 1;

      setTimeout(() => {
        this.obs.next(positionInfo);
      });
    });
  };
}
