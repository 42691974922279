import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { LoadingEntity, PopoverDirective } from '@inst-iot/bosch-angular-ui-components';
import { flatten, merge } from 'lodash-es';
import { DashboardStateService } from '../../../dashboards/services/dashboard-state.service';
import { WidgetInstanceService } from '../../../dashboards/services/widget-instance.service';
import {
  createRandomIdContext,
  iteratorInPlaceholderExp,
  placeholderExp,
  WidgetPlaceholderContext
} from '../../data-widgets/data-widgets-common';
import { HyperlinkService } from '../../data-widgets/data-widgets-common/lib/hyperlink.service';
import {
  ActionButtonConfiguration,
  ActionButtonStyle,
  AZURE_ERROR_CODE
} from '../../rest-request/models/rest-request.model';
import { RestRequestRun, RestRequestService } from '../../rest-request/rest-request.service';
import {
  actionButtonDefaultLabel,
  checkDisabledCondition,
  createLinkDefinitionContext,
  getTriggerRouteIfAvailable,
  processRestResponse,
  readBlob,
  setRestResponse,
  validateInputReference
} from '../action-config-util';
import { DashboardDataService } from '../../../dashboards/services/dashboard-data.service';

interface TableContext {
  row: number;
  column: string;
}

@Component({
  selector: 'action-table-button',
  templateUrl: './action-table-button.component.html',
  styleUrls: ['../action-buttons.scss']
})
export class ActionTableButtonComponent implements OnInit, OnChanges {
  responseLoader: LoadingEntity<any> = new LoadingEntity<any>();

  @Input() popoverContent: TemplateRef<any>;

  @Input() action = {} as ActionButtonConfiguration;

  @Input() data: any;

  @Input() context: WidgetPlaceholderContext;

  @Input() icon = 'rb-ic-start-play-frame';

  @Input() disabled: boolean;

  @Input() isBatchActionButton = false;

  @Output() actionExecutionFinished = new EventEmitter<void>();

  @Input() actionButtonStyling: ActionButtonStyle;

  @Input() tableContext: TableContext;

  @ViewChild(PopoverDirective)
  private popoverDirective: PopoverDirective;

  disabledByCondition = true;

  get status() {
    return this.action.restResponse?.status;
  }

  get statusText() {
    if (
      this.action.restResponse?.statusText === 'unknown' &&
      this.action.restResponse?.status >= 200 &&
      this.action.restResponse?.status < 300
    ) {
      return 'ok';
    }
    return this.action.restResponse?.statusText;
  }

  get response() {
    return this.action.restResponse?.response;
  }

  get error() {
    return this.action.restResponse?.error;
  }

  constructor(
    private restRequestService: RestRequestService,
    private dashboardStateService: DashboardStateService,
    private dashboardDataService: DashboardDataService,
    private widgetInstance: WidgetInstanceService,
    private hyperlinkService: HyperlinkService
  ) {}

  ngOnInit() {
    if (!this.action.buttonLabel) {
      this.action.buttonLabel = actionButtonDefaultLabel;
    }
    this.setDisabledByCondition();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.data || changes.context) {
      if (this.isBatchActionButton) {
        this.transformBatchActionButtonDataOnPlaceholderIterator();
      }
      this.setDisabledByCondition();
    }
  }

  setDisabledByCondition(): void {
    this.disabledByCondition = checkDisabledCondition(
      this.action?.disableConditionsChips?.syntaxTree,
      Array.isArray(this.data) ? this.data : [this.data],
      this.context
    );
  }

  makeRestCall() {
    this.action.restResponse = null;

    if (!this.widgetInstance.widgetType) {
      // Request not yet saved in DB while in Widget Edit Mode
      // Restrict REST Execution
      const response = {
        error: 'Preview does not allow REST executions',
        statusText: 'Restricted RestRequest Execution',
        status: 400
      };

      setRestResponse(response, this.action);
      return;
    }

    if (
      !validateInputReference(this.dashboardDataService, this.dashboardStateService, this.action)
    ) {
      return;
    }

    this.responseLoader
      .run(
        this.restRequestService.executeRestRequestDefinitionWithObserverWithCache(
          this.action.actionConfigId,
          {
            context: merge(this.context, createRandomIdContext()),
            data: this.data
          } as RestRequestRun,
          this.action?.restRequestCacheTime
        )
      )
      .subscribe({
        next: (response: HttpResponse<Blob>) => {
          this.onRestResponseLoaded(response, this.action, this.data);
        },
        error: (error) => {
          this.onRestResponseLoaded(error, this.action);
        }
      });
  }

  onRestResponseLoaded(
    response: HttpResponse<any> | HttpErrorResponse,
    action: ActionButtonConfiguration,
    data?: any
  ) {
    const triggerRoute = getTriggerRouteIfAvailable(response, data, action.triggerConfiguration);

    processRestResponse(response, action, data);

    if (triggerRoute !== undefined) {
      this.resolveAndTriggerUrl(response, triggerRoute);
    } else {
      this.actionExecutionFinished.emit();
    }

    if (response.status === 440 && response['error'] instanceof Blob) {
      readBlob(response['error']).then((message) => {
        if (message?.includes(AZURE_ERROR_CODE)) {
          this.popoverDirective.closePopOver(true);
        }
      });
    }
  }

  private resolveAndTriggerUrl(
    response: HttpResponse<any> | HttpErrorResponse,
    matchingTriggerUrl: { url?: string; target: string }
  ) {
    createLinkDefinitionContext(response, this.context, this.data, matchingTriggerUrl).then(
      (linkContext) => {
        const resolvedLink = this.hyperlinkService.resolveLink(
          linkContext.linkDefinition,
          ...linkContext.dataSources
        );
        this.hyperlinkService.openLink(resolvedLink);
        this.actionExecutionFinished.emit();
      }
    );
  }

  private generateBatchActionButtonData() {
    return this.data.reduce((acc, column) => {
      for (const prop in column) {
        if (Object.prototype.hasOwnProperty.call(acc, prop)) {
          acc[prop].push(column[prop]);
          continue;
        }

        acc[prop] = [column[prop]];
      }

      return acc;
    }, {});
  }

  /* This is done so that a usage of the iterator ( ${[i].foo} ) works properly.
   * Otherwise the data is kept as it is, so it works with indexes - ${[1].foo}
   */
  private transformBatchActionButtonDataOnPlaceholderIterator() {
    const iteratorPattern = iteratorInPlaceholderExp;
    const placeholderPattern = placeholderExp;
    placeholderPattern.lastIndex = 0;

    const placeholder = placeholderPattern.exec(this.action?.restRequest?.uri);
    const headers = flatten(Object.entries(this.action?.restRequest?.headers || []));

    if (
      (placeholder && iteratorPattern.test(placeholder[1])) ||
      headers.some((el) => iteratorPattern.test(el))
    ) {
      this.data = [this.generateBatchActionButtonData()];
    }
  }
}
