import { Component, OnDestroy, OnInit } from '@angular/core';
import { DashboardDataService } from '../services/dashboard-data.service';
import { LoadingEntity, ModalService } from '@inst-iot/bosch-angular-ui-components';
import { DashboardConfig } from '../models/DashboardConfig';

@Component({
  selector: 'dashboard-selection',
  templateUrl: './dashboard-selection.component.html'
})
export class DashboardSelectionComponent implements OnInit, OnDestroy {
  dashboards = new LoadingEntity<DashboardConfig[]>();

  constructor(private dashboardService: DashboardDataService, private modalService: ModalService) {}

  ngOnInit() {
    this.dashboards.run(this.dashboardService.getDashboardsWithDefaults()).subscribe();
  }

  ngOnDestroy(): void {
    this.dashboards.complete();
  }

  selectDashboard(name: string) {
    this.modalService.close(name);
  }
}
