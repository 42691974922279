<div [title]="lastUpdate | date: 'medium'">
  <div
    class="subtext"
    [ngClass]="{
      'text-muted': !showInTooltip,
      'mr-2': !showInTooltip,
      'tooltip-content': showInTooltip
    }"
    data-cy="widgetLoadingLastUpdate"
    style="direction: rtl"
    *ngIf="!isLoading && !error"
  >
    {{ 'queryResult.lastUpdate' | translate }} {{ lastUpdate | timeAgo }}
  </div>
</div>
<div
  *ngIf="isLoading"
  class="subtext"
  [ngClass]="{
    'text-muted': !showInTooltip,
    'mr-2': !showInTooltip,
    'tooltip-content': showInTooltip
  }"
>
  {{ 'queryResult.dataIsLoading' | translate }}
</div>
<div
  class="text-danger mr-2 subtext pointer-cursor"
  *ngIf="error && error.status !== 403 && !isLoading"
  (click)="openErrorDialog()"
>
  <span class="rb-ic rb-ic-alert-warning"></span>
  {{ 'widget.loadingError' | translate }}
</div>

<ng-template #errorTpl>
  <h1 class="mt-3">{{ 'widget.errorDetails' | translate }}</h1>
  <rb-callout>
    <rb-error-format [error]="error"></rb-error-format>
  </rb-callout>
</ng-template>
