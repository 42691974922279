import { Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { QueryParameter } from '../models/query-parameter';
import { DevicesService } from '../../../devices/services/devices.service';
import { DeviceTypesService } from '../../../devices/services/device-types.service';
import { isAbsolute, isRelative, PickerValue } from '@inst-iot/bosch-angular-ui-components';
import { TimeRange } from '../../../shared/date-time-range-popover/date-time-range-popover.component';

/**
 * Represents a primitive value input.
 */
@Component({
  selector: 'value-input',
  templateUrl: './value-input.component.html',
  providers: [DevicesService, DeviceTypesService]
})
export class ValueInputComponent {
  @Input() label: string | TemplateRef<any>;

  @Input() dataType: string;

  @Input() required = false;

  @Input() validationRegExp = null;

  private _value = null;

  defaultValue = null;

  @Input()
  set value(value) {
    this._value = value;
    if (value?.from) {
      this.defaultValue = this.toPickerValue(value);
    }
  }

  get value() {
    return this._value;
  }

  @Input() readonly = false;

  @Output() valueChange = new EventEmitter<any>();

  toRange(dateTime: PickerValue): TimeRange {
    let range: TimeRange;
    if (isAbsolute(dateTime)) {
      range = { from: dateTime[0] as string, to: dateTime[1] as string };
    }
    if (isRelative(dateTime)) {
      const start = (dateTime[0] as number) < 0 ? '' + dateTime[0] : '+' + dateTime[0];
      const end = (dateTime[1] as number) < 0 ? '' + dateTime[1] : '+' + dateTime[1];
      range = { from: start, to: end };
    }
    return range;
  }

  // convert the "from...to..." format to picker format
  toPickerValue(range): PickerValue {
    if (range.from.charAt(0) === '-' || range.from.charAt(0) === '+') {
      return [parseInt(range.from), parseInt(range.to)];
    }
    return [range.from, range.to];
  }

  updateValue(v: any) {
    const isOfTypeNumber = this.dataType === 'INT' || this.dataType === 'FLOAT';
    if ((v === '' || isNaN(v)) && v !== '-' && isOfTypeNumber) {
      this.value = undefined;
      this.valueChange.next(this.value);
    } else if ((isOfTypeNumber && v !== '-') || !isOfTypeNumber) {
      if (this.dataType === 'TIMESTAMP_RANGE') {
        v = this.toRange(v);
      }
      const value = QueryParameter.getValueByType(v, this.dataType);
      this.value = value;
      this.valueChange.next(value);
    }
  }
}
