<div class="w-100 position-relative">
  <button
    [disabled]="disabledAnyhow"
    data-cy="actionButton"
    class="rb-btn rb-primary"
    type="button"
    [ngStyle]="actionButtonStyling"
  >
    {{ actionConfiguration.buttonLabel | translate }}
  </button>
  <div
    *ngIf="!disabledAnyhow && !dsLoading"
    data-cy="restRequestActionButton"
    [rbPopover]="responseRestRequest"
    [position]="'bottom'"
    class="cursor-pointer absolute-placeholder"
    (click)="makeRestCall(); $event.stopPropagation()"
  ></div>
  <div
    class="absolute-placeholder"
    data-cy="restRequestActionButton"
    *ngIf="disabledByCondition && !dsLoading"
    [noFix]="false"
    [rbTooltip]="disabledByConditionTooltip"
    (click)="$event.stopPropagation()"
  ></div>
  <div
    class="absolute-placeholder"
    *ngIf="disabled && !dsLoading"
    data-cy="restRequestActionButton"
  ></div>
</div>

<!-- Response from Rest Request-->
<ng-template #responseRestRequest>
  <div class="p-2">
    <http-response-status
      [loading]="responseLoader.loading"
      [restResponse]="actionConfiguration.restResponse"
      [responsePopoverContent]="responsePopoverContent"
    ></http-response-status>
  </div>
</ng-template>

<ng-template #disabledByConditionTooltip>
  <div class="p-1 mw-500">
    {{
      actionConfiguration?.disableConditionsChips?.chips?.length === 1
        ? ('widgets.actionButton.disabledMessage.disabledByCondition'
          | translate
            : {
                property: actionConfiguration.disableConditionsChips.chips[0].input,
                value: actionConfiguration.disableConditionsChips.chips[0].value
              })
        : ('widgets.actionButton.disabledMessage.disabledByConditionGeneric' | translate)
    }}
  </div>
</ng-template>
