import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'table-selector',
  templateUrl: './table-selector.component.html',
  styleUrls: ['./table-selector.component.scss']
})
export class TableSelectorComponent {
  @Output() selectedRowsAndColumns = new EventEmitter<{ rows: number; columns: number }>(null);
  tableData: string[][] = Array(15).fill(Array(15).fill(''));
  hoveredRow: number | null = null;
  hoveredColumn: number | null = null;
  selectedRows: number[] = [];
  selectedColumns: number[] = [];

  get selectedRowAndColumn(): string {
    return `${this.hoveredRow + 1} x ${this.hoveredColumn + 1} Table`;
  }

  onMouseEnter(row: number, col: number) {
    this.hoveredRow = row;
    this.hoveredColumn = col;
  }

  selectCell(row: number, col: number) {
    this.selectedRows = [];
    this.selectedColumns = [];
    for (let i = 0; i <= row; i++) {
      this.selectedRows.push(i);
    }
    for (let j = 0; j <= col; j++) {
      this.selectedColumns.push(j);
    }

    this.selectedRowsAndColumns.emit({
      rows: this.selectedRows.length,
      columns: this.selectedColumns.length
    });
  }

  isSelected(row: number, col: number): boolean {
    return this.selectedRows.includes(row) && this.selectedColumns.includes(col);
  }

  isHovered(row: number, col: number): boolean {
    return (
      this.hoveredRow !== null &&
      this.hoveredColumn !== null &&
      row <= this.hoveredRow &&
      col <= this.hoveredColumn
    );
  }
}
