<div class="bosch-light-gray-w75 px-3 py-2" [class.block-disabled]="disabled">
  <div *ngIf="searchInput.type === 'filter'" class="d-flex mb-1">
    <span class="flex-grow-1 font-weight-bold description">
      {{ 'simpleSearch.description' | translate }}
    </span>
    <button
      class="rb-btn rb-link"
      data-cy="simpleSearchClearButton"
      (click)="clearSimpleSearch()"
      [disabled]="chipsAreEmpty || disabled"
    >
      <span class="rb-ic rb-ic-refresh px-1"></span>
      <span class="vlign-icons">
        {{ 'simpleSearch.clearSimpleSearch' | translate }}
      </span>
    </button>
  </div>
  <simple-search-input
    [propertyPathType]="PropertyPathType.PREDEFINED_PATH"
    [inputs]="searchInput.type === 'filter' ? searchInput.filters : searchInput.paths"
    [chips]="chips"
    [activeChip]="activeChip"
    (create)="createChip($event)"
    (update)="updateChip($event)"
    (selected)="selectChip($event)"
  ></simple-search-input>
  <ng-container *ngIf="chips.length">
    <div class="row mt-1 px-3">
      <div class="flex-grow-1 mw-100">
        <div *ngTemplateOutlet="chipsTemplate"></div>
      </div>

      <ng-container *ngIf="!showSimpleSearchEditor && showSearchButton">
        <ng-container *ngTemplateOutlet="filterButton"></ng-container>
      </ng-container>
    </div>

    <div class="row mt-1 px-3" *ngIf="showSimpleSearchEditor">
      <div class="flex-grow-1">
        <simple-search-editor
          [chips]="chips"
          [simpleLogic]="simpleLogic"
          [syntaxTree]="syntaxTree"
          [syntaxErrors]="syntaxErrors"
          [storageKey]="storageKey"
          (logicInputChanged)="updateSimpleSearchEditor('INPUT', $event); emitChangeValue()"
        ></simple-search-editor>
      </div>

      <ng-container *ngIf="showSearchButton">
        <ng-container *ngTemplateOutlet="filterButton"></ng-container>
      </ng-container>
    </div>
  </ng-container>

  <div class="rb-callout rb-callout-error mb-0 mt-3" *ngIf="queryLoader.error">
    <rb-error-format [error]="queryLoader.error"></rb-error-format>
  </div>
</div>

<ng-template #chipsTemplate>
  <simple-search-chip
    [activeChip]="activeChip"
    [chips]="chips"
    [showLabels]="showSimpleSearchEditor"
    (remove)="removeChip($event)"
    (selected)="selectChip($event)"
  ></simple-search-chip>
</ng-template>

<ng-template #cancelButton>
  <div class="col-12 col-lg-auto mt-4 px-0 pl-lg-3 mt-lg-0 text-right">
    <rb-loading-spinner [size]="0.7" class="float-left pr-3 py-1"></rb-loading-spinner>
    <button (click)="queryLoader.reset()" class="rb-btn rb-secondary h-100">
      <span>{{ 'simpleSearch.cancel' | translate }}</span>
    </button>
  </div>
</ng-template>

<ng-template #filterButton>
  <div
    class="col-12 col-lg-auto mt-4 px-0 pl-lg-3 mt-lg-0 text-right"
    *ngIf="!queryLoader.loading; else cancelButton"
  >
    <button
      [disabled]="!queryChanged || syntaxErrors.length || !syntaxTree.childNodes.length"
      (click)="buildQueryStatement(); activeChip = null"
      class="rb-btn rb-primary"
      data-cy="simpleSearchFilter"
    >
      {{ 'simpleSearch.filter' | translate }}
    </button>
  </div>
</ng-template>
