<rb-form-input
  [placeholder]="placeholder | translate"
  [ngModel]="deviceProperty"
  maxlength="100"
  [rbInitialOpen]="true"
  [rbFormInputAutocomplete]="autoCompleteSearchFunction"
  [rbAutocompleteList]="devicePropertyList"
></rb-form-input>
<ng-template #devicePropertyList>
  <div class="pl-3" data-cy="device-property-search-options">
    <div *ngIf="!deviceTypeDefinition">
      {{ 'widget.filter.deviceAttributeSearch.noDeviceTypeDefinition' | translate }}
    </div>
    <div *ngIf="deviceTypeDefinition && !filteredDeviceFeatureProperties?.length">
      {{ 'widget.filter.deviceAttributeSearch.noDevicePropertiesFound' | translate }}
    </div>
    <ng-container *ngFor="let feature of filteredDeviceFeatureProperties">
      <ng-container *ngIf="feature.properties.length > 0">
        <div class="pt-2">{{ getFeatureName(feature.featureDefaultName) }}</div>
        <button
          type="button"
          class="rb-btn rb-link rb-dropdown-item"
          [disabled]="property?.type === 'hyperlink'"
          *ngFor="let property of feature.properties"
          (click)="updateDeviceProperty(property)"
        >
          {{ property.propertyName }}
          <!--
       -->
          <span *ngIf="property?.type === 'hyperlink'">
            ({{ 'widget.filter.deviceAttributeSearch.hyperlinksNotSupport' | translate }})
          </span>
        </button>
      </ng-container>
    </ng-container>
  </div>
</ng-template>
