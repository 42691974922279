<div class="d-flex align-items-center">
  <span *ngIf="itemsCount || itemsCount === 0" class="flex-grow-1">
    <span class="font-weight-bold">{{ itemsCount || '' }}</span>
    <span [ngPlural]="itemsCount">
      <ng-template ngPluralCase="=0">
        {{ 'tableFilter.noResults' | translate }}
      </ng-template>
      <ng-template ngPluralCase="=1">
        {{ 'tableFilter.singleResult' | translate }}
      </ng-template>
      <ng-template ngPluralCase="other">
        {{ 'tableFilter.multipleResults' | translate }}
      </ng-template>
    </span>
  </span>
  <button class="rb-btn rb-link" data-cy="tableClearColumnFilters" (click)="clearFilters()">
    <i class="rb-ic rb-ic-reset mr-1"></i>
    {{ 'tableFilter.clearColumnFilters' | translate }}
  </button>
</div>
