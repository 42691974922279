import {
  DashboardParameterOptions,
  DashboardParameterType,
  SyncAbstractDashboardParameter
} from './dashboard-parameter';
import { ParamMap } from '@angular/router';
import { UrlSearchParamMap } from './url-search-param-map';
import { isEmpty } from 'lodash-es';

export interface RangeParameterValue {
  from: string;
  to: string;
}

export class RangeDashboardParameter extends SyncAbstractDashboardParameter<RangeParameterValue> {
  constructor(
    name: string,
    i18nLabel: string,
    type: DashboardParameterType,
    widgetId: string,
    value?: RangeParameterValue,
    options?: DashboardParameterOptions
  ) {
    super(name, i18nLabel, type, widgetId, value, options);
  }

  get queryKeys(): string[] {
    return [this.paramKey + 'From', this.paramKey + 'To'];
  }

  toParams(): ParamMap {
    const params = new URLSearchParams();

    if (this.value !== undefined) {
      if (!isEmpty(this.value.from)) {
        params.set(this.queryKeys[0], this.value.from);
      }
      if (!isEmpty(this.value.to)) {
        params.set(this.queryKeys[1], this.value.to);
      }
    }

    return new UrlSearchParamMap(params);
  }

  fromParams(params: ParamMap, writeOnlyOnChange = false) {
    if (params.has(this.paramKey + 'From') && this.paramKey + 'To') {
      const value = {
        from: params.get(this.paramKey + 'From'),
        to: params.get(this.paramKey + 'To')
      };
      this.writeValue(value, writeOnlyOnChange);
    }
  }
}
