import {
  Component,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
  ViewEncapsulation
} from '@angular/core';

@Component({
  selector: 'widget-settings-item',
  templateUrl: './widget-settings-item.component.html',
  styleUrls: ['./widget-settings-item.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class WidgetSettingsItemComponent {
  @Input() label: string | TemplateRef<any>;
  @Input() hint: string | TemplateRef<any>;
  @Input() valid = true;
  @Input() isOpen = false;
  @Input() dataCyName = '';

  @Output() opened = new EventEmitter();
  @Output() closed = new EventEmitter();

  openChanged(isOpen) {
    if (isOpen) {
      this.opened.next(null);
    } else {
      this.closed.next(null);
    }
  }
}
