<ng-container>
  <rb-callout *ngIf="pathError && !preview" [type]="'error'">
    {{ 'dataWidgets.valueView.noPaths' | translate }}
  </rb-callout>
  <rb-callout *ngIf="pathError && preview" [type]="'info'">
    {{ 'dataWidgets.valueView.noPreview' | translate }}
  </rb-callout>
  <div *ngIf="!pathError" class="d-flex flex-wrap" data-cy="valueViewWidget">
    <div *ngFor="let value of values" class="m-2 p-3 value-container" [ngClass]="checkRule(value)">
      <rb-callout *ngIf="value.configErr" [type]="'error'">
        {{ 'dataWidgets.valueView.columnConfig' | translate }}
      </rb-callout>
      <ng-container *ngIf="!value.configErr">
        <div class="d-flex justify-content-center">
          <div>
            <div class="value">{{ formatValue(value) }}</div>
          </div>
        </div>
        <div class="text-center label">{{ value.label }}</div>
      </ng-container>
    </div>
  </div>
</ng-container>
