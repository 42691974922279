import { BehaviorSubject, Observable } from 'rxjs';
import { ParamMap } from '@angular/router';
import { isEqual } from 'lodash-es';

export type DashboardParameterType =
  | 'string'
  | 'string[]'
  | 'number'
  | 'number[]'
  | 'boolean'
  | 'boolean[]'
  | 'date'
  | 'dateTimeRange'
  | 'dateTimeRange[]'
  | 'ThingId'
  | 'ThingId[]'
  | 'BookingId'
  | 'json';

export interface DashboardParameterOptions {
  skipWidgetPrefix?: boolean;
  isQueryParam?: boolean;
  isGlobal?: boolean;
  isLocalStorage?: boolean;
  validationFn?: () => boolean;
}

export interface DashboardParameter<S = any, R = S> {
  name: string;
  i18nLabel: string;
  type: DashboardParameterType;
  widgetId: string;

  options: DashboardParameterOptions;

  /**
   * Combination of widgetId and name, see buildDashboardParamId
   */
  id: string;

  /**
   * Emits on every value update.
   * Use pipe(distinctUntilChanged()) to only get changes of the value
   */
  valueChanges: Observable<S>;

  /**
   * Emits the resolved value
   * For async resolving, it emits undefined after a value change and emits again a valid value when resolving is done
   */
  resolvedValueChanges: Observable<R>;

  /**
   * Current value of the parameter
   * undefined = no value, null is a valid value
   */
  value: S;

  /**
   * Resolved value of the parameter
   * undefined = no value/resolving running, null is a valid value
   */
  resolvedValue: R;

  /**
   * Indicates that the resolved value is not ready yet
   */
  isResolving: boolean;

  /**
   * All query parameter keys for this dashboard parameter.
   * Types like date-time-range, can have multiple.
   */
  queryKeys: string[];

  /**
   * Called to update the value of the parameter with the storage value
   */
  writeValue(value: any, writeOnlyOnChange?: boolean): void;

  /**
   * Function to convert the field value to 2d parameters
   * Based on the storageValue
   */
  toParams(): ParamMap;

  fromParams(params: ParamMap, writeOnlyOnChange?: boolean);

  /**
   * Returns a map of values that can be used in the dashboard.
   * Based on the resolvedValue
   */
  toFilterParams(): Record<string, any>;
}

const defaultOptions: DashboardParameterOptions = {
  skipWidgetPrefix: false,
  isQueryParam: false,
  isLocalStorage: false
};

export abstract class AbstractDashboardParameter<S = any, R = S>
  implements DashboardParameter<S, R>
{
  name: string;
  i18nLabel: string;
  type: DashboardParameterType;
  widgetId: string;
  options: DashboardParameterOptions;

  valueChanges: BehaviorSubject<S>;
  resolvedValueChanges: BehaviorSubject<R>;

  protected constructor(
    name: string,
    i18nLabel: string,
    type: DashboardParameterType,
    widgetId: string,
    storageValue?: S,
    resolvedValue?: R,
    options: DashboardParameterOptions = defaultOptions
  ) {
    this.name = name;
    this.i18nLabel = i18nLabel;
    this.type = type;
    this.widgetId = widgetId;
    this.valueChanges = new BehaviorSubject<S>(storageValue);
    this.resolvedValueChanges = new BehaviorSubject<R>(resolvedValue);
    this.options = options;
  }

  get id(): string {
    return this.widgetId + '.' + this.name;
  }

  get value(): S {
    return this.valueChanges.value;
  }

  get resolvedValue(): R {
    return this.resolvedValueChanges.value;
  }

  get isResolving(): boolean {
    return this.value !== undefined && this.resolvedValue === undefined;
  }

  writeValue(value: S, writeOnlyOnChange = false) {
    if (writeOnlyOnChange && isEqual(this.value, value)) {
      return;
    }
    this.valueChanges.next(value);
    this.resolvedValueChanges.next(value as unknown as R); // overwrite this method to change this
  }

  /**
   * Name for the query parameters
   */
  protected get paramKey() {
    return buildDashboardParamId(this.widgetId, this.name, this.options.skipWidgetPrefix);
  }

  get queryKeys(): string[] {
    return [this.paramKey];
  }

  toFilterParams(): Record<string, any> {
    return {
      [this.name]: this.value
    };
  }

  abstract toParams(): ParamMap;

  abstract fromParams(params: ParamMap, writeOnlyOnChange: boolean): void;
}

export abstract class SyncAbstractDashboardParameter<
  S = any
> extends AbstractDashboardParameter<S> {
  protected constructor(
    name: string,
    i18nLabel: string,
    type: DashboardParameterType,
    widgetId: string,
    storageValue?: S,
    options: DashboardParameterOptions = defaultOptions
  ) {
    super(name, i18nLabel, type, widgetId, storageValue, storageValue, options);
  }
}

export function buildDashboardParamId(
  widgetId: string,
  name: string,
  skipWidgetPrefix = false
): string {
  return skipWidgetPrefix ? name : widgetId + '.' + name;
}
