import { DOCUMENT } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewChildren,
  ViewContainerRef,
  ViewEncapsulation
} from '@angular/core';
import { ItemWithState, PageData, SortDirective } from '@inst-iot/bosch-angular-ui-components';
import { Sort } from '@inst-iot/bosch-angular-ui-components/molecules/table/sort.directive';
import { castArray, cloneDeep, isEmpty, isEqual } from 'lodash-es';
import { Subscription } from 'rxjs';
import { DashboardLayout } from '../../../dashboards/models/DashboardConfig';
import { LayoutBehavior } from '../../../dashboards/models/DashboardWidgetConfig';
import { WidgetInstanceService } from '../../../dashboards/services/widget-instance.service';
import {
  BackgroundColorTarget,
  CustomColumnDefinition,
  FooterContent,
  RowClickAction
} from '../../../dashboards/widgets/table-view-widget/table-view-widget-edit/table-view-widget.model';
import { TableWidgetOptions } from '../../../dashboards/widgets/table-view-widget/table-widget-options.model';
import { DeviceTypesService } from '../../../devices/services/device-types.service';
import { ProjectsService } from '../../../shared-projects/services/projects.service';
import { PaginationAction } from '../../../shared/pagination-bar/pagination-bar.component';
import { PaginationSettingsService } from '../../../shared/pagination-bar/pagination-settings.service';
import { TranslatedPipe } from '../../../shared/pipes/translated.pipe';
import { outerHeight } from '../../../shared/style-utils';
import {
  DataFormattingInputType,
  ValueFormattingConfig,
  ValueFormattingJsonConfig,
  ValueFormattingResolvedConfig
} from '../../data-conditional-formatting/data-conditional-formatting.model';
import { DataConditionalFormattingPipe } from '../../data-conditional-formatting/data-conditional-formatting.pipe';
import { getDataPathIterator } from '../../data-selection/data-info-util';
import { HyperlinkService } from '../../data-widgets/data-widgets-common/lib/hyperlink.service';
import { TableFilterService } from './services/table-filter.service';
import { TableWidgetStateService } from './services/table-widget-state.service';
import { BatchActionButtonData, EntryCell, Filter } from './table-view.model';
import {
  containsDeviceImageColumn,
  createCustomColumnEntries,
  getCellInfo,
  getPaths,
  getSortedEntryCells,
  resolvePath
} from './util/table-view.util';
import { ActionTableButtonComponent } from '../../action-buttons/action-table-button/action-table-button.component';
import { ActionLinkComponent } from '../../action-buttons/action-link/action-link.component';
import { DashboardGridService } from '../../../dashboards/services/dashboard-grid.service';

export const DEFAULT_TABLE_ROW_LIMIT = 20;

@Component({
  selector: 'table-view',
  templateUrl: './table-view.component.html',
  styleUrls: ['./table-view.component.scss'],
  providers: [TableFilterService, TableWidgetStateService, PaginationSettingsService],
  encapsulation: ViewEncapsulation.None
})
export class TableViewComponent implements OnChanges, OnInit, OnDestroy, AfterViewInit {
  columns: string[] = [];

  path: string[] = [];

  entries: EntryCell[][] = [];

  convertedData: EntryCell[][] = [];

  paths = [];

  sort: Sort = null;

  pageData: PageData = new PageData();

  start: number;

  filteredData: EntryCell[][] = [];

  calculatedFooter: number[] = [];

  tableMaxHeight = 'none';

  columnSelectorStates: ItemWithState[] = [];

  limits = [];

  isRowSelectionEnabled = false;

  batchActionButtonData: BatchActionButtonData[] = [];

  filteredCustomColumnDefinitions: CustomColumnDefinition[] = [];

  noDataFound = false;

  // display empty rows when no data is true and overlay option is enabled
  noDataOverlayTableRows = 4;

  shouldShowTableHover = false;
  shouldShowDefaultCursor = false;

  // if a column is marked as 'actionable' and hidden, we render it with display: none, needed because of how the action is executed(DOM)
  // the 'other' hidden columns are not rendered at all
  hiddenRowActionColumn: string;

  readonly footerContents = FooterContent;

  private subscriptions: Subscription = new Subscription();

  private resizeObserver: ResizeObserver;

  @Input() set tableWidgetOptions(options: TableWidgetOptions) {
    this._tableWidgetOptions = options;
    // we need to reinitialize the component (similarly to the ngOnInit) when options change
    if (this.inEditMode) {
      if (this._tableWidgetOptions?.customColumnDefinitionConfig) {
        this.setColumnSelectorStates();
      }
      this.filterService.setFilters(this.filters || {});
      this.updateData(this.start);
    }

    this.updateTableHoverAndCursor();
  }
  _tableWidgetOptions: TableWidgetOptions = new TableWidgetOptions();

  @Input() data: any;

  /**
   * Data to be primarily resolved in placeholders
   */
  @Input() context: any = {};

  @Input() filters: Record<string, Filter[]>;

  @Input() layout: DashboardLayout = DashboardLayout.ColumnBased;

  @Input() inEditMode = false;

  @ViewChild('tableView') tableView: ElementRef;

  @ViewChildren('paginationBar', { read: ElementRef }) paginationBars: QueryList<ElementRef>;
  @ViewChildren('actionTableButtons')
  actionTableButtons: QueryList<ActionTableButtonComponent>;

  @ViewChildren('actionLinks')
  actionLinks: QueryList<ActionLinkComponent>;

  @ViewChild(SortDirective) sortDirective: SortDirective;

  @Output() filtersChanged = new EventEmitter<Record<string, Filter[]>>();

  @HostListener('window:resize') onResize() {
    if (this.hasFixedColumn()) {
      this.triggerColumnsResetAndResize();
    }
  }

  get showPaginationBar() {
    return (
      (!!this.context['widgetId'] || this.inEditMode) &&
      this.pageData.totalElements > Math.min(...this.limits)
    );
  }

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private hyperlinkService: HyperlinkService,
    private viewContainerRef: ViewContainerRef,
    private changeDetectorRef: ChangeDetectorRef,
    private tableWidgetStateService: TableWidgetStateService,
    public filterService: TableFilterService,
    public deviceTypeService: DeviceTypesService,
    private dataConditionalFormattingPipe: DataConditionalFormattingPipe,
    public translatedPipe: TranslatedPipe,
    private projectsService: ProjectsService,
    private widgetInstanceService: WidgetInstanceService,
    @Optional() private dashboardGridService: DashboardGridService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    this.parseTableRowLimitIfString();

    if (changes.customColumnDefinitions?.currentValue) {
      this.updateColumnSelectorStatesFromDefinitions();
    }
    if (
      changes.tableRowLimit ||
      changes.showCustomColumnDefinition ||
      changes.context ||
      changes.customColumnDefinitionConfig ||
      changes.customColumnDefinitions ||
      changes.data
    ) {
      if (this.inEditMode) {
        this.tableWidgetStateService.setPaginationSettingsServiceLimit(
          this._tableWidgetOptions.tableRowLimit
        );
        this.setPaginationLimits();
        setTimeout(() => this.updateArrowDirection());
      }
      this.updateData(this.start, !!changes.customColumnDefinitions || !!changes.data);
      this.noDataFound = !this.data?.length;
    }

    if (changes.maxVisibleRows && this.inEditMode) {
      this.calculateTableHeight();
    }
    if (this._tableWidgetOptions.footerContent !== FooterContent.NONE) {
      this.calculateFooter();
    }
  }

  ngOnInit() {
    if (this._tableWidgetOptions.customColumnDefinitionConfig) {
      this.setColumnSelectorStates();
    }
    this.filterService.setFilters(this.filters || {});
    this.noDataFound = !this.data?.length;
    this.updateData(this.start);
    this.setPaginationLimits();
    this.updatePaginationData();
    this.initSubscriptions();
    this.observeResize();
    this.calcNoDataOverlayTableRows();
  }

  ngAfterViewInit() {
    // for image type columns the calculation is not correct without setTimeout
    if (this.tableView?.nativeElement) {
      setTimeout(() => {
        this.resizeObserver?.observe(this.tableView.nativeElement);
        this.updateArrowDirection();
      });
    }
    this.calculateTableHeight();
  }

  ngOnDestroy() {
    if (this.tableView?.nativeElement) {
      this.resizeObserver?.unobserve(this.tableView.nativeElement);
    }
    this.subscriptions.unsubscribe();
  }

  initSubscriptions() {
    if (this.hasDeviceImage()) {
      this.subscriptions.add(this.deviceTypeService.getDeviceTypes().subscribe());
    }

    this.subscriptions.add(
      this.filterService.filters$.subscribe((filters) => {
        this.filtersChanged.emit(filters);
      })
    );
    this.subscriptions.add(
      this.widgetInstanceService.containerResized.subscribe(() => this.calculateTableHeight())
    );
  }

  handleRowClick(rowIndex: number) {
    const { rowClickActionColumnName } = this._tableWidgetOptions;
    const isNotExcludedAction = ![RowClickAction.HIGHLIGHT, RowClickAction.INACTIVE].includes(
      rowClickActionColumnName
    );

    if (isNotExcludedAction && !isEmpty(rowClickActionColumnName)) {
      this.executeRowAction(rowIndex, rowClickActionColumnName);
    }
  }

  private executeRowAction(row: number, column: string) {
    const actionButton = this.findActionComponent(this.actionTableButtons, row, column);

    if (actionButton) {
      if (!actionButton.disabledByCondition) {
        actionButton.makeRestCall();
      }
      return;
    }

    const actionLink = this.findActionComponent(this.actionLinks, row, column);

    if (actionLink) {
      const actionLinkElement = actionLink.elementRef?.nativeElement?.querySelector('a');
      if (actionLinkElement) {
        actionLinkElement.click();
      }
    }
  }

  private findActionComponent<T>(
    components: QueryList<T>,
    row: number,
    column: string
  ): T | undefined {
    return components.find(
      (component: any) =>
        component.tableContext.row === row && component.tableContext.column === column
    );
  }

  uncheckCheckboxesAndResetBatchActionButtonData() {
    for (const cells of this.entries) {
      cells[0].checked = false;
    }
    this.batchActionButtonData = [];
  }

  buildBatchActionButtonData(isChecked: boolean, cells: EntryCell[], rowIndex: number) {
    if (isChecked) {
      const currentCellData: BatchActionButtonData = cells.reduce((acc, cell) => {
        const data =
          cell.type !== 'richText' ? { [cell.path[0].toLowerCase()]: cell.value, rowIndex } : {};

        return { ...acc, ...data };
      }, {} as BatchActionButtonData);

      this.batchActionButtonData = [...this.batchActionButtonData, currentCellData];
    } else {
      this.batchActionButtonData = this.batchActionButtonData.filter(
        (data) => data.rowIndex !== rowIndex
      );
    }
  }

  jsonEditorMaxLines(valueFormatingConfig: ValueFormattingResolvedConfig) {
    return (valueFormatingConfig as ValueFormattingJsonConfig<string>).jsonEditorMaxLines;
  }

  isColumnHeaderHidden(idx: number) {
    return this.filteredCustomColumnDefinitions[idx]?.columnNameHidden;
  }

  isColumnHeaderFixed(idx: number) {
    return this.filteredCustomColumnDefinitions[idx]?.fixedHorizontal;
  }

  isFixedColumnStriped(cell: EntryCell) {
    if (cell.type === 'array') {
      return this._tableWidgetOptions.showTableStriped && cell?.fixedHorizontal;
    }
    return (
      this._tableWidgetOptions.showTableStriped && !cell.backgroundColor && cell?.fixedHorizontal
    );
  }

  isCustomMinWidthSet(idx: number) {
    return this.filteredCustomColumnDefinitions[idx]?.columnMinWidth;
  }

  getCustomMinWidth(idx: number) {
    return this.filteredCustomColumnDefinitions[idx]?.columnMinWidthValuePx;
  }

  isWrapTextSet(cell: EntryCell) {
    let index: number;
    for (const entryList of this.entries) {
      const foundIndex = entryList.indexOf(cell);
      if (foundIndex !== -1) {
        index = foundIndex;
        break;
      }
    }
    return this.filteredCustomColumnDefinitions[index].wrapText !== false;
  }

  parseTableRowLimitIfString() {
    if (
      this._tableWidgetOptions?.tableRowLimit &&
      typeof this._tableWidgetOptions.tableRowLimit === 'string'
    ) {
      this._tableWidgetOptions.tableRowLimit = parseInt(this._tableWidgetOptions.tableRowLimit);
    }
  }

  getStorageKeyByFeature(featureType: 'columnVisibility' | 'tableWidgetLimit') {
    return `${this.context['dashboardName']}_${this.context['widgetId']}_${featureType}`;
  }

  updateData(start = 0, refreshCache = false) {
    this.columns = [];
    this.entries = [];
    this.start = start;
    this.triggerTableResize();

    if (
      !this.data ||
      (this._tableWidgetOptions.showCustomColumnDefinition &&
        this._tableWidgetOptions.customColumnDefinitionConfig === undefined) ||
      (this._tableWidgetOptions.showCustomColumnDefinition && !this.columnSelectorStates?.length)
    ) {
      if (this._tableWidgetOptions.footerContent !== FooterContent.NONE) {
        this.calculateFooter();
      }
      return;
    }

    const dataAsArray = castArray(this.data);

    if (this._tableWidgetOptions.showCustomColumnDefinition && this.path.length <= 0) {
      this.identifyCustomColumns();
      this.entries = createCustomColumnEntries(
        dataAsArray,
        this.filteredCustomColumnDefinitions,
        this.context,
        this.hyperlinkService,
        this.dataConditionalFormattingPipe,
        this.projectsService.projectName
      );
    } else {
      const resolvedPath = resolvePath(
        this.context?.insights?.language,
        this.path,
        this._tableWidgetOptions.customColumnDefinitionConfig,
        this._tableWidgetOptions.showCustomColumnDefinition
      );
      const resolvedValues = [...getDataPathIterator(dataAsArray, resolvedPath)];
      this.identifyColumns(resolvedValues);
      resolvedValues.forEach((document) => this.createEntry(document));
    }
    this.paths = getPaths(this.path);
    this.convertedData = this.entries;
    this.updateFilterKeys();
    this.updateFilteredData(
      this.filterService.filteredData(this.joinedPath, this.convertedData, !refreshCache)
    );

    if (this._tableWidgetOptions.footerContent !== FooterContent.NONE) {
      this.calculateFooter();
    }
  }

  updateFilterKeys() {
    if (this.filters && this.filters[this.joinedPath]) {
      const filters = this.filters[this.joinedPath].map((filter) => ({
        ...filter,
        keyIndex: this.columns.findIndex((val) => val === filter.key)
      }));
      this.filterService.setPathFilters(this.joinedPath, filters);
    }
  }

  updatePaginationData(start = 0) {
    const limit = this.getPaginationSetting();
    let startIndex = start * limit;
    if (startIndex > this.entries.length) {
      start = Math.floor(this.entries.length / limit);
      startIndex = start * limit;
      this.start = start;
    }
    const endIndex = startIndex + limit;

    this.entries = this.entries.slice(startIndex, endIndex);
    this.pageData = new PageData({
      size: limit,
      number: start,
      numberOfElements: this.entries.length,
      totalElements: this.filteredData.length
    });
  }

  updateSort(sortEntry: Sort) {
    if (this.isRowSelectionEnabled) {
      this.uncheckCheckboxesAndResetBatchActionButtonData();
    }

    this.sort = sortEntry;
    this.updateData(this.pageData ? this.pageData.number : 0);
  }

  hasFixedColumn(): boolean {
    return this.filteredCustomColumnDefinitions.some((column) => column?.fixedHorizontal);
  }

  updatePath(path: string[]) {
    this.sort = null;
    this.path = path;
    this.updateData();
  }

  private createEntry(data: any) {
    const entry: EntryCell[] = [];
    this.columns.forEach((key) => {
      const value = data ? data[key] : undefined;
      entry.push(getCellInfo(value, [...this.path, key]));
    });
    this.entries.push(entry);
  }

  private identifyColumns(resolvedValues: any[]) {
    resolvedValues.forEach((value) => {
      if (!isEmpty(value)) {
        Object.keys(value).forEach((key) => {
          if (!this.columns.includes(key) && this.columns.length < 50) {
            this.columns.push(key);
          }
        });
      }
    });
  }

  private updateTableHoverAndCursor() {
    this.shouldShowTableHover =
      this._tableWidgetOptions?.rowClickActionColumnName !== RowClickAction.INACTIVE ||
      this._tableWidgetOptions?.showTableHover;
    this.shouldShowDefaultCursor =
      this._tableWidgetOptions?.rowClickActionColumnName === RowClickAction.HIGHLIGHT ||
      this._tableWidgetOptions?.showTableHover;
  }

  private updateArrowDirection() {
    if (
      this._tableWidgetOptions.showCustomColumnDefinition &&
      this._tableWidgetOptions.customColumnDefinitionConfig?.length
    ) {
      const sortedCustomColumn = this._tableWidgetOptions.customColumnDefinitionConfig.find(
        (column: CustomColumnDefinition) => !!column.sort?.id
      );
      if (sortedCustomColumn && this.sortDirective?.activeSort) {
        this.sortDirective.activeSort = {
          id: sortedCustomColumn.sort.id,
          direction: sortedCustomColumn.sort.direction
        };
        this.sortDirective.stateChanges$.next();
      }
    }
  }

  private identifyCustomColumns() {
    this.hiddenRowActionColumn = '';
    this.filteredCustomColumnDefinitions =
      this._tableWidgetOptions.customColumnDefinitionConfig.filter((definition) => {
        const key = definition.label?.en;
        const isVisible = this.columnSelectorStates.find((item) => item.item === key)?.state;
        const isRowClickAction = key === this._tableWidgetOptions.rowClickActionColumnName;

        if (!isVisible && isRowClickAction) {
          this.hiddenRowActionColumn = this.translatedPipe.transform(definition.label);
        }
        return isVisible || isRowClickAction;
      });
    this.changeOrderOfFixedColumns();
    this.columns = this.filteredCustomColumnDefinitions.map((element) =>
      this.translatedPipe.transform(element.label)
    );
  }

  filterAllowed(idx: number, key: string): boolean {
    const valueFormattingConfig = this.valueFormatting(key);
    return (
      this._tableWidgetOptions.showFilter &&
      (this.convertedData?.some((row) =>
        ['atomic', 'button', 'link', 'richText'].includes(row[idx].type)
      ) ||
        valueFormattingConfig?.multipleValues)
    );
  }

  filterApplied(key: string): boolean {
    return !!this.filterService.filterByKey(this.joinedPath, key);
  }

  filtersApplied(): boolean {
    return !!this.filterService.filtersByPath(this.joinedPath).length;
  }

  clearFilters() {
    this.triggerColumnsResetAndResize();
    this.filterService.removeAllFilters(this.joinedPath);
    this.updateFilteredData(this.filterService.filter(this.convertedData, this.joinedPath));
  }

  get joinedPath() {
    return this.paths.map((p) => p.key).join('.');
  }

  valueFormatting(key: string): ValueFormattingConfig {
    return this.filteredCustomColumnDefinitions?.find(
      (def) => this.translatedPipe.transform(def.label) === key
    )?.valueFormatting;
  }

  closeFilterCallback(filteredData: EntryCell[][]) {
    if (this.isRowSelectionEnabled) {
      this.uncheckCheckboxesAndResetBatchActionButtonData();
    }

    this.triggerColumnsResetAndResize();
    this.updateFilteredData(filteredData);
  }

  openFilter(key: string, element: HTMLElement) {
    const valueFormatting = this.valueFormatting(key);
    const dataType = valueFormatting?.dataType || DataFormattingInputType.STRING;

    this.filterService.openFilter(
      {
        anchor: element,
        path: this.joinedPath,
        key,
        keyIndex: this.columns.indexOf(key),
        entries: this.convertedData,
        dataType
      },
      this.viewContainerRef,
      this.closeFilterCallback.bind(this)
    );
  }

  private updateFilteredData(data: EntryCell[][]) {
    this.entries = getSortedEntryCells(data, this.columns, this.sort);
    this.filteredData = this.entries;
    this.updatePaginationData(this.start);
  }

  private hasDeviceImage() {
    return containsDeviceImageColumn(this.filteredCustomColumnDefinitions);
  }

  hasTextResponse(cell: EntryCell): boolean {
    return typeof cell.value.restResponse?.response === 'string';
  }

  updateStateOfItem(index: number, $event: boolean) {
    this.columnSelectorStates[index].state = $event;
    if (!this.inEditMode) {
      this.tableWidgetStateService.updateColumnVisibilityParameter(this.columnSelectorStates);
    }
    this.updateData(this.start, true);
    this.triggerTableResize();
  }

  setColumnSelectorStates() {
    if (!this.inEditMode && this.isStoredColumnVisibilityValid) {
      this.columnSelectorStates = cloneDeep(
        this.tableWidgetStateService.getColumnVisibilityParameter('columnVisibility')
      );
    } else {
      this.updateColumnSelectorStatesFromDefinitions();
    }
  }

  get isStoredColumnVisibilityValid() {
    const columnSelectorsStatesFromLocalStorage =
      this.tableWidgetStateService.getColumnVisibilityParameter('columnVisibility');
    if (!columnSelectorsStatesFromLocalStorage) {
      return false;
    }
    const columnLabelFromLocalStorage = columnSelectorsStatesFromLocalStorage.map(
      (item) => item.item
    );
    const columnLabelFromDefinitions = this._tableWidgetOptions.customColumnDefinitionConfig
      .filter((def) => def.label)
      .map((def) => def.label?.en);
    return isEqual(columnLabelFromDefinitions, columnLabelFromLocalStorage);
  }

  updateColumnSelectorStatesFromDefinitions() {
    this.columnSelectorStates = this._tableWidgetOptions.customColumnDefinitionConfig
      .filter((item) => this.translatedPipe.transform(item.label))
      .map((item) => {
        return {
          item: item.label?.en,
          state: item.defaultVisibility === undefined ? true : item.defaultVisibility
        } as ItemWithState;
      });
    this.changeDetectorRef.detectChanges();
  }

  private changeOrderOfFixedColumns() {
    if (this.filteredCustomColumnDefinitions.length) {
      let fixedColumnIndex = 0;
      this.filteredCustomColumnDefinitions.forEach(
        (column: CustomColumnDefinition, index: number) => {
          if (column?.fixedHorizontal) {
            const fixedColumn = this.filteredCustomColumnDefinitions[index];
            this.filteredCustomColumnDefinitions.splice(index, 1);
            this.filteredCustomColumnDefinitions.splice(fixedColumnIndex, 0, fixedColumn);
            fixedColumnIndex++;
          }
        }
      );
    }
  }

  private calculateTableHeight() {
    if (this._tableWidgetOptions.maxVisibleRows === this._tableWidgetOptions.tableRowLimit) {
      this.tableMaxHeight = 'none';
      return;
    }

    if (this.widgetInstanceService.layoutBehavior === LayoutBehavior.Fixed) {
      let tableBodyHeight = this.tableView?.nativeElement.closest('.widget-body')?.offsetHeight;
      if (this.showPaginationBar) {
        const paginationBarsHeight = this.paginationBars.toArray().reduce((acc, el: any) => {
          return acc + outerHeight(el.nativeElement.firstChild);
        }, 0);
        tableBodyHeight = tableBodyHeight - paginationBarsHeight;
      }
      this.triggerTableResize(tableBodyHeight - 24 + 'px');
      return;
    }
    if (this._tableWidgetOptions.maxVisibleRows && this.tableView) {
      const tableDiv = this.tableView.nativeElement.parentElement;
      const hasScrollbar = tableDiv.scrollWidth > tableDiv.clientWidth;

      const tableHeaderHeight =
        this.tableView.nativeElement
          ?.getElementsByTagName('thead')
          ?.item(0)
          ?.getBoundingClientRect().height + 1 || 1;
      const tableRowsHeights = [...this.tableView.nativeElement.querySelectorAll('tbody tr')].map(
        (row) => row.getBoundingClientRect().height
      );
      let tableHeightInPixels = tableRowsHeights
        .slice(0, this._tableWidgetOptions.maxVisibleRows)
        .reduce((acc, row) => acc + row, tableHeaderHeight);

      if (hasScrollbar) {
        const scrollbarWidthDimension = tableDiv.offsetWidth - tableDiv.clientWidth;

        // Add the scrollbar width to the total height
        tableHeightInPixels += scrollbarWidthDimension;
      }

      this.triggerTableResize(`${tableHeightInPixels}px`);
    }
  }

  private observeResize(): void {
    this.resizeObserver = new ResizeObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.target.tagName === 'TABLE') {
          this.calculateTableHeight();
        }
      });
    });
  }

  search() {
    this.triggerColumnsResetAndResize();
    const filteredData = this.filterService.filter(this.convertedData, this.joinedPath);
    this.updateFilteredData(filteredData);
  }

  showJsonInPopover(valueFormatingConfig: ValueFormattingResolvedConfig) {
    return (valueFormatingConfig as ValueFormattingJsonConfig<string>).showInPopover ? true : false;
  }

  storePaginationSetting(event: PaginationAction) {
    if (this.context['widgetId']) {
      const pageSetting = {
        limit: event.userAction ? this.pageData.size : event.size,
        limits: this.limits
      };

      this.tableWidgetStateService.updateTablePaginationSettingsParameter(pageSetting);
    } else if (this.inEditMode) {
      this.tableWidgetStateService.setPaginationSettingsServiceLimit(event.size);
    }
  }

  getPaginationSetting(): number {
    if (this.context['widgetId']) {
      return this.tableWidgetStateService.restorePaginationSettings(
        this._tableWidgetOptions.tableRowLimit
      );
    }
    return this.tableWidgetStateService.getPaginationSettingsServiceLimit();
  }

  setPaginationLimits() {
    this.limits = [10, 20, 50, 100];
    if (this.filteredData.length > 100 && this.filteredData.length <= 250) {
      this.limits = [10, 20, 50, 100, 250];
    } else if (this.filteredData.length > 250) {
      this.limits = [10, 20, 50, 100, 250, 1000];
    }
    if (
      this._tableWidgetOptions?.tableRowLimit &&
      !this.limits.includes(this._tableWidgetOptions.tableRowLimit)
    ) {
      this.limits.unshift(this._tableWidgetOptions.tableRowLimit);
    }
  }

  calculateFooter() {
    this.calculatedFooter = [];
    if (!this.entries?.length) {
      return;
    }
    for (let columnIndex = 0; columnIndex < this.entries[0].length; columnIndex++) {
      this.calculatedFooter[columnIndex] = undefined;
      let counted = 0;
      this.entries.forEach((row) => {
        const cell = row[columnIndex].value;
        if (!Number.isFinite(parseFloat(cell))) {
          return;
        }
        this.calculatedFooter[columnIndex] = this.calculatedFooter[columnIndex]
          ? this.calculatedFooter[columnIndex]
          : 0;
        counted++;
        this.calculatedFooter[columnIndex] += Number(cell);
      });
      if (this._tableWidgetOptions.footerContent === FooterContent.AVERAGE && counted > 0) {
        this.calculatedFooter[columnIndex] /= counted;
      }
    }
  }

  /*
   * Triggers a change in maxHeight attribute to fire a re-calculation on library-level.
   * Avoid using window.dispatchEvent(), as it causes the content to jump around.
   * */
  triggerTableResize(height?: string) {
    this.tableMaxHeight = String(height || this.tableMaxHeight);
    this.changeDetectorRef.detectChanges();
  }

  /**
   * Reset columns to fire re-calculation on the library-level for the table header.
   */
  triggerColumnsResetAndResize() {
    if (
      this._tableWidgetOptions.showCustomColumnDefinition &&
      this._tableWidgetOptions.customColumnDefinitionConfig?.length
    ) {
      this.columns = [];
      this.triggerTableResize();
      this.identifyCustomColumns();
    }
  }

  private calcNoDataOverlayTableRows() {
    if (
      this.noDataFound &&
      this.dashboardGridService.widgets &&
      this.dashboardGridService.gridster
    ) {
      const currentWidgetConfig = this.dashboardGridService.widgets.find(
        (config) => config.id === this.widgetInstanceService.id
      );
      if (
        currentWidgetConfig &&
        currentWidgetConfig.rows &&
        this.dashboardGridService.gridster.curRowHeight
      ) {
        const rowHeightInPx = 60;
        const height =
          (currentWidgetConfig.rows - 2) * this.dashboardGridService.gridster.curRowHeight;
        // calculate how many empty rows to show, deduct 1 because of the header
        this.noDataOverlayTableRows = Math.max(Math.floor(height / rowHeightInPx) - 1, 0);
      }
    }
  }

  protected readonly BackgroundColorTarget = BackgroundColorTarget;
}
