import { Component, Input } from '@angular/core';
import { EntryCell } from '../table-view.model';
import { ImageType } from '../../../../dashboards/widgets/table-view-widget/table-view-widget-edit/table-view-widget.model';
import { DeviceTypeDefinition } from '../../../../devices/models/device-types';
import { isString } from 'lodash-es';

const base64regex = /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;

@Component({
  selector: 'table-image-display',
  templateUrl: './table-image-display.component.html'
})
export class TableImageDisplayComponent {
  @Input() cell: EntryCell;
  @Input() deviceTypes: DeviceTypeDefinition[];

  imageType = ImageType;

  showImage(imageSrc: string): boolean {
    if (!imageSrc) {
      return false;
    }
    if (this.cell.value.imageType === this.imageType.BASE_64) {
      return this.isValidBase64Image(imageSrc);
    } else {
      return true;
    }
  }

  isValidBase64Image(imageSrc: string): boolean {
    return (
      imageSrc &&
      isString(imageSrc) &&
      imageSrc.startsWith('data:image/') &&
      base64regex.test(imageSrc.split(',')[1])
    );
  }
}
