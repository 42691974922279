import { Location } from '@angular/common';
import {
  Component,
  ComponentRef,
  ElementRef,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { LoadingEntity, ModalService } from '@inst-iot/bosch-angular-ui-components';
import { LocalStorageService } from 'ngx-localstorage';
import { combineLatest, concat, EMPTY, Observable, of } from 'rxjs';
import { switchMap, takeLast, tap } from 'rxjs/operators';
import { ProjectUrlPipe } from '../../shared-projects/pipes/project-url.pipe';
import { DashboardSelectionComponent } from '../dashboard-selection/dashboard-selection.component';
import { DashboardWidgetConfig, LayoutBehavior } from '../models/DashboardWidgetConfig';
import {
  WidgetEditComponentBase,
  WidgetTypeDefinition,
  WidgetVisibility
} from '../models/widget-registry.model';
import { DashboardDataService } from '../services/dashboard-data.service';
import { NavigationBackService } from '../services/navigation-back.service';
import { WidgetsRegistryService } from '../services/widgets-registry.service';

const size2Columns = {
  full: 12,
  'two-thirds': 8,
  half: 6,
  third: 4,
  quarter: 3
};

@Component({
  selector: 'widget-edit',
  templateUrl: './widget-edit.component.html'
})
export class WidgetEditComponent implements OnInit, OnDestroy {
  @Input() type: string; // widget type
  @Input() id: string; // widget id if available

  size = 'full';

  visibility: WidgetVisibility = 'VISIBLE';

  selectedRoles: string[] = ['admin'];

  layoutBehavior: LayoutBehavior;

  dataLoader = new LoadingEntity();

  typeDefinition: WidgetTypeDefinition;

  widget: DashboardWidgetConfig;

  editComponentRef: ComponentRef<WidgetEditComponentBase>;

  loadingEntity = new LoadingEntity<any>();

  error = null;

  isOnDashboard = false;

  previewTpl: TemplateRef<any>;

  title = { en: '' };

  layoutBehaviorEnum = LayoutBehavior;

  ignoreColumns = false;

  customWidgetsComponentName: string;

  @ViewChild('widgetEditTarget', { static: true }) widgetEditTarget: ElementRef;

  @ViewChild('form', { static: true }) form: NgForm;

  constructor(
    public dashboardService: DashboardDataService,
    private widgetRegistry: WidgetsRegistryService,
    private injector: Injector,
    private viewContainerRef: ViewContainerRef,
    private modalService: ModalService,
    private location: Location,
    private projectUrl: ProjectUrlPipe,
    private localStorageService: LocalStorageService,
    private navigationBackService: NavigationBackService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.parseDashboardConfigAndSetProperties();
    this.createWidgetEditComponentRef();
    this.widgetEditTarget.nativeElement.appendChild(this.editComponentRef.location.nativeElement);
  }

  ngOnDestroy(): void {
    if (this.editComponentRef) {
      const index = this.viewContainerRef.indexOf(this.editComponentRef.hostView);
      if (index !== -1) {
        this.viewContainerRef.detach(index);
      }
    }
  }

  parseDashboardConfigAndSetProperties() {
    this.typeDefinition = this.widgetRegistry.getTypeDefinition(this.type);
    if (this.dashboardService.currentConfig) {
      this.isOnDashboard = true;
    }
    if (this.id && this.dashboardService.currentConfig) {
      this.widget = JSON.parse(JSON.stringify(this.dashboardService.getWidget(this.id)));
      if (this.dashboardService.isGridLayout()) {
        this.layoutBehavior = this.widget.layoutBehavior ?? LayoutBehavior.Adapt;
      }
    } else {
      this.widget = this.createEmptyWidget();
      this.layoutBehavior = this.widget.layoutBehavior;
    }

    this.size = Object.keys(size2Columns).find(
      (size) => size2Columns[size] === this.widget.columns
    );
    this.visibility = this.widget.visibility ?? 'VISIBLE';
    if (this.widget.properties['title']) {
      this.title = this.widget.properties['title'];
    }

    this.selectedRoles = this.widget?.selectedRoles ?? [];

    this.ignoreColumns = this.dashboardService.isGridLayout();
    this.customWidgetsComponentName =
      this.widget?.properties?.customWidgetsComponentName ||
      this.route.snapshot?.queryParams?.preset;
  }

  createEmptyWidget(): DashboardWidgetConfig {
    return new DashboardWidgetConfig({
      type: this.type,
      columns: this.typeDefinition.defaultColumns || 12,
      properties: {
        title: this.typeDefinition.defaultTitle || { en: '' },
        lazyLoading: true
      },
      layoutBehavior: this.dashboardService.isGridLayout() ? LayoutBehavior.Fixed : null
    });
  }

  createWidgetEditComponentRef() {
    const widgetType = this.widgetRegistry.getTypeDefinition(this.widget.type);
    if (!widgetType) {
      this.error = 'Could not load widget of type ' + this.widget.type;
      return;
    }
    this.editComponentRef = this.viewContainerRef.createComponent(widgetType.editComponent, {
      injector: this.injector
    });
    this.editComponentRef.instance.properties = this.widget.properties;
    this.editComponentRef.instance.widgetId = this.widget.id;
    this.editComponentRef.instance.dashboardName =
      this.dashboardService.currentConfig?.name ?? null;

    this.editComponentRef.instance.registerPreviewTemplate = (previewTpl) => {
      this.previewTpl = previewTpl;
    };
  }

  navigateBackWithFilterParametersIfExist() {
    if (this.dashboardService.getFilterParameter()?.length) {
      this.navigationBackService.setLocationBackUsed(true);
      this.location.back();
    } else {
      this.projectUrl.navigate(this.dashboardService.getDashboardUrlParts());
    }
  }

  saveInDashboardContext() {
    this.modalService
      .openComponent(DashboardSelectionComponent, null, this.injector)
      .result.then((name) => {
        if (!name) {
          return;
        }
        this.loadingEntity
          .run(this.dashboardService.getDashboardConfig(name))
          .subscribe((dashboard) => {
            this.dashboardService.updateConfig(dashboard);
            this.editComponentRef.instance.dashboardName =
              this.dashboardService.currentConfig?.name ?? null;
            this.isOnDashboard = true;
            this.save();
          });
      });
  }

  save(back = false) {
    if (!this.isOnDashboard) {
      this.saveInDashboardContext();
      return;
    }

    this.widget.columns = this.ignoreColumns ? this.widget.columns : size2Columns[this.size];
    this.widget.visibility = this.visibility ?? 'VISIBLE';
    this.widget.properties['title'] = this.title;
    this.widget.selectedRoles = this.selectedRoles ?? [];
    this.changeLayoutBehavior();
    this.editComponentRef.instance.updateProperties();
    // get the properties from the edit component
    this.widget.properties = this.editComponentRef.instance.properties;

    const preSave: Observable<any> = this.editComponentRef.instance.preSave
      ? this.editComponentRef.instance.preSave()
      : EMPTY;
    const postSave: Observable<any> = this.editComponentRef.instance.postSave
      ? this.editComponentRef.instance.postSave()
      : EMPTY;

    if (!this.id) {
      if (this.typeDefinition.uniqueId) {
        this.widget.id = this.typeDefinition.uniqueId;
      }
      if (this.dashboardService.isGridLayout()) {
        this.widget.rows = this.typeDefinition.gridLayoutConfig?.defaultRowsCount ?? null;
      }

      this.loadingEntity
        .run(
          concat(
            preSave,
            this.dashboardService.addWidget(this.widget).pipe(
              tap((widget) => {
                this.editComponentRef.instance.widgetId = widget.id;
              })
            ),
            postSave
          ).pipe(takeLast(1))
        )
        .subscribe(() => {
          this.projectUrl.navigate(this.dashboardService.getDashboardUrlParts());
        });
    } else {
      this.loadingEntity
        .run(
          concat(preSave, this.dashboardService.saveWidget(this.widget), postSave).pipe(takeLast(1))
        )
        .subscribe(() => {
          this.resetTableViewLocalStorage();
          if (back) {
            this.navigateBackWithFilterParametersIfExist();
          }
        });
    }
  }

  remove(redirect = true) {
    const observables = [];
    let preRemove: Observable<any> = of(null);
    if (this.editComponentRef.instance?.preRemove) {
      preRemove = this.editComponentRef.instance.preRemove();
    }

    if (this.widget.insideTab) {
      const tabWidgets = this.dashboardService.currentConfig.widgets.filter(
        (w) => w.type === 'tabwidget'
      );
      tabWidgets.forEach((w) => {
        w.properties['tabConfig'].forEach((tab) => {
          if (tab.widgets.includes(this.widget.id)) {
            if (tab?.instances) {
              delete tab.instances;
            }
            const index = tab.widgets.indexOf(this.widget.id);
            tab.widgets.splice(index, 1);
            observables.push(this.dashboardService.saveWidget(w));
          }
        });
      });
      if (observables?.length) {
        preRemove = combineLatest([...observables, preRemove]);
      }
    }

    const piped = preRemove.pipe(
      switchMap(() => this.dashboardService.removeWidget(this.widget.id))
    );

    this.loadingEntity.run(piped).subscribe(() => {
      this.resetTableViewLocalStorage();
      if (redirect) {
        this.projectUrl.navigate(this.dashboardService.getDashboardUrlParts());
      }
    });
  }

  get isValid() {
    if (!this.editComponentRef || this.form.invalid) {
      return false;
    }
    if (this.editComponentRef.instance && this.editComponentRef.instance.isValid) {
      return this.editComponentRef.instance.isValid();
    }
    return !!this.editComponentRef.instance;
  }

  resetTableViewLocalStorage() {
    if (this.type === 'table-view' && this.dashboardService.currentConfig) {
      const storageKey = `${this.dashboardService.currentConfig.name}_${this.widget.id}_customColumnVisibility`;
      this.localStorageService.remove(storageKey);
    }
  }

  private changeLayoutBehavior() {
    // when changing from fixed to adapt layout, we need to re-set the rows count as some widgets has disableWidgetHeightBasedCalculation set to true
    // which will not automatically resize
    if (
      this.id &&
      this.widget.layoutBehavior === LayoutBehavior.Fixed &&
      this.layoutBehavior === LayoutBehavior.Adapt
    ) {
      this.widget.rows = this.typeDefinition.gridLayoutConfig?.defaultRowsCount ?? null;
    }
    this.widget.layoutBehavior = this.layoutBehavior;
  }
}
