import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseElementComponent } from 'slate-angular';
import { ImageElement, resolveIndent } from '../../slate.util';
import { BaseSelection } from 'slate';

@Component({
  selector: 'element-image',
  templateUrl: './element-image.component.html',
  styleUrls: ['element-image.component.scss']
})
export class ElementImageComponent extends BaseElementComponent<ImageElement> {
  @Output() imageSelected = new EventEmitter<BaseSelection>();
  @Output() editTriggered = new EventEmitter<BaseSelection>();

  @Input() canEdit: boolean;

  resolveIndent = resolveIndent;

  onContextChange() {
    super.onContextChange();
    if (!this.selection) {
      return;
    }

    // this part will ensure that for images that are not linked with a hyperlink, the edit pop up should appear on first click.
    if (!this.canEdit) {
      this.imageSelected.emit(this.selection);
    } else if (!this.isChildOfALink()) {
      this.editTriggered.emit(this.selection);
    }
  }

  evaluateEdit(retryCount = 0) {
    // this explicit call is required on click due to an underlying bug in the slate.
    // If the image element is already selected then slate will not trigger onContextChange for subsequent clicks until deselection happens from other user activity.
    super.onContextChange();
    if (this.selection && this.canEdit) {
      this.editTriggered.emit(this.selection);
    }

    if (!this.selection && retryCount === 0 && this.isChildOfALink()) {
      setTimeout(() => {
        this.evaluateEdit(retryCount + 1);
      }, 100);
    }
  }

  private isChildOfALink(): boolean {
    return this.nativeElement.parentElement?.getAttribute('slateLinkContainingImage') === 'true';
  }
}
