<div class="m-form-field">
  <div class="a-text-field" [class.no-label]="!label" [class.-error]="!!error">
    <label *ngIf="label" [for]="id + 'input'">
      <rb-render-tpl [tpl]="label"></rb-render-tpl>
    </label>

    <div
      class="input"
      contenteditable="true"
      #input
      [id]="id + 'input'"
      (blur)="onTouched()"
      (input)="updateValueFromEvent($event)"
    ></div>
  </div>

  <rb-form-errors [messages]="messages"></rb-form-errors>
</div>
