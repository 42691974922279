import {
  ChangeDetectorRef,
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  QueryList,
  Self,
  SimpleChanges
} from '@angular/core';
import {
  ControlValueAccessor,
  NgControl,
  UntypedFormControl,
  ValidationErrors
} from '@angular/forms';
import {
  FormValidationMessageDirective,
  LoadingEntity
} from '@inst-iot/bosch-angular-ui-components';
import { isEqual, uniqBy, uniqWith } from 'lodash-es';
import { Observable, of, Subject, Subscription, throwError } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { Device } from '../../../devices/models/device';
import { DeviceTypeDefinition } from '../../../devices/models/device-types';
import { DeviceTypesService } from '../../../devices/services/device-types.service';
import { DeviceSearchResult, DevicesService } from '../../../devices/services/devices.service';
import { PickList } from '../../../shared/pick-list';
import { getValueByPath } from '../../data-selection/data-info-util';
import { assembleDeviceSearchFilter } from '../device.utils';
import { PickListItem, PicklistItemData } from '../../picklist/model/picklist.model';

interface DeviceSearchSelectionList {
  thingId: string;
  label: string;
}

@Component({
  selector: 'device-search',
  templateUrl: './device-search.component.html'
})
export class DeviceSearchComponent implements OnInit, OnDestroy, ControlValueAccessor, OnChanges {
  @Input() usePickList = false;

  @Input() label;

  @Input() white = false;

  @Input() required = false;

  @Input() showImages = false;

  @Input() selectedDeviceTypes: string[] | string = [];

  @Input()
  set selectedDevicesByThingId(value: string[]) {
    if (!Array.isArray(value)) {
      return;
    }
    this._selectedDevicesByThingId = value;
  }

  _selectedDevicesByThingId: string[] = null;

  @Input() valuePath: string | undefined;

  @Input() advancedFilter = '';

  @Input() filterWidgetQuery: string = null;

  @Input() defaultValue: any;

  @Output() deviceSelected = new EventEmitter<Device>();

  @Output() displaySelectAllButton$ = new EventEmitter<boolean>();

  @Output() removeSelectedDevice = new EventEmitter<PicklistItemData<DeviceSearchSelectionList>>();

  @ContentChildren(FormValidationMessageDirective)
  messages: QueryList<FormValidationMessageDirective>;

  control: UntypedFormControl;

  thingId: string;

  thingLabel: string;

  disabled = false;

  deviceLimit = 10;

  deviceLabels: string[] = [];

  pickListAvailableItems: PickListItem[] = [];

  defaultItem: DeviceSearchSelectionList[] = [];

  devices: Device[] = [];

  term = '';

  currentTerm = '';

  labelsToThingId = {};

  hasMore = false;

  deviceTypes: DeviceTypeDefinition[] = [];

  loader = new LoadingEntity<any>();

  searchFunc;

  resetOrRemoveAllClicked$: Observable<string>;

  private resetOrRemoveAllClicked = new Subject<string>();

  get selectedDevicesList(): PickList<DeviceSearchSelectionList> {
    return this._selectedDevicesList;
  }

  private _selectedDevicesList: PickList<DeviceSearchSelectionList> = new PickList({
    itemSelectorId: 'label'
  });

  // search cursor we receive from things search requests
  private searchCursor: string;

  private fields = '';

  private sub: Subscription;

  constructor(
    @Optional() @Self() public ngControl: NgControl,
    private devicesService: DevicesService,
    private cd: ChangeDetectorRef,
    private deviceTypesService: DeviceTypesService
  ) {
    this.initFormControl();

    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }

    this.resetOrRemoveAllClicked$ = this.resetOrRemoveAllClicked.asObservable();
    this.searchFunc = this.search.bind(this);
  }

  get isSelectionMode(): boolean {
    return this._selectedDevicesByThingId?.length >= 0;
  }

  onChange = (value: string) => {
    // do nothing
  };
  onTouched = () => {
    // do nothing
  };

  ngOnInit() {
    this.initThingFields();
    this.resolveDeviceTypes();
    this.resolveSelectedDevices();

    // default item is not needed outside of picklist
    if (this.defaultValue && this.usePickList) {
      this.resolveDefaultDevice();
    }

    this.sub = this.control.valueChanges.subscribe((label) => this.updateValue(label));
  }

  ngOnDestroy(): void {
    this._selectedDevicesList.destroy();
    this.loader.complete();
    this.sub?.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      Array.isArray(changes.selectedDeviceTypes?.previousValue) &&
      changes.selectedDeviceTypes.previousValue.length
    ) {
      this.writeValue(null);
    }

    if (changes.selectedDeviceTypes && !changes.selectedDeviceTypes.firstChange) {
      this.searchCursor = null;
      this.initiateSearch();
    }
    if (changes.advancedFilter && !changes.advancedFilter.firstChange) {
      this.searchCursor = null;
      this.writeValue(null);
    }
  }

  resolveDeviceTypes(): void {
    this.deviceTypesService.getDeviceTypes().subscribe((deviceTypes: DeviceTypeDefinition[]) => {
      this.deviceTypes = deviceTypes;
    });
  }

  resolveSelectedDevices(): void {
    if (!this.isSelectionMode) {
      return;
    }

    this.devicesService
      .findDevicesByIds(this._selectedDevicesByThingId, '+thingId', true)
      .pipe(
        catchError(this.handleError),
        map((devices: Device[]) =>
          devices.map((device: Device) => {
            const label = this.getLabel(device);
            this.labelsToThingId[label] = device.thingId;
            return {
              thingId: device.thingId,
              label
            } as DeviceSearchSelectionList;
          })
        )
      )
      .subscribe((items: DeviceSearchSelectionList[]) => {
        this._selectedDevicesList.selectedItems = items;
        this._selectedDevicesList.itemList = items;
      });
  }

  resolveDefaultDevice() {
    this.devicesService
      .findDevicesByIds(this.defaultValue, '+thingId', true)
      .pipe(
        catchError(this.handleError),
        map((devices: Device[]) =>
          devices.map((device) => ({ thingId: device.thingId, label: this.getLabel(device) }))
        )
      )
      .subscribe((items: DeviceSearchSelectionList[]) => (this.defaultItem = items));
  }

  initiateSearch() {
    this.loader.run(this.search('')).subscribe();
  }

  private initThingFields() {
    const thingFields = [
      'thingId',
      'features/general',
      'features/images',
      'features/bookingColor',
      'attributes/type'
    ];

    if (this.valuePath && !thingFields.some((field) => this.valuePath.startsWith(field))) {
      thingFields.push(this.valuePath);
    }
    this.fields = thingFields.join(',');
  }

  getLabelByThingId(thingId: string): Observable<string> {
    const found = Object.entries(this.labelsToThingId).find(([label, id]) => id === thingId);
    if (found) {
      return of(found[0]);
    }
    return this.devicesService.getDevice(thingId).pipe(
      map((device: Device) => {
        const label = this.getLabel(device);
        this.labelsToThingId[label] = device.thingId;
        return label;
      })
    );
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    if (isDisabled) {
      this.control.disable();
    } else {
      this.control.enable();
    }
  }

  writeValue(thingId: string): void {
    this.thingId = thingId;
    if (!thingId) {
      this.thingLabel = '';
      this.control.patchValue('');
      return;
    }
    this.loader
      .run(
        this.getLabelByThingId(thingId).pipe(
          catchError((e) => {
            this.writeValue(null);
            const errors: ValidationErrors = {};
            errors['thingIdNotFound'] = true;
            this.control.setErrors(errors, { emitEvent: true });
            return throwError(e);
          })
        )
      )
      .subscribe((label) => {
        this.thingLabel = label;
        this.control.patchValue(label, { emitEvent: true });
      });
  }

  updateValue(label: string) {
    if (label === '') {
      this.onChange('');
    } else {
      this.thingId = this.labelsToThingId[label];
      this.control.markAsDirty();
      this.onChange(this.thingId);
      this.control.updateValueAndValidity({ emitEvent: false });
    }

    if (this.thingId) {
      this.loader
        .run(this.devicesService.getDevice(this.thingId, true))
        .subscribe((device: Device) => {
          this.deviceSelected.next(device);
          if (this.isSelectionMode) {
            this.addSelectedDevice({ thingId: device.thingId, label });
            this.control.patchValue('', { emitEvent: false });
          }
        });
    }
  }

  private addSelectedDevice(label: DeviceSearchSelectionList) {
    if (!this.isSelectionMode) {
      return;
    }

    this._selectedDevicesList.selectItem(label);

    if (this._selectedDevicesList.filteredItems.length === 0 && !this.usePickList) {
      this.displaySelectAllButton$.emit(false);
      this.initiateSearch();
    }
  }

  search(text: string, deviceLimit = 50): Observable<string[]> {
    if (typeof text !== 'string') {
      return of([]);
    }
    this.term = text.trim();
    if (this.term === this.thingLabel) {
      this.term = '';
    }

    if (this.currentTerm !== this.term) {
      this.searchCursor = null;
    }

    if (this.searchCursor === null) {
      this.pickListAvailableItems = [];
      this.deviceLabels = [];
    }
    this.currentTerm = this.term;

    if (this.term.includes(' ')) {
      this.term = this.term.split(' ').join('*');
    }

    return this.devicesService.getNamespace().pipe(
      switchMap((namespace) => {
        const filter = assembleDeviceSearchFilter({
          namespace,
          selectionModeFilter:
            this.isSelectionMode && !this.usePickList ? this.getSelectionModeFilter() : null,
          advancedFilter: this.advancedFilter,
          filterWidgetQuery: this.filterWidgetQuery,
          term: this.term,
          selectedDeviceTypes: this.selectedDeviceTypes,
          valuePath: this.valuePath
        });
        return this.devicesService.findDevices(
          filter,
          this.searchCursor,
          deviceLimit,
          '+features/general/properties/name',
          true,
          this.fields
        );
      }),
      tap((results: DeviceSearchResult) => {
        this.hasMore = !!results?.cursor;

        if (!results.items.length) {
          this._selectedDevicesList.itemList = [];
        }

        this.searchCursor = results?.cursor ?? null;
        this.fillSelectedItemList(results.items);
        this.fillPicklistAvailableItems(results.items);
      }),
      map((results: DeviceSearchResult) =>
        results.items.length ? this.mapSearchResultToLabels(results.items) : null
      ),
      tap((labels) => {
        if (labels) {
          this.deviceLabels = [...new Set(this.deviceLabels.concat(labels))];
          this.cd.markForCheck();
        }
      }),
      catchError((e) => {
        this.cd.markForCheck();
        throw e;
      })
    );
  }

  private initFormControl() {
    this.control = new UntypedFormControl('', [
      () => {
        if (this.ngControl.invalid) {
          return { invalid: true };
        }
        return null;
      }
    ]);
  }

  private mapSearchResultToLabels(devices: Device[]): string[] {
    return devices.map((device: Device) => {
      const label = this.getLabel(device);
      if (!this.devices.some((d) => d.thingId === device.thingId)) {
        this.devices.push(device);
      }

      this.labelsToThingId[label] = device.thingId;
      return label;
    });
  }

  fillPicklistAvailableItems(devices: Device[]) {
    const pickListItems = devices.map((device) => {
      const label = this.getLabel(device);
      return { label, id: device.thingId };
    });

    this.pickListAvailableItems = uniqBy([...this.pickListAvailableItems, ...pickListItems], 'id');
  }

  private fillSelectedItemList(devices: Device[]) {
    if (!this.isSelectionMode) {
      return;
    }
    const itemList: DeviceSearchSelectionList[] =
      this.searchCursor !== null ? [...this._selectedDevicesList.itemList] : [];
    devices.forEach((device: Device) => {
      const label = this.getLabel(device);
      itemList.push({ thingId: device.thingId, label });
    });
    // the itemList does not include the already selectedItems so we need to merge them together
    // using uniqueWith makes sure to avoid duplicated entries
    this._selectedDevicesList.itemList = uniqWith(
      [...this._selectedDevicesList.selectedItems, ...itemList],
      isEqual
    );
  }

  private getSelectionModeFilter(): string {
    let excludeSelectedDevices = this._selectedDevicesList.selectedItems
      .map((item: DeviceSearchSelectionList) => `ne(thingId,"${item.thingId}")`)
      .join(',');
    if (excludeSelectedDevices.length > 0) {
      excludeSelectedDevices = `and(${excludeSelectedDevices})`;
    }
    if (excludeSelectedDevices.length) {
      return excludeSelectedDevices;
    }
    return null;
  }

  removeSelectedItem(device: { label; thingId }) {
    if (!this.isSelectionMode) {
      return;
    }

    this._selectedDevicesList.removeSelectedItem(device);
    if (this.searchCursor && !this.usePickList) {
      this.resetSearchCursor();
      this.rerunSearch();
    }
  }

  removeAllSelectedItems() {
    if (!this.isSelectionMode) {
      return;
    }

    this.resetOrRemoveAllClicked.next('');
    this._selectedDevicesList.removeAllSelectedItems();
  }

  resetItemsToDefault() {
    this.resetOrRemoveAllClicked.next(this.defaultItem.length ? this.defaultItem[0].thingId : '');
    this.selectedDevicesList.selectedItems = this.defaultItem;
  }

  rerunSearch() {
    if (!this.hasMore) {
      return;
    }

    this.loader.run(this.search(this.currentTerm, this.deviceLimit)).subscribe();
    this.displaySelectAllButton$.emit(true);
  }

  getDevice(label: string): Device | undefined {
    return this.devices.find((d) => this.getLabel(d) === label);
  }

  getLabel(device: Device): string {
    return this.valuePath ? this.getValuePathLabel(device) : this.getThingIdLabel(device);
  }

  getThingIdLabel(device: Device): string {
    return device.getName() + ' (' + device.getShortId() + ')';
  }

  resetSearchCursor(): void {
    this.searchCursor = null;
  }

  setDeviceLimit(deviceLimit = 10): void {
    this.deviceLimit = deviceLimit;
  }

  private getValuePathLabel(device: Device) {
    const valuePathParts = this.valuePath.split(/[./]/);
    const value = getValueByPath(device, valuePathParts);
    return device.getName() + ` (${value})`;
  }

  private handleError(e: Error) {
    this.loader.error = e;
    return throwError(() => new Error(e.message));
  }
}
