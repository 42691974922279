export const downloadOptions = {
  charset: [
    { value: 'UTF-8', label: 'UTF-8' },
    { value: 'UTF-16', label: 'UTF-16' },
    { value: 'US-ASCII', label: 'US-ASCII' },
    { value: 'ISO-8859-1', label: 'ISO-8859-1 (ISO-LATIN-1)' }
  ],
  header: [
    { value: 'present', label: 'Present' },
    { value: 'absent', label: 'Absent' }
  ],
  locale: [
    { value: 'en', label: 'English' },
    { value: 'de', label: 'German' }
  ],
  lineBreak: [
    { value: 'CRLF', label: '\\r\\n (CRLF)' },
    { value: 'LFCR', label: '\\n\\r (LFCR)' },
    { value: 'LF', label: '\\n (LF)' },
    { value: 'CR', label: '\\r (CR)' }
  ],
  columnSeparator: [
    { value: 'comma', label: ', (comma)' },
    { value: 'semicolon', label: '; (semicolon)' },
    { value: 'tab', label: '\\t (tab)' }
  ],
  masking: [
    { value: 'false', label: 'No' },
    { value: 'true', label: 'Yes (quotes)' }
  ],
  decimalSeparator: [
    { value: 'dot', label: '. (dot)' },
    { value: 'comma', label: ', (comma)' }
  ],
  dateTimeFormat: [
    { value: 'yyyy-MM-dd HH:mm:ss', label: 'yyyy-MM-dd HH:mm:ss' },
    { value: 'yyyy/MM/dd HH:mm:ss', label: 'yyyy/MM/dd HH:mm:ss' },
    { value: 'MM/dd/yyyy HH:mm:ss', label: 'MM/dd/yyyy HH:mm:ss' },
    { value: 'dd/MM/yyyy HH:mm:ss', label: 'dd/MM/yyyy HH:mm:ss' },
    { value: 'dd.MM.yyyy HH:mm:ss', label: 'dd.MM.yyyy HH:mm:ss' },
    { value: "yyyy-MM-dd'T'HH:mm:ss.SZ", label: 'yyyy-MM-ddTHH:mm:ss.SZ' },
    { value: "yyyy-MM-dd'T'HH:mm:ss.SSSZ", label: 'yyyy-MM-ddTHH:mm:ss.SSSZ' }
  ]
};

export interface CSVDownloadPreset {
  label: string;
  charset: string;
  locale: string;
  header: string;
  lineBreak: string;
  columnSeparator: string;
  masking: string;
  decimalSeparator: string;
  dateTimeFormat: string;
}

export class DownloadParameter implements CSVDownloadPreset {
  label = '';
  charset = downloadOptions.charset[0].value;
  locale = downloadOptions.locale[0].value;
  header = downloadOptions.header[0].value;
  lineBreak = downloadOptions.lineBreak[0].value;
  columnSeparator = downloadOptions.columnSeparator[0].value;
  masking = downloadOptions.masking[0].value;
  decimalSeparator = downloadOptions.decimalSeparator[0].value;
  dateTimeFormat = downloadOptions.dateTimeFormat[0].value;

  static fromObject(params: object): DownloadParameter {
    const param = new DownloadParameter();
    Object.keys(param).forEach((key) => {
      param[key] = params[key];
    });
    return param;
  }

  toAccept() {
    const parts = ['text/csv'];
    parts.push('charset=' + this.charset);
    parts.push('locale=' + this.locale);
    parts.push('header=' + this.header);
    parts.push('lineBreak=' + this.lineBreak);
    parts.push('columnSeparator=' + this.columnSeparator);
    parts.push('masking=' + this.masking);
    parts.push('decimalSeparator=' + this.decimalSeparator);
    parts.push('dateTimeFormat="' + this.dateTimeFormat + '"');
    return parts.join(';');
  }

  equals(params: CSVDownloadPreset) {
    return Object.keys(this)
      .filter((key) => key !== 'label')
      .every((key) => this[key] === params[key]);
  }
}

export const presets: CSVDownloadPreset[] = [
  {
    label: 'Default (RFC 4180)',
    charset: 'ISO-8859-1',
    locale: 'en',
    header: 'present',
    lineBreak: 'CRLF',
    columnSeparator: 'comma',
    masking: 'true',
    decimalSeparator: 'dot',
    dateTimeFormat: 'yyyy-MM-dd HH:mm:ss'
  },
  {
    label: 'Excel DE',
    charset: 'UTF-8',
    locale: 'de',
    header: 'present',
    lineBreak: 'CRLF',
    columnSeparator: 'semicolon',
    masking: 'true',
    decimalSeparator: 'comma',
    dateTimeFormat: 'dd.MM.yyyy HH:mm:ss'
  }
];
