import { DashboardWidgetConfig } from '../../../dashboards/models/DashboardWidgetConfig';
import { Component, Input, OnInit, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import {
  ActionButtonConfiguration,
  RestReqDefinition,
  ActionButtonStyle,
  AZURE_ERROR_CODE
} from '../../rest-request/models/rest-request.model';
import {
  WidgetPlaceholderContext,
  createRandomIdContext
} from '../../data-widgets/data-widgets-common';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { LoadingEntity, PopoverDirective } from '@inst-iot/bosch-angular-ui-components';
import { RestRequestRun, RestRequestService } from '../../rest-request/rest-request.service';
import { HyperlinkService } from '../../data-widgets/data-widgets-common/lib/hyperlink.service';
import {
  actionButtonDefaultLabel,
  checkDisabledCondition,
  createLinkDefinitionContext,
  getTriggerRouteIfAvailable,
  processRestResponse,
  readBlob,
  validateInputReference
} from '../action-config-util';
import { DashboardStateService } from '../../../dashboards/services/dashboard-state.service';
import { DashboardDataService } from '../../../dashboards/services/dashboard-data.service';
import { EMPTY, Observable, mergeMap } from 'rxjs';
import { merge } from 'lodash-es';
@Component({
  selector: 'action-button',
  templateUrl: './action-button.component.html',
  styleUrls: ['../action-buttons.scss']
})
export class ActionButtonComponent implements OnInit {
  responseLoader: LoadingEntity<any> = new LoadingEntity<any>();
  @Input() disabled = false;

  @Input() responsePopoverContent: TemplateRef<any>;

  @Input() actionConfiguration = {} as ActionButtonConfiguration;

  @Input() context: WidgetPlaceholderContext;

  @Input() data: any;

  @Input() dsLoading = false;

  @Input() actionButtonStyling: ActionButtonStyle;

  @ViewChild(PopoverDirective)
  private popoverDirective: PopoverDirective;

  disabledByCondition: boolean;

  get disabledAnyhow(): boolean {
    return this.disabled || this.disabledByCondition;
  }

  constructor(
    private restRequestService: RestRequestService,
    private dashboardStateService: DashboardStateService,
    private dashboardDataService: DashboardDataService,
    private hyperlinkService: HyperlinkService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data || changes.context) {
      this.disabledByCondition = checkDisabledCondition(
        this.actionConfiguration?.disableConditionsChips?.syntaxTree,
        this.data,
        this.context
      );
    }
  }

  ngOnInit() {
    if (!this.actionConfiguration.buttonLabel) {
      this.actionConfiguration.buttonLabel = actionButtonDefaultLabel;
    }

    this.disabledByCondition = checkDisabledCondition(
      this.actionConfiguration?.disableConditionsChips?.syntaxTree,
      this.data,
      this.context
    );
  }

  makeRestCall() {
    if (this.actionConfiguration.restResponse) {
      this.actionConfiguration.restResponse = undefined;
    }

    this.responseLoader
      .run(
        this.hasInputWidgetConfigured()
          ? this.getRestDefinitionAndExecuteRequest()
          : this.executeRestRequestDefinition()
      )
      .subscribe({
        next: (response: HttpResponse<Blob>) => {
          this.onRestResponseLoaded(response, this.actionConfiguration, this.data);
        },
        error: (error) => {
          this.onRestResponseLoaded(error, this.actionConfiguration);
        }
      });
  }

  onRestResponseLoaded(
    response: HttpResponse<any> | HttpErrorResponse,
    action: ActionButtonConfiguration,
    data?: any
  ) {
    const triggerRoute = getTriggerRouteIfAvailable(response, data, action.triggerConfiguration);

    processRestResponse(response, action, data);

    if (triggerRoute !== undefined) {
      this.resolveAndTriggerUrl(response, triggerRoute);
    }

    if (response.status === 440 && response['error'] instanceof Blob) {
      readBlob(response['error']).then((message) => {
        if (message?.includes(AZURE_ERROR_CODE)) {
          this.popoverDirective.closePopOver(true);
        }
      });
    }
  }

  private hasInputWidgetConfigured(): boolean {
    return this.dashboardDataService.currentConfig?.widgets?.some(
      (widget: DashboardWidgetConfig) => widget.type === 'input'
    );
  }

  private executeRestRequestDefinition(): Observable<HttpResponse<any>> {
    return this.restRequestService.executeRestRequestDefinitionWithObserverWithCache(
      this.actionConfiguration.actionConfigId,
      {
        context: merge(this.context, createRandomIdContext()),
        data: this.data
      } as RestRequestRun,
      this.actionConfiguration?.restRequestCacheTime
    );
  }

  private getRestDefinitionAndExecuteRequest(): Observable<HttpResponse<any>> {
    return this.restRequestService
      .getRestRequestDefinition(this.actionConfiguration.actionConfigId)
      .pipe(
        mergeMap((restRequestDef: RestReqDefinition) => {
          if (
            !validateInputReference(
              this.dashboardDataService,
              this.dashboardStateService,
              restRequestDef
            )
          ) {
            if (this.popoverDirective) {
              this.popoverDirective.closePopOver(true);
            }
            return EMPTY;
          }
          return this.executeRestRequestDefinition();
        })
      );
  }

  private resolveAndTriggerUrl(
    response: HttpResponse<any> | HttpErrorResponse,
    matchingTriggerUrl: { url?: string; target: string }
  ) {
    createLinkDefinitionContext(response, this.context, this.data, matchingTriggerUrl).then(
      (linkContext) => {
        const resolvedLink = this.hyperlinkService.resolveLink(
          linkContext.linkDefinition,
          ...linkContext.dataSources
        );
        this.hyperlinkService.openLink(resolvedLink);
      }
    );
  }
}
