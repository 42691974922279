import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AngularEditor } from 'slate-angular';
import {
  TableButton,
  performTableEditAction,
  richTextTableButtons,
  Position
} from '../../slate.util';

@Component({
  selector: 'table-popover',
  templateUrl: 'table-popover.component.html'
})
export class TablePopoverComponent {
  @Input()
  editor: AngularEditor;
  @Output() closePopover = new EventEmitter();

  tableButtons = richTextTableButtons as TableButton[];

  close() {
    this.closePopover.emit();
  }

  tableAction(action: string, position?: Position) {
    performTableEditAction(this.editor, action, position);
  }
}
