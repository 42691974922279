import { Component, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'table-filter-bar',
  templateUrl: './table-filter-bar.component.html'
})
export class TableFilterBarComponent {
  @Input() itemsCount: number;

  @Output() clearFiltersClicked = new EventEmitter();

  clearFilters() {
    this.clearFiltersClicked.emit();
  }
}
