import { Component, Input } from '@angular/core';
import { ClipboardService } from '../../../shared/services/clipboard.service';

@Component({
  selector: 'query-info-dialog',
  templateUrl: './query-info-dialog.component.html',
  providers: [ClipboardService]
})
export class QueryInfoDialogComponent {
  readonly aceOptions = {
    minLines: 10,
    maxLines: 30
  };

  @Input()
  query: any;

  constructor(private clipboardService: ClipboardService) {}

  getJSONQueryStatement(): string {
    return JSON.stringify([{ $match: this.query }], null, 2);
  }

  copyQueryToClipBoard() {
    this.clipboardService.copyToClipboard(this.getJSONQueryStatement());
  }
}
