import { BaseRange, BaseSelection, Descendant, Editor, Path, Transforms } from 'slate';
import { isEqual } from 'lodash-es';

export function selectStartToEnd(editor: Editor, path: Path): BaseRange {
  const pointBefore = Editor.before(editor, path);
  const pointAfter = Editor.next(editor, { at: path })[1];

  return {
    anchor: pointBefore,
    focus: {
      path: pointAfter,
      offset: 0
    }
  };
}

export function getSelectionForImageElement(
  editor: Editor,
  selection: BaseSelection,
  element: any
): BaseRange {
  const [parentNode, parentPath] = Editor.parent(editor, selection.focus?.path);
  const currentNodePath = Editor.node(editor, selection.focus?.path)[1];
  let pathToConsider = currentNodePath;
  if (parentNode && parentNode['type'] === 'image' && parentNode['url'] === element.url) {
    pathToConsider = parentPath;
  }
  return selectStartToEnd(editor, pathToConsider);
}

export function getSelectionOfReference(editor: Editor, selection: BaseSelection): BaseRange {
  const [parentNode, parentPath] = Editor.parent(editor, selection.focus?.path);
  const currentNodePath = Editor.node(editor, selection.focus?.path)[1];
  let pathToConsider = currentNodePath;
  if (parentNode && parentNode['type'] === 'reference') {
    pathToConsider = parentPath;
  }
  return selectStartToEnd(editor, pathToConsider);
}

export function extendSelectionByLength(selection: BaseSelection, length: number) {
  return {
    anchor: {
      ...selection.anchor,
      offset: 0
    },
    focus: {
      ...selection.focus,
      offset: length
    }
  };
}

export function selectLink(editor, path: Path): BaseRange {
  const clone = [...path];
  clone.splice(-1);

  const anchorPath = [...clone];
  const focusPath = [...clone];

  focusPath[focusPath.length - 1] += 1;
  const selection = {
    anchor: { path: anchorPath, offset: 0 },
    focus: { path: focusPath, offset: 0 }
  };
  Transforms.select(editor, selection);
  return selection;
}

export function detectPath(nodes: any[], element: Descendant, index = 0): Path {
  const result: Path = [];

  if (!nodes?.length) {
    return result;
  }
  for (let i = 0; i < nodes.length; i++) {
    if (isEqual(nodes[i], element)) {
      result.push(index, i);
      return result;
    } else {
      const found = detectPath(nodes[i].children, element, i);
      if (found.length) {
        result.push(...found, index);
        return result;
      }
    }
  }
  return result;
}
