import { Editor, Transforms } from 'slate';
import { ImageElement } from '../slate.util';
import imageExtensions from 'image-extensions';
import { RichTextImageDimension } from '../components/image-properties-editor/image-properties-editor.component';

export function withImages(editor) {
  const { insertData, isVoid, isInline } = editor;

  editor.isVoid = (element) => {
    return element.type === 'image' ? true : isVoid(element);
  };

  editor.isInline = (element) => {
    return element.type === 'image' ? true : isInline(element);
  };

  editor.insertData = (data) => {
    const text = data.getData('text/plain');
    const { files } = data;

    if (files && files.length > 0) {
      for (const file of files) {
        getUrlFromImageFile(file, (url) => {
          insertImage(editor, url);
        });
      }
    } else if (isImageUrl(text)) {
      insertImage(editor, text);
    } else {
      insertData(data);
    }
  };

  return editor;
}

function isImageUrl(url) {
  if (!url) {
    return false;
  }
  try {
    const ext = new URL(url).pathname.split('.').pop();
    return imageExtensions.includes(ext);
  } catch (e) {
    return false;
  }
}

export function getUrlFromImageFile(file: File, callBack: (string) => void) {
  const reader = new FileReader();

  if (isImage(file)) {
    reader.addEventListener('load', () => {
      const url = reader.result;
      callBack(url);
    });

    reader.readAsDataURL(file);
  }
}

export function isImage(file: File): boolean {
  const [mime] = file.type.split('/');
  return mime === 'image';
}

/**
 * Inserts or replaces the selection with the provided image url.
 * @param editor
 * @param url
 * @param dimension
 */
export function insertImage(
  editor: Editor,
  url: string | ArrayBuffer,
  dimension?: RichTextImageDimension
) {
  Transforms.insertNodes(editor, getImageNode(url, dimension));
}

/**
 * Constructs a slate image node using the url and the dimension information.
 * @param url
 * @param dimension
 */
export function getImageNode(
  url: string | ArrayBuffer,
  dimension?: RichTextImageDimension
): ImageElement {
  const text = { text: '' };
  const node = { type: 'image', url, children: [text] } as ImageElement;
  if (dimension?.maxHeight) {
    node['maxHeight'] = dimension.maxHeight + 'px';
  }
  if (dimension?.maxWidth) {
    node['maxWidth'] = dimension.maxWidth + 'px';
  }
  return node;
}
