import { Directive, ElementRef, inject, OnDestroy, OnInit, Renderer2 } from '@angular/core';

@Directive({
  selector: '[activateLoadingStatusTooltip]'
})
export class ActivateLoadingStatusTooltipDirective implements OnInit, OnDestroy {
  private host: ElementRef<HTMLElement> = inject(ElementRef);
  private renderer = inject(Renderer2);
  private mouseEnterListenFn: () => void;
  private mouseLeaveListenFn: () => void;

  ngOnInit() {
    const mouseEnterEvent = new Event('mouseenter');
    const mouseLeaveEvent = new Event('mouseleave');
    const containerEl = this.host.nativeElement.parentElement;

    this.mouseEnterListenFn = this.renderer.listen(containerEl, 'mouseenter', () =>
      this.host.nativeElement.dispatchEvent(mouseEnterEvent)
    );
    this.mouseLeaveListenFn = this.renderer.listen(containerEl, 'mouseleave', () =>
      this.host.nativeElement.dispatchEvent(mouseLeaveEvent)
    );
  }

  ngOnDestroy() {
    this.mouseEnterListenFn();
    this.mouseLeaveListenFn();
  }
}
