import { Component, Input } from '@angular/core';
import { Constants } from '../../../../../constants';

@Component({
  selector: 'collection-retention-time-popover',
  templateUrl: './collection-retention-time-popover.component.html'
})
export class CollectionRetentionTimePopoverComponent {
  @Input()
  retentionTimes: number[] = [];

  @Input()
  retentionTimeProperty: string[];

  roles = Constants.roles;

  routing = Constants.routing;
}
