import { atomicTypes, CellType, EntryCell } from '../table-view.model';
import {
  editorToPlainText,
  resolveSlateNodesWithFixedIndex,
  resolveSlateNodesWithIteratorIndex
} from '../../../../shared/slate-richtext/slate.util';
import { sortRbTableData } from '../../../../shared/sort-utils';
import { Sort } from '@inst-iot/bosch-angular-ui-components/molecules/table/sort.directive';
import {
  ColumnDefinitionType,
  CustomColumnDefinition,
  ImageType
} from '../../../../dashboards/widgets/table-view-widget/table-view-widget-edit/table-view-widget.model';
import {
  calculateColor,
  resolveDataFormattingValues
} from '../../../data-conditional-formatting/data-conditional-formatting-utils';
import { ActionButtonConfiguration } from '../../../rest-request/models/rest-request.model';
import {
  DsParameter,
  encodeUriWithModifiers,
  placeholderExp,
  resolveMultipleIteratingPlaceholders,
  resolvePlaceholders,
  resolveStringWithModifiers,
  WidgetContext
} from '../../../data-widgets/data-widgets-common';
import { resolveActionConfiguration } from '../../../action-buttons/action-config-util';
import { getDataPathIterator } from '../../../data-selection/data-info-util';
import {
  ColorType,
  DataFormattingInputType,
  ValueFormattingJsonConfig,
  ValueFormattingResolvedConfig
} from '../../../data-conditional-formatting/data-conditional-formatting.model';
import { HyperlinkService } from '../../../data-widgets/data-widgets-common/lib/hyperlink.service';
import { isArray, isEmpty, isNaN, isNil } from 'lodash-es';
import { DataConditionalFormattingPipe } from '../../../data-conditional-formatting/data-conditional-formatting.pipe';
import { isArrayString } from '../../../../shared/functional-utils';
import { ResolvedEditorElement } from '../../../../shared/slate-richtext/plugins/reference';
import { attachmentUrlToImgResolution } from '../../../../devices/models/device';

export function getSortedEntryCells(
  entries: EntryCell[][],
  columns: string[],
  sort: Sort
): EntryCell[][] {
  if (sort === null) {
    return entries;
  }
  const keyIndex = columns.indexOf(sort.id);
  if (keyIndex === -1) {
    return entries;
  }
  const retrieveCellLabel = (val) => {
    switch (val[keyIndex].type) {
      case 'link': // Sort by Link Label
        return val[keyIndex].value.label;
      case 'button': // Sort By Action Button Label
        return val[keyIndex].value.buttonLabel;
      case 'richText':
        return editorToPlainText(val[keyIndex].value.editor);
      default:
        // Sort by Cell Value
        return val[keyIndex].value;
    }
  };

  const compareFunction = (a, b, direction) => {
    if (isArray(retrieveCellLabel(a)) && isArray(retrieveCellLabel(b))) {
      const minLength = Math.min(retrieveCellLabel(a).length, retrieveCellLabel(b).length);
      for (let i = 0; i < minLength; i++) {
        if (retrieveCellLabel(a)[i].value !== retrieveCellLabel(b)[i].value) {
          return sortTableCell(
            retrieveCellLabel(a)[i].value,
            retrieveCellLabel(b)[i].value,
            direction
          );
        }
      }
    }
    return sortTableCell(retrieveCellLabel(a), retrieveCellLabel(b), direction);
  };

  return sortRbTableData(sort, entries, undefined, compareFunction);
}

export function containsDeviceImageColumn(customColumns: CustomColumnDefinition[]) {
  return customColumns?.find(
    (def) =>
      def.type === ColumnDefinitionType.IMAGE &&
      def.imageSettings.imageType === ImageType.DEVICE_IMAGE
  );
}

function convertToCellInfoEntries(
  columnValues: any[][],
  maxNumberOfRows,
  data,
  context,
  filteredCustomColumnDefinitions
): EntryCell[][] {
  const entries = [];
  for (let r = 0; r < maxNumberOfRows; r++) {
    const entry = [];
    for (let c = 0; c < filteredCustomColumnDefinitions.length; c++) {
      const col = filteredCustomColumnDefinitions[c];
      const value = columnValues[c][r];
      const valueFormattingConfig = resolveDataFormattingValues(
        col['valueFormatting'],
        context,
        data,
        r
      );
      const language = context?.insights?.language;
      const defaultLabel = col?.label ? Object.values(col.label)[0] : '';
      const cell = getCellInfo(
        value,
        [col.label?.[language] || defaultLabel],
        valueFormattingConfig,
        col.label?.en
      );
      if (value?.dataIndex !== undefined) {
        cell.dataIndex = value.dataIndex;
      }

      if (col.fixedHorizontal !== undefined) {
        cell.fixedHorizontal = col.fixedHorizontal;
      }

      if (col.tooltipText) {
        setTooltip({ tooltipText: col.tooltipText, cell, data, rowIndex: r });
      }

      entry.push(cell);
    }
    entries.push(entry);
  }
  return entries;
}

function setTooltip(config: { tooltipText: string; cell: EntryCell; data: any; rowIndex: number }) {
  const { tooltipText, cell, data, rowIndex } = config;

  const match = tooltipText.match(placeholderExp);

  if (!match) {
    cell.tooltip = { text: tooltipText };
    return;
  }

  const resolvedPlaceholder = resolvePlaceholders(
    tooltipText,
    rowIndex,
    resolveStringWithModifiers,
    data
  );

  cell.tooltip = { text: resolvedPlaceholder };

  const resolvedPlaceholders = resolveMultipleIteratingPlaceholders(
    match,
    resolveStringWithModifiers,
    data
  );

  const matchingArrays = getArraysFromPlaceholders(resolvedPlaceholders, rowIndex);

  if (matchingArrays.length && cell.type === 'array') {
    cell.tooltip.array = matchingArrays;
  }
}

function getArraysFromPlaceholders(resolvedPlaceholders: any[], rowIndex: number): any[][] {
  if (!resolvedPlaceholders.length) {
    return [];
  }

  return resolvedPlaceholders[rowIndex].reduce((acc, resolvedPlaceholder) => {
    const value = isArrayString(resolvedPlaceholder)
      ? JSON.parse(resolvedPlaceholder)
      : resolvedPlaceholder;

    if (Array.isArray(value)) {
      acc.push(value);
    }

    return acc;
  }, []);
}

export function getCellInfo(
  value: any,
  path: string[],
  valueFormattingConfig: ValueFormattingResolvedConfig = {} as ValueFormattingResolvedConfig,
  columnName?: string
): EntryCell {
  const type = typeof value;
  let cellType: CellType = atomicTypes.includes(type) ? 'atomic' : 'undefined';
  if (value && value.url !== undefined && !value.imageType) {
    cellType = 'link';
  } else if (value && value.imageType) {
    cellType = 'image';
  } else if (value && value.editor) {
    cellType = 'richText';
  } else if (type === 'object' && value === null) {
    cellType = 'atomic';
  } else if (type === 'object' && value.restRequest) {
    cellType = 'button';
  } else if (type === 'object' && Array.isArray(value)) {
    cellType = 'array';
    value = value.map((v, i) =>
      getCellInfo(v, [...path, i.toString()], valueFormattingConfig, columnName)
    );
  } else if (type === 'object') {
    cellType = 'object';
  }

  return {
    type: cellType,
    path: path,
    value: value,
    valueFormattingConfig,
    backgroundColor: valueFormattingConfig?.backgroundColor
      ? calculateColor(value, valueFormattingConfig)
      : null,
    textColor: valueFormattingConfig?.textColor
      ? calculateColor(value, valueFormattingConfig, ColorType.TEXT_COLOR)
      : null,
    columnName
  };
}

function resolveActionArray(
  data: any,
  action: ActionButtonConfiguration,
  context: WidgetContext
): ActionButtonConfiguration[] {
  const triggerUrls = action.triggerConfiguration?.triggerUrls?.map((trigger) => trigger.url);
  const resolvedPlaceholders = resolveMultipleIteratingPlaceholders(
    [
      action.buttonLabel,
      action.triggerConfiguration?.defaultUrl?.url,
      triggerUrls?.length ? triggerUrls : null
    ],
    [resolveStringWithModifiers, encodeUriWithModifiers, encodeUriWithModifiers],
    context,
    data
  );
  return resolveActionConfiguration(resolvedPlaceholders, action, data);
}

function resolveImageArray(
  data: any[],
  col: CustomColumnDefinition,
  context: WidgetContext,
  projectName: string
) {
  const images = [];
  let devices = [];

  if (col.imageSettings.imageType === ImageType.DEVICE_IMAGE) {
    const basePath = col.pathValue.slice(0, col.pathValue.lastIndexOf(']') + 1);
    devices = [...getDataPathIterator(data, basePath)];
  }
  const resolvedValues =
    col.imageSettings.imageType === ImageType.FROM_URL
      ? resolveMultipleIteratingPlaceholders(
          [col.imageSettings.imageSrc],
          [resolveStringWithModifiers],
          context,
          data
        )
      : [...getDataPathIterator(data, col.pathValue)];

  for (const [index, val] of resolvedValues.entries()) {
    let imageSrc = val;
    if (imageSrc && col.imageSettings.imageType === ImageType.DEVICE_IMAGE) {
      if (imageSrc[0]) {
        imageSrc = attachmentUrlToImgResolution(imageSrc[0].url, 256) || undefined;
      } else {
        imageSrc = undefined;
      }
    } else if (imageSrc && col.imageSettings.imageType === ImageType.FROM_URL) {
      imageSrc = imageSrc[0];
    }

    images.push({
      ...col.imageSettings,
      imageSrc,
      device: devices[index]
    });
  }

  if (!images.length) {
    data.forEach((_, index) => {
      images.push({
        ...col.imageSettings,
        device: devices[index]
      });
    });
  }

  return images;
}

function resolveJsonArray(data: any[], col: CustomColumnDefinition): string[] {
  const valueFormatting = col.valueFormatting as ValueFormattingJsonConfig<DsParameter>;
  const isAutoFormat = valueFormatting.autoFormat;
  const rows = [];

  data.forEach((row) => {
    const isString = typeof row === 'string';
    let value = isString ? row : JSON.stringify(row);
    if (isAutoFormat) {
      try {
        value = value ? JSON.stringify(JSON.parse(value), null, 2) : undefined;
      } catch (error) {
        value = 'Error: ' + error.message;
      }
    }
    rows.push(value);
  });
  return rows;
}

export function resolvePath(
  language: string,
  path: string[],
  customColumnDefinitions: CustomColumnDefinition[],
  showCustomColumns: boolean
): string[] {
  if (path.length <= 0 || !showCustomColumns === true) {
    return ['[i]'].concat(path);
  }
  const resolvedPathObject = customColumnDefinitions.find(
    (item) => item.label[language] || Object.values(item.label)[0] === path[0]
  );
  if (resolvedPathObject) {
    return resolvedPathObject.pathValue?.split('.').concat(path.slice(1));
  }
  return ['[i]'];
}

export function getPaths(path: string[]): Record<string, any>[] {
  const paths = [];
  for (let i = 0; i <= path.length; i++) {
    paths.push({
      path: path.slice(0, i),
      key: i === 0 ? 'Root' : path[i - 1],
      active: i === path.length
    });
  }
  return paths;
}

export function createCustomColumnEntries(
  data: any[],
  customColumnDefinitions: CustomColumnDefinition[],
  context: WidgetContext,
  hyperlinkService: HyperlinkService,
  dataConditionalFormattingPipe: DataConditionalFormattingPipe,
  projectName: string
): EntryCell[][] {
  const col2Link = (col) => ({
    label: col.linkLabel,
    path: col.linkValue,
    target: col.linkTarget
  });
  const colValues: any[][] = [];
  let initialSort = { id: '', direction: '' };
  let maxRows = 0;

  for (const col of customColumnDefinitions) {
    let rows = [];
    if (col.type === ColumnDefinitionType.JSON) {
      if (col?.pathValue?.includes('[i]')) {
        const dataList = [...getDataPathIterator(data, col.pathValue)];
        if (col.valueFormatting?.dataType === DataFormattingInputType.JSON) {
          rows = resolveJsonArray(dataList, col);
        } else {
          rows = dataList;
        }
      } else {
        rows = new Array(data.length);
        rows.fill(
          resolvePlaceholders('${' + col.pathValue + '}', 0, resolveStringWithModifiers, data)
        );
      }
    }
    if (col.type === ColumnDefinitionType.HYPERLINK) {
      rows = hyperlinkService.resolveLinkArray(col2Link(col), context, data);
    }
    if (col.type === ColumnDefinitionType.ACTION) {
      rows = resolveActionArray(data, col.actionConfiguration, context);
      rows.forEach((r) => (r.dataIndex = rows.indexOf(r)));
    }
    if (col.type === ColumnDefinitionType.IMAGE) {
      rows = resolveImageArray(data, col, context, projectName);
    }
    if (col.type === ColumnDefinitionType.RICH_TEXT) {
      rows = resolveSlateNodesWithIteratorIndex(
        col.editor,
        context,
        data,
        dataConditionalFormattingPipe
      );
    }

    if (col?.sort && col?.sort?.direction) {
      initialSort = col.sort;
    }

    maxRows = Math.max(maxRows, rows.length);
    colValues.push(rows);
  }

  // fill up non-iterable columns and rich-text columns
  for (let c = 0; c < customColumnDefinitions.length; c++) {
    const col = customColumnDefinitions[c];
    if (colValues[c].length < maxRows) {
      if (col.type === ColumnDefinitionType.HYPERLINK) {
        colValues[c] = new Array(maxRows);
        colValues[c].fill(hyperlinkService.resolveLink(col2Link(col), context, data));
      }
      // if a different column generates rows due to a nested iterator
      if (col.type === ColumnDefinitionType.RICH_TEXT) {
        const entries: ResolvedEditorElement[] = [];
        for (let index = colValues[c].length; index < maxRows; index++) {
          colValues[c][index] = resolveSlateNodesWithFixedIndex(
            col.editor,
            context,
            data,
            dataConditionalFormattingPipe,
            index
          );
        }
      }
    }
  }

  if (initialSort?.id && initialSort?.direction) {
    return getSortedEntryCells(
      convertToCellInfoEntries(colValues, maxRows, data, context, customColumnDefinitions),
      customColumnDefinitions.map((col) => Object.values(col.label)[0]), // use default label, otherwise initial sorting won't work if language != defaultLanguage
      initialSort as Sort
    );
  }

  return convertToCellInfoEntries(colValues, maxRows, data, context, customColumnDefinitions);
}

function sortTableCell(a, b, direction) {
  const sortDirection = direction === 'desc' ? -1 : 1;

  if (isNil(a) || (!a && isNaN(a)) || a === '' || (isArray(a) && isEmpty(a))) {
    return 1;
  } else if (isNil(b) || (!b && isNaN(b)) || b === '' || (isArray(b) && isEmpty(b))) {
    return -1;
  } else if (a > b) {
    return sortDirection;
  } else if (a < b) {
    return sortDirection * -1;
  } else {
    return 0;
  }
}
