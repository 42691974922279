<rb-dialog
  [dialogTitle]="'simpleSearch.showQueryHeader' | translate"
  [confirmationButtonLabel]="'simpleSearch.queryCopyButton' | translate"
  [confirmationButtonAction]="copyQueryToClipBoard.bind(this)"
  [cancelButtonLabel]="'simpleSearch.cancel' | translate"
>
  <json-editor
    [text]="getJSONQueryStatement()"
    [readOnly]="true"
    [options]="aceOptions"
  ></json-editor>
</rb-dialog>
