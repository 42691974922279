import { Directive, ElementRef, inject, Input, OnInit, Renderer2 } from '@angular/core';
import { ValueFormattingResolvedConfig } from '../../../data-conditional-formatting/data-conditional-formatting.model';
import { BackgroundColorTarget } from '../../../../dashboards/widgets/table-view-widget/table-view-widget-edit/table-view-widget.model';

export type ChipConfig = {
  backgroundColor: string;
  valueFormatting: ValueFormattingResolvedConfig;
};

@Directive({
  selector: '[chip]'
})
export class ChipDirective implements OnInit {
  @Input() chip: ChipConfig;

  private renderer = inject(Renderer2);
  private el: ElementRef<HTMLElement> = inject(ElementRef);

  ngOnInit() {
    const { backgroundColor, valueFormatting } = this.chip;

    const isTargetChip =
      valueFormatting?.backgroundColor?.['target'] === BackgroundColorTarget.CHIP;

    if (isTargetChip && backgroundColor) {
      this.renderer.setStyle(this.el.nativeElement, 'background-color', backgroundColor);
      ['text-background-rounded', 'with-padding-y', 'd-inline-block'].forEach((cssClass) =>
        this.renderer.addClass(this.el.nativeElement, cssClass)
      );
    }
  }
}
