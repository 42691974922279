import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { PathInfo } from '../data-info-util';
import { NgForm } from '@angular/forms';
import { cloneDeep } from 'lodash-es';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { TooltipRow } from '../../dynamic-charts/lib/tooltips/tooltips';

@Component({
  selector: 'data-tooltip-selector',
  templateUrl: './data-tooltip-selector.component.html'
})
export class DataTooltipSelectorComponent implements OnInit {
  tooltips: TooltipRow[];

  @ViewChild('tooltipRowForm', { static: true }) tooltipRowForm: NgForm;

  @Input() paths: PathInfo[];

  @Input() tooltipConfig: TooltipRow[];

  @Input() showEnableTooltipHint = false;

  @Output()
  tooltipChange = new EventEmitter<TooltipRow[]>();

  ngOnInit() {
    this.tooltips = this.tooltipConfig || [];
  }

  addTooltipRow() {
    this.tooltips.push({ type: 'json' });
    this.notifyChange();
  }

  removeTooltipRow(tooltipRow: TooltipRow) {
    const index = this.tooltips.indexOf(tooltipRow);
    if (index !== -1) {
      this.tooltips.splice(index, 1);
    }
    this.notifyChange();
  }

  notifyChange() {
    const cleanedUpTooltipRows = this.cleanUpTooltipRows();
    this.tooltipChange.emit(cleanedUpTooltipRows);
  }

  private cleanUpTooltipRows(): TooltipRow[] {
    const clonedTooltips = cloneDeep(this.tooltips);
    if (clonedTooltips.length === 0) {
      return null;
    }
    return clonedTooltips.map((item) => {
      if (item.type === 'json') {
        delete item.linkTarget;
        delete item.text;
      }
      if (item.type === 'hyperlink') {
        delete item.text;
      }
      if (item.type === 'text') {
        delete item.label;
        delete item.linkTarget;
        delete item.path;
      }
      return item;
    });
  }

  updatePathValue(newPath: string, tooltipRow) {
    tooltipRow.path = newPath;
    this.notifyChange();
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.tooltips, event.previousIndex, event.currentIndex);
    this.notifyChange();
  }
}
