<ng-container *ngIf="linkOptions">
  <ng-container *ngIf="!linkOptions.isCopyToClipboard; else clipboard">
    <div
      [rbTooltip]="disabledByCondition ? disabledByConditionTooltip : null"
      [noFix]="false"
      [hideTooltipIfContentIsFalsey]="true"
    >
      <a
        [ngClass]="customCssClasses"
        *ngIf="!linkOptions.isInternal"
        [href]="linkOptions.targetUrl"
        [ngStyle]="actionButtonStyling"
        [class.disabled]="disabledAnyhow"
        [class.mb-1]="!isGridLayout"
        [target]="linkOptions.target"
        data-cy="actionLinkButton"
        rel="noopener noreferrer"
        unsafeUrlHandler
        (click)="linkClicked($event); linkOptions.callback($event)"
      >
        <ng-container *ngTemplateOutlet="linkContentTpl"></ng-container>
      </a>
      <a
        [ngClass]="customCssClasses"
        [class.disabled]="disabledAnyhow"
        [ngStyle]="actionButtonStyling"
        [class.mb-1]="!isGridLayout"
        *ngIf="linkOptions.isInternal && !linkOptions.hasQueryParams"
        [routerLink]="linkOptions.internalUrl"
        (click)="linkClicked($event)"
        data-cy="actionLinkButton"
      >
        <ng-container *ngTemplateOutlet="linkContentTpl"></ng-container>
      </a>
      <a
        [ngClass]="customCssClasses"
        [ngStyle]="actionButtonStyling"
        [class.disabled]="disabledAnyhow"
        [class.mb-1]="!isGridLayout"
        *ngIf="linkOptions.isInternal && linkOptions.hasQueryParams"
        [routerLink]="linkOptions.urlWithoutQueryParams"
        [queryParams]="linkOptions.queryParams"
        (click)="linkClicked($event)"
        data-cy="actionLinkButton"
      >
        <ng-container *ngTemplateOutlet="linkContentTpl"></ng-container>
      </a>
    </div>
  </ng-container>
</ng-container>

<ng-template #clipboard>
  <div
    [rbTooltip]="disabledByCondition ? disabledByConditionTooltip : null"
    [noFix]="false"
    [hideTooltipIfContentIsFalsey]="true"
  >
    <a
      [ngClass]="customCssClasses"
      [ngStyle]="actionButtonStyling"
      [class.disabled]="disabledAnyhow"
      [class.mb-1]="!isGridLayout"
      href="#"
      data-cy="actionLinkButton"
      (click)="linkClicked($event); linkOptions.callback($event)"
      [rbPopover]="'copied' | translate"
      [position]="'top'"
      [closePopoverAfter]="closePopoverAfterMs"
    >
      <ng-container *ngTemplateOutlet="linkContentTpl"></ng-container>
    </a>
  </div>
</ng-template>

<ng-template #linkContentTpl>
  <ng-container *ngIf="!linkContent; else linkContent">
    {{ linkOptions.label }}
  </ng-container>
</ng-template>

<ng-template #disabledByConditionTooltip>
  <div class="p-1 mw-500">
    {{
      actionConfiguration?.disableConditionsChips?.chips?.length === 1
        ? ('widgets.actionButton.disabledMessage.disabledByCondition'
          | translate
            : {
                property: actionConfiguration.disableConditionsChips.chips[0].input,
                value: actionConfiguration.disableConditionsChips.chips[0].value
              })
        : ('widgets.actionButton.disabledMessage.disabledByConditionGeneric' | translate)
    }}
  </div>
</ng-template>
