// code snippets copied from https://github.com/worktile/slate-angular/blob/bb237a8243aec0a4f1386b131cc5726b008aa7a4/demo/app/inlines/inlines.component.ts
// and https://github.com/ianstormtaylor/slate/blob/main/site/examples/inlines.tsx

import { Editor, Element, Range, Transforms } from 'slate';
import { EditorElement, isBlockActive, isElement, LinkElement } from '../slate.util';
import { AngularEditor } from 'slate-angular';
import { Constants } from '../../../../constants';
import { AbstractPlaceholderResolver } from '../placeholder-resolver';
import { CustomText } from './reference';

export class HyperLinkInfo {
  tooltip?: string;
  url: string;
  target?: string;
  urlText?: string;
  constructor() {
    this.tooltip = '';
    this.target = 'blank';
    this.url = '';
    this.urlText = '';
  }
}

export function withInlines(editor: AngularEditor) {
  const { insertData, insertText, isInline } = editor;

  editor.isInline = (element: EditorElement) => {
    return 'link' === element.type || isInline(element);
  };

  editor.insertText = (text) => {
    if (text && isUrl(text)) {
      wrapLink(editor, text);
    } else {
      insertText(text);
    }
  };

  editor.insertData = (data) => {
    const text = data.getData('text/plain');

    if (text && isUrl(text)) {
      wrapLink(editor, text);
    } else {
      insertData(data);
    }
  };

  return editor;
}

function isUrl(url: string): boolean {
  return url ? Constants.validation.urlRegEx.test(url) : false;
}

export function setLink(editor: Editor, hyperlink: HyperLinkInfo) {
  if (editor.selection) {
    wrapLink(editor, hyperlink.url, hyperlink.target, hyperlink.tooltip);
  }
}

export function wrapLink(editor: Editor, url: string, target = 'blank', tooltip = '') {
  const { isVoid } = editor;
  editor.isVoid = (element) => {
    return element['type'] === 'image' ? true : isVoid(element);
  };

  if (isBlockActive(editor, 'link')) {
    unwrapLink(editor);
  }

  const { selection } = editor;
  const isCollapsed = selection && Range.isCollapsed(selection);
  const link: LinkElement = {
    tooltip,
    type: 'link',
    url,
    target,
    children: isCollapsed ? [{ text: url }] : []
  };

  const [parentNode] = Editor.parent(editor, selection.focus?.path);
  if (isCollapsed && !editor.isVoid(parentNode)) {
    Transforms.insertNodes(editor, link);
  } else {
    Transforms.wrapNodes(editor, link, { split: true });
    Transforms.collapse(editor, { edge: 'end' });
  }
}

export function unwrapLink(editor: Editor) {
  Transforms.unwrapNodes(editor, {
    match: (n: EditorElement) => !Editor.isEditor(n) && isElement(n) && n.type === 'link'
  });
}

export class LinkResolver extends AbstractPlaceholderResolver {
  extractEditorValues(
    nodes: Element[] | CustomText[],
    values: Record<string, string>[] = []
  ): Record<string, string>[] {
    nodes.forEach((node) => {
      const isLink = node.type === 'link';
      if (isLink) {
        values.push({ url: node.url, text: node.children[0].text });
      }
      if (node.children) {
        this.extractEditorValues(node.children, values);
      }
    });

    return values;
  }

  replaceValues(
    resolvedValues: Record<string, string>[] = [],
    nodes: Element[] | CustomText[]
  ): EditorElement[] {
    nodes.forEach((node, index) => {
      const isLink = node.type === 'link';

      if (isLink) {
        const { url, text } = resolvedValues.shift();
        nodes[index] = { ...node, url, ...(node.markTypes || {}) };
        nodes[index]['children'][0].text = text;
      }

      if (node.children) {
        this.replaceValues(resolvedValues, node.children);
      }
    });

    return nodes as EditorElement[];
  }
}
