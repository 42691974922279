import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PathInfo } from '../../../../shared-modules/data-selection/data-info-util';
import { ValueFormattingBaseConfig } from '../../../../shared-modules/data-conditional-formatting/data-conditional-formatting.model';
import { DsParameter } from '../../../../shared-modules/data-widgets/data-widgets-common';
import { FilterParameterConfig } from '../../../../dashboards/widgets/filter-widget/filter-widget.model';
import { DashboardDataService } from '../../../../dashboards/services/dashboard-data.service';
import { FieldConfig } from '../../../../dashboards/widgets/input-widget/models/input.model';

@Component({
  selector: 'reference-configuration',
  templateUrl: 'reference-configuration.component.html'
})
export class ReferenceConfigurationComponent implements OnInit {
  @Input() paths: PathInfo[];

  @Input() path: string;

  @Input() valueFormatting: ValueFormattingBaseConfig<DsParameter>;

  @Input() tooltip: string;

  @Output() cancelled = new EventEmitter();

  @Output() valueChange = new EventEmitter<{
    path: string;
    valueFormatting: ValueFormattingBaseConfig<DsParameter>;
    tooltip: string;
  }>();

  value = {
    path: '',
    valueFormatting: null,
    tooltip: ''
  };

  filterParameters: FilterParameterConfig[];
  inputParameters: FieldConfig[];

  constructor(private dashboardService: DashboardDataService) {}

  ngOnInit() {
    this.filterParameters = this.getAvailableParameters();
    this.inputParameters = this.dashboardService.getInputWidgetDefinitions();

    this.value = {
      path: this.path,
      valueFormatting: this.valueFormatting,
      tooltip: this.tooltip
    };
  }

  changeValue() {
    this.valueChange.emit(this.value);
  }

  private getAvailableParameters(): FilterParameterConfig[] {
    if (!this.dashboardService) {
      return [];
    }
    return this.dashboardService.getFilterParameter();
  }
}
