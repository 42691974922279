import {
  createComponent,
  Directive,
  EnvironmentInjector,
  EventEmitter,
  HostListener,
  Injector,
  Input,
  Output
} from '@angular/core';
import { DownloadDialogComponent } from './download-dialog.component';
import { downloadOptions, DownloadParameter } from './csv-download-model';
import { DialogCloseEventWithResult, ModalService } from '@inst-iot/bosch-angular-ui-components';

@Directive({
  selector: '[downloadDialog]'
})
export class DownloadDialogDirective {
  @Input() downloadOptions = downloadOptions;
  @Output() downloadDialog = new EventEmitter<DownloadParameter>();

  constructor(
    private modalService: ModalService,
    private injector: Injector,
    private environmentInjector: EnvironmentInjector
  ) {}

  @HostListener('click') open() {
    const componentRef = createComponent(DownloadDialogComponent, {
      environmentInjector: this.environmentInjector,
      elementInjector: this.injector
    });
    componentRef.instance.options = this.downloadOptions;
    this.modalService.open<DialogCloseEventWithResult>(componentRef).then((result) => {
      if (result.event === 'confirmed') {
        this.downloadDialog.next(componentRef.instance.parameter);
      }
    });
  }
}
