import { Directive, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { processTableCellSelection } from '../table.util';
import { Editor } from 'slate';
import { removeSelection } from '../plugins/table';

@Directive({
  selector: '[tableMouseEvents]'
})
export class TableMouseEventsDirective implements OnInit {
  @Input() editor: Editor;
  @Output() tableElementKeyChange = new EventEmitter<string>();

  tableElementKey: string;

  ngOnInit() {
    // prevent showing of selection when you initially open the editor
    removeSelection(this.editor);
  }

  @HostListener('mousedown', ['$event'])
  onMouseDown(event: MouseEvent) {
    const cell = (event.target as HTMLBaseElement).closest('td');
    const key = cell?.getAttribute('data-key') || '';
    this.tableElementKey = key;
    if (event.buttons !== 2) {
      processTableCellSelection(event, this.editor, this.tableElementKey);
    }
    this.tableElementKeyChange.emit(this.tableElementKey);
  }

  @HostListener('mouseup', ['$event'])
  onMouseUp() {
    this.tableElementKey = '';
    this.tableElementKeyChange.emit(this.tableElementKey);
  }

  @HostListener('mouseleave', ['$event'])
  onMouseLeave() {
    this.tableElementKey = '';
    this.tableElementKeyChange.emit(this.tableElementKey);
    removeSelection(this.editor);
  }

  @HostListener('mousemove', ['$event'])
  onMouseMove(event: MouseEvent) {
    processTableCellSelection(event, this.editor, this.tableElementKey);
  }
}
