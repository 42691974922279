<h3 class="mb-3">
  <ng-container *ngIf="type === 'custom-widget'; else defaultLabel">
    {{
      (customWidgetsComponentName | customWidgetsDisplayName | async) || typeDefinition?.label
        | translated
    }}
  </ng-container>
  <ng-template #defaultLabel>
    {{ typeDefinition?.label | translated }}
  </ng-template>
</h3>
<p class="mb-5">
  {{ typeDefinition?.description | translated }}
</p>
<form action="#" style="min-width: 400px" #form="ngForm">
  <div class="row">
    <div [ngClass]="{ 'col-lg-6 col-12': previewTpl, 'col-12': !previewTpl }">
      <widget-settings-item
        [valid]="form.valid"
        label="{{ 'widget.dashboard.label' | translate }}"
        hint="{{
          !dashboardService.isGridLayout()
            ? ('widget.dashboard.hint' | translate)
            : ('widget.dashboard.gridHint' | translate)
        }}
        "
        data-cy="widgetEditDashboardSettingsItem"
      >
        <rb-form-select
          *ngIf="!dashboardService.isGridLayout()"
          label="{{ 'widget.dashboard.size' | translate }}"
          [(ngModel)]="size"
          name="size"
        >
          <option value="full">{{ 'widget.dashboard.full' | translate }}</option>
          <option value="two-thirds">{{ 'widget.dashboard.twoThirds' | translate }}</option>
          <option value="half">{{ 'widget.dashboard.half' | translate }}</option>
          <option value="third">{{ 'widget.dashboard.third' | translate }}</option>
          <option value="quarter">{{ 'widget.dashboard.quarter' | translate }}</option>
        </rb-form-select>

        <widget-visibility-selector
          [(selectedRoles)]="selectedRoles"
          [(visibility)]="visibility"
        ></widget-visibility-selector>
        <ng-container *ngIf="dashboardService.isGridLayout()">
          <span
            class="hint-label"
            [innerHTML]="'widget.dashboard.layoutBehaviorHint' | translate"
          ></span>
          <rb-form-select
            [label]="'widget.dashboard.layoutBehavior' | translate"
            [(ngModel)]="layoutBehavior"
            data-cy="widgetEditLayoutBehavior"
            name="layoutBehavior"
          >
            <option [value]="layoutBehaviorEnum.Fixed">
              {{ 'widget.dashboard.fixed' | translate }}
            </option>
            <option [value]="layoutBehaviorEnum.Adapt">
              {{ 'widget.dashboard.adapt' | translate }}
            </option>
          </rb-form-select>
        </ng-container>

        <ellipsis-text>
          {{ 'widget.dashboard.titlePlaceholder' | translate }}
        </ellipsis-text>
        <translated-text-input
          class="flex-grow-1"
          label="{{ 'widget.dashboard.title' | translate }}"
          [(ngModel)]="title"
          name="title"
          data-cy="widgetEditTitleInput"
        ></translated-text-input>
      </widget-settings-item>

      <div #widgetEditTarget class="mb-5"></div>
    </div>
    <div class="col-lg-6 col-12" *ngIf="previewTpl">
      <div class="sticky-top" [ngStyle]="{ top: '50px' }">
        <ng-container *ngTemplateOutlet="previewTpl"></ng-container>
      </div>
    </div>
  </div>

  <div *ngIf="loadingEntity.loading || loadingEntity.error" style="min-height: 100px">
    <div *rbLoading="loadingEntity"></div>
  </div>

  <div
    class="text-right mt-4"
    [ngClass]="{ 'col-sm-12 col-md-6 col-lg-6': previewTpl, 'col-12': !previewTpl }"
  >
    <button
      type="button"
      class="rb-btn mr-2 mb-2"
      data-cy="cancelWidgetEditButton"
      *ngIf="isOnDashboard"
      (click)="navigateBackWithFilterParametersIfExist()"
    >
      {{ 'widget.buttons.cancel' | translate }}
    </button>

    <button
      type="button"
      class="rb-btn rb-danger mr-2 mb-2"
      *ngIf="id"
      data-cy="removeWidgetButton"
      [confirmation]="'confirmation.remove' | translate"
      (confirmed)="remove()"
    >
      {{ 'widget.buttons.remove' | translate }}
    </button>

    <button
      type="button"
      class="rb-btn rb-primary mr-2 mb-2"
      *ngIf="id"
      data-cy="saveWidgetButton"
      [disabled]="!isValid || loadingEntity.loading"
      (click)="save(true)"
    >
      {{ 'widget.buttons.save' | translate }}
    </button>

    <button
      type="button"
      class="rb-btn rb-primary mr-2 mb-2"
      data-cy="addWidgetButton"
      *ngIf="!id && isOnDashboard"
      (click)="save()"
      [disabled]="!isValid || loadingEntity.loading"
    >
      {{ 'widget.buttons.add' | translate }}
    </button>

    <button
      type="submit"
      class="rb-btn rb-primary mr-2 mb-2"
      *ngIf="!id && !isOnDashboard"
      (click)="save()"
      [disabled]="!isValid || loadingEntity.loading"
    >
      {{ 'widget.dashboard.addToDashboardButton' | translate }}
    </button>
  </div>
</form>
